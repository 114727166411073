import React from 'react';
import styles from './footer.module.scss';
import FooterLink from "../links/FooterLink";
import { useTranslation } from 'react-i18next';

interface IProps {
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
}

const Footer: React.FC<IProps> = () => {
    const { t } = useTranslation();

    return (<footer className={styles.footer}>
        <div className={styles.links}>
            <FooterLink>{t('FAQs')}</FooterLink>
            <FooterLink>{t('T&Cs')}</FooterLink>
            <FooterLink>{t('Privacy Policy')}</FooterLink>
        </div>
    </footer>);
};

export default Footer;