import { GetCurrenciesAction } from './../actions/CurrencyAction';
import { Container } from 'inversify';
import { getContext, apply, put } from '@redux-saga/core/effects';
import ICurrencyService from './../../domain/services/ICurrencyService';
import CurrencyService from './../../domain/services/CurrencyService';
import ActionTypes from './../actions/ActionTypes';

export function* getCurrencies(action: GetCurrenciesAction) {
    const container: Container = yield getContext('container');
    const currencyApi = container.get<ICurrencyService>(CurrencyService);
    try {
        const response = yield apply(currencyApi, currencyApi.getCurrencies, []);
        yield put({ type: ActionTypes.GET_CURRENCIES_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CURRENCIES_ERROR, payload: err });
    }
}