import React from 'react';
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IPackage from '../../types/IPackage';
import ICarProps from '../../types/ICarProps';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Grid, LinearProgress, Box } from '@material-ui/core';
import PackageCard from '../packageSelection/PackageCard';
import MainPackageCard from '../packageSelection/MainPackageCard';
import styles from './upgradeWarrantyPackageLayout.module.scss';
import IPackageSelection from '../../types/IPackageSelection';
import ISelectedPackage from '../../types/ISelectedPackage';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';

interface IProps {
  packages?: IPackageSelection,
  car?: ICarProps,
  currentPackage?: number,
  currentExtraPackages?: IPackage[],
  findAvailablePackages: () => void,
  packageChanged: (id: number) => void,
  extraPackageChanged: (id: number) => void,
  handleUpgrade: () => void,
  getCarProblemExtraPackage?: (car: ICarProps) => void,
  carProblemExtraPackages?: { data: ICarProblemExtraPackage[] }
}

export const UpgradeWarrantyPackageLayout: React.FC<IProps> = (props: IProps) => {
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    props.findAvailablePackages();
  };

  const handleClose = () => {
    setOpen(false);
  };

  function PackageSelected(id: number) {
    props.packageChanged(id)
  }

  function ExtraPackagesSelected(id: number) {
    props.extraPackageChanged(id);
}

  return (
    <div style={{display: 'inline', marginLeft: '5px'}}>
      <IconButton aria-label="delete" size="small" onClick={handleClickOpen} style={{ margin: -2 }}>
        <AddIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.Root}
      >
        <DialogTitle id="alert-dialog-title">{"Nadogradnja jamstva"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Odabrani paket jamstva: <br/>
            <Grid item container direction="row" spacing={3} style={{marginTop: 20, textAlign: 'center'}}>
              {props.packages && props.packages.packages.length > 0 ? props.packages.packages.map((item, index) =>
                <Grid key={index} item sm={12} md={12} lg={12} >
                  <MainPackageCard PackageSelected={PackageSelected} selected={item.packageId === props.currentPackage!}
                    {...item} disabled={false} />
                </Grid>
              ) : <div/>}
              {!props.packages ? <LinearProgress style={{ flexGrow: 1 }} /> : <div/>}
              {props.packages && props.packages.packages.length === 0 ? <span>Ovaj auto nema mogućnost nadogradnje jamstva.</span> : <div/>}

              {props.packages!.extraPackages && props.packages!.extraPackages.length > 0 ?
                <h4 style={{ width: '100%', display: 'block' }}>Sami kreirajte svoje pravo na produženo auto jamstvo tako da odaberete jedan ili više dodataka</h4>
                :
                <h4 style={{ width: '100%', display: 'block' }}>Ovaj automobil nema opciju dodavanja dodataka.</h4>
            }
            {props.packages!.extraPackages && props.packages!.extraPackages.length > 0 ? props.packages!.extraPackages.map((item: IPackage, index: number) =>
                <Grid key={index} item sm={12} md={6} lg={6} >
                    <PackageCard PackageSelected={ExtraPackagesSelected} selected={!!props.currentExtraPackages!.find(obj => obj.id == item.id)}
                        {...item} disabled={false || !!(props.carProblemExtraPackages && props.carProblemExtraPackages.data.find(obj => obj.extraPackageId == item.id))} />
                </Grid>
            ) : <LinearProgress style={{ flexGrow: 1 }} />}


            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Odustani
          </Button>
          <Button onClick={props.handleUpgrade} variant="contained" color="primary">
            Nadogradi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}