enum WarrantyStatus {
  Approved = "Approved",
  Declined = "Declined",
  InProcess = "InProcess",
  Active = "Active",
  Inactive = "Inactive",
  Submitted = "Submitted",
  Archive = "Archive"
}

export default WarrantyStatus;
