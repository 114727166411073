import { Reducer } from "redux";
import ActionTypes from './../actions/ActionTypes';
import IAdminAttrs from "./../../types/IAdminAttrs"

const initialAdminTableData: { loading: boolean, data: IAdminAttrs[] } = { loading: false, data: new Array<IAdminAttrs>() }

export const adminTableReducer: Reducer<{ loading: boolean, data: IAdminAttrs[] }> = (state = initialAdminTableData, action) => {

    switch (action.type) {
        case ActionTypes.GET_ADMIN_TABLE_DATA:  return { ...state, loading: true, data: [] }
        case ActionTypes.GET_ADMIN_TABLE_DATA_SUCCESS:  return { ...state, loading: false, data: action.payload }
        case ActionTypes.GET_ADMIN_TABLE_DATA_ERROR: return { ...state, loading: false, errors: action.payload, data: [] }
        case ActionTypes.GET_WARRANTIES_FOR_BUYER: return {...state, loading: true, data: []}
        case ActionTypes.GET_WARRANTIES_FOR_BUYER_SUCCESS: return { ...state, loading: false, data: action.payload }
        case ActionTypes.GET_WARRANTIES_FOR_BUYER_ERROR: return { ...state, loading: false, errors: action.payload, data: [] }
        case ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA: return { ...state, loading: true, data: [] }
        case ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA_SUCCESS: return { ...state, loading: false, data: action.payload }
        case ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA_ERROR: return { ...state, loading: false, errors: action.payload, data: [] }
        case ActionTypes.GET_WARRANTIES_FOR_SELLER: return { ...state, loading: true, data: [] }
        case ActionTypes.GET_WARRANTIES_FOR_SELLER_SUCCESS: return { ...state, loading: false, data: action.payload }
        case ActionTypes.GET_WARRANTIES_FOR_SELLER_ERROR: return { ...state, loading: false, errors: action.payload, data: [] }

        default: return state;
    }
}

const initialAdminArchiveTableData: { loading: boolean, data: IAdminAttrs[] } = { loading: false, data: new Array<IAdminAttrs>() }

export const adminArchiveTableReducer: Reducer<{ loading: boolean, data: IAdminAttrs[] }> = (state = initialAdminArchiveTableData, action) => {

    switch (action.type) {
        case ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA: return { ...state, loading: true, data: [] }
        case ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA_SUCCESS: return { ...state, loading: false, data: action.payload }
        case ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA_ERROR: return { ...state, loading: false, errors: action.payload, data: [] }

        default: return state;
    }
}



