import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import IDamageCarTable from '../../types/Damage/IDamageCarTable';
import DamagesCarTable from '../../components/tables/DamagesCarTable';
import { GetDamagesCarAction, GetCarNameAction } from '../../redux/actions/DamageAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    damages: IDamageCarTable[],
    carName: string
}

interface IDispatchToProps {
    getDamages: (carId: number) => void,
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => void,
    getCarName: (id: number) => void
}

interface IProps extends IStateToProps, IDispatchToProps {
    match: {
        params: {
            id: number
        }
    }
}

const DamageCarOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getDamages(props.match.params.id);
        props.getCarName(props.match.params.id);
    }, []);

    return (props.damages ? <DamagesCarTable rows={props.damages} goToDamagePage={props.goToDamagePage} carName={props.carName} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damages: state.damages.data.car,
    carName: state.carName.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getDamages: (carId: number) => dispatch<GetDamagesCarAction>({
        type: ActionTypes.GET_DAMAGES_CAR,
        payload: { carId }
    }),
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: `/damage/${carId}/${warrantyId}/${damageId}` }
    }),
    getCarName: (id: number) => dispatch<GetCarNameAction>({
        type: ActionTypes.GET_CAR_NAME,
        payload: { id }
    }),

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(DamageCarOverview);