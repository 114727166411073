import { injectable, inject } from 'inversify';
import { AxiosInstance } from 'axios';
import ThirdPartyTypes from './../../di/ThirdPartyTypes';
import { adminTableDataURL, adminArchiveTableDataURL } from '../../constants/apiRoutes';
import IAdminAttrs from '../../types/IAdminAttrs';
import IAdminTableServices from './IAdminTableService';

@injectable()
export default class AdminTableService implements IAdminTableServices {

    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;
    
    public async getAdminTableData(page: number, pageSize: number, filters: string): Promise<IAdminAttrs[]> {
        const response = await this.apiClient.get<IAdminAttrs[]>(adminTableDataURL + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters)
        return response.data;
    }

    public async getWarrantyAdminTableTotalCount(): Promise<number> {
        const response = await this.apiClient.get(adminTableDataURL + '/warrantyAdminTableTotalCount');
        return response.data;
    }

    public async getAdminTableForBuyerData(email: string): Promise<IAdminAttrs[]> {
        const response = await this.apiClient.get<IAdminAttrs[]>(adminTableDataURL + '/GetAllForBuyer/' + email)
        return response.data;
    }

    public async getWarrantyAdminBuyerTableTotalCount(email: string): Promise<number> {
        const response = await this.apiClient.get(adminTableDataURL + '/warrantyAdminBuyerTableTotalCount/' + email);
        return response.data;
    }

    public async getAdminArchiveTableData(page: number, pageSize: number, filters: string): Promise<IAdminAttrs[]> {
        const response = await this.apiClient.get<IAdminAttrs[]>(adminArchiveTableDataURL + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters)
        return response.data;
    }

    public async getWarrantyAdminArchiveTableTotalCount(): Promise<number> {
        const response = await this.apiClient.get(adminTableDataURL + '/warrantyAdminArchiveTableTotalCount');
        return response.data;
    }

    public async getAdminTableForSellerData(id: string): Promise<IAdminAttrs[]> {
        const response = await this.apiClient.get<IAdminAttrs[]>(adminTableDataURL + '/GetAllForSeller/' + id)
        return response.data;
    }

    public async getWarrantyAdminSellerTableTotalCount(id: string): Promise<number> {
        const response = await this.apiClient.get(adminTableDataURL + '/warrantyAdminSellerTableTotalCount/' + id);
        return response.data;
    }
    
}