import React, { useEffect } from 'react';
import WarrantyStepper from '../../components/stepper/Stepper';
import ICarProps from '../../types/ICarProps';
import { Dispatch } from 'redux';
import { PostCarAction, GetCarDataAction, GetModelsByBrandAction, ReviewCar, CurrentCarReview, GetCarProblemExtraPackageAction } from '../../redux/actions/CarAction';
import ActionTypes from '../../redux/actions/ActionTypes';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import ISelectedPackage from '../../types/ISelectedPackage';
import IWarrantyData from '../../types/IWarranty';
import { SaveWarrantyAction, DeleteWarrantyAction, UpdateWarrantyStatusAction, GetWarrantyAction, ChangeValidDateAction, SendWarrantyEmailAction } from '../../redux/actions/WarrantyAction';
import { SaveSelectedPackageAction, GetPackagesAction } from '../../redux/actions/PackagesAction';
import ICarData from '../../types/ICarData';
import { GetWarrantyStatusesDataAction } from './../../redux/actions/WarrantyAction';
import IWarrantyStatus from '../../types/IStatus';
import IWarranty from '../../types/IWarranty';
import IUrl from './../../types/IUrl';
import IPhotoUploadProps from '../../types/IPhotoUploadProps';
import { SaveCarPhotosAction } from './../../redux/actions/CarPhotosAction';
import GoToPageAction from './../../redux/actions/GoToPageAction';
import ICarReview from '../../types/ICarReview';
import { StepperHelper } from '../../utils/StepperHelper';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';
import IPackageSelection from '../../types/IPackageSelection';
import ISellerProps from '../../types/ISellerProps';
import { GetAllSellersAction } from '../../redux/actions/SellerAction';
import { Paper, Button, Grid, Fab, Box, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { GetChassisDamageAction } from './../../redux/actions/DamageAction';
import { IDamageProps } from '../../types/Damage/IDamageProps';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';

interface IStateToProps {
    postCar: ICarProps,
    selectedPackage: ISelectedPackage,
    selectedExtraPackages: { data: ISelectedPackage[] },
    packagesData: IPackageSelection,
    carData: ICarData,
    warrantyStatuses: IWarrantyStatus[],
    carWarranty: IWarranty,
    carPhotos: IPhotoUploadProps,
    carReview: ICarReview,
    history?: any,
    sellers: ISellerProps[],
    chassisDamage: IDamageProps,
    carProblemExtraPackages: { data: ICarProblemExtraPackage[] }
}

interface IDispatchToProps {
    saveCar: (data: ICarProps) => void,
    saveSelectedPackage: (data: ISelectedPackage) => void,
    saveExtraSelectedPackages: (data: ISelectedPackage) => void,
    removeExtraPackage: (data: ISelectedPackage) => void,
    saveWarranty: (data: IWarranty) => void,
    deleteWarranty: (data: IWarranty) => void,
    getCarData: () => void,
    getWarrantyStatusesData: () => void,
    updateWarrantyStatus: (data: IWarranty, isReview: boolean) => void,
    getModelsByBrand?: (brandId: number) => void,
    getWarranty: (id: number) => void,
    savePhotos: (data: IPhotoUploadProps) => void,
    reviewCar: (data: ICarReview) => void,
    getCarReview: (carId: number) => void,
    clearExtraPackagesSelection: () => void,
    getAllSellers: () => void,
    goToImportCars: () => void,
    getChassisDamage?: (chassisNumber: string) => void,
    getCarProblemExtraPackage?: (car: ICarProps) => void,
    changeValidDate: (warrantyId: string, validFrom: string, validUntil: string) => void,
    sendWarrantyMail: (warrantyId: string) => void
}


interface IProps extends IDispatchToProps, IStateToProps, IUrl {
}

const WarrantyFormPage: React.FC<IProps> = (props: IProps) => {
    useEffect(() => {
        const warrantyId = StepperHelper.getWarrantyIdFromUrl();
        if (!props.carWarranty // new warranty creation
            || (warrantyId != props.carWarranty.id) // request for warranty with id in URL
            || (warrantyId === undefined && props.carWarranty && props.carWarranty.id) // if URL still is not changed, but props contains warranty ID
            || (StepperHelper.isInitialUpgrade() && props.packagesData.packages.length === 0)) { // if warranty is upgrading
            props.clearExtraPackagesSelection();
            props.getWarranty(warrantyId!);
            props.getCarData();
            props.getWarrantyStatusesData();
            props.getAllSellers();
            
        }
    }, [props.match]);

    useEffect(() => {

    }, [props.chassisDamage]);
    if (StepperHelper.isUpgradeMode()) {
        return props.packagesData.packages.length > 0 ? <WarrantyStepper
            // @ts-ignore
            history={props.history} step={StepperHelper.getStep()} carReview={props.carReview}
            // @ts-ignore
            reviewCar={props.reviewCar} getCarReview={props.getCarReview}  {...props.carData} sellers={props.sellers} postCar={props.postCar}
            // @ts-ignore
            saveCar={props.saveCar} getModelsByBrand={props.getModelsByBrand} postPhotoUpload={props.carPhotos} sendWarrantyMail={props.sendWarrantyMail}
            // @ts-ignore
            savePhotos={props.savePhotos} selectedPackage={props.selectedPackage} saveSelectedPackage={props.saveSelectedPackage}
            // @ts-ignore
            packagesData={props.packagesData} saveExtraSelectedPackages={props.saveExtraSelectedPackages} removeExtraPackage={props.removeExtraPackage}
            // @ts-ignore
            selectedExtraPackages={props.selectedExtraPackages} saveWarranty={props.saveWarranty} deleteWarranty={props.deleteWarranty} currentWarranty={props.carWarranty}
            // @ts-ignore
            warrantyStatuses={props.warrantyStatuses} updateWarrantyStatus={props.updateWarrantyStatus} getChassisDamage={props.getChassisDamage} chassisDamage={props.chassisDamage}
            // @ts-ignore
            getCarProblemExtraPackage={props.getCarProblemExtraPackage} carProblemExtraPackages={props.carProblemExtraPackages} changeValidDate={props.changeValidDate}/>
            : <div />;
    } else {
        return <div style={{width: '100%' }}>
            {(isAdmin() || isSuperAdmin()) && <Fab style={{ marginBottom: 15, float: 'right' }} onClick={() => props.goToImportCars()} variant="extended" color="primary" aria-label="delete">
            Uvezi vozila
        <AddIcon />
          </Fab>}
            <WarrantyStepper
            // @ts-ignore
                history={props.history} step={StepperHelper.getStep()} carReview={props.carReview}
                // @ts-ignore
                reviewCar={props.reviewCar} getCarReview={props.getCarReview} {...props.carData} sellers={props.sellers}
                // @ts-ignore
                postCar={props.postCar} saveCar={props.saveCar} getModelsByBrand={props.getModelsByBrand}
                // @ts-ignore
                postPhotoUpload={props.carPhotos} savePhotos={props.savePhotos} selectedPackage={props.selectedPackage}
                // @ts-ignore
                selectedExtraPackages={props.selectedExtraPackages} saveSelectedPackage={props.saveSelectedPackage}
                // @ts-ignore
                saveExtraSelectedPackages={props.saveExtraSelectedPackages} removeExtraPackage={props.removeExtraPackage} sendWarrantyMail={props.sendWarrantyMail}
                // @ts-ignore
                packagesData={props.packagesData} saveWarranty={props.saveWarranty} deleteWarranty={props.deleteWarranty} currentWarranty={props.carWarranty}
                // @ts-ignore
                warrantyStatuses={props.warrantyStatuses} updateWarrantyStatus={props.updateWarrantyStatus} getChassisDamage={props.getChassisDamage} chassisDamage={props.chassisDamage}
                // @ts-ignore
                getCarProblemExtraPackage={props.getCarProblemExtraPackage} carProblemExtraPackages={props.carProblemExtraPackages} changeValidDate={props.changeValidDate} />
            </div>
    }
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    postCar: state.postCar,
    selectedPackage: state.selectedPackage,
    selectedExtraPackages: state.selectedExtraPackages,
    packagesData: state.packagesData.data,
    carData: state.carData.data,
    warrantyStatuses: state.warrantyStatuses,
    carWarranty: state.carWarranty,
    carPhotos: state.carPhotos,
    carReview: state.carReview,
    sellers: state.sellers.data,
    chassisDamage: state.carDamage.damage,
    carProblemExtraPackages: state.carProblemExtraPackages
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    saveCar: (data: ICarProps) => {
        dispatch<GetPackagesAction>({
            type: ActionTypes.GET_PACKAGES,
            payload: data
        })
        dispatch<GetPackagesAction>({
            type: ActionTypes.GET_EXTRA_PACKAGES,
            payload: data
        })
        dispatch<PostCarAction>({
            type: ActionTypes.POST_CAR_DATA,
            payload: data
        })
    },
    saveSelectedPackage: (data: ISelectedPackage) => dispatch<SaveSelectedPackageAction>({
        type: ActionTypes.SAVE_PACKAGE,
        payload: data
    }),
    saveExtraSelectedPackages: (data: ISelectedPackage) => dispatch<SaveSelectedPackageAction>({
        type: ActionTypes.SAVE_EXTRA_PACKAGE,
        payload: data
    }),
    removeExtraPackage: (data: ISelectedPackage) => dispatch<SaveSelectedPackageAction>({
        type: ActionTypes.REMOVE_EXTRA_PACKAGE,
        payload: data
    }),
    saveWarranty: (data: IWarrantyData) => dispatch<SaveWarrantyAction>({
        type: ActionTypes.SAVE_WARRANTY,
        payload: data
    }),
    deleteWarranty: (data: IWarrantyData) => dispatch<DeleteWarrantyAction>({
        type: ActionTypes.DELETE_WARRANTY,
        payload: data
    }),
    getCarData: () => dispatch<GetCarDataAction>({
        type: ActionTypes.GET_CAR_DATA
    }),
    getAllSellers: () => dispatch<GetAllSellersAction>({
        type: ActionTypes.GET_SELLERS
    }),
    getWarrantyStatusesData: () => dispatch<GetWarrantyStatusesDataAction>({
        type: ActionTypes.GET_WARRANTY_STATUSES
    }),
    updateWarrantyStatus: (data: IWarranty, isReview: boolean) => {
        !isReview ? dispatch<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/jamstva' }
        })
            : dispatch<UpdateWarrantyStatusAction>({
                type: ActionTypes.UPDATE_WARRANTY_STATUS,
                payload: data
            })
    },
    getWarranty: (id: number) => dispatch<GetWarrantyAction>({
        type: ActionTypes.GET_WARRANTY,
        payload: id
    }),
    getModelsByBrand: (id: number) => dispatch<GetModelsByBrandAction>({
        type: ActionTypes.GET_CAR_MODELS,
        payload: id
    }),
    savePhotos: (data: IPhotoUploadProps) => {
        dispatch<SaveCarPhotosAction>({
            type: ActionTypes.SAVE_PHOTOS,
            payload: data
        })
    },
    reviewCar: (data: ICarReview) => {
        dispatch<ReviewCar>({
            type: ActionTypes.REVIEW_CAR,
            payload: data
        })
    },
    getCarReview: (carId: number) => {
        dispatch<CurrentCarReview>({
            type: ActionTypes.GET_CURRENT_CAR_REVIEW,
            payload: carId
        })
    },
    clearExtraPackagesSelection: () => {
        dispatch<any>({
            type: ActionTypes.CLEAR_SELECTED_EXTRA_PACKAGES,
        })
    },
    goToImportCars: () => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/uvoz' }
    }),
    getChassisDamage: (chassisNumber: string) => dispatch<GetChassisDamageAction>({
        type: ActionTypes.GET_CHASSIS_DAMAGE,
        payload: { chassisNumber }
    }),
    getCarProblemExtraPackage: (car: ICarProps) => {
        dispatch<GetCarProblemExtraPackageAction>({
            type: ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE,
            payload: car
        })
    },
    changeValidDate: (warrantyId: string, validFrom: string, validUntil: string) => dispatch<ChangeValidDateAction>({
        type: ActionTypes.CHANGE_VALID_DATE,
        payload: { warrantyId, validFrom, validUntil}
    }),
    sendWarrantyMail: (warrantyId: string) => dispatch<SendWarrantyEmailAction>({
        type: ActionTypes.SEND_WARRANTY_EMAIL,
        payload: { warrantyId }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(WarrantyFormPage); 
