import React, { ReactNode } from 'react';
import styles from './background.module.scss';
import { withRouter } from 'react-router-dom';

interface IProps {
    children?: ReactNode,
    location: any
};

const Background: React.FC<IProps> = (props) => {
    return (
        <div className={styles.Background}>
                {props.children}
        </div>
    );
};

export default withRouter(props => <Background {...props}/>);