export{}
declare global {
    interface Array<T> {
        first(): T;
    }
}

if (!Array.prototype.first) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.first = function<T>(this: T[]): T | undefined {
        return this.find(x => x !== undefined);
    };
}
