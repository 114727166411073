
import { injectable, inject } from 'inversify';
import IDamageService from './IDamageService';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import { AxiosInstance } from 'axios';
import { damageApiURL, damageAdminApiURL } from '../../constants/apiRoutes';
import { IDamageProps } from '../../types/Damage/IDamageProps';
import IStatus from '../../types/IStatus';
import IDamageAdminTable from '../../types/Damage/IDamageAdminTable';
import { damageSellerApiURL } from './../../constants/apiRoutes';
import { IDamageSellerTable } from '../../types/Damage/IDamageSellerTable';
import { damageBuyerApiURL } from './../../constants/apiRoutes';
import IDamageBuyerTable from '../../types/Damage/IDamageBuyerTable';
import IDamageWarrantyTable from '../../types/Damage/IDamageWarrantyTable';
import { damageWarrantyApiURL } from './../../constants/apiRoutes';
import IDamageSellerByAdminTable from '../../types/Damage/IDamageWarrantyTable';
import { damageSellerByAdminApiURL } from './../../constants/apiRoutes';
import IDamageCarTable from '../../types/Damage/IDamageCarTable';
import { damageCarApiURL } from './../../constants/apiRoutes';

@injectable()
export default class DamageService implements IDamageService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getActiveDamage(carId: number, warrantyId: number): Promise<IDamageProps> {
        const response = await this.apiClient.get(damageApiURL + "/getActiveDamage/" + carId + "/" + warrantyId);
        return response.data;
    }

    public async getDamageStatuses(): Promise<IStatus[]> {
        const response = await this.apiClient.get(damageApiURL + "/statuses");
        return response.data;
    }

    public async saveDamage(data: IDamageProps): Promise<any> {
        const response = await this.apiClient.post<IDamageProps>(damageApiURL, data);
        return response.data;
    }

    public async getDamagesAdmin(): Promise<IDamageAdminTable[]> {
        const response = await this.apiClient.get(damageAdminApiURL);
        return response.data;
    }

    public async getDamagesAdminTotalCount(): Promise<number> {
        const response = await this.apiClient.get(damageApiURL + '/damagesAdminTableTotalCount');
        return response.data;
    }

    public async getDamage(carId: number, damageId: number): Promise<IDamageProps> {
        const response = await this.apiClient.get(damageApiURL + '/getDamage' + `/${carId}/${damageId}`);
        return response.data;
    }

    public async getDamagesSeller(): Promise<IDamageSellerTable[]> {
        const response = await this.apiClient.get(damageSellerApiURL);
        return response.data;
    }
    public async getDamagesBuyer(email: string): Promise<IDamageBuyerTable[]> {
        const response = await this.apiClient.get(damageBuyerApiURL + "/" + email);
        return response.data;
    }
    public async getDamagesWarranty(warrantyId: number): Promise<IDamageWarrantyTable[]> {
        const response = await this.apiClient.get(damageWarrantyApiURL + "/" + warrantyId);
        return response.data;
    }
    public async getDamagesSellerByAdmin(id: string): Promise<IDamageSellerByAdminTable[]> {
        const response = await this.apiClient.get(damageSellerByAdminApiURL + "/" + id);
        return response.data;
    }
    public async getChassisDamage(chassisNumber: string): Promise<IDamageProps> {
        const response = await this.apiClient.get(damageApiURL + "/chassis/" + chassisNumber);
        return response.data;
    }
    public async getDamagesCar(carId: number): Promise<IDamageCarTable[]> {
        const response = await this.apiClient.get(damageCarApiURL + "/" + carId);
        return response.data;
    }
    public async getSellerName(id: string): Promise<string> {
        const response = await this.apiClient.get(damageApiURL + "/sellerName/" + id);
        return response.data;
    }
    public async getBuyerName(email: string): Promise<string> {
        const response = await this.apiClient.get(damageApiURL + "/buyerName/" + email);
        return response.data;
    }
    public async getCarName(id: number): Promise<string> {
        const response = await this.apiClient.get(damageApiURL + "/carName/" + id);
        return response.data;
    }
    public async deleteDamage(damageId: number): Promise<null> {
        const response = await this.apiClient.post(damageApiURL + "/delete/" + damageId)
        return response.data;
    }
}