import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField, Button, Tooltip, Modal, Paper } from '@material-ui/core';
import NewCarForm from '../forms/newCarForm/NewCar';
import ISelectedPackage from '../../types/ISelectedPackage';
import IPackageSelection from '../../types/IPackageSelection';
import ICarProps from '../../types/ICarProps';
import PackageSelection from './../packageSelection/PackageSelection';
import style from './warrantyReview.module.scss';
import ICarData from '../../types/ICarData';
import Divider from '@material-ui/core/Divider';
import WarrantyStatusForm from './../forms/WarrantyStatusForm';
import IWarrantyStatusFormProps from './../../types/IWarrantyStatusFormProps';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';
import WarrantyStatus from '../../utils/warrantyStatusChecker';
import WarrantyStatusEnum from './../../domain/enums/WarrantyStatus';
import PhotoList from './../lists/PhotoList';
import IPhotoUploadProps from '../../types/IPhotoUploadProps';
import ICarReview from "../../types/ICarReview";
import IReviewMode from '../../types/IReviewModeProps';
import CarReviewHelper from './../../utils/CarReviewHelper';
import IWarranty from '../../types/IWarranty';
import { StepperHelper } from '../../utils/StepperHelper';
import Select from "react-select";
import ISellerProps from '../../types/ISellerProps';
import { DeleteWarrantyModal } from '../forms/warrantyForm/DeleteWarrantyModal';
import { ChangeWarrantyDateModal } from '../forms/warrantyForm/ChangeWarrantyDateModal';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';
import { WarrantyMailModal } from '../forms/warrantyForm/WarrantyMailModal';
import { SaveWarrantyModal } from '../forms/warrantyForm/SaveWarrantyModal';
import { ChangeSellerModal } from '../forms/warrantyForm/ChangeSellerModal';
import { ChangeBuyerModal } from '../forms/warrantyForm/ChangeBuyerModal';
import { ChangeWarrantyDurationModal } from '../forms/warrantyForm/ChangeWarrantyDurationModal';

interface IProps extends ICarProps, ICarData, IWarrantyStatusFormProps, IPhotoUploadProps, IReviewMode {
    postCar: ICarProps,
    selectedPackage: ISelectedPackage,
    selectedExtraPackages: { data: ISelectedPackage[] },
    warrantyDataChanged?: (data: IWarrantyStatusFormProps) => void,
    carReview: ICarReview,
    setCarReview?: (data: ICarReview) => void,
    save: () => void,
    changeSeller?: () => void,
    changeBuyer?: (email: string) => void,
    delete: () => void,
    //  getCarReview: (carId: number) => void,
    currentWarranty: IWarranty,
    carData?: ICarProps,
    packagesData: IPackageSelection,
    saveWarrantyStatus: () => void,
    sellers: ISellerProps[],
    carProblemExtraPackages: { data: ICarProblemExtraPackage[] },
    changeValidDate: (warrantyId: string, validFrom: string, validUntil: string) => void,
    sendWarrantyMail: (warrantyId: string) => void,
    changeWarrantyDuration?: () => void,
}

function showWarrantyF(status: string) {
    return WarrantyStatus.AdminViewOrUpdate(status);
}


const WarrantyReview: React.FC<IProps> = (props: IProps) => {

    let shouldRender = props.status && (isAdmin() || isSuperAdmin());
    const images = props.carData ? props.carData.images : [];
    const isActive = props.status && WarrantyStatus.IsActive(props.status!.name);
    const [enabledSaveButton, setEnabledSaveButton] = useState(true);
    const [tooltipMessage, setTooltipMessage] = useState<string>('');
    const [warrantyHasMistakes, setWarrantyHasMistakes] = useState(CarReviewHelper.warrantyHasMistakes(props.carReview));
    const showButton = !props.currentWarranty.status || props.currentWarranty.status && (props.currentWarranty.status!.name === WarrantyStatusEnum.InProcess || props.currentWarranty.status!.name === WarrantyStatusEnum.Submitted && (isAdmin() || isSuperAdmin()));
    const [showDeleteModal, setDeleteShowModal] = React.useState(false);
    const [showChangeValidModal, setShowChangeValidModal] = React.useState(false);
    const [showWarrantyMailModal, setShowWarrantyMailModal] = React.useState(false);
    const [showSaveModal, setShowSaveModal] = React.useState(false);
    const [showChangeSellerModal, setShowChangeSellerModal] = React.useState(false);
    const [showChangeBuyerModal, setShowChangeBuyerModal] = React.useState(false);
    const [showChangeWarrantyDurationModal, setShowChangeWarrantyDurationModal] = React.useState(false);

    function setCarReview(data: ICarReview) {
        setWarrantyHasMistakes(CarReviewHelper.warrantyHasMistakes(data));
        props.setCarReview!(data);
    }

    const handleSelectSeller = (value: any) => {
        localStorage.setItem("carSeller", JSON.stringify(value));
        setSelectedSeller(value);
    };

    let seller = {
        label: props.sellerFullName,
        value: props.sellerId
    };

    const [selectedSeller, setSelectedSeller] = useState<any>();

    useEffect(() => {
        handleSelectSeller(seller);
    }, [props.sellerId]);

    const handleDelete = () => {
        setDeleteShowModal(true);
    }

    const handleYesModal = () => {
        props.delete();
    }

    const handleChangeValidModal = () => {
        setShowChangeValidModal(true);
    }


    const handleSendMailModal = () => {
        setShowWarrantyMailModal(true);
    }


    const handleSubmitValidModal = (warrantyId: string, validFrom: string, validUntil: string) => {
        props.changeValidDate(warrantyId, validFrom, validUntil);
    }

    const handleSubmitMailModal = () => {
        if (props.code) {
            props.sendWarrantyMail(props.code);
        }
    }

    const handleSave = () => {
        setShowSaveModal(true);
    }

    const handleSaveModal = () => {
        props.save()
    }

    const handleChangeSeller = () => {
        setShowChangeSellerModal(true);
    }

    const handleChangeSellerModal = () => {
        if (props.changeSeller) props.changeSeller()
    }

    const handleChangeBuyer = () => {
        setShowChangeBuyerModal(true);
    }

    const handleChangeBuyerModal = (email: string) => {
        if (props.changeBuyer) props.changeBuyer(email)
    }

    const handleChangeWarrantyDuration = () => {
        setShowChangeWarrantyDurationModal(true);
    }

    const handleChangeWarrantyDurationModal = () => {
        if (props.changeWarrantyDuration) props.changeWarrantyDuration()
    }

    return (
        <Box p={1}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
                <Grid item xs={12} className={style.Title}>
                    <h3>Završni pregled podataka</h3>
                </Grid>
                {(isAdmin() || isSuperAdmin()) && <Grid item xs={12} className={style.ListItem}>
                    <p style={{ fontSize: 22, paddingLeft: 30 }}>Trgovac</p>
                    <Select
                        placeholder={"Svi trgovci"}
                        value={selectedSeller}
                        onChange={handleSelectSeller}
                        options={
                            props.sellers &&
                            props.sellers.map((m: ISellerProps) => ({
                                label: m.fullName,
                                value: m.id
                            }))
                        }
                        className={style.selectSeller}
                        isClearable
                    />
                </Grid>
                }
                
                {/*<Grid item xs={12} className={style.ListItem}>*/}
                {/*    <p style={{ fontSize: 22, paddingLeft: 30 }}>Podaci o autu</p>*/}
                {/*    <NewCarForm {...props} {...props.carData} setCarReview={setCarReview} carReview={props.carReview} isReview {...props.postCar} />*/}
                {/*    <Divider />*/}
                {/*</Grid>*/}
                {
                    props.carId &&
                    <Grid item xs={12} className={style.ListItem}>
                        <p style={{ fontSize: 22, paddingLeft: 30 }}>Podaci o jamstvu</p>
                        <Grid item container xs={12} direction="row" justify="flex-start" alignItems="center" className={style.GridItem}>
                            <Grid item container xs={12} md={6} direction="column" style={{ padding: 5 }}>
                                <TextField
                                    disabled
                                    label="Šifra jamstva"
                                    defaultValue="Ime"
                                    value={props.code}
                                    margin="normal" />
                            </Grid>
                            <Grid item container xs={12} md={6} direction="column" style={{ padding: 5 }}>
                                <TextField
                                    disabled
                                    label="Prodavač jamstva"
                                    defaultValue="Ime"
                                    value={props.sellerFullName}
                                    margin="normal" />
                            </Grid>
                            {<Grid item container xs={12} md={12}>
                                <Grid item container xs={12} md={6} direction="column" style={{ padding: 5 }}>
                                    <TextField
                                        disabled
                                        label="Jamstvo vrijedi od"
                                        defaultValue=""
                                        value={props.validFrom}
                                        margin="normal" />
                                </Grid>
                                <Grid item container xs={12} md={6} direction="column" style={{ padding: 5 }}>
                                    <TextField
                                        disabled
                                        label="Jamstvo vrijedi do"
                                        defaultValue=""
                                        value={props.validUntil}
                                        margin="normal" />
                                </Grid>
                            </Grid>
                            }
                            <Grid item container xs={12} md={12}>
                                <Grid item container xs={12} md={6} direction="column" style={{ padding: 5 }}>
                                    <TextField
                                        disabled
                                        label="Status jamstva"
                                        defaultValue="Predano"
                                        value={props.status ? props.status!.name : "Predano"}
                                        margin="normal" />
                                </Grid>
                                    {(isAdmin() || isSuperAdmin()) && <Grid item container xs={12} md={6} justify="flex-end">
                                    <Button className={style.ChangeModalButton} color="inherit" variant="contained" onClick={() => handleChangeValidModal()}>Uredi valjanost</Button>
                                </Grid>}
                                {
                                        (isAdmin() || isSuperAdmin()) && props.code &&
                                    <Grid item xs={3}>
                                        <Button className={style.MailButton} color="inherit" variant="contained" onClick={() => handleSendMailModal()}>Pošalji mail za prodano jamstvo trgovcu</Button>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                        <Divider />
                    </Grid>
                }
                <Grid item xs={12} className={style.ListItem}>
                    <p style={{ fontSize: 22, paddingLeft: 30 }}>Podaci o pravu na jamstvo</p>
                    <PackageSelection selectedPackage={props.selectedPackage} selectedExtraPackages={props.selectedExtraPackages} packagesData={props.packagesData} postCar={props.postCar} carProblemExtraPackages={props.carProblemExtraPackages} />
                </Grid>
                <Divider />
                <Grid item xs={12} className={style.ListItem}>
                    <p style={{ fontSize: 22, paddingLeft: 30 }}>Slike vozila</p>
                    <PhotoList photos={props.id ? images : props.images} photoFiles={props.pictures} />
                </Grid>
                {
                    shouldRender && <Grid item xs={12} className={style.ListItem}>
                        <Divider />
                        <WarrantyStatusForm warrantyHasMistakes={warrantyHasMistakes} setEnabledSaveButton={setEnabledSaveButton}
                            setTooltipMessage={setTooltipMessage} dataChanged={props.warrantyDataChanged}
                            saveWarrantyStatus={props.saveWarrantyStatus} {...props} />
                    </Grid >
                }
                {
                    (!props.isReview || StepperHelper.isUpgradeMode() || (isAdmin() || isSuperAdmin())) && <Grid container item xs={12} justify="center" alignItems="center" spacing={6}>
                        {props.id && !props.postCar.newWarranty && 
                            <Grid item>
                                <Button className={style.SaveButton} color="secondary" variant="contained" onClick={() => handleDelete()}>Obriši jamstvo</Button>
                            </Grid>}
                        {props.id && !props.postCar.newWarranty &&
                            <Grid item>
                                <Button className={style.SaveButton} color="primary" variant="contained" onClick={props.save}>Spremi promjene</Button>
                            </Grid>}
                        {props.id && props.postCar.newWarranty &&
                            <Grid item>
                                <Button className={style.SaveButton} color="primary" variant="contained" onClick={props.save}>Spremi jamstvo</Button>
                            </Grid>}
                        {!props.id && <Grid item>
                                <Button className={style.SaveButton} color="primary" variant="contained" onClick={() => handleSave()}>Spremi novo jamstvo</Button>
                        </Grid>}

                    </Grid>
                }
                {
                    (!props.isReview || StepperHelper.isUpgradeMode() || (isAdmin() || isSuperAdmin())) && <Grid container item xs={12} justify="center" alignItems="center" spacing={6}>
                        {props.id && <Grid item>
                            <Button className={style.SaveButton} color="inherit" variant="contained" onClick={() => handleChangeSeller()}>Promijeni trgovca</Button>
                        </Grid>}
                        {props.id && <Grid item>
                            <Button className={style.SaveButton} color="inherit" variant="contained" onClick={() => handleChangeBuyer()}>Promijeni kupca</Button>
                        </Grid>}
                        {props.id && <Grid item>
                            <Button className={style.SaveButton} color="inherit" variant="contained" onClick={() => handleChangeWarrantyDuration()}>Promijeni trajanje jamstva</Button>
                        </Grid>}
                    </Grid>
                }
            </Grid >
            {
                showSaveModal &&
                <Box>
                    <Modal open={showSaveModal} className={style.Modal} onClose={() => setShowSaveModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                <SaveWarrantyModal closeModal={() => setShowSaveModal(false)} submitYesModal={handleSaveModal} isOneYearWarranty={props.isOneYearWarranty}></SaveWarrantyModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
            {
                showChangeSellerModal &&
                <Box>
                    <Modal open={showChangeSellerModal} className={style.Modal} onClose={() => setShowChangeSellerModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                <ChangeSellerModal closeModal={() => setShowChangeSellerModal(false)} submitYesModal={handleChangeSellerModal} ></ChangeSellerModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
            {
                showChangeBuyerModal &&
                <Box>
                    <Modal open={showChangeBuyerModal} className={style.Modal} onClose={() => setShowChangeBuyerModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                <ChangeBuyerModal closeModal={() => setShowChangeBuyerModal(false)} submitYesModal={handleChangeBuyerModal} ></ChangeBuyerModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
            {
                showDeleteModal &&
                <Box>
                    <Modal open={showDeleteModal} className={style.Modal} onClose={() => setDeleteShowModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                <DeleteWarrantyModal closeModal={() => setDeleteShowModal(false)} submitYesModal={handleYesModal}></DeleteWarrantyModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
            {
                showChangeValidModal &&
                <Box>
                    <Modal open={showChangeValidModal} className={style.Modal} onClose={() => setShowChangeValidModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                <ChangeWarrantyDateModal closeModal={() => setShowChangeValidModal(false)} submitValidModal={handleSubmitValidModal} validFrom={props.validFrom}
                                    validUntil={props.validUntil} warrantyId={props.code}></ChangeWarrantyDateModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
            {
                showWarrantyMailModal &&
                <Box>
                    <Modal open={showWarrantyMailModal} className={style.Modal} onClose={() => setShowWarrantyMailModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                <WarrantyMailModal closeModal={() => setShowWarrantyMailModal(false)} submitMailModal={handleSubmitMailModal}></WarrantyMailModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
            {
                showChangeWarrantyDurationModal &&
                <Box>
                    <Modal open={showChangeWarrantyDurationModal} className={style.Modal} onClose={() => setShowChangeWarrantyDurationModal(false)}>
                        <Paper className={style.RootModal}>
                            <div>
                                    <ChangeWarrantyDurationModal closeModal={() => setShowChangeWarrantyDurationModal(false)} submitYesModal={handleChangeWarrantyDurationModal}
                                        isOneYearWarranty={props.isOneYearWarranty} warrantyFromDate={props.validFrom}></ChangeWarrantyDurationModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
        </Box >)
}

export default WarrantyReview;