import React from 'react'
import IBuyerProps from '../../../types/IBuyerProps';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import style from "./userData.module.scss"

interface IDispatchToProps { }

interface IProps extends IDispatchToProps {
    user: IBuyerProps
}

interface IState extends IBuyerProps { }

class UserData extends React.Component<IProps>  {

    state: IState = {
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        legalEntityName: this.props.user.legalEntityName,
        legalEntity: this.props.user.legalEntity,
        oib: this.props.user.oib,
        address: this.props.user.address,
        email: this.props.user.email,
        zipCode: this.props.user.zipCode,
        city: this.props.user.city,
        country: this.props.user.country,
        phoneNumber: this.props.user.phoneNumber
    }

    static getDerivedStateFromProps(props: IProps, state: IState) {
        if (props.user.firstName !== state.firstName || props.user.legalEntityName !== state.legalEntityName) {
            return ({
                ...state,
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                legalEntityName: props.user.legalEntityName,
                legalEntity: props.user.legalEntity,
                oib: props.user.oib,
                address: props.user.address,
                email: props.user.email,
                zipCode: props.user.zipCode,
                city: props.user.city,
                country: props.user.country,
                phoneNumber: props.user.phoneNumber
            })
        }
    }

    render() {
        return (
            <div className={style.Root}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} justify="center" alignItems="center" className={style.Title}>
                        <h2 style={{ color: "black" }}>Pregled osobnih podataka</h2>
                    </Grid>
                    <Grid item xs={12} md={8} container justify="flex-start" alignItems="center" spacing={1}>
                        {!this.state.legalEntity ?
                            <React.Fragment>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Ime"
                                        defaultValue=""
                                        value={this.state.firstName}
                                        margin="normal"
                                        fullWidth />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Prezime"
                                        defaultValue=""
                                        value={this.state.lastName}
                                        margin="normal"
                                        fullWidth />
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item xs={12} md={12}  lg={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label="Ime tvrtke"
                                        defaultValue=""
                                        value={this.state.legalEntityName}
                                        margin="normal" 
                                        fullWidth />
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} md={12}>
                            <TextField
                              InputProps={{
                                readOnly: true,
                            }}
                                label="OIB"
                                defaultValue=""
                                value={this.state.oib}
                                margin="normal"
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                               InputProps={{
                                readOnly: true,
                            }}
                                label="E-mail"
                                defaultValue=""
                                value={this.state.email}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Kontakt broj"
                                defaultValue=""
                                value={this.state.phoneNumber}
                                margin="normal" 
                                fullWidth/>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Adresa"
                                defaultValue=""
                                value={this.state.address}
                                margin="normal" 
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Poštanski broj"
                                defaultValue=""
                                value={this.state.zipCode}
                                margin="normal" 
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Grad"
                                defaultValue=""
                                value={this.state.city}
                                margin="normal" 
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Država"
                                defaultValue=""
                                value={this.state.country}
                                margin="normal" 
                                fullWidth/>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}



export default UserData;