import ICarReview from "../types/ICarReview";
import IStatus from "../types/IStatus";
import WarrantyStatus from "../domain/enums/WarrantyStatus";

export default class Validator {
  static validateEmail(email: string): boolean {
    let re = /^[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()[\]{}_+=|\\-]+@[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()[\]{}_+=|\\-]+\.[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()[\]{}_+=|\\-]+$/;
    return re.test(email);
  }
  static validatePassword(password: string): boolean {
    let re = /^(.){8,}$/;
    return re.test(password);
  }
  static validateChassis(chassis: string): boolean {
    let re = /^(.){17}$/;
    return re.test(chassis);
  }
  static validateOIB(oib: string): boolean {
    if (!oib) return true;
    let checkDigit, num;

    let code = oib.toString();

    if (code.length === 13 && code.substr(0, 2).toUpperCase() === 'HR') {
      code = code.substr(2, 11);
    } else if (code.length !== 11) {
      return false;
    }

    let numCheck = parseInt(code, 10);
    if (isNaN(numCheck)) {
      return false;
    }

    num = 10;
    for (let i = 0; i < 10; i++) {
      num = num + parseInt(code.substr(i, 1), 10);
      num = num % 10;
      if (num === 0) {
        num = 10;
      }
      num *= 2;
      num = num % 11;
    }

    checkDigit = 11 - num;
    if (checkDigit === 10) {
      checkDigit = 0;
    }

    return parseInt(code.substr(10, 1), 10) === checkDigit;
  }

  static validateZipCode(zipCode: string): boolean {
    if (zipCode) {
      let re = /^(\d){2,}$/;
      return re.test(zipCode);
    }
    return true;
  }
  static validatePhoneNumber(number: string): boolean {
    if (number) {
      let re = /^(\d){5,}$/;
      return re.test(number);
    }
    return true;
  }

  static validateRegistrationDate(registrationDate: string): boolean {
    const currentDate = new Date().toISOString().split("T")[0];
    return registrationDate < currentDate;
  }

  static validateRegistrationYear(
    regYear: string,
    manufactureYear?: number
  ): boolean {
    alert(manufactureYear)
    var registrationYear = regYear ? regYear.split("-")[0] : null;
    if (manufactureYear) return registrationYear! >= manufactureYear.toString();
    return true;
  }

  static validateManufactureYear(
    manufactureYear: string,
    registrationDate?: Date
  ): boolean {
    alert(manufactureYear)
    var registrationYear = registrationDate
      ? new Date(registrationDate!).getFullYear()
      : null;
    if (registrationYear && manufactureYear > registrationYear.toString()) {
      return false;
    }
    return true;
  }

  static carIsValid(carReview: ICarReview): boolean {
    if (!carReview.brandIsValid) return false;
    else if (!carReview.modelIsValid) return false;
    else if (!carReview.engineTypeIsValid) return false;
    else if (!carReview.enginePowerIsValid) return false;
    else if (!carReview.distanceIsValid) return false;
    else if (!carReview.chassisNumberIsValid) return false;
    else if (!carReview.manufactureYearIsValid) return false;
    else if (!carReview.registrationYearIsValid) return false;
    else if (!carReview.priceIsValid) return false;
    return true;
  }

  static isEmailMatch(email1: string, email2: string): boolean {
    return email1 === email2;
  }

}
