import React, { useEffect } from 'react';
import ActionTypes from '../../redux/actions/ActionTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import { ConfirmPasswordAction } from '../../redux/actions/ForgotPasswordAction';
import PublicFormLayout from '../../components/authentication/PublicFormLayout';
import ConfirmPasswordForm from './../../components/forms/forgotPassword/confirmPassword';
import { IForgotPassword } from '../../types/IForgotPassword';

interface IDispatchToProps {
    onHandleSubmit: (data: IForgotPassword) => void
}

interface IProps extends IDispatchToProps { }

const ConfirmPasswordFormPage: React.FC<IProps> = (props: IProps) => {
    const submit = (password: string) => {
        var urlParams = new URLSearchParams(window.location.search);
        const data = {
            email: urlParams.get('email')!,
            token: urlParams.get('token') as string,
            password
        }
        props.onHandleSubmit(data);
    }

    return <PublicFormLayout>
        <ConfirmPasswordForm {...props} onSubmit={submit} />
    </PublicFormLayout>
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onHandleSubmit: (data: IForgotPassword) => dispatch<ConfirmPasswordAction>({
        type: ActionTypes.PASSWORD_CONFIRM_SUBMIT,
        payload: data
    })
})

export default connect<null, IDispatchToProps, null, IApplicationState>(null, mapDispatchToProps)(ConfirmPasswordFormPage);