import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Button,
  Paper,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Modal,
  Box,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Validator from "../../../utils/validation";
import ISellerProps from "../../../types/ISellerProps";
import styles from "./sellerForm.module.scss";
import { SellerButtons } from "./SellerButtons";
import Select from "react-select";
import { ActionMeta, ValueType } from "react-select/src/types";
import ILegalEntity from "../../../types/ILegalEntitiy";
import NumberFormat from "react-number-format";
import { SellerActivateModal } from "../../../components/forms/sellerRegistrationForm/SellerActivateModal";
import { SellerDeactivateModal } from "../../../components/forms/sellerRegistrationForm/SellerDeactivateModal";
import { SellerDeniedContractModal } from "../../../components/forms/sellerRegistrationForm/SellerDeniedContractModal";
import SellerContracts from "../../../containers/seller/SellerContracts";
import ISellerContractData from "../../../types/ISellerContractData";
import ExportSellerContract from "../../sellerContract/ExportSellerContract";

interface IProps extends IState {
  seller: ISellerProps;
  submit: (seller: ISellerProps) => void;
  activate: (seller: ISellerProps) => void;
  handleDeniedContract?: (data: ISellerContractData) => void;
  delete: (seller: ISellerProps) => void;
  isEditForm: boolean;
  submitSellerContract?: (data: ISellerContractData) => void;
  cancelSellerContract?: (data: ISellerContractData) => void;
}

interface IState extends ISellerProps {
  hasError?: boolean;
  legalEntities?: ILegalEntity[];
  open?: boolean;
  selectedLegalEntity?: { label: string; value: string };
  showDeleteModal?: boolean;
  showActivateModal?: boolean;
  showDeniedContractModal?: boolean;
  showPdfForm?: boolean;
}

export default class SellerRegistrationForm extends React.Component<IProps> {
  state: IState = {
    id: "",
    firstName: "",
    lastName: "",
    legalEntityName: "",
    legalEntityBool: false,
    billingAddress: "",
    carShowroomName: "",
    parentId: "",
    oib: "",
    address: "",
    email: "",
    zipCode: "",
    city: "",
    country: "",
    phoneNumber: "",
    hasError: false,
    legalEntities: undefined,
    open: false,
    message: this.props.message,
    odgovornaOsoba: "",
    osobaZaKontakt: "",
    active: "",
    kontaktOdgovornaOsoba: "",
  };

  private formRef = React.createRef<ValidatorForm>();

  componentDidMount() {
    ValidatorForm.addValidationRule("isOIB", (oib: string) =>
      Validator.validateOIB(oib)
    );
    ValidatorForm.addValidationRule("isZipCode", (zipCode: string) =>
      Validator.validateZipCode(zipCode)
    );
    ValidatorForm.addValidationRule("isPhoneNumber", (number: string) =>
      Validator.validatePhoneNumber(number)
    );
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    /* ovo je shit ovako raspakiravat, proba sam po objektima, al onda sam ima jos veci shit kod handle change postavit dinamicki neko polje npr u seller objektu, ajme... */
    if (
      nextProps.isEditForm &&
      (nextProps.oib !== prevState.oib ||
        (nextProps.legalEntities !== undefined &&
          nextProps.legalEntities.length > 0 &&
          prevState.selectedLegalEntity === undefined))
    )
      return {
        id: nextProps.id,
        firstName: nextProps.firstName,
        lastName: nextProps.lastName,
        parentId: nextProps.parentId,
        legalEntityName: nextProps.legalEntityName,
        legalEntityBool: nextProps.legalEntityBool,
        billingAddress: nextProps.billingAddress,
        carShowroomName: nextProps.carShowroomName,
        oib: nextProps.oib,
        address: nextProps.address,
        email: nextProps.email,
        zipCode: nextProps.zipCode,
        city: nextProps.city,
        country: nextProps.country,
        phoneNumber: nextProps.phoneNumber,
        legalEntities: nextProps.legalEntities,
        odgovornaOsoba: nextProps.odgovornaOsoba,
        osobaZaKontakt: nextProps.osobaZaKontakt,
        kontaktOdgovornaOsoba: nextProps.kontaktOdgovornaOsoba,
        active: nextProps.active,
        /* ako postoji popis firmi i ako osoba ima firmu kojoj pripada selektiraj ime te firme */
        selectedLegalEntity:
          nextProps.legalEntities !== undefined &&
          nextProps.legalEntities.length > 0 &&
          nextProps.parentId
            ? {
                label: nextProps.legalEntities.find(
                  ({ id }) => id === nextProps.parentId
                )!.name,
                value: nextProps.legalEntities.find(
                  ({ id }) => id === nextProps.parentId
                )!.id,
              }
            : null,
      };
    return prevState;
  }

  private handleChange(el: any) {
    const { name, value } = el.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (
    value: ValueType<{ label: string; value: string }>,
    _: ActionMeta
  ) => {
    this.setState({ selectedLegalEntity: value });
  };

  public handleSubmit() {
    const state = this.state;
    if (state.hasError) return;
    const seller: ISellerProps = {
      id: state.id,
      firstName: state.firstName,
      lastName: state.lastName,
      oib: state.oib,
      address: state.address,
      email: state.email,
      zipCode: state.zipCode,
      city: state.city,
      country: state.country,
      phoneNumber: state.phoneNumber,
      legalEntityName: state.legalEntityName,
      legalEntityBool: state.legalEntityBool,
      odgovornaOsoba: state.odgovornaOsoba,
      osobaZaKontakt: state.osobaZaKontakt,
      billingAddress: state.billingAddress,
      carShowroomName: state.carShowroomName,
      active: state.active,
      kontaktOdgovornaOsoba: state.kontaktOdgovornaOsoba,
      parentId:
        state.selectedLegalEntity && state.selectedLegalEntity !== undefined
          ? state.selectedLegalEntity.value
          : "",
    };
    this.props.submit(seller);
    this.setState({ open: true });
  }

  public validateSelect() {
    const hasError =
      this.state.selectedLegalEntity === undefined &&
      !this.state.legalEntityBool;
    this.setState({ hasError });
  }

  public handleDelete() {
    const state = this.state;
    if (state.hasError) return;
    const seller: ISellerProps = {
      id: state.id,
      firstName: state.firstName,
      lastName: state.lastName,
      oib: state.oib,
      address: state.address,
      email: state.email,
      zipCode: state.zipCode,
      city: state.city,
      country: state.country,
      phoneNumber: state.phoneNumber,
      legalEntityName: state.legalEntityName,
      legalEntityBool: state.legalEntityBool,
      odgovornaOsoba: state.odgovornaOsoba,
      osobaZaKontakt: state.osobaZaKontakt,
      billingAddress: state.billingAddress,
      carShowroomName: state.carShowroomName,
      active: state.active,
      kontaktOdgovornaOsoba: state.kontaktOdgovornaOsoba,
      parentId:
        state.selectedLegalEntity && state.selectedLegalEntity !== undefined
          ? state.selectedLegalEntity.value
          : "",
    };
    this.props.delete(seller);
    this.setState({ open: true });
  }

  public handleActivate() {
    const state = this.state;
    if (state.hasError) return;
    const seller: ISellerProps = {
      id: state.id,
      firstName: state.firstName,
      lastName: state.lastName,
      oib: state.oib,
      address: state.address,
      email: state.email,
      zipCode: state.zipCode,
      city: state.city,
      country: state.country,
      phoneNumber: state.phoneNumber,
      legalEntityName: state.legalEntityName,
      legalEntityBool: state.legalEntityBool,
      odgovornaOsoba: state.odgovornaOsoba,
      osobaZaKontakt: state.osobaZaKontakt,
      billingAddress: state.billingAddress,
      carShowroomName: state.carShowroomName,
      active: state.active,
      kontaktOdgovornaOsoba: state.kontaktOdgovornaOsoba,
      parentId:
        state.selectedLegalEntity && state.selectedLegalEntity !== undefined
          ? state.selectedLegalEntity.value
          : "",
    };
    this.props.activate(seller);
    this.setState({ open: true });
  }

  public handleDenied() {
    const state = this.state;
    if (state.hasError) return;
    const seller: ISellerContractData = {
      sellerId: state.id,
    };
    if (this.props.handleDeniedContract)
      this.props.handleDeniedContract(seller);
    this.setState({ open: true });
  }

  public handleDeleteModal() {
    this.setState({ showDeleteModal: true });
  }

  public handlePdfModal() {
    this.setState({ showPdfForm: true });
  }

  public handleActivateModal() {
    this.setState({ showActivateModal: true });
  }

  public handleDeniedModal() {
    this.setState({ showDeniedContractModal: true });
  }

  public handleDeleteYesModal() {
    this.handleDelete();
  }

  public handleActivateYesModal() {
    this.handleActivate();
  }

  public handleDeniedContractYesModal() {
    if (this.props.handleDeniedContract) this.handleDenied();
  }

  render() {
    const { ...formControls } = { ...this.state };

    return (
      <Paper className={styles.Root}>
        <ValidatorForm
          onSubmit={this.handleSubmit.bind(this)}
          ref={this.formRef}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={1}
          >
            <Grid item direction="row" xs={12}>
              <h2
                style={{
                  color: "black",
                  display: "block",
                  fontSize: 22,
                  paddingLeft: 30,
                }}
              >
                Podaci o prodavaču
              </h2>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item style={{ paddingLeft: 50 }}>
                <SellerButtons
                  legalEntityBool={this.state.legalEntityBool}
                  buyerEntityChanged={(legalEntityBool: boolean) =>
                    this.setState({ legalEntityBool })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="column"
              justify="center"
              alignItems="center"
              sm={5}
            >
              <Grid item className={styles.GridItem}>
                <TextValidator
                  label="OIB"
                  onChange={this.handleChange.bind(this)}
                  name="oib"
                  value={formControls.oib}
                  validators={["required", "isOIB"]}
                  errorMessages={[
                    "Ovo polje je obavezno.",
                    "Molimo unesite ispravan OIB",
                  ]}
                />
              </Grid>
              {this.state.legalEntityBool ? (
                <React.Fragment>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Naziv tvrtke"
                      onChange={this.handleChange.bind(this)}
                      name="legalEntityName"
                      value={formControls.legalEntityName}
                      validators={["required"]}
                      errorMessages={["Ovo polje je obavezno."]}
                    />
                  </Grid>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Odgovorna osoba"
                      onChange={this.handleChange.bind(this)}
                      name="odgovornaOsoba"
                      value={formControls.odgovornaOsoba}
                    />
                  </Grid>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Kontakt broj odgovorne osobe"
                      onChange={this.handleChange.bind(this)}
                      name="kontaktOdgovornaOsoba"
                      value={formControls.kontaktOdgovornaOsoba}
                      validators={["isPhoneNumber"]}
                      errorMessages={["Neispravan kontakt broj"]}
                    />
                  </Grid>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Adresa sjedišta tvrtke (za račun)"
                      onChange={this.handleChange.bind(this)}
                      name="billingAddress"
                      value={formControls.billingAddress}
                      validators={["required"]}
                      errorMessages={["Ovo polje je obavezno."]}
                    />
                  </Grid>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Naziv salona/brenda"
                      onChange={this.handleChange.bind(this)}
                      name="carShowroomName"
                      value={formControls.carShowroomName}
                      validators={["required"]}
                      errorMessages={["Ovo polje je obavezno."]}
                    />
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Ime"
                      onChange={this.handleChange.bind(this)}
                      name="firstName"
                      value={formControls.firstName}
                      validators={["required"]}
                      errorMessages={["Ovo polje je obavezno."]}
                    />
                  </Grid>
                  <Grid item className={styles.GridItem}>
                    <TextValidator
                      label="Prezime"
                      onChange={this.handleChange.bind(this)}
                      name="lastName"
                      value={formControls.lastName}
                      validators={["required"]}
                      errorMessages={["Ovo polje je obavezno."]}
                    />
                  </Grid>
                  <Grid
                    item
                    className={styles.GridItem}
                    style={{ marginBottom: "30px", marginTop: "10px" }}
                  >
                    <FormControl
                      error={this.state.hasError}
                      className={styles.Select}
                    >
                      <Select
                        placeholder={"Kojoj tvrtki osoba pripada..."}
                        value={this.state.selectedLegalEntity}
                        onChange={this.handleSelectChange}
                        options={
                          this.props.legalEntities &&
                          this.props.legalEntities.map((m: ILegalEntity) => ({
                            label: m.name,
                            value: m.id,
                          }))
                        }
                      />
                      {this.state.hasError && (
                        <FormHelperText focused={true}>
                          Ovo polje je obavezno.
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item className={styles.GridItem}>
                <TextValidator
                  label={
                    this.state.legalEntityBool ? "Adresa salona" : "Adresa"
                  }
                  onChange={this.handleChange.bind(this)}
                  name="address"
                  value={formControls.address}
                  validators={["required"]}
                  errorMessages={["Ovo polje je obavezno."]}
                />
              </Grid>
              {this.state.legalEntityBool && (
                <Grid item className={styles.GridItem}>
                  <TextValidator
                    label={
                      this.state.legalEntityBool
                        ? "Poštanski broj salona"
                        : "Poštanski broj"
                    }
                    onChange={this.handleChange.bind(this)}
                    name="zipCode"
                    value={formControls.zipCode}
                    validators={["required", "isZipCode"]}
                    errorMessages={[
                      "Ovo polje je obavezno.",
                      "Molimo unesite ispravan poštanski broj",
                    ]}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="column"
              justify="center"
              alignItems="center"
              sm={5}
            >
              {!this.state.legalEntityBool && (
                <Grid item className={styles.GridItem}>
                  <NumberFormat
                    customInput={TextValidator}
                    thousandSeparator={" "}
                    label="Poštanski broj"
                    onChange={this.handleChange.bind(this)}
                    name="zipCode"
                    value={formControls.zipCode}
                    validators={["required"]}
                    errorMessages={["Ovo polje je obavezno."]}
                  />
                </Grid>
              )}
              <Grid item className={styles.GridItem}>
                <TextValidator
                  label={this.state.legalEntityBool ? "Grad salona" : "Grad"}
                  onChange={this.handleChange.bind(this)}
                  name="city"
                  value={formControls.city}
                  validators={["required"]}
                  errorMessages={["Ovo polje je obavezno."]}
                />
              </Grid>
              <Grid item className={styles.GridItem}>
                <TextValidator
                  label="Država"
                  onChange={this.handleChange.bind(this)}
                  name="country"
                  value={formControls.country}
                  validators={["required"]}
                  errorMessages={["Ovo polje je obavezno."]}
                />
              </Grid>
              <Grid item className={styles.GridItem}>
                <TextValidator
                  label="Email"
                  onChange={this.handleChange.bind(this)}
                  name="email"
                  value={formControls.email}
                  validators={["required", "isEmail"]}
                  errorMessages={["Ovo polje je obavezno.", "Neispravan email"]}
                />
              </Grid>
              <Grid item className={styles.GridItem}>
                <TextValidator
                  label="Kontakt broj"
                  onChange={this.handleChange.bind(this)}
                  name="phoneNumber"
                  value={formControls.phoneNumber}
                  validators={["required", "isPhoneNumber"]}
                  errorMessages={[
                    "Ovo polje je obavezno.",
                    "Neispravan kontakt broj",
                  ]}
                />
              </Grid>
              {this.state.legalEntityBool ? (
                <Grid item className={styles.GridItem}>
                  <TextValidator
                    label="Osoba za kontakt"
                    onChange={this.handleChange.bind(this)}
                    name="osobaZaKontakt"
                    value={formControls.osobaZaKontakt}
                  />
                </Grid>
              ) : (
                <Grid></Grid>
              )}
            </Grid>
            <Grid
              container
              item
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              {!(this.props.odbioUgovor === true) &&
                !(this.props.aktivanUgovor !== null) && (
                  <Grid item className={styles.OdbioUgovorButton}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={this.handleDeniedModal.bind(this)}
                    >
                      ODBIO UGOVOR
                    </Button>
                  </Grid>
                )}
                        {this.props.id !== undefined && <Grid item className={styles.PrikaziUgovorButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={this.handlePdfModal.bind(this)}
                            >
                                PRIKAŽI UGOVOR
                            </Button>
                        </Grid>}
              {this.state.active == "DA" ? (
                <Grid item className={styles.GridButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={this.handleDeleteModal.bind(this)}
                  >
                    DEAKTIVIRAJ TRGOVCA
                  </Button>
                </Grid>
              ) : null}
              {this.state.active == "NE" ? (
                <Grid item className={styles.GridButton}>
                  <Button
                    variant="contained"
                    color="inherit"
                    type="button"
                    onClick={this.handleActivateModal.bind(this)}
                  >
                    AKTIVIRAJ TRGOVCA
                  </Button>
                </Grid>
              ) : null}
              <Grid item className={styles.GridButton}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={this.validateSelect.bind(this)}
                >
                  SPREMI
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
            {
          this.state.showDeleteModal && (
          <Box>
            <Modal
              open={this.state.showDeleteModal}
              className={styles.Modal}
              onClose={() => this.setState({ showDeleteModal: false })}
            >
              <Paper className={styles.RootModal}>
                <div>
                  <SellerDeactivateModal
                    closeModal={() => this.setState({ showDeleteModal: false })}
                    submitYesModal={this.handleDeleteYesModal.bind(this)}
                  ></SellerDeactivateModal>
                </div>
              </Paper>
            </Modal>
          </Box>
        )}
            {
          this.state.showActivateModal && (
          <Box>
            <Modal
              open={this.state.showActivateModal}
              className={styles.Modal}
              onClose={() => this.setState({ showActivateModal: false })}
            >
              <Paper className={styles.RootModal}>
                <div>
                  <SellerActivateModal
                    closeModal={() =>
                      this.setState({ showActivateModal: false })
                    }
                    submitYesModal={this.handleActivateYesModal.bind(this)}
                  ></SellerActivateModal>
                </div>
              </Paper>
            </Modal>
          </Box>
        )}
        {
          this.state.showDeniedContractModal !== undefined &&
          this.state.showDeniedContractModal === true && (
            <Box>
              <Modal
                open={this.state.showDeniedContractModal}
                className={styles.Modal}
                onClose={() =>
                  this.setState({ showDeniedContractModal: false })
                }
              >
                <Paper className={styles.RootModal}>
                  <div>
                    <SellerDeniedContractModal
                      closeModal={() =>
                        this.setState({ showDeniedContractModal: false })
                      }
                      submitYesModal={this.handleDeniedContractYesModal.bind(
                        this
                      )}
                    ></SellerDeniedContractModal>
                  </div>
                </Paper>
              </Modal>
            </Box>
          )}
        {this.state.showPdfForm && this.props.seller.id !== undefined && (
          <ExportSellerContract
            sellerId={this.props.seller.id}
            sellerName={this.props.seller.legalEntityName}
            ugovorAktivan={this.props.seller.aktivanUgovor}
            submitSellerContract={this.props.submitSellerContract}
            cancelSellerContract={this.props.cancelSellerContract}
          />
        )}
      </Paper>
    );
  }
}
