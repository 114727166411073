import React from 'react';
import { Button, TextField, Grid, InputAdornment, IconButton, Paper, Snackbar, SnackbarContent } from '@material-ui/core';
import styles from './publicForms.module.scss';
import Validator from '../../utils/validation';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import { IAuthenticationProps } from '../../types/IAuthenticationProps';

export interface IProps extends IAuthenticationProps {
    handleSubmit: (event: IAuthenticationProps) => void,
    loading: boolean
}

class LoginForm extends React.Component<IProps>{
    state = {
        email: '',
        password: '',
        error: this.props.error,
        emailError: '',
        passError: '',
        showPassword: false
    }

    private handleChange(el: any) {
        const name = el.target.name;
        const value = el.target.value;

        if (this.state.error) this.setState({ error: false, emailError: '', passError: '' })
        this.setState({ [name]: value });
    }

    private handleClickShowPassword() {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
    private onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const { email, password } = this.state;
        let err = false;

        if (!Validator.validateEmail(email)) {
            this.setState({ error: true, emailError: 'Molimo unesite ispravan email' });
            err = true;
        }
        if (!Validator.validatePassword(password)) {
            this.setState({ error: true, passError: 'Lozinka je prekratka (min. 8 znakova)' });
            err = true;
        }
        if (!err) {
            this.setState({ error: false });
            this.props.handleSubmit(this.state);
        }
    }

    render() {
        const showPassword = this.state.showPassword;
        return (
            <Paper className={styles.Root}>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                        {this.props.error &&
                            <Grid item xs={10}>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={true}
                                >
                                    <SnackbarContent
                                        className={styles.PaperRoot}
                                        aria-describedby="client-snackbar"
                                        message={
                                            <span id="client-snackbar">
                                                {this.props.error}
                                            </span>
                                        }
                                    />
                                </Snackbar>
                            </Grid>}
                        <Grid item xs={10}>
                            <TextField label="Email" helperText={this.state.emailError} error={this.state.emailError !== ''} name="email"
                                onChange={this.handleChange.bind(this)} fullWidth />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField label="Password" name="password" helperText={this.state.passError}
                                error={this.state.passError !== ''} type={showPassword ? 'text' : 'password'} onChange={this.handleChange.bind(this)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword.bind(this)}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }} fullWidth />
                        </Grid>
                        <Grid item xs={10} container alignItems="center" justify="center">
                            {this.props.loading ? <Grid><CircularProgress /> </Grid> :
                                <Button className={styles.SubmitButton} type="submit" variant="contained" color="primary">Prijava</Button>
                            }
                        </Grid>
                        <Grid item xs={10}>
                            <div className={styles.ForgotPass}><Link to="/forgotPassword">Zaboravili ste lozinku?</Link></div>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }
}

export default LoginForm;