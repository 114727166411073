import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import { FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import { BuyerType } from '../../../domain/enums/BuyerType';

interface IProps {
    buyerEntityChanged: (legalEntity: boolean) => void,
    legalEntity: boolean,
    disabled: boolean
}

export const BuyerRadioButtons: React.FC<IProps> = (props: IProps) => {
    const [isLegalEntity, setIsLegalEntity] = React.useState(props.legalEntity);

    useEffect(() => setIsLegalEntity(props.legalEntity), [props]);

    function handleChange(event: React.ChangeEvent<unknown>) {
        const legalEntity = (event.target as HTMLInputElement).value === BuyerType.LegalEntity;
        setIsLegalEntity(legalEntity);
        props.buyerEntityChanged(legalEntity);
    }

    return (
        <FormControl component="fieldset" style={{ paddingLeft: 20 }}>
            <FormLabel component="legend">Kupac je:</FormLabel>
            <RadioGroup aria-label="position" name="position" value={isLegalEntity ? BuyerType.LegalEntity : BuyerType.Person} onChange={handleChange} row>
                <FormControlLabel
                    value="start"
                    control={<Radio
                        checked={!isLegalEntity}
                        onChange={handleChange}
                        value={BuyerType.Person}
                        name="buyer"
                    />}
                    disabled={props.disabled}
                    label="Osoba"
                    labelPlacement="start"
                />
                <FormControlLabel
                    value="start"
                    control={<Radio
                        checked={isLegalEntity}
                        onChange={handleChange}
                        value={BuyerType.LegalEntity}
                        name="legalEntity"
                    />}
                    label="Tvrtka"
                    disabled={props.disabled}
                    labelPlacement="start"
                />
            </RadioGroup>
        </FormControl>
    );
}
