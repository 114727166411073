import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from './changeWarrantyDateModal.module.scss';

interface IProps {
    closeModal: () => void,
    submitValidModal: (warrantyId: string, validFrom: string, validUntil: string) => void,
    validFrom?: string,
    validUntil?: string,
    warrantyId?: string
}

interface IState {
    validFrom: string,
    validUntil: string,
    warrantyId: string
}

export class ChangeWarrantyDateModal extends React.Component<IProps> {

    state: IState = {
        validFrom: this.props.validFrom ? this.props.validFrom : "",
        validUntil: this.props.validUntil ? this.props.validUntil : "",
        warrantyId: this.props.warrantyId ? this.props.warrantyId : ""
    };


    private formRef = React.createRef<ValidatorForm>();

    handleSubmitModal = () => {
        this.props.submitValidModal(this.state.warrantyId, this.state.validFrom, this.state.validUntil);
        this.props.closeModal();
    }

    private handleValidFromChange(el: any) {
        const { value } = el.target;

        this.setState({ validFrom: value });
    }

    private handleValidUntilChange(el: any) {
        const { value } = el.target;

        this.setState({ validUntil: value });
    }

    render() {
        return (
            <React.Fragment>
                <ValidatorForm
                    onSubmit={() => this.handleSubmitModal()}
                    ref={this.formRef}
                    className={styles.Form}
                >
                    <Grid container direction="row" justify="center" >
                        <Grid>
                            <h4>Promijenite datume valjanosti jamstva</h4>
                        </Grid>
                        <Grid container item xs={12} md={12} justify="center">
                            <Grid container item className={styles.Date} xs={12} md={6} justify="center">
                                <TextValidator
                                    label="Jamstvo vrijedi od"
                                    name="validFrom"
                                    onChange={this.handleValidFromChange.bind(this)}
                                    disabled={false}
                                    value={this.state.validFrom}
                                />
                            </Grid>
                            <Grid container item className={styles.Date} xs={12} md={6} justify="center">
                                <TextValidator
                                    label="Jamstvo vrijedi do"
                                    name="validUntil"
                                    onChange={this.handleValidUntilChange.bind(this)}
                                    disabled={false}
                                    value={this.state.validUntil}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={12} justify="center">
                            <Grid container item className={styles.Date} xs={12} md={6} justify="center">
                                <h6>*format datuma mora biti identičan</h6>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} md={12} justify="center">
                            <Grid item>
                                <Button className={styles.Button} variant="contained" color="primary" type="submit">Spremi promjene</Button>
                            </Grid>

                        </Grid>
                    </Grid>

                </ValidatorForm>
            </React.Fragment>
        )
    }
}