import { Reducer } from "redux";
import ActionTypes from '../actions/ActionTypes';
import IBuyerProps from "../../types/IBuyerProps";
import IActivateWarranty from "../../types/IActivateWarranty";

const initialBuyersData: { loading: boolean, data: IBuyerProps[] } = { loading: false, data: new Array<IBuyerProps>() }

export const getBuyersReducer: Reducer<{ loading: boolean, data: IBuyerProps[] }> = (state = initialBuyersData, action) => {
    switch (action.type) {
        case ActionTypes.GET_BUYERS: return { loading: true, data: [] }
        case ActionTypes.GET_BUYERS_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_BUYERS_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}

const initialLegalBuyersData: { loading: boolean, data: IBuyerProps[] } = { loading: false, data: new Array<IBuyerProps>() }

export const getLegalBuyersReducer: Reducer<{ loading: boolean, data: IBuyerProps[] }> = (state = initialLegalBuyersData, action) => {
    switch (action.type) {
        case ActionTypes.GET_LEGAL_BUYERS: return { loading: true, data: [] }
        case ActionTypes.GET_LEGAL_BUYERS_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_LEGAL_BUYERS_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}

const initialActivationData: IActivateWarranty = {
    email: "",
    warrantyId: ""
}

export const activateWarranty: Reducer<IActivateWarranty> = (state = initialActivationData, action): IActivateWarranty => {
    switch (action.type) {
        case ActionTypes.ACTIVATE_WARRANTY:  return {...action.payload} 
        case ActionTypes.ACTIVATE_WARRANTY_SUCCESS:  return { ...action.payload} 
        case ActionTypes.ACTIVATE_WARRANTY_ERROR: return {...action.payload}
        default: return state;
    }
}


export const initialBuyer: IBuyerProps = {
    firstName: "",
    lastName: "",
    fullName: "",
    legalEntityName: "",
    legalEntity: false,
    billingAddress: "",
    carShowroomName: "",
    oib: "",
    address: "",
    email: "",
    zipCode: "",
    city: "",
    country: "",
    phoneNumber: "",
    carId: 0,
    activated: false,
    carSoldSuccess: false,
    buyerEditing: false,
    createdAt: "",
    createdBy: "",
    note: "",
    id: ""
}

const initialBuyerData: { loading: boolean, buyer: IBuyerProps | null } = { loading: false, buyer: initialBuyer }

export const buyerReducer: Reducer<{ loading: boolean, buyer: IBuyerProps | null }> = (state = initialBuyerData, action) => {
    switch (action.type) {
        case ActionTypes.SAVE_BUYER: return { ...state, loading: true, buyer: null, message: undefined, error: false }
        case ActionTypes.SAVE_BUYER_SUCCESS: return {
            ...state, loading: false, buyer: initialBuyer, message: {
                content: "Kupac uspješno registriran!",
                errorMessage: false
            }
        }
        case ActionTypes.SAVE_BUYER_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, buyer: null, error: true
        }
        case ActionTypes.GET_BUYER: return {
            loading: true, buyer: initialBuyer
        }
        case ActionTypes.GET_BUYER_SUCCESS: return {
            loading: false, buyer: action.payload
        }
        case ActionTypes.GET_BUYER_ERROR: return {
            loading: false, buyer: initialBuyer
        }
        case ActionTypes.DELETE_BUYER: return { ...state, loading: true, buyer: null, message: undefined, error: false }
        case ActionTypes.DELETE_BUYER_SUCCESS: return {
            ...state, loading: false, buyer: initialBuyer, message: {
                content: "Kupac uspješno deaktiviran!",
                errorMessage: false
            }
        }
        case ActionTypes.DELETE_BUYER_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, buyer: null, error: true
        }
        case ActionTypes.ACTIVATE_BUYER: return { ...state, loading: true, buyer: null, message: undefined, error: false }
        case ActionTypes.ACTIVATE_BUYER_SUCCESS: return {
            ...state, loading: false, buyer: initialBuyer, message: {
                content: "Kupac uspješno aktiviran!",
                errorMessage: false
            }
        }
        case ActionTypes.ACTIVATE_BUYER_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, buyer: null, error: true
        }
       
        default: return state;
    }
}


export default buyerReducer;

type BuyersAdminTotalCountData = { data: number };

export const buyersAdminTotalCountReducer: Reducer<BuyersAdminTotalCountData> = (state: BuyersAdminTotalCountData = { data: 0 }, action: any): BuyersAdminTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}