import React, { useState } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormConstants from '../../../constants/FormConstants';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { IForgotPassword } from './../../../types/IForgotPassword';
import styles from '../publicForms.module.scss';

interface IProps extends IForgotPassword {
    onSubmit: (email: string) => void
}

const EmailForm: React.FC<IProps> = (props: IProps) => {
    const [email, setEmail] = useState('');

    const handleChange = (event: any) => {
        const { value } = event.target;
        setEmail(value);
    }
    const onSubmit = () => {
        props.onSubmit(email);
    }

    return props.emailDelivered ?
        <div className={styles.EmailDelivered}>
            <Typography variant="h3" gutterBottom>
                Email Vam je uspješno poslan.
      </Typography> <Typography variant="h4" gutterBottom>
                Kako biste obnovili zaporku molimo Vas pratite upute u emailu.
      </Typography> </div> :
        <Paper className={styles.Root}>
            <ValidatorForm id={FormConstants.EMAIL_FORM} onSubmit={() => onSubmit()}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="h6" component="h6" >Molimo unesite email za aktivaciju nove zaporke</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <TextValidator
                            label="Email"
                            value={email}
                            validators={["required", "isEmail"]}
                            errorMessages={[
                                "Molimo unesite email", "Molimo unesite ispravan email"
                            ]} name="email"
                            onChange={handleChange}
                            fullWidth />
                    </Grid>
                    <Grid item xs={10}>
                        <Button variant="contained" color="primary" className={styles.SubmitButton} type="submit">Pošalji</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Paper >
}

export default EmailForm;