import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './global.scss';
import configureStore from './store';
import ActionTypes from './redux/actions/ActionTypes';
import configureContainer from './di/configureContainer';
import { Switch, Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router';
import WarrantyFormPage from './containers/warranty/WarrantyFormPage';
import CarFormPage from './containers/car/CarFormPage';
import WarrantyOverview from './containers/warranty/WarrantyOverview';
import PrivateRoute from './components/routing/PrivateRoute';
import LoginFormPage from './containers/authentication/LoginFormPage';
import AdminTableOverview from "./containers/admin/AdminTableOverview";
import AllWarrantiesOverview from "./containers/admin/AllWarrantiesOverview";
import ArchiveWarrantiesOverview from "./containers/admin/ArchiveWarrantiesOverview";
import SellerRegistration from "./containers/admin/SellerRegistration";
import BuyerRegistration from "./containers/admin/BuyerRegistration";
import WarrantiesForBuyer from "./containers/admin/WarrantiesForBuyer";
import WarrantiesForSeller from "./containers/admin/WarrantiesForSeller";
import { StylesProvider } from '@material-ui/styles';
import { isAdmin, isMarketing, isSuperAdmin } from './utils/AuthFunctions';
import DamagePage from './containers/damage/DamagePage';
import NewDamagePage from './containers/damage/NewDamagePage';
import DamageAdminOverview from './containers/damage/DamageAdminOverview';
import CarAdminOverview from './containers/car/CarAdminOverview';
import CarSellerOverview from './containers/car/CarSellerOverview';
import CarAdminOnlyCarsOverview from './containers/car/CarAdminOnlyCarsOverview';
import CarAdminSoldOnlyCarsOverview from './containers/car/CarAdminSoldOnlyCarsOverview';
import PublicRoute from './components/routing/PublicRoute';
import EmailFormPage from './containers/authentication/EmailFormPage';
import ConfirmPasswordPage from './containers/authentication/ConfirmPasswordPage';
import FirstLoginDashboard from './components/dashboards/firstLogin/FirstLoginDashboard';
import BuyersOverview from './containers/admin/BuyersOverview';
import DamageSellerOverview from './containers/damage/DamageSellerOverview';
import DamageBuyerOverview from './containers/damage/DamageBuyerOverview';
import DamageSellerByAdminOverview from './containers/damage/DamageSellerByAdminOverview';
import DamageWarrantyOverview from './containers/damage/DamageWarrantyOverview';
import DamageCarOverview from './containers/damage/DamageCarOverview';
import SellersOverview from './containers/admin/SellersOverview';
import MarketingSellersOverview from './containers/marketing/MarketingSellersOverview';
import OtherActiveSellersOverview from './containers/admin/OtherActiveSellersOverview';
import MarketingSoldOverview from './containers/marketing/MarketingSoldOverview';
import BuyerOverview from './containers/admin/BuyerOverview';
import SellerOverview from './containers/admin/SellerOverview';
import StatisticsMarketingWarrantiesOverview from './containers/statistics/StatisticsMarketingWarrantiesOverview';
import StatisticsWarrantiesOverview from './containers/statistics/StatisticsWarrantiesOverview';
import SellersUnactiveOverview from './containers/admin/SellersUnactiveOverview';
import TOS from './components/legal/TOS';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import SellerContract from './components/legal/SellerContract';
import Consent from './components/legal/Consent';
import BuyerSale from './containers/buyer/BuyerSale';
import PortalOverview from './containers/portal/PortalOverview';
import "react-datepicker/dist/react-datepicker.css";
import CarDetails from './containers/portal/CarDetails';
import InvoicesOverview from './containers/invoicing/InvoicesOverview';
import AnalyticsOverview from './components/analytics/AnalyticsOverview';
import ImportCars from './containers/admin/ImportCars';
import ReactGA from 'react-ga';
const container = configureContainer();
const history = createBrowserHistory();

const store = configureStore(history, {
    container: container
});
store.dispatch({ type: ActionTypes.INIT_STATE });

ReactGA.initialize('UA-154431706-2');


const App: React.FC = () => {
    return (
        <React.Fragment>
            <StylesProvider injectFirst>
                <ReduxProvider store={store}>
                    <Router history={history}>
                        <Switch>
                            <PrivateRoute exact path="/overview" component={WarrantyOverview} />
                            <PrivateRoute path="/warranty/:id?" component={WarrantyFormPage} />
                            <PrivateRoute path="/upgrade/warranty/:id?" component={WarrantyFormPage} />
                            <PrivateRoute path="/car/:id?" component={CarFormPage} />
                            <PrivateRoute path="/upgrade/car/:id?" component={CarFormPage} />
                            <PrivateRoute exact path="/prodano" component={AdminTableOverview} />
                            <PrivateRoute exact path="/soldMarketing" component={MarketingSoldOverview} />
                            <PrivateRoute exact path="/jamstva" component={AllWarrantiesOverview} />
                            <PrivateRoute exact path="/jamstva/arhiv" component={ArchiveWarrantiesOverview} />
                            <PrivateRoute exact path="/buyers" component={BuyersOverview} />
                            <PrivateRoute exact path="/buyers/:email/warranties" component={WarrantiesForBuyer} />
                            <PrivateRoute exact path="/seller/:id/warranties" component={WarrantiesForSeller} />
                            <PrivateRoute exact path="/sellers" component={SellersOverview} />
                            <PrivateRoute exact path="/statistics/marketingWarranties" component={StatisticsMarketingWarrantiesOverview} />
                            <PrivateRoute exact path="/statistics/warranties" component={StatisticsWarrantiesOverview} />
                            <PrivateRoute exact path="/otherActiveSellers" component={OtherActiveSellersOverview} />
                            <PrivateRoute exact path="/marketingSellers" component={MarketingSellersOverview} />
                            <PrivateRoute exact path="/unactiveSellers" component={SellersUnactiveOverview} />
                            {/*<PrivateRoute exact path="/portal" component={PortalOverview} />*/}
                            {/*<PrivateRoute exact path="/portal/:id" component={CarDetails} />*/}
                            <PrivateRoute exact path="/seller/:id" key="edit-seller" component={SellerOverview} />
                            <PrivateRoute exact path="/buyer/:id" key="edit-buyer" component={BuyerOverview} />
                            {/* pazi ako ces mjenjat naziv /create_seller, hardkodirano je u SellerRegistration kontroleru, gleda taj string na loadu*/}
                            <PrivateRoute exact path="/create_seller" key="create-seller" component={SellerRegistration} />
                            <PrivateRoute exact path="/create_buyer" key="create-buyer" component={BuyerRegistration} />
                            <PrivateRoute exact path="/seller_damages" component={DamageSellerOverview} />
                            <PrivateRoute exact path="/admin_damages" component={DamageAdminOverview} />
                            <PrivateRoute exact path="/cars" component={CarAdminOverview} />
                            <PrivateRoute exact path="/sellerCars" component={CarSellerOverview} />
                            <PrivateRoute exact path="/cars/upisani" component={CarAdminOnlyCarsOverview} />
                            <PrivateRoute exact path="/cars/prodani" component={CarAdminSoldOnlyCarsOverview} />
                            <PrivateRoute exact path="/buyer/:email/damages" component={DamageBuyerOverview} />
                            <PrivateRoute exact path="/sellerDamage/:id/damages" component={DamageSellerByAdminOverview} />
                            <PrivateRoute exact path="/carDamage/:id/damages" component={DamageCarOverview} />
                            <PrivateRoute exact path="/warranty_damage/:warrantyId" component={DamageWarrantyOverview} />
                            <PrivateRoute exact path="/racuni" component={InvoicesOverview} />
                            <PrivateRoute exact path="/izvjestaji" component={AnalyticsOverview} />
                            <PrivateRoute exact path="/damage/:carId/:warrantyId" component={NewDamagePage} />
                            <PrivateRoute exact path="/damage/:carId/:warrantyId/:damageId" component={DamagePage} />
                            <PrivateRoute exact path='/warranty-rules' component={BuyerSale} />
                            <PrivateRoute exact path="/uvoz" component={ImportCars} />
                            <PublicRoute exact path='/login' component={LoginFormPage} />
                            <PublicRoute path='/confirmPassword' component={ConfirmPasswordPage} />
                            <PublicRoute exact path='/forgotPassword' component={EmailFormPage} />
                            <PublicRoute exact path="/first_login" component={FirstLoginDashboard} />
                            <PublicRoute exact path="/tos" component={TOS} />
                            <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
                            <PublicRoute exact path="/seller-contract" component={SellerContract} />
                            <PublicRoute exact path="/consent" component={Consent} />
                            <PublicRoute exact path="/activate-warranty/:email?/:id?" component={BuyerSale} />
                            <Route exact path="/" render={() => (isAdmin() || isSuperAdmin()) ?
                                <Redirect to="/prodano" /> :
                                isMarketing() ? < Redirect to="/soldMarketing" /> :  < Redirect to="/overview" />
                            } />
                        </Switch>
                    </Router>
                </ReduxProvider>
            </StylesProvider>
        </React.Fragment>
    );
};

export default App;
