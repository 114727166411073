import { warrantyApiURL } from './../../constants/apiRoutes';
import {
    GetAdminTableData, GetWarrantyForBuyerAction, GetAdminArchiveTableData, GetWarrantyForSellerAction,
    GetWarrantyAdminTableTotalCountAction, GetWarrantyAdminBuyerTableTotalCountAction, GetWarrantyAdminSellerTableTotalCountAction,
    GetWarrantyAdminArchiveTableTotalCountAction
} from './../actions/WarrantyAction';
import { SagaIterator } from '@redux-saga/core';
import { Container } from 'inversify';
import { getContext, apply, put } from '@redux-saga/core/effects';
import AdminTableService from '../../domain/services/AdminTableService';
import ActionTypes from '../actions/ActionTypes';
import IAdminTableServices from '../../domain/services/IAdminTableService';


export function* getAdminTableData(action: GetAdminTableData): SagaIterator {
    const container: Container = yield getContext('container');
    const getAdminTableData = container.get<IAdminTableServices>(AdminTableService);
    try {
        const response = yield apply(getAdminTableData, getAdminTableData.getAdminTableData, [action.payload.page, action.payload.pageSize, action.payload.filters])
        yield put({ type: ActionTypes.GET_ADMIN_TABLE_DATA_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_ADMIN_TABLE_DATA_ERROR, payload: err })
    }
}

export function* getAdminTableForBuyerData(action: GetWarrantyForBuyerAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getAdminTableData = container.get<IAdminTableServices>(AdminTableService);
    try {
        const response = yield apply(getAdminTableData, getAdminTableData.getAdminTableForBuyerData, [action.payload.email])
        yield put({ type: ActionTypes.GET_WARRANTIES_FOR_BUYER_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTIES_FOR_BUYER_ERROR, payload: err })
    }
}


export function* getAdminArchiveTableData(action: GetAdminArchiveTableData): SagaIterator {
    const container: Container = yield getContext('container');
    const adminTableApi = container.get<IAdminTableServices>(AdminTableService);
    try {
        const response = yield apply(adminTableApi, adminTableApi.getAdminArchiveTableData, [action.payload.page, action.payload.pageSize, action.payload.filters])
        yield put({ type: ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA_ERROR, payload: err })
    }
}

export function* getAdminTableForSellerData(action: GetWarrantyForSellerAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getAdminTableData = container.get<IAdminTableServices>(AdminTableService);
    try {
        const response = yield apply(getAdminTableData, getAdminTableData.getAdminTableForSellerData, [action.payload.id])
        yield put({ type: ActionTypes.GET_WARRANTIES_FOR_SELLER_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTIES_FOR_SELLER_ERROR, payload: err })
    }
}

export function* getWarrantyAdminTableTotalCount(action: GetWarrantyAdminTableTotalCountAction) {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IAdminTableServices>(AdminTableService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.getWarrantyAdminTableTotalCount, []);
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getWarrantyAdminArchiveTableTotalCount(action: GetWarrantyAdminArchiveTableTotalCountAction) {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IAdminTableServices>(AdminTableService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.getWarrantyAdminArchiveTableTotalCount, []);
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getWarrantyAdminBuyerTableTotalCount(action: GetWarrantyAdminBuyerTableTotalCountAction) {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IAdminTableServices>(AdminTableService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.getWarrantyAdminBuyerTableTotalCount, [action.payload]);
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_BUYER_TABLE_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_BUYER_TABLE_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getWarrantyAdminSellerTableTotalCount(action: GetWarrantyAdminSellerTableTotalCountAction) {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IAdminTableServices>(AdminTableService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.getWarrantyAdminSellerTableTotalCount, [action.payload]);
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}