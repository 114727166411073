import React from 'react';
import LoginFormLayout from '../../components/authentication/PublicFormLayout';
import { IAuthenticationProps } from '../../types/IAuthenticationProps';
import { LoginSubmitAction } from '../../redux/actions/LoginAction';
import ActionTypes from '../../redux/actions/ActionTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import PublicFormLayout from '../../components/authentication/PublicFormLayout';
import LoginForm from '../../components/forms/loginForm';
import ReactGA from 'react-ga';

interface IDispatchToProps {
    onHandleSubmit: (event: IAuthenticationProps) => void
}

interface IStateToProps extends IAuthenticationProps {
    loading: boolean
}

interface IProps extends IDispatchToProps, IStateToProps { }

const LoginFormPage: React.FC<IProps> = (props: IProps) => {
    ReactGA.pageview("Login page");

    return <PublicFormLayout>
        <LoginForm {...props} handleSubmit={props.onHandleSubmit} />
    </PublicFormLayout>
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onHandleSubmit: (values: IAuthenticationProps) => dispatch<LoginSubmitAction>({
        type: ActionTypes.LOGIN_SUBMIT,
        payload: values
    })
})

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    email: state.login.data.email,
    password: state.login.data.password,
    error: state.login.data.error,
    loading: state.login.loading
})

export default connect<IStateToProps, IDispatchToProps, null, IApplicationState>(mapStateToProps, mapDispatchToProps)(LoginFormPage);