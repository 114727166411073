import { CurrentUserAction, ConfirmData } from './../actions/UserAction';
import { LoginSubmitAction } from '../actions/LoginAction';
import { SagaIterator } from '@redux-saga/core';
import { Container } from 'inversify';
import { getContext, apply, put, delay } from '@redux-saga/core/effects';
import IUserService from '../../domain/services/IUserService';
import UserService from '../../domain/services/UserService';
import ActionTypes from '../actions/ActionTypes';
import { RegisterSubmitAction } from './../actions/RegisterAction';
import { EmailSubmitAction, ConfirmPasswordAction } from '../actions/ForgotPasswordAction';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';
import IBuyerService from '../../domain/services/IBuyerService';
import BuyerService from '../../domain/services/BuyerService';
import { isBuyer } from '../../utils/AuthFunctions';
import { HAKRedirectURL } from '../../constants/RoleConstants';
import ReactGA from 'react-ga';

export function* userLogin(action: LoginSubmitAction): SagaIterator {
    const container: Container = yield getContext('container');
    const userService = container.get<IUserService>(UserService);
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        const userResponse = yield apply(userService, userService.login, [action.payload]);
        const buyerResponse = yield apply(buyerService, buyerService.getApprovedWarranties, [action.payload.email]);
        const warrantyId = buyerResponse.length > 0 ? buyerResponse[0].warrantyId : undefined;
        const activateUrl = 'activate-warranty/' + action.payload.email + '/' + warrantyId;
        const isHakRole = yield apply(userService, userService.isHakRole, []);
        if (isHakRole) window.location.href = HAKRedirectURL;
        else {
            yield put({ type: ActionTypes.LOGIN_SUCCESS, payload: userResponse });
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: userResponse.activated ? warrantyId ? activateUrl : '' : '/first_login' } })
            ReactGA.event({
                category: 'App',
                action: 'Login',
                label: action.payload.email.split('@')[1]
              });
        }
    } catch (err) {
        yield put({ type: ActionTypes.LOGIN_ERROR, payload: err })
    }
}

export function* userRegister(action: RegisterSubmitAction): SagaIterator {
    const container: Container = yield getContext('container');
    const userService = container.get<IUserService>(UserService);
    try {
        const response = yield apply(userService, userService.register, [action.payload]);
        yield put({ type: ActionTypes.REGISTER_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.LOGIN_ERROR, payload: err })
    }
}

export function* forgotPasswordRequest(action: EmailSubmitAction): SagaIterator {
    const container: Container = yield getContext('container');
    const userService = container.get<IUserService>(UserService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(userService, userService.forgotPasswordRequest, [action.payload]);
        yield put({ type: ActionTypes.EMAIL_SUBMIT_SUCCESS, payload: response });
        ReactGA.event({
            category: 'App',
            action: 'Forgot password',
            label: action.payload
          });
    } catch (err) {
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Slanje email-a nije uspjelo',
                style: NotificationStyle.Error
            }
        });
        yield put({ type: ActionTypes.EMAIL_SUBMIT_ERROR, payload: err })
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* confirmPassword(action: ConfirmPasswordAction): SagaIterator {
    const container: Container = yield getContext('container');
    const userService = container.get<IUserService>(UserService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(userService, userService.confirmPassword, [action.payload]);
        yield put({ type: ActionTypes.PASSWORD_CONFIRM_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Lozinka je uspješno ažurirana.',
                style: NotificationStyle.Success
            }
        });
        yield delay(3000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Lozinka je uspješno ažurirana.',
                style: NotificationStyle.Success
            }
        });
        yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/login' } });
    } catch (err) {
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Ažuriranje lozinke nije uspjelo',
                style: NotificationStyle.Error
            }
        });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({ type: ActionTypes.PASSWORD_CONFIRM_ERROR, payload: err })
    }
}
export function* currentUser(action: CurrentUserAction): SagaIterator {
    const container: Container = yield getContext('container');
    const userService = container.get<IUserService>(UserService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(userService, userService.getCurrentUser, []);
        yield put({ type: ActionTypes.GET_CURRENT_USER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CURRENT_USER_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* confirmData(action: ConfirmData): SagaIterator {
    const container: Container = yield getContext('container');
    const userService = container.get<IUserService>(UserService);
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(userService, userService.confirmData, [action.payload]);
        const buyerResponse = yield apply(buyerService, buyerService.getApprovedWarranties, [action.payload.email]);
        const warrantyId = buyerResponse.length > 0 ? buyerResponse[0].warrantyId : undefined;
        const activateUrl = 'activate-warranty/' + action.payload.email + '/' + warrantyId;
        yield put({ type: ActionTypes.CONFIRM_DATA_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Podaci su uspješno potvrđeni.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Podaci su uspješno potvrđeni.',
                style: NotificationStyle.Success
            }
        });
        yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: warrantyId ? activateUrl : '/' } });
    } catch (err) {
        yield put({ type: ActionTypes.CONFIRM_DATA_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Podaci nisu potvrđeni.',
                style: NotificationStyle.Error
            }
        });
    }
}
