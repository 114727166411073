import { SagaIterator } from '@redux-saga/core';
import { Container } from 'inversify';
import { getContext, apply, put, delay } from '@redux-saga/core/effects';
import CarService from '../../domain/services/CarService';
import { EditMissingCarDetailsAction } from '../actions/EditMissingCarDetailsAction';
import ActionTypes from '../actions/ActionTypes';
import ICarService from '../../domain/services/ICarService';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';
import ReactGA from 'react-ga';

export function* editMissingCarDetails(action: EditMissingCarDetailsAction): SagaIterator {
    const container: Container = yield getContext('container');
    const editMissingCarDetailsApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(editMissingCarDetailsApi, editMissingCarDetailsApi.editMissingCarDetails, [action.payload]);
        yield put({ type: ActionTypes.GET_PACKAGES_SUCCESS, payload: response })
        yield put({ type: ActionTypes.GET_WARRANTIES })
        yield put({ type: ActionTypes.SET_IS_MISSING_CAR_DETAILS, payload: { isCarDetailsMissing: false }})
        ReactGA.event({
            category: 'Popunjavanje podataka o autu koji nedostaju',
            action: 'Spremljena šasija',
          });
        
        yield put({ type: ActionTypes.LOADING_END })
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Dodatni podaci spremljeni, možete nastaviti unositi podatke o kupcu.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Dodatni podaci spremljeni, možete nastaviti unositi podatke o kupcu.',
                style: NotificationStyle.Success
            }
        });
    } catch (err) {
        yield put({ type: ActionTypes.GET_PACKAGES_ERROR, payload: err })
    }
}