import { Reducer } from "redux";
import ActionTypes from "../actions/ActionTypes";
import IActionNotification from "../../types/utilities/IActionNotification";

const initialNotificationValues: IActionNotification = {
    message: undefined,
    style: undefined,
    show: false
}

const actionNotificationReducer: Reducer<IActionNotification> = (state: IActionNotification = initialNotificationValues, action): IActionNotification => {
    switch (action.type) {
        case ActionTypes.NOTIFICATION_SHOW: return { ...action.payload, show: true }
        case ActionTypes.NOTIFICATION_CLOSE: return initialNotificationValues
        default: return state;
    }
}

export default actionNotificationReducer;


