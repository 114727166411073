import React, { useEffect, useState } from 'react';
import AdminWarrantyTable from '../../components/tables/AdminWarrantyTable'
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import IAdminAttrs from './../../types/IAdminAttrs'
import { connect } from 'react-redux';
import { GetAdminTableData } from '../../redux/actions/WarrantyAction';
import { Grid } from '@material-ui/core';
import style from "./../../components/warrantyReview/warrantyView.module.scss"
import ICarData from '../../types/ICarData';
import { GetCarDataAction } from '../../redux/actions/CarAction';
import { ThisMonthTotalWarrantiesSold } from '../../components/cards/thisMonthTotalWarrantiesSold';
import { TotalWarranties } from '../../components/cards/totalWarranties';
import SoldWarrantiesTable from '../../components/tables/SoldWarrantiesTable';
import { ISoldWarranties } from '../../types/ISoldWarranties';
import ISellerProps from '../../types/ISellerProps';
import { GetAllSellersAction } from '../../redux/actions/SellerAction';
import { ActionMeta, ValueType } from "react-select/src/types";
import ReactGA from 'react-ga';
import { ICurrency } from '../../types/ICurrency';
import { MarketingThisMonthSoldTotalWarranties } from '../../components/cards/MarketingThisMonthSoldTotalWarranties';
import { LastYearThisMonthTotalSoldWarranties } from '../../components/cards/LastYearThisMonthTotalSoldWarranties';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';

interface IStateToProps {
    thisMonthtotalSoldWarranties: {
        lastMonth: number,
        thisMonth: number
    },
    soldWarranties: { data: ISoldWarranties, loading: boolean },
    sellers: ISellerProps[],
    lastInvoice: string,
    thisMonthtotalSoldWarrantiesLoading: boolean,
    currencies: ICurrency[],
    marketingThisMonthTotalSoldWarranties: {
        lastMonth: number,
        thisMonth: number
    },
    lastYearThisMonthTotalSoldWarranties: {
        lastMonth: number,
        thisMonth: number
    },
    marketingThisMonthTotalSoldWarrantiesLoading: boolean,
    lastYearThisMonthTotalSoldWarrantiesLoading: boolean
}

interface IDispatchToProps {
    getThisMonthSoldStatistics: (startDate: string, endDate: string, sellerName: string) => void,
    getSoldStatistics: (startDate: string, endDate: string, sellerName: string, skip: number, take: number) => void,
    getAllSellers: () => void,
    getLastInvoice?: () => void;
    getCurrencies: () => void;
    getMarketingThisMonthSoldStatistics: (startDate: string, endDate: string) => void,
    getLastYearThisMonthTotalSoldWarranties: (startDate: string, endDate: string) => void,

}

interface IProps extends IStateToProps, IDispatchToProps { }

const AdminTableOverview: React.FC<IProps> = props => {

    var date = new Date();
    const [firstDay, setFirstDay] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [lastDay, setLastDay] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const [selectedSeller, setSelectedSeller] = useState<any>();



    useEffect(() => {
        ReactGA.pageview("Admin | prodana jamstva");
        props.getThisMonthSoldStatistics(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"), 'all');
        props.getSoldStatistics(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"), '', 0, 5);
        props.getMarketingThisMonthSoldStatistics(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"));
        props.getLastYearThisMonthTotalSoldWarranties(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"));
        props.getAllSellers()
        props.getLastInvoice!();
        props.getCurrencies();
    }, []);


    const handleStartDateChange = (date: Date | null) => {
        setFirstDay(date!);
      };

    const handleEndDateChange = (date: Date | null) => {
        setLastDay(date!);
      };

    const handleSelectSellerChange = (value: ValueType<{ label: string, value: string }>) => {
        setSelectedSeller(value);
      };
    
      const handleSearch = (skip: number, take: number) => {
        props.getSoldStatistics(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"), selectedSeller ? selectedSeller.label : '', skip, take)
      }
    return (
        <React.Fragment>
            <Grid container direction="row" style={{ display: 'block' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={6} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        <ThisMonthTotalWarrantiesSold loading={props.thisMonthtotalSoldWarrantiesLoading} thisMonthtotalSoldWarranties={props.thisMonthtotalSoldWarranties} currentMonth={date.getMonth() + 1} />
                    </Grid>
                    {(isAdmin() || isSuperAdmin()) && <Grid item xs={6} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        <MarketingThisMonthSoldTotalWarranties loading={props.marketingThisMonthTotalSoldWarrantiesLoading} thisMonthTotalSoldWarranties={props.marketingThisMonthTotalSoldWarranties} currentMonth={date.getMonth() + 1} />
                    </Grid>}
                    {(isAdmin() || isSuperAdmin()) && <Grid item xs={6} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        <LastYearThisMonthTotalSoldWarranties loading={props.lastYearThisMonthTotalSoldWarrantiesLoading} thisMonthTotalSoldWarranties={props.lastYearThisMonthTotalSoldWarranties} currentMonth={date.getMonth() + 1} />
                    </Grid>}
                </Grid>
                <SoldWarrantiesTable soldWarranties={props.soldWarranties} startDate={firstDay} endDate={lastDay} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} handleSearch={handleSearch} sellers={props.sellers}
                    handleSelectSellerChange={handleSelectSellerChange} selctedSeller={selectedSeller} lastInvoice={props.lastInvoice} currencies={props.currencies} />
                <br />
            </Grid>

        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    thisMonthtotalSoldWarranties: state.thisMonthStatistics.thisMonth.totalSoldWarranties,
    thisMonthtotalSoldWarrantiesLoading: state.thisMonthStatistics.loading,
    soldWarranties: state.soldWarrantiesStatistics,
    sellers: state.sellers.data,
    lastInvoice: state.lastInvoice.data,
    currencies: state.currencies.data,
    marketingThisMonthTotalSoldWarranties: state.marketingThisMonthStatistics.thisMonth.totalSoldWarranties,
    marketingThisMonthTotalSoldWarrantiesLoading: state.marketingThisMonthStatistics.loading,
    lastYearThisMonthTotalSoldWarranties: state.lastYearThisMonthStatistics.thisMonth.totalSoldWarranties,
    lastYearThisMonthTotalSoldWarrantiesLoading: state.lastYearThisMonthStatistics.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getThisMonthSoldStatistics: (startDate: string, endDate: string, sellerName: string) => {
        dispatch({
            type: ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC,
            payload: { startDate, endDate, sellerName }
        })
    },
    getSoldStatistics: (startDate: string, endDate: string, sellerName: string, skip: number, take: number) => {
        dispatch({
            type: ActionTypes.GET_SOLD_STATISTIC,
            payload: { startDate, endDate, sellerName, skip, take }
        })
    },
    getAllSellers: () => dispatch<GetAllSellersAction>({
        type: ActionTypes.GET_SELLERS
    }),
    getLastInvoice: () => dispatch({ type: ActionTypes.GET_LAST_INVOICE }),
    getCurrencies: () => dispatch({ type: ActionTypes.GET_CURRENCIES }),
    getMarketingThisMonthSoldStatistics: (startDate: string, endDate: string) => {
        dispatch({
            type: ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC,
            payload: { startDate, endDate }
        })
    },
    getLastYearThisMonthTotalSoldWarranties: (startDate: string, endDate: string) => {
        dispatch({
            type: ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC,
            payload: { startDate, endDate }
        })
    }
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(AdminTableOverview);
