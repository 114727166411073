import React, { useState, useEffect, ReactNode } from 'react';
import styles from './navbar.module.scss';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { List, ListItemText, Drawer, Divider, ListItemIcon, Box, Grid } from '@material-ui/core';
import { Link as RouterLink, Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ChevronRightIcon from '@material-ui/icons/MoreVert';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import BuyerIcon from '@material-ui/icons/Payment';
import SellerIcon from '@material-ui/icons/Shop';
import SubscribeIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import RegisterUser from '@material-ui/icons/PersonAddOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LogOutIcon from '@material-ui/icons/ExitToAppOutlined'
import UserService from './../../domain/services/UserService';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dashboard from '@material-ui/icons/Dashboard';
import DamageIcon from '@material-ui/icons/Description';
import Receipt from '@material-ui/icons/Receipt';
import Web from '@material-ui/icons/Web';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import DirectionsCarOutlined from '@material-ui/icons/DirectionsCarOutlined';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useStyles } from './NavBar.animations';
import { useTheme } from '@material-ui/core/styles';
import { isAdmin, isAuthenticated, isSeller, isBuyer, isMarketing, isSuperAdmin } from '../../utils/AuthFunctions';
import { instructionsToActivate } from '../../constants/apiRoutes';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FileCopy from '@material-ui/icons/FileCopy';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';

interface IProps {
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
    history: any,
    children?: ReactNode
}

const useNestedStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

const Navbar: React.FC<IProps> = props => {
    const userService = new UserService();
    const [authenticated, setIsAuthenticated] = useState(isAuthenticated())
    const [admin, setIsAdmin] = useState(isAdmin() && isAuthenticated())
    const [seller, setIsSeller] = useState(isSeller() && isAuthenticated())
    const [buyer, setIsBuyer] = useState(isBuyer() && isAuthenticated())
    const [marketing, setIsMarketing] = useState(isMarketing() && isAuthenticated())
    const [superAdmin, setIsSuperAdmin] = useState(isSuperAdmin() && isAuthenticated())
    const classes = useStyles();
    const nestedClasses = useNestedStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [openJamstva, setOpenJamstva] = React.useState(false);
    const [openKupac, setOpenKupac] = React.useState(false);
    const [openProdavac, setOpenProdavac] = React.useState(false);
    const [openVozilo, setOpenVozilo] = React.useState(false);
    const [openStatistika, setOpenStatistika] = React.useState(false);

    useEffect(() => {
        setIsAuthenticated(isAuthenticated());
        setIsSeller(isSeller() && isAuthenticated());
        setIsBuyer(isBuyer() && isAuthenticated());
        setIsAdmin(isAdmin() && isAuthenticated());
        setIsMarketing(isMarketing() && isAuthenticated());
        setIsSuperAdmin(isSuperAdmin() && isAuthenticated());
    }, [props]);

    useEffect(() => {
        window.addEventListener('touchmove', handleMove);
    }, [])

    function closeNavBar() {
        window.innerWidth <= 600 ? setOpen(false) : '';
        setOpenProdavac(false);
        setOpenJamstva(false);
        setOpenVozilo(false);
        setOpenKupac(false);
        setOpenStatistika(false);
    }

    const logout = () => {
        userService.logout();
        props.history.push('/login');
    }

    const handleClickJamstva = () => {
        setOpenJamstva(!openJamstva);
        setOpenKupac(false);
        setOpenProdavac(false);
        setOpenVozilo(false);
        setOpenStatistika(false);
    };

    const handleClickProdavac = () => {
        setOpenProdavac(!openProdavac);
        setOpenKupac(false);
        setOpenJamstva(false);
        setOpenVozilo(false);
        setOpenStatistika(false);
    };

    const handleClickKupac = () => {
        setOpenKupac(!openKupac);
        setOpenProdavac(false);
        setOpenJamstva(false);
        setOpenVozilo(false);
        setOpenStatistika(false);
    };

    const handleClickVozilo = () => {
        setOpenVozilo(!openVozilo);
        setOpenProdavac(false);
        setOpenJamstva(false);
        setOpenKupac(false);
        setOpenStatistika(false);
    };

    const handleClickStatistika = () => {
        setOpenVozilo(false);
        setOpenProdavac(false);
        setOpenJamstva(false);
        setOpenKupac(false);
        setOpenStatistika(!openStatistika);
    };

    const handleMove = (ev: any) => {
        setOpen(ev.touches[0].clientX < 0)
    }

    return (
        <div className={styles.Root}>
            <CssBaseline />
            <div className={clsx(classes.appBar, styles.MenuBar, {
                [classes.appBarShift]: open,
            })}>
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? <MenuIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Drawer
                variant="permanent"
                className={clsx(styles.Drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                }, !open && styles.DrawerClose)}
                classes={{
                    paper: clsx(styles.Img, styles.Drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    }, !open && styles.DrawerClose)
                }}
                open={open}
            >
                <div className={`${styles.Home} ${styles.Toolbar}`}>
                    <Link to="/"><HomeIcon /></Link>
                </div>
                <span className={styles.name}>{localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')!).fullName}</span>
                <Divider variant="middle" />
                {authenticated &&
                    <div>
                        <List>
                            {(!admin && !superAdmin) && !marketing && <ListItem button component={RouterLink} to="/overview" onClick={() => closeNavBar()}>
                                <ListItemIcon><Dashboard /></ListItemIcon>
                                <ListItemText>Pregled jamstava</ListItemText>
                            </ListItem>}
                            {seller && <ListItem button component={RouterLink} to="/sellerCars" onClick={() => closeNavBar()}>
                                <ListItemIcon><DirectionsCar /></ListItemIcon>
                                <ListItemText>Pregled vozila</ListItemText>
                            </ListItem>}
                            {(admin || superAdmin) && <ListItem button component={RouterLink} to="/prodano" onClick={() => closeNavBar()}>
                                <ListItemIcon><Dashboard /></ListItemIcon>
                                <ListItemText>Pregled prodanih</ListItemText>
                        </ListItem>}
                        {marketing && <ListItem button component={RouterLink} to="/soldMarketing" onClick={() => closeNavBar()}>
                            <ListItemIcon><Dashboard /></ListItemIcon>
                            <ListItemText>Pregled prodanih</ListItemText>
                        </ListItem>}
                            {(admin || superAdmin) && <ListItem button component={RouterLink} to="/jamstva" onClick={handleClickJamstva}>
                                <ListItemIcon><FileCopy /></ListItemIcon>
                                <ListItemText>Jamstva</ListItemText>
                                {openJamstva ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>}
                            <Collapse in={openJamstva} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/jamstva">
                                        <ListItemIcon><FileCopyOutlined /></ListItemIcon>
                                        <ListItemText>Aktivna</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                            <Collapse in={openJamstva} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/jamstva/arhiv">
                                        <ListItemIcon><ArchiveIcon /></ListItemIcon>
                                        <ListItemText>Arhiv</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                            {(admin || superAdmin) && <ListItem button component={RouterLink} to="/sellers" onClick={handleClickProdavac}>
                                <ListItemIcon><SellerIcon /></ListItemIcon>
                                <ListItemText>Prodavači</ListItemText>
                                {openProdavac ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>}
                            <Collapse in={openProdavac} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/sellers">
                                        <ListItemIcon><Dashboard /></ListItemIcon>
                                        <ListItemText>Aktivni Njuškalo</ListItemText>
                                    </ListItem>}
                                </List>
                        </Collapse>
                        <Collapse in={openProdavac} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/otherActiveSellers">
                                    <ListItemIcon><Dashboard /></ListItemIcon>
                                    <ListItemText>Ostali aktivni</ListItemText>
                                </ListItem>}
                            </List>
                        </Collapse>
                        <Collapse in={openProdavac} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/unactiveSellers">
                                    <ListItemIcon><ArchiveIcon /></ListItemIcon>
                                    <ListItemText>Neaktivni</ListItemText>
                                </ListItem>}
                            </List>
                        </Collapse>
                            <Collapse in={openProdavac} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/create_seller">
                                        <ListItemIcon><RegisterUser /></ListItemIcon>
                                        <ListItemText>Registracija</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                            {(admin || superAdmin) && <ListItem button component={RouterLink} to="/buyers" onClick={handleClickKupac}>
                                <ListItemIcon><BuyerIcon /></ListItemIcon>
                                <ListItemText>Kupci</ListItemText>
                                {openKupac ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>}
                            <Collapse in={openKupac} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/buyers">
                                        <ListItemIcon><Dashboard /></ListItemIcon>
                                        <ListItemText>Pregled</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                            <Collapse in={openKupac} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/create_buyer">
                                        <ListItemIcon><PersonAddIcon /></ListItemIcon>
                                        <ListItemText>Registracija</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                            {(admin || superAdmin) && <ListItem button onClick={handleClickVozilo}>
                                <ListItemIcon><DirectionsCar /></ListItemIcon>
                                <ListItemText>Vozila</ListItemText>
                                {openVozilo ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>}
                            <Collapse in={openVozilo} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/car/step=1">
                                        <ListItemIcon><NoteAddIcon /></ListItemIcon>
                                        <ListItemText>Dodaj</ListItemText>
                                    </ListItem>}
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/cars">
                                        <ListItemIcon><DirectionsCar /></ListItemIcon>
                                        <ListItemText>S jamstvom</ListItemText>
                                    </ListItem>}
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/cars/prodani">
                                    <ListItemIcon><DirectionsCarOutlined /></ListItemIcon>
                                        <ListItemText>Bez jamstva</ListItemText>
                                    </ListItem>}
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/cars/upisani">
                                        <ListItemIcon><DirectionsCar /></ListItemIcon>
                                        <ListItemText>Upisana</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                            {(seller) && <ListItem button component={RouterLink} to="/car/step=1" onClick={() => closeNavBar()}>
                                <ListItemIcon><NoteAddIcon /></ListItemIcon>
                                <ListItemText>Dodaj vozilo</ListItemText>
                            </ListItem>}
                            {(superAdmin) && <ListItem button component={RouterLink} to="/racuni" onClick={() => closeNavBar()}>
                                <ListItemIcon><Receipt /></ListItemIcon>
                                <ListItemText>Računi</ListItemText>
                            </ListItem>}
                            {((admin || superAdmin) || seller) && <ListItem button component={RouterLink}
                                to={(admin || superAdmin) ? '/admin_damages' : '/seller_damages'} onClick={() => closeNavBar()}>
                                <ListItemIcon><DamageIcon /></ListItemIcon>
                                <ListItemText>Štete</ListItemText>
                            </ListItem>}
                            {(seller) && <ListItem button component={Link} target="_blank" to={instructionsToActivate} onClick={() => closeNavBar()}>
                                <ListItemIcon><PictureAsPdf /></ListItemIcon>
                                <ListItemText>Upute za aktivaciju</ListItemText>
                        </ListItem>}
                        {(seller && (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')!).ugovorPdfUrl !== 'NEMA')) && <ListItem button onClick={() => closeNavBar()}>
                            <ListItemIcon><PictureAsPdf /></ListItemIcon><a href={localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')!).ugovorPdfUrl} style={{ color: "white", textDecoration: "none" }} target="_blank">Ugovor</a>
                        </ListItem>}
                            {buyer && <ListItem button component={RouterLink} to="/warranty-rules" onClick={() => closeNavBar()}>
                                <ListItemIcon><AssignmentIcon /></ListItemIcon>
                                <ListItemText>Pravila jamstva</ListItemText>
                            </ListItem>}
                            {(admin || superAdmin) && <ListItem button onClick={handleClickStatistika}>
                                <ListItemIcon><FileCopyOutlined /></ListItemIcon>
                                <ListItemText>Statistika</ListItemText>
                                {openStatistika ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>}
                            <Collapse in={openStatistika} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/statistics/marketingWarranties">
                                        <ListItemIcon><SellerIcon /></ListItemIcon>
                                        <ListItemText>Njuškalo</ListItemText>
                                    </ListItem>}
                                    {(admin || superAdmin) && <ListItem button className={nestedClasses.nested} component={RouterLink} to="/statistics/warranties">
                                        <ListItemIcon><DirectionsCar /></ListItemIcon>
                                        <ListItemText>Sva jamstva</ListItemText>
                                    </ListItem>}
                                </List>
                            </Collapse>
                        </List>
                    </div>
                }
                {authenticated &&
                    <div>
                        <Divider variant="middle" />
                        <List>
                            <ListItem button onClick={logout} component={RouterLink} to="/warranty">
                                <ListItemIcon className={styles.Icon}><LogOutIcon style={{ transform: 'rotate(180deg)' }} /></ListItemIcon>
                                <ListItemText>Odjavi se</ListItemText>
                            </ListItem>
                        </List>
                    </div>}
                {!authenticated &&
                    <div>
                        <List>
                            <ListItem button onClick={logout} component={RouterLink} to="/login">
                                <ListItemIcon><SubscribeIcon /></ListItemIcon>
                                <ListItemText>Prijavi se</ListItemText>
                            </ListItem>
                        </List>
                    </div>}
            </Drawer>
            <Box p={{ xs: 2 }} flexGrow={1}>
                <Grid className={styles.Content} container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
}

export default withRouter(props => <Navbar {...props} />);