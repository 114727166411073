import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import IBuyerProps from '../../../types/IBuyerProps';
import { Button, SnackbarContent, Grid, Tooltip } from '@material-ui/core';
import styles from './buyerForm.module.scss';
import { initialBuyerProp } from '../../../redux/reducers/carReducer';
import Validator from '../../../utils/validation';
import { BuyerRadioButtons } from './BuyerRadioButtons';
import NumberFormat from 'react-number-format';
import { red } from '@material-ui/core/colors'
import RateReviewIcon from '@material-ui/icons/RateReview';
import { uuid } from 'uuidv4';
import { isAdmin, isSuperAdmin } from '../../../utils/AuthFunctions';

interface IProps {
    buyer: IBuyerProps,
    submit: (buyer: IBuyerProps) => void,
    getBuyerByOIB: (oib: string) => void,
    closeModal: () => void,
    carIsSold: boolean,
    isEditable: boolean,
    buyerEmail: string
}

interface IState extends IBuyerProps {
    disableControls?: boolean,
    oibIsValid?: boolean,
    resetForm?: boolean,
    initialLoad?: boolean,
    confirmEmail?: string
}

export class BuyerForm extends React.Component<IProps> {
    state: IState = {
        ...initialBuyerProp,
        disableControls: false,
        oibIsValid: false,
        resetForm: false,
        initialLoad: true,
        confirmEmail: ""
    };

    private formRef = React.createRef<ValidatorForm>();

    componentDidMount() {
        ValidatorForm.addValidationRule('isOIB', (oib: string) => Validator.validateOIB(oib))
        ValidatorForm.addValidationRule('isPhoneNumber', (number: string) => Validator.validatePhoneNumber(number))
        ValidatorForm.addValidationRule('isEmailMatch', (email: string) => Validator.isEmailMatch(email, this.state.email));
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
        const buyerByOibSuccess = prevState.oibIsValid && nextProps.buyer.oib && prevState.oib === nextProps.buyer.oib;
        // Reset form
        if (prevState.resetForm) return { ...prevState, resetForm: false };

        // User data fetched successfully by oib 
        if (buyerByOibSuccess) return { ...nextProps.buyer, disableControls: !nextProps.isEditable && true, initialLoad: false };

        // Initial user load
        if (nextProps.buyer.oib && prevState.initialLoad) return { ...nextProps.buyer, disableControls: !nextProps.isEditable && true, initialLoad: false };
        // Input elements changing values
        return { ...prevState, disableControls: false };
    }

    private handleChange(el: any) {
        const { name, value } = el.target;
        let oibIsValid = false;

        //if (name === 'oib') { ne smije se prema oibu dohvacati... sve radi preko emaila
        //    if (Validator.validateOIB(value)) {
        //        this.props.getBuyerByOIB(value);
        //        oibIsValid = true;
        //    }
        //}

        this.setState({ [name]: value, oibIsValid });
    }

    public handleSubmit() {
        if (this.formRef) this.props.submit(this.getBuyerFromState());
    }

    public resetForm() {
        this.setState((prevState: IState) => ({ ...initialBuyerProp, carId: prevState.carId, oibIsValid: false, disableControls: false, resetForm: true, legalEntity: false }));
    }

    public generateRandomEmail() {
        const randomMail = uuid() + '@nemamail.hr';
        this.setState({ email: randomMail, confirmEmail: randomMail });
    }

    private getBuyerFromState(): IBuyerProps {
        const state = this.state;
        return {
            firstName: state.firstName, lastName: state.lastName, oib: state.oib,
            address: state.address, email: this.props.carIsSold ? state.email : this.props.buyerEmail, zipCode: state.zipCode,
            city: state.city, country: state.country, phoneNumber: state.phoneNumber,
            legalEntityName: state.legalEntityName, legalEntity: state.legalEntity,
            buyerEditing: state.buyerEditing, id: state.id
        };
    }

    render() {
        const { disableControls, ...formControls } = { ...this.state };
        return (
            <React.Fragment>
                <ValidatorForm
                    onSubmit={() => this.handleSubmit()}
                    ref={this.formRef}
                    className={styles.Form}
                >
                    <Grid container alignItems="flex-start" direction="row" spacing={2}>
                        <Grid item xs={12}><div style={{float: 'right', cursor: 'pointer'}} onClick={() => this.props.closeModal()}>X</div></Grid>
                        <Grid item xs={12} justify="flex-start" alignItems="flex-start"><p style={{ fontSize: 20, paddingLeft: 20 }}>{!this.props.carIsSold ? 'Ispunite podatke o kupcu' : 'Pregled podataka o kupcu'}</p>
                        </Grid>
                        <Grid item xs={12} direction="column" justify="center" alignItems="flex-start">
                            <BuyerRadioButtons disabled={disableControls!}
                                legalEntity={this.state.legalEntity!}
                                buyerEntityChanged={(legalEntity: boolean) => this.setState({ legalEntity })} />
                        </Grid>
                        <Grid container xs={12} md={6} direction="column" className={styles.OuterGrid}>
                            <Grid  xs={12} item className={styles.Grid}>
                                <TextValidator
                                    label="OIB"
                                    onChange={this.handleChange.bind(this)}
                                    name="oib"
                                    value={formControls.oib}
                                    validators={['required', 'isOIB']}
                                    errorMessages={['Ovo polje je obavezno.', 'Molimo unesite ispravan OIB']}
                                    disabled={disableControls}
                                    className={styles.BuyerInputs}
                                />
                            </Grid>
                            {this.state.legalEntity ?
                                <Grid item className={styles.Grid}>
                                    <TextValidator
                                        label="Naziv tvrtke"
                                        onChange={this.handleChange.bind(this)}
                                        name="legalEntityName"
                                        value={formControls.legalEntityName}
                                        validators={['required']}
                                        errorMessages={['Ovo polje je obavezno.']}
                                        disabled={disableControls}
                                        className={styles.BuyerInputs}
                                    />
                                </Grid> :
                                <div>
                                    <Grid item className={styles.Grid}>
                                        <TextValidator
                                            label="Ime"
                                            onChange={this.handleChange.bind(this)}
                                            name="firstName"
                                            value={formControls.firstName}
                                            validators={['required']}
                                            errorMessages={['Ovo polje je obavezno.']}
                                            disabled={disableControls}
                                            className={styles.BuyerInputs}
                                        />
                                    </Grid>
                                    <Grid item className={styles.Grid}>
                                        <TextValidator
                                            label="Prezime"
                                            onChange={this.handleChange.bind(this)}
                                            name="lastName"
                                            value={formControls.lastName}
                                            validators={['required']}
                                            disabled={disableControls}
                                            errorMessages={['Ovo polje je obavezno.']}
                                            className={styles.BuyerInputs}
                                        />
                                    </Grid>
                                </div>
                            }
                            <Grid item className={styles.Grid}>
                                <TextValidator
                                    label="Email"
                                    onChange={this.handleChange.bind(this)}
                                    name="email"
                                    value={this.props.carIsSold ? formControls.email : this.props.buyerEmail}
                                    validators={['required', 'isEmail']}
                                    disabled={disableControls || this.props.carIsSold || !this.state.initialLoad || (!isAdmin && !isSuperAdmin)}
                                    errorMessages={['Ovo polje je obavezno.', 'Neispravan email']}
                                    className={styles.BuyerInputs}
                                    style={{ width: this.props.carIsSold ? "100%" : "80%"}}
                                />
                                {!this.props.carIsSold && <div style={{ float: 'right', cursor: 'pointer', paddingTop: 15 }}>
                                    <Tooltip title="Generiraj random e-mail">
                                        <RateReviewIcon onClick={() => this.generateRandomEmail()} />
                                    </Tooltip>
                                </div>}
                            </Grid>
                            {/*{!this.props.carIsSold && <Grid item className={styles.Grid}>*/}
                            {/*    <TextValidator*/}
                            {/*        label="Ponovite email"*/}
                            {/*        onChange={this.handleChange.bind(this)}*/}
                            {/*        name="confirmEmail"*/}
                            {/*        value={formControls.confirmEmail}*/}
                            {/*        validators={['required', 'isEmail', 'isEmailMatch']}*/}
                            {/*        disabled={disableControls}*/}
                            {/*        errorMessages={['Ovo polje je obavezno.', 'Neispravan email', 'Niste unijeli jednaki email.']}*/}
                            {/*        className={styles.BuyerInputs}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            {/*}*/}
                        </Grid>
                        <Grid item xs={12} md={6} direction="column" className={styles.OuterGrid}>
                            <Grid item className={styles.Grid}>
                                <TextValidator
                                    label="Adresa"
                                    onChange={this.handleChange.bind(this)}
                                    name="address"
                                    disabled={disableControls}
                                    value={formControls.address}
                                    validators={['required']}
                                    errorMessages={['Ovo polje je obavezno.']}
                                    className={styles.BuyerInputs}
                                />
                            </Grid>
                            <Grid item className={styles.Grid}>
                                <NumberFormat
                                    customInput={TextValidator}
                                    label="Poštanski broj"
                                    onChange={this.handleChange.bind(this)}
                                    disabled={disableControls}
                                    name="zipCode"
                                    thousandSeparator={' '}
                                    value={formControls.zipCode}
                                    validators={['required']}
                                    errorMessages={['Molimo unesite vrijednost.']}
                                    className={styles.BuyerInputs}
                                />
                            </Grid>
                            <Grid item className={styles.Grid}>
                                <TextValidator
                                    label="Grad"
                                    onChange={this.handleChange.bind(this)}
                                    name="city"
                                    disabled={disableControls}
                                    value={formControls.city}
                                    validators={['required']}
                                    errorMessages={['Ovo polje je obavezno.']}
                                    className={styles.BuyerInputs}

                                />
                            </Grid>
                            <Grid item className={styles.Grid}>
                                <TextValidator
                                    label="Država"
                                    onChange={this.handleChange.bind(this)}
                                    name="country"
                                    value={formControls.country}
                                    disabled={disableControls}
                                    validators={['required']}
                                    errorMessages={['Ovo polje je obavezno.']}
                                    className={styles.BuyerInputs}
                                />
                            </Grid>
                            <Grid item className={styles.Grid}>
                                <TextValidator
                                    label="Kontakt broj"
                                    onChange={this.handleChange.bind(this)}
                                    name="phoneNumber"
                                    disabled={disableControls}
                                    value={formControls.phoneNumber}
                                    validators={['required', 'isPhoneNumber']}
                                    errorMessages={['Ovo polje je obavezno.', 'Neispravan kontakt broj']}
                                    className={styles.BuyerInputs}

                                />
                            </Grid>
                        </Grid>
                        </Grid>
                        {this.props.isEditable && <React.Fragment>
                            <Grid container justify="flex-end" alignItems="center" spacing={4} >
                                {/*<Grid item>*/}
                                {/*    <Button className={styles.Button} variant="outlined" color="secondary" onClick={this.resetForm.bind(this)}>Očisti</Button>*/}
                                {/*</Grid>*/}
                                <Grid item >
                                    <Button className={styles.Button} variant="contained" color="primary" type="submit">{this.props.carIsSold ? 'Uredi' : 'Prodaj'}</Button>
                                </Grid>
                            </Grid>
                           
                        </React.Fragment>}
                        </ValidatorForm>
                    </React.Fragment>
            )
    }
}