import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import styles from "./exportSellerContract.module.scss";
import { IInvoiceData } from "../../types/IInvoiceData";
import ISellerContractData from "../../types/ISellerContractData";
import { SellerSubmitContractModal } from "../forms/sellerRegistrationForm/SellerSubmitContractModal";
import { SellerCancelContractModal } from "../forms/sellerRegistrationForm/SellerCancelContractModal";
import { Button, Modal, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { Grid, LinearProgress, Box } from "@material-ui/core";
import { sellerContractPdf } from "../../constants/apiRoutes";

interface IProps extends IState {
  submitSellerContract?: (data: ISellerContractData) => void;
  cancelSellerContract?: (data: ISellerContractData) => void;
  sellerName: string;
  getInvoice?: (invoiceNumber: string) => void;
  ugovorAktivan?: boolean;
}

interface IState extends ISellerContractData {
  open?: boolean | string;
  hasError?: boolean;
  generate?: boolean;
}

const ExportSellerContract: React.FC<IProps> = (props: IProps) => {
  const [open, setOpen] = React.useState(true);
  const [generate, setGenerate] = React.useState(false);
  const [showSubmitModal, setShowSubmitModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);

  const handleClickOpen = (id: string) => {
    if (id == props.sellerId) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const viewContract = () => {
    setGenerate(true);
  };

  const handleYesSubmitModal = () => {
    submitContract();
  };

  const handleYesCancelModal = () => {
    cancelContract();
  };

  const submitContract = () => {
    const data: ISellerContractData = {
      sellerId: props.sellerId,
    };
    if (props.submitSellerContract) {
      props.submitSellerContract(data);
    }
    //setOpen(false);
  };

  const cancelContract = () => {
    const data: ISellerContractData = {
      sellerId: props.sellerId,
    };
    if (props.cancelSellerContract) {
      props.cancelSellerContract(data);
    }
    //setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.Root}
      >
        <DialogTitle id="alert-dialog-title">{props.sellerName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} container direction="row" sm={12}>
              <Grid item sm={2} className={styles.GridItem}>
                <Button
                  color="primary"
                  className={styles.generateButton}
                  variant="outlined"
                  onClick={viewContract}
                >
                  Pregledaj ugovor
                </Button>
              </Grid>
            </Grid>
            <div className={styles.iframeContainer}>
              {generate && (
                <Grid item xs={12} container direction="column" sm={12}>
                  <iframe
                    className={styles.iframePlaceholder}
                    title="Ugovor"
                    height="600px"
                    src={sellerContractPdf + "?sellerId=" + props.sellerId}
                  ></iframe>
                </Grid>
              )}
            </div>
            <DialogActions style={{ marginTop: 50 }}>
              <Grid item style={{ marginRight: 20 }}>
                <Button onClick={handleClose} color="primary">
                  {" "}
                  Odustani{" "}
                </Button>
              </Grid>
              {props.ugovorAktivan === null && (
                <Grid item>
                  <Button
                    onClick={() => setShowSubmitModal(true)}
                    color="primary"
                    variant="contained"
                  >
                    {" "}
                    Potvrdi ugovor{" "}
                  </Button>
                </Grid>
              )}
              {props.ugovorAktivan === true && (
                <Grid item>
                  <Button
                    onClick={() => setShowCancelModal(true)}
                    color="secondary"
                    variant="contained"
                  >
                    {" "}
                    Otkaži ugovor{" "}
                  </Button>
                </Grid>
              )}
            </DialogActions>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {showSubmitModal && (
        <Box>
          <Modal
            open={showSubmitModal}
            className={styles.Modal}
            onClose={() => setShowSubmitModal(false)}
          >
            <Paper className={styles.RootModal}>
              <div>
                <SellerSubmitContractModal
                  closeModal={() => setShowSubmitModal(false)}
                  submitYesModal={handleYesSubmitModal}
                ></SellerSubmitContractModal>
              </div>
            </Paper>
          </Modal>
        </Box>
      )}
      {showCancelModal && (
        <Box>
          <Modal
            open={showCancelModal}
            className={styles.Modal}
            onClose={() => setShowCancelModal(false)}
          >
            <Paper className={styles.RootModal}>
              <div>
                <SellerCancelContractModal
                  closeModal={() => setShowCancelModal(false)}
                  submitYesModal={handleYesCancelModal}
                ></SellerCancelContractModal>
              </div>
            </Paper>
          </Modal>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ExportSellerContract;
