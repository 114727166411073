import React, { useState } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Validator from "../../../utils/validation";
import FormConstants from '../../../constants/FormConstants';
import { Grid, Button } from '@material-ui/core';
import IMissingCarDetailsProps from '../../../types/IMissingCarDetailsProps';
import styles from './missingCarDetailsForm.module.scss';

interface IProps {
    onSubmit: (missingCarDetails: IMissingCarDetailsProps) => void,
    carId?: number,
}

const MissingCarDetailsForm: React.FC<IProps> = (props: IProps) => {
    const [chassis, setChassis] = useState('');
    const [carId] = useState(props.carId);

    ValidatorForm.addValidationRule("validateChassis", chassis => Validator.validateChassis(chassis));


    const handleChange = (event: any) => {
        const { value } = event.target;
        setChassis(value);
    }
    const onSubmit = () => {
        props.onSubmit({ carId, chassis });
    }

    return (
        <ValidatorForm id={FormConstants.MISSING_CAR_DETAILS_FORM} onSubmit={() => onSubmit()} className={styles.Form}>
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={12}>¸
                    <p style={{ fontSize: 20, paddingLeft: 20 }}>Molimo prvo popuniti podatke koji nedostaju</p>
                </Grid>
                <Grid item xs={10}>
                    <TextValidator
                        label="Broj šasije automobila"
                        value={chassis}
                        validators={["required", "validateChassis"]}
                        errorMessages={["Ovo polje je obavezno.", "Broj šasije mora imati 17 znakova."]}
                        name="email"
                        onChange={handleChange}
                        fullWidth />
                </Grid>
            </Grid>
            <Grid container item justify="flex-end" alignItems="center" spacing={2} className={styles.ButtonGrid}>
                <Grid item justify="flex-end" alignItems="center" spacing={2}>
                    <Button className={styles.Button} variant="contained" color="primary" type="submit">Spremi</Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

export default MissingCarDetailsForm;