import React from 'react';
import { IApplicationState } from './../../types/applicationState';
import { connect } from 'react-redux';
import { AppLoading } from './../../components/utilities/AppLoading';
import ILoading from '../../types/utilities/ILoading';

interface IStateToProps {
    loading?: ILoading
}

interface IProps extends IStateToProps { }

const Loading: React.FC<IProps> = (props: IProps) => {
    return <AppLoading {...props.loading!} />
}


const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    loading: state.loading
})


export default connect<IStateToProps, null, IProps, IApplicationState>(mapStateToProps, null)(Loading); 
