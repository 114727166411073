import React from 'react';
import { Grid, Paper, Box, Typography, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Email';

export const BuyerInfoCards: React.FC = () => {
    return <Grid style={{ marginBottom: 20 }} container direction="column" alignItems="center">
        <Grid item>
            <Typography style={{ marginLeft: 80 }}>KONTAKT</Typography>
            <Divider style={{ width: 250 }} variant="fullWidth" />
        </Grid>
        <Grid item>
            <List>
                <ListItem>
                    <ListItemIcon><MailIcon /></ListItemIcon>
                    <ListItemText>info@g1klub.hr</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><PhoneIcon /></ListItemIcon>
                    <ListItemText>INFO: +385 91 333 4443</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><PhoneIcon /></ListItemIcon>
                    <ListItemText>HAK-G1: +385 1 4693786</ListItemText>
                </ListItem>
            </List>
        </Grid>
    </Grid >
}