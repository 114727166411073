import {
  SaveAllSeller,
  DeleteAllSeller,
  ActivateAllSeller,
  GetLegalEntities,
  GetAllSellersAction,
  GetSellerByIdAction,
  GetSellersAdminTotalCountAction,
    GetAllSellersTableAction,
    SubmitSellerContract, CancelSellerContract, DeniedSellerContract, GetAllSellersUnactiveTableAction, GetSellersUnactiveAdminTotalCountAction,
    GetMarketingSellersTableAction, GetMarketingSellersTableTotalCountAction, GetOtherActiveSellersTableAction, GetOtherActiveSellersTotalCountAction
} from "./../actions/SellerAction";
import { Container } from "inversify";
import { getContext, apply, put, delay } from "@redux-saga/core/effects";
import ActionTypes from "./../actions/ActionTypes";
import ISellerService from "../../domain/services/ISellerService";
import SellerService from "../../domain/services/SellerService";
import { NotificationStyle } from "../../domain/enums/NotificationStyle";
import { SagaIterator } from "redux-saga";
import ReactGA from "react-ga";

export function* saveSeller(action: SaveAllSeller) {
  const container: Container = yield getContext("container");
  const sellerApi = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerApi, sellerApi.saveSeller, [
      action.payload.seller,
    ]);
    yield put({ type: ActionTypes.SAVE_SELLER_SUCCESS, payload: response });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Prodavač je uspješno registriran",
        style: NotificationStyle.Success,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Prodavač je uspješno registriran",
        style: NotificationStyle.Success,
      },
    });
    ReactGA.event({
      category: "Admin",
      action: "Spremanje novog trgovca",
    });
    yield put({
      type: ActionTypes.GO_TO_PAGE,
      payload: {
        url: "/",
      },
    });
  } catch (err) {
    yield put({ type: ActionTypes.SAVE_SELLER_ERROR, payload: err });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Prodavač nije registriran.",
        style: NotificationStyle.Error,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Prodavač nije registriran.",
        style: NotificationStyle.Error,
      },
    });
  }
}

export function* deleteSeller(action: DeleteAllSeller) {
  const container: Container = yield getContext("container");
  const sellerApi = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerApi, sellerApi.deleteSeller, [
      action.payload.seller,
    ]);
    yield put({ type: ActionTypes.DELETE_SELLER_SUCCESS, payload: response });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Prodavač je uspješno obrisan",
        style: NotificationStyle.Success,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Prodavač je uspješno obrisan",
        style: NotificationStyle.Success,
      },
    });
  } catch (err) {
    yield put({ type: ActionTypes.DELETE_SELLER_ERROR, payload: err });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Prodavač nije obrisan.",
        style: NotificationStyle.Error,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Prodavač nije obrisan.",
        style: NotificationStyle.Error,
      },
    });
  }
}

export function* activateSeller(action: ActivateAllSeller) {
  const container: Container = yield getContext("container");
  const sellerApi = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerApi, sellerApi.activateSeller, [
      action.payload.seller,
    ]);
    yield put({ type: ActionTypes.ACTIVATE_SELLER_SUCCESS, payload: response });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Prodavač je uspješno aktiviran",
        style: NotificationStyle.Success,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Prodavač je uspješno aktiviran",
        style: NotificationStyle.Success,
      },
    });
  } catch (err) {
      yield put({ type: ActionTypes.ACTIVATE_SELLER_ERROR, payload: err });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Prodavač nije aktiviran.",
        style: NotificationStyle.Error,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Prodavač nije aktiviran.",
        style: NotificationStyle.Error,
      },
    });
  }
}

export function* getLegalEntities(action: GetLegalEntities) {
  const container: Container = yield getContext("container");
  const sellerApi = container.get<ISellerService>(SellerService);
  try {
    const legalEntities = yield apply(
      sellerApi,
      sellerApi.getLegalEntities,
      []
    );
    yield put({
      type: ActionTypes.GET_LEGAL_ENTITIES_SUCCESS,
      payload: { legalEntities },
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_LEGAL_ENTITIES_SUCCESS, payload: err });
  }
}

export function* getAllSellers(action: GetAllSellersAction): SagaIterator {
  const container: Container = yield getContext("container");
  const sellerService = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerService, sellerService.getAll, []);
    yield put({ type: ActionTypes.GET_SELLERS_SUCCESS, payload: response });
    yield put({ type: ActionTypes.LOADING_END });
  } catch (err) {
    yield put({ type: ActionTypes.GET_SELLERS_ERROR, payload: err });
    yield put({ type: ActionTypes.LOADING_END });
  } finally {
  }
}

export function* getAllSellersTable(
  action: GetAllSellersTableAction
): SagaIterator {
  const container: Container = yield getContext("container");
  const sellerService = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerService, sellerService.getAllTable, [
      action.payload.page,
      action.payload.pageSize,
      action.payload.filters,
    ]);
    yield put({
      type: ActionTypes.GET_SELLERS_TABLE_SUCCESS,
      payload: response,
    });
    yield put({ type: ActionTypes.LOADING_END });
  } catch (err) {
    yield put({ type: ActionTypes.GET_SELLERS_TABLE_ERROR, payload: err });
    yield put({ type: ActionTypes.LOADING_END });
  } finally {
  }
}

export function* getAllSellersUnactiveTable(
    action: GetAllSellersUnactiveTableAction
): SagaIterator {
    const container: Container = yield getContext("container");
    const sellerService = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(sellerService, sellerService.getAllUnactiveTable, [
            action.payload.page,
            action.payload.pageSize,
            action.payload.filters,
        ]);
        yield put({
            type: ActionTypes.GET_SELLERS_UNACTIVE_TABLE_SUCCESS,
            payload: response,
        });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_SELLERS_UNACTIVE_TABLE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    } finally {
    }
}

export function* getOtherActiveSellersTable(
    action: GetOtherActiveSellersTableAction
): SagaIterator {
    const container: Container = yield getContext("container");
    const sellerService = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(sellerService, sellerService.getOtherActiveSellersTable, [
            action.payload.page,
            action.payload.pageSize,
            action.payload.filters,
        ]);
        yield put({
            type: ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE_SUCCESS,
            payload: response,
        });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    } finally {
    }
}


export function* getSellersAdminTotalCount(
  action: GetSellersAdminTotalCountAction
) {
  const container: Container = yield getContext("container");
  const sellerApi = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(
      sellerApi,
      sellerApi.getSellersAdminTotalCount,
      []
    );
    yield put({
      type: ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT_SUCCESS,
      payload: response,
    });
    yield put({ type: ActionTypes.LOADING_END });
  } catch (err) {
    yield put({
      type: ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT_ERROR,
      payload: err,
    });
    yield put({ type: ActionTypes.LOADING_END });
  }
}

export function* getSellersUnactiveAdminTotalCount(
    action: GetSellersUnactiveAdminTotalCountAction
) {
    const container: Container = yield getContext("container");
    const sellerApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(
            sellerApi,
            sellerApi.getSellersUnactiveAdminTotalCount,
            []
        );
        yield put({
            type: ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT_SUCCESS,
            payload: response,
        });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({
            type: ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT_ERROR,
            payload: err,
        });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getOtherActiveSellersTotalCount(
    action: GetOtherActiveSellersTotalCountAction
) {
    const container: Container = yield getContext("container");
    const sellerApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(
            sellerApi,
            sellerApi.getOtherActiveSellersTotalCount,
            []
        );
        yield put({
            type: ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT_SUCCESS,
            payload: response,
        });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({
            type: ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT_ERROR,
            payload: err,
        });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getSellerById(action: GetSellerByIdAction): SagaIterator {
  const container: Container = yield getContext("container");
  const sellerService = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerService, sellerService.getSellerById, [
      action.payload,
    ]);
    yield put({ type: ActionTypes.GET_SELLER_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: ActionTypes.GET_SELLER_ERROR, payload: err });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: `Prodavač s ID-jem; ${action.payload} ne postoji.`,
        style: NotificationStyle.Error,
      },
    });
  } finally {
    yield put({ type: ActionTypes.LOADING_END });
  }
}

export function* getSellerDropdownData() {
  const container: Container = yield getContext("container");
  const userService = container.get<ISellerService>(SellerService);

  try {
    const response = yield apply(userService, userService.getDropdownData, []);
    yield put({
      type: ActionTypes.GET_SELLER_DROPDOWN_DATA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_SELLER_DROPDOWN_DATA_ERROR });
  }
}

export function* saveNoteForSeller(action: any) {
  const container: Container = yield getContext("container");
  const sellerApi = container.get<ISellerService>(SellerService);
  try {
    yield put({ type: ActionTypes.LOADING_START });
    const response = yield apply(sellerApi, sellerApi.saveNoteForSeller, [
      action.payload.note,
      action.payload.sellerId,
    ]);
    yield put({
      type: ActionTypes.SAVE_NOTE_FOR_SELLER_SUCCESS,
      payload: response,
    });

    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Zabilješka spremljena.",
        style: NotificationStyle.Success,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Zabilješka spremljena.",
        style: NotificationStyle.Success,
      },
    });
    ReactGA.event({
      category: "Admin",
      action: "Spremanje bilješke za trgovca",
    });
  } catch (err) {
    yield put({ type: ActionTypes.SAVE_NOTE_FOR_SELLER_ERROR, payload: err });
    yield put({ type: ActionTypes.LOADING_END });
    yield put({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Bilješka nije spremljena.",
        style: NotificationStyle.Error,
      },
    });
    yield delay(2000);
    yield put({
      type: ActionTypes.NOTIFICATION_CLOSE,
      payload: {
        message: "Bilješka nije spremljena.",
        style: NotificationStyle.Error,
      },
    });
  }
}

export function* submitSellerContract(action: SubmitSellerContract) {
    const container: Container = yield getContext("container");
    const sellerApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(sellerApi, sellerApi.submitSellerContract, [
            action.payload.data
        ]);
        yield put({ type: ActionTypes.SUBMIT_SELLER_CONTRACT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: {
                message: "Ugovor trgovca uspješno prihvaćen",
                style: NotificationStyle.Success,
            },
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE,
            payload: {
                message: "Ugovor trgovca uspješno prihvaćen",
                style: NotificationStyle.Success,
            },
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/prodano' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.SUBMIT_SELLER_CONTRACT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: {
                message: "Ugovor trgovca nije uspješno prihvaćen",
                style: NotificationStyle.Error,
            },
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE,
            payload: {
                message: "Ugovor trgovca nije uspješno prihvaćen",
                style: NotificationStyle.Error,
            },
        });
    }
}

export function* cancelSellerContract(action: CancelSellerContract) {
    const container: Container = yield getContext("container");
    const sellerApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(sellerApi, sellerApi.cancelSellerContract, [
            action.payload.data
        ]);
        yield put({ type: ActionTypes.CANCEL_SELLER_CONTRACT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: {
                message: "Ugovor trgovca uspješno otkazan",
                style: NotificationStyle.Success,
            },
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE,
            payload: {
                message: "Ugovor trgovca uspješno otkazan",
                style: NotificationStyle.Success,
            },
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/prodano' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.CANCEL_SELLER_CONTRACT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: {
                message: "Ugovor trgovca nije uspješno otkazan",
                style: NotificationStyle.Error,
            },
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE,
            payload: {
                message: "Ugovor trgovca nije uspješno otkazan",
                style: NotificationStyle.Error,
            },
        });
    }
}

export function* deniedSellerContract(action: DeniedSellerContract) {
    const container: Container = yield getContext("container");
    const sellerApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(sellerApi, sellerApi.deniedSellerContract, [
            action.payload.data
        ]);
        yield put({ type: ActionTypes.DENIED_SELLER_CONTRACT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: {
                message: "Ugovor trgovca uspješno odbijen",
                style: NotificationStyle.Success,
            },
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE,
            payload: {
                message: "Ugovor trgovca uspješno odbijen",
                style: NotificationStyle.Success,
            },
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/prodano' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.DENIED_SELLER_CONTRACT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: {
                message: "Ugovor trgovca nije uspješno odbijen",
                style: NotificationStyle.Error,
            },
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE,
            payload: {
                message: "Ugovor trgovca nije uspješno odbijen",
                style: NotificationStyle.Error,
            },
        });
    }
}

//marketing

export function* getMarketingSellersTable(
    action: GetMarketingSellersTableAction
): SagaIterator {
    const container: Container = yield getContext("container");
    const marketingApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(marketingApi, marketingApi.getMarketingSellersTable, [
            action.payload.page,
            action.payload.pageSize,
            action.payload.filters,
        ]);
        yield put({
            type: ActionTypes.GET_MARKETING_SELLERS_TABLE_SUCCESS,
            payload: response,
        });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_MARKETING_SELLERS_TABLE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    } finally {
    }
}

export function* getMarketingSellersTableTotalCount(
    action: GetMarketingSellersTableTotalCountAction
) {
    const container: Container = yield getContext("container");
    const marketingApi = container.get<ISellerService>(SellerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(
            marketingApi,
            marketingApi.getMarketingSellersTableTotalCount,
            []
        );
        yield put({
            type: ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT_SUCCESS,
            payload: response,
        });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({
            type: ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT_ERROR,
            payload: err,
        });
        yield put({ type: ActionTypes.LOADING_END });
    }
}
