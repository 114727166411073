import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormConstants from '../../../constants/FormConstants';
import { Grid, Paper, Button, Typography, InputAdornment, IconButton } from '@material-ui/core';
import styles from '../publicForms.module.scss';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Validator from './../../../utils/validation';

interface IProps {
    onSubmit: (password: string) => void
}
interface IState {
    password: { value: string, show: boolean },
    confirmPass: { value: string, show: boolean }
}

class ConfirmPasswordForm extends React.Component<IProps> {
    state: IState = {
        password: { value: '', show: false },
        confirmPass: { value: '', show: false }
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            return value === this.state.password.value;
        });
        ValidatorForm.addValidationRule('isPass', value => Validator.validatePassword(value));
    }

    private handleChange(event: any) {
        const { name, value } = event.target;
        this.setState((prevState: any) => ({
            [name]: {
                ...prevState[name], value
            }
        }))
    }

    private handleClickShowPassword(isPassword: boolean) {
        const name = isPassword ? 'password' : 'confirmPass';
        this.setState((prevState: any) => ({
            [name]: {
                ...prevState[name], show: !prevState[name].show
            }
        }))
    }

    private onSubmit() {
        this.props.onSubmit(this.state.password.value);
    }

    render() {
        const st = this.state;
        return <Paper className={styles.Root}>
            <ValidatorForm id={FormConstants.CONFIRM_PASSWORD_FORM} onSubmit={() => this.onSubmit()}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="h6" component="h6" >Molimo unesite novu lozinku</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <TextValidator
                            label="Lozinka"
                            value={st.password.value}
                            name="password"
                            validators={["required", "isPass"]}
                            errorMessages={[
                                "Molimo unesite lozinku", "Lozinka je prekratka (min. 8 znakova)"]}
                            onChange={this.handleChange.bind(this)}
                            type={st.password.show ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={() => this.handleClickShowPassword(true)}>
                                        {st.password.show ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={10}>
                        <TextValidator
                            label="Potvrdite lozinku"
                            value={st.confirmPass.value}
                            name="confirmPass"
                            validators={["required", "isPasswordMatch"]}
                            errorMessages={[
                                "Molimo ponovno unesite lozinku", "Niste unijeli jednaku lozinku"]}
                            onChange={this.handleChange.bind(this)}
                            type={st.confirmPass.show ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={() => this.handleClickShowPassword(false)}>
                                        {st.confirmPass.show ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={10}>
                        <Button variant="contained" color="primary" className={styles.SubmitButton} type="submit">Pošalji</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Paper >
    }
}

export default ConfirmPasswordForm;