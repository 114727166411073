import React, { useState, useRef, useEffect } from "react";
import { Paper, Button, Input, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import IBuyerProps from "./../../types/IBuyerProps";
import NotIcon from "@material-ui/icons/CancelSharp";
import YesIcon from "@material-ui/icons/CheckBox";
import styles from "./adminBuyersOverview.module.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import IFilterModel from "../../types/IFilterModel";

interface IProps {
  rows: IBuyerProps[];
  getBuyers: (page: number, pageSize: number, filters: string) => void;
  goToBuyerDetails: (buyerId: string) => void;
  totalCount: number;
}

const BuyersTable: React.FC<IProps> = (props: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterArray, setFilterArray] = useState<IFilterModel[]>([
    { key: "fullName", value: "" },
    { key: "oib", value: "" },
    { key: "email", value: "" },
  ]);
  const [filters, setFilters] = useState<string>("");
  const [columnFocus, setColumnFocus] = useState<string>("");
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const a = tableRef.current as any;
      a.onQueryChange();
    }
  }, [props.rows]);


  const onRowClick = (
    event?: React.MouseEvent,
    rowData?: IBuyerProps,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => {
    if (
      event &&
      !event.currentTarget.baseURI.endsWith("damages") &&
      !event.currentTarget.baseURI.endsWith("warranties")
    ) {

      window.open("/buyer/" + rowData!.id!);
    }
  };

  const carsButton = (email: string) => (
    <Link
      to={"/buyers/" + email + "/warranties"}
      style={{
        display: "-webkit-inline-box",
        color: "unset",
        textDecoration: "none",
      }}
    >
      <Button variant="contained" size="small">
        Pregled
      </Button>
    </Link>
  );

  const damagesButton = (email: string) => (
    <Link
      to={"/buyer/" + email + "/damages"}
      style={{
        display: "-webkit-inline-box",
        color: "unset",
        textDecoration: "none",
      }}
    >
      <Button variant="contained" size="small">
        Pregled
      </Button>
    </Link>
  );

  const createFilters = () => {
    let newFilters = "";

    const name = filterArray.find(
      (x: IFilterModel): boolean => x.key === "fullName"
    )!.value;
    const oib = filterArray.find(
      (x: IFilterModel): boolean => x.key === "oib"
    )!.value;
    const email = filterArray.find(
      (x: IFilterModel): boolean => x.key === "email"
    )!.value;

    if (name !== "") {
      newFilters = newFilters + "FullName@=*" + name;
    }

    if (oib !== "") {
      newFilters = newFilters + ",Oib==" + oib;
    }

    if (email !== "") {
      newFilters = newFilters + ",Email@=*" + email;
    }

    if (newFilters.substring(0, 1) === ",")
      newFilters = newFilters.substring(1);

    setFilters(newFilters);

    // if (
    //   (name !== "" && name.length > 2) ||
    //   (oib !== "" && oib.length === 11) ||
    //   (email !== "" && email.length > 2) ||
    //   (name === "" && oib === "" && email === "")
    // )
    //   props.getBuyers(page + 1, pageSize, newFilters);
  };

  const setFilterValue = (field: string, value: string) => {
    filterArray.find((x: IFilterModel): boolean => x.key === field)!.value =
      value;

    createFilters();
  };

  return (
    <Paper className={styles.Root}>
      <Grid
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => props.getBuyers(page + 1, pageSize, filters)}
          color="inherit"
          variant="contained"
        >
          TRAŽI
        </Button>
      </Grid>
      <MaterialTable
        tableRef={tableRef}
        options={{
          filtering: true,
          search: false,
          paging: true,
          initialPage: page,
          paginationType: "normal",
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Nema podataka za prikaz.",
          },
        }}
        title="Pregled svih kupaca"
        onRowClick={onRowClick}
        columns={[
          { title: "Ime kupca", field: "fullName", filtering: true },
          { title: "OIB", field: "oib", filtering: true },
          { title: "Email", field: "email", filtering: true },
          { title: "Kontakt broj", field: "phoneNumber", filtering: false },
          { title: "Aktivan", field: "active", filtering: false },
          {
            title: "Datum registracije",
            field: "createdAt",
            render: (row: IBuyerProps) =>
              moment(row.createdAt).format("DD.MM.YYYY"),
            defaultSort: "desc",
            filtering: false,
          },
          { title: "Trgovac", field: "createdBy", filtering: false },
          {
            title: "Kupljena vozila",
            field: "cars",
            filtering: false,
            render: (row: IBuyerProps) => carsButton(row.email!),
          },
          {
            title: "Štete",
            field: "damages",
            filtering: false,
            render: (row: IBuyerProps) => damagesButton(row.email!),
          },
        ]}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            resolve({
              data: props.rows,
              page: page,
              totalCount: props.totalCount,
            });
          })
        }
        components={{
          FilterRow: (rowProps) => {
            const { columns } = rowProps;

            return (
              <>
                <tr>
                  {columns
                    .filter((c: any): boolean => c.filtering === true)
                    .map((col: any) => {
                      if (col.field) {
                        return (
                          <td>
                            <Input
                              autoFocus={columnFocus === col.field}
                              key={col.field}
                              id={col.field}
                              value={
                                filterArray.find(
                                  (x: IFilterModel): boolean =>
                                    x.key === col.field
                                )!.value
                              }
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                              onChange={(e) => {
                                setFilterValue(col.field, e.target.value);
                                setColumnFocus(col.field);
                              }}
                            />
                          </td>
                        );
                      }
                    })}
                </tr>
              </>
            );
          },
        }}
        onChangePage={(newPage: number) => {
          setPage(newPage);
          props.getBuyers(newPage + 1, pageSize, filters);
        }}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
          props.getBuyers(page + 1, newPageSize, filters);
        }}
      />
    </Paper>
  );
};

export default BuyersTable;
