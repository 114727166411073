import React from 'react';
import ActionTypes from '../../redux/actions/ActionTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import { EmailSubmitAction } from '../../redux/actions/ForgotPasswordAction';
import EmailForm from '../../components/forms/forgotPassword/emailForm';
import PublicFormLayout from '../../components/authentication/PublicFormLayout';
import { IForgotPassword } from '../../types/IForgotPassword';

interface IStateToProps extends IForgotPassword { }

interface IDispatchToProps {
    onHandleSubmit: (email: string) => void
}

interface IProps extends IDispatchToProps, IStateToProps { }

const EmailFormPage: React.FC<IProps> = (props: IProps) => {
    return <PublicFormLayout>
        <EmailForm {...props} onSubmit={props.onHandleSubmit} />
    </PublicFormLayout>
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    email: state.forgotPassword.email,
    emailDelivered: state.forgotPassword.emailDelivered
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onHandleSubmit: (email: string) => dispatch<EmailSubmitAction>({
        type: ActionTypes.EMAIL_SUBMIT,
        payload: email
    })
})

export default connect<IStateToProps, IDispatchToProps, null, IApplicationState>(mapStateToProps, mapDispatchToProps)(EmailFormPage);