import React, { useState, useEffect } from 'react';
import PublicFormLayout from '../../authentication/PublicFormLayout'
import { Paper, Grid, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import ContractText from './ContractText';
import style from './buyerContract.module.scss';
import IWarranty from '../../../types/IWarranty';
import WarrantyStatus from '../../../domain/enums/WarrantyStatus';

interface IProps {
    activateWarranty: () => void,
    warranty: IWarranty,
    checkWarranties: () => void
}

const BuyerContract: React.FC<IProps> = (props: IProps) => {
    const [buttonDisabled, disableButton] = useState(true);

    useEffect(() => disableButton(true), [props.warranty]);

    const activated = props.warranty && props.warranty.status! && props.warranty.status!.name === WarrantyStatus.Active;
    const hideTitle = window.location.pathname === '/warranty-rules';
    const p1 = props.warranty && props.warranty.carData && props.warranty.carData.isOneYearWarranty ? 
        "1. Vaše pravo na koordinaciju produženog auto-jamstva traje godinu dana od dana izdavanja jamstva."
        :
        "1. Vaše pravo na koordinaciju produženog auto-jamstva traje dvije godine od dana izdavanja jamstva."

    return (

        <PublicFormLayout className={style.Layout} md={true} lg={true} hideTitle={hideTitle}>
            <Paper>
                <Grid container xs={12} direction="row" alignItems="center" justify="flex-start" className={style.RootGrid}>
                    <Grid container item xs={12} direction="row" alignItems="center" justify="flex-start">
                        <h2 className={style.Header}>Aktivacija prava na koordinirano jamstvo</h2>
                    </Grid>
                    <Grid container item xs={12} direction="row" alignItems="center" justify="flex-start">
                        {!activated && p1 ? <div className={style.Container}>
                            <h3 className={style.Header}>Važne informacije za valjanost vašeg prava na koordinaciju produženog auto-jamstva</h3><br />
                            <p>{p1}</p><br />
                            <p>2. Kako bi se zaštitili od mogućih pokušaja prevara prvih 1000 km i 10 dana Vaše pravo na auto-jamstvo ne vrijedi, ali vrijedi HAK usluga pomoći na cesti ako vozilo ostane nepokretno.</p><br />
                            <p>3. Ako uz Vaše vozilo niste dobili ovjerenu servisnu knjižicu vozila ili ne postoji servisna povijest i nije moguće utvrditi termin zadnje izmjene ulja i filtera, obavezno promijeniti ulje i filter te o tome sačuvati dokaz-račun.</p><br />
                            <p>4. Obavezno pratite i poštujte redovne i izvanredne servisne intervale koje propisuje proizvođač vozila, u suprotnom pravo na auto-jamstvo ne vrijedi.</p><br />
                            <p>5. Servise obavezno dogovorite u servisnoj G1 mreži ili kod ovlaštenog servisnog partnera za određenu marku vozila, a o izvršenoj servisnoj obavezi također čuvati dokaz-račun.</p><br />
                            <p>6. G1 servisnu mrežu u kojoj imate povoljnije uvjete servisiranja vozila kao član G1 kluba, možete pronaći na našem webu <b>www.G1klub.hr</b></p><br />
                            <p>7. Ako vozilo ostane nepokretno i postoji potreba za pomoć na cesti, obavezno nazvati <b>HAK-G1 broj +385 1 4693786</b></p><br />
                            <p>8. Nikako ne pokušavati  popravljati sam vozilo i obavezno se zaustaviti čim se opazi nepravilnost u radu motora, putem kontrolne ploče ili vizualno.</p><br /></div>
                            : <Grid item xs={12}><p className={style.Header}>Vaše jamstvo je već uspješno aktivirano! Molimo kliknite na gumb nastavi kako bi ušli u aplikaciju.</p></Grid>}
                    </Grid>
                    {props.warranty && props.warranty.carData && !activated && <React.Fragment>
                        <Grid container item xs={12} direction="row" alignItems="center" justify="flex-end" className={style.FormLabel}>
                            <FormControlLabel
                                label={
                                    <div>
                                        <span>Pročitao sam tekst i slažem se s uvjetima</span>
                                    </div>
                                }
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        value={!buttonDisabled}
                                        color="primary"
                                        onClick={() => disableButton(!buttonDisabled)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item direction="row" xs={12}>
                            <p className={style.Header}><b>Prihvaćanjem ovog teksta, aktivirat će Vam se jamstvo za automobil {props.warranty.carData!.model + " " + props.warranty.carData!.brand}, broj šasije: {props.warranty.carData!.chassisNumber}.</b></p>
                        </Grid>
                        <Grid item container direction="row" xs={12} justify="center" alignItems="flex-end" className={style.ButtonGrid}>
                            <Grid container item direction="row" xs={12} justify="flex-end" alignItems="flex-end">
                                <Button className={style.Button} disabled={buttonDisabled} color="primary" variant="contained" onClick={props.activateWarranty}>Prihvati</Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>}
                    {props.warranty && props.warranty.carData && activated && 
                    <Grid container xs={12}><Grid container item direction="row" xs={12} justify="flex-end" alignItems="flex-end">
                        <Button className={style.Button} color="primary" variant="contained" onClick={props.checkWarranties}>Nastavi</Button>
                    </Grid></Grid>
                    }
                </Grid>
            </Paper>
        </PublicFormLayout>
    )
}

export default BuyerContract;