import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import IDamageBuyerTable from '../../types/Damage/IDamageBuyerTable';
import DamagesBuyerTable from '../../components/tables/DamagesBuyerTable';
import { GetDamagesBuyerAction, GetBuyerNameAction } from '../../redux/actions/DamageAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    damages: IDamageBuyerTable[],
    buyerName: string
}

interface IDispatchToProps {
    getDamages: (email: string) => void,
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => void,
    getBuyerName: (email: string) => void
}

interface IProps extends IStateToProps, IDispatchToProps {
    match: {
        params: {
            email: string
        }
    }
}

const DamageBuyerOverview: React.FC<IProps> = props => {
    useEffect(() => {
        const { email } = props.match.params;
        props.getDamages(email);
        props.getBuyerName(email);
    }, []);

    return (props.damages ? <DamagesBuyerTable rows={props.damages} goToDamagePage={props.goToDamagePage} buyerName={props.buyerName} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damages: state.damages.data.buyer,
    buyerName: state.buyerName.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getDamages: (email: string) => dispatch<GetDamagesBuyerAction>({
        type: ActionTypes.GET_DAMAGES_BUYER,
        payload: { email }
    }),
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: `/damage/${carId}/${warrantyId}/${damageId}` }
    }),
    getBuyerName: (email: string) => dispatch<GetBuyerNameAction>({
        type: ActionTypes.GET_BUYER_NAME,
        payload: { email }
    }),

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(DamageBuyerOverview);