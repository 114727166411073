import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import { IInvoiceTable } from "../../types/IInvoiceTable";
import InvoicesTable from "../../components/tables/InvoicesTable";
import ReactGA from 'react-ga';
import { IYearDropdown } from "../../types/IYearDropdown";

interface IStateToProps {
    invoiceList: IInvoiceTable[],
    loading: boolean,
    years: IYearDropdown[]
}

interface IDispatchToProps {
    getInvoices: (year: string) => void,
    getYears: () => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const InvoicesOverview: React.FC<IProps> = props => {

    useEffect(() => {
        var date = new Date();
        ReactGA.pageview("Admin | pregled racuna");
        props.getYears();
        props.getInvoices(date.getFullYear().toString());
    }, [])

    return (
        <InvoicesTable
            rows={props.invoiceList}
            loading={props.loading}
            getInvoices={props.getInvoices}
            years={props.years}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    invoiceList: state.invoiceList.data.invoices,
    loading: state.invoiceList.loading, 
    years: state.years.data
})


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getInvoices: (year: string) =>
        dispatch({
            type: ActionTypes.GET_INVOICES,
            payload: { year }
        }),
    getYears: () =>
        dispatch({
            type: ActionTypes.GET_YEARS
        }),


});

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(InvoicesOverview);
