import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import ICarAdminTable from '../../types/ICarAdminTable';
import CarsAdminTable from '../../components/tables/CarsAdminTable';
import { GetCarsAdminAction, DeactivateCarAction, ActivateCarAction, GetCarsAdminTotalCountAction } from '../../redux/actions/CarAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    cars: ICarAdminTable[],
    totalCount: number
}

interface IDispatchToProps {
    getCars: (page: number, pageSize: number, filters: string) => void,
    getTotalCount: () => void,
    goToCarDetails: (carId: number) => void,
    goToCarWarrantyDetails: (carId: number, warrantyId: string) => void,
    deactivate: (carId: number) => void,
    activate: (carId: number) => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const CarAdminOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getCars(1, 10, "");
        props.getTotalCount();
    }, []);

    return (props.cars ? <CarsAdminTable rows={props.cars} totalCount={props.totalCount} goToCarDetails={props.goToCarDetails} goToCarWarrantyDetails={props.goToCarWarrantyDetails} getCars={props.getCars}
        deactivate={props.deactivate} activate={props.activate} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    cars: state.cars.data.admin, 
    totalCount: state.carsAdminTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getCars: (page: number, pageSize: number, filters: string) => dispatch<GetCarsAdminAction>({
        type: ActionTypes.GET_CARS_ADMIN,
        payload: { page, pageSize, filters }
    }),
    getTotalCount: () => dispatch<GetCarsAdminTotalCountAction>({
        type: ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT
    }),
    goToCarDetails: (carId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/car/' + carId + "/step=1" }
    }),
    goToCarWarrantyDetails: (carId: number, warrantyId: string) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
    }),
    deactivate: (carId: number) => dispatch<DeactivateCarAction>({
        type: ActionTypes.DEACTIVATE_CAR,
        payload: { carId }
    }),
    activate: (carId: number) => dispatch<ActivateCarAction>({
        type: ActionTypes.ACTIVATE_CAR,
        payload: { carId }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(CarAdminOverview);