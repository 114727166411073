
import qs from 'qs';
import { injectable } from 'inversify';
import IStateInitializer from './IStateInitializer';
import { IApplicationProps } from '../types/IApplicationProps';

@injectable()
export default class StateInitializer implements IStateInitializer {
    public init(): IApplicationProps {
        var queryParameters = qs.parse(window.location.search.slice(1)); // remove '?' from query string before parsing

        const queryProps = (
            ({client_id, provider_id, config_id}) => {
                // probably not the best way to check?
                if (/^\d+$/.test(provider_id)) {
                    provider_id = parseInt(provider_id);
                } else {
                    provider_id = undefined;
                }

                return {
                    clientId: client_id,
                    providerId: provider_id,
                    configId: config_id
                };
            }
        )(queryParameters);

        return {
            ...queryProps,
            language: this.getLocale(),
        };
    }

    private getLocale(): string {
        return navigator.languages ? navigator.languages[0] : navigator.language;
    }
};