import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import SellerRegistrationForm from "../../components/forms/sellerRegistrationForm/SellerRegistrationForm";
import SellerNote from "../../components/forms/sellerNoteForm/SellerNote";
import ISellerProps from "./../../types/ISellerProps";
import ILegalEntity from "./../../types/ILegalEntitiy";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { GetLegalEntities } from "../../redux/actions/SellerAction";
import {
  GetSellerByIdAction,
  ClearSellerAction,
} from "./../../redux/actions/SellerAction";
import { connect } from "react-redux";
import { URLHelper } from "./../../utils/URLHelper";
import ReactGA from "react-ga";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ISellerContractData from "../../types/ISellerContractData";

interface IProps extends IDispatchToProps, IStateToProps {}

interface IDispatchToProps {
  getLegalEntities: () => void;
  saveSeller: (seller: ISellerProps) => void;
  activateSeller: (seller: ISellerProps) => void;
  deleteSeller: (seller: ISellerProps) => void;
  getSeller: (sellerId: string) => void;
  clearSeller: () => void;
  saveNoteForSeller: (note: string, sellerId: string) => void;
  submitSellerContract?: (data: ISellerContractData) => void;
    cancelSellerContract?: (data: ISellerContractData) => void;
    handleDeniedContract?: (data: ISellerContractData) => void;
}

interface IStateToProps {
  legalEntities: ILegalEntity[];
  seller: ISellerProps;
}

const SellerOverview: React.FC<IProps> = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview("Admin | registracija trgovca");
    props.clearSeller();
    props.getLegalEntities();
    if (URLHelper.getSellerId() !== "create_seller")
      props.getSeller(URLHelper.getSellerId()!);
  }, []);

  const handleSubmit = (seller: ISellerProps) => {
    props.saveSeller(seller);
  };

  const handleDelete = (seller: ISellerProps) => {
    props.deleteSeller(seller);
  };

  const handleActivate = (seller: ISellerProps) => {
    props.activateSeller(seller);
  };

  return (
    <Grid container direction="row" spacing={5}>
      <Grid item sm={12} lg={9}>
        <SellerRegistrationForm
                  submit={handleSubmit}
                  delete={handleDelete}
                  activate={handleActivate}
                  seller={props.seller}
                  {...props.seller}
                  legalEntities={props.legalEntities}
                  isEditForm={URLHelper.getSellerId() !== "create_seller"}
                  submitSellerContract={props.submitSellerContract}
                  cancelSellerContract={props.cancelSellerContract}
                  handleDeniedContract={props.handleDeniedContract}
        />
      </Grid>
      <Grid item sm={12}>
        {props.seller && (
          <SellerNote
            saveNoteForSeller={props.saveNoteForSeller}
            note={props.seller.note ? props.seller.note! : ""}
            sellerId={props.seller.id!}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
  legalEntities: state.legalEntities.entities,
  seller: state.seller.seller,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getLegalEntities: () =>
    dispatch<GetLegalEntities>({
      type: ActionTypes.GET_LEGAL_ENTITIES,
    }),
  saveSeller: (seller: ISellerProps) =>
    dispatch({
      type: ActionTypes.SAVE_SELLER,
      payload: { seller },
    }),
  activateSeller: (seller: ISellerProps) =>
    dispatch({
      type: ActionTypes.ACTIVATE_SELLER,
      payload: { seller },
    }),
  deleteSeller: (seller: ISellerProps) =>
    dispatch({
      type: ActionTypes.DELETE_SELLER,
      payload: { seller },
    }),
  getSeller: (sellerId: string) =>
    dispatch<GetSellerByIdAction>({
      type: ActionTypes.GET_SELLER,
      payload: sellerId,
    }),
  clearSeller: () =>
    dispatch<ClearSellerAction>({
      type: ActionTypes.CLEAR_SELLER,
    }),
  saveNoteForSeller: (note: string, sellerId: string) =>
    dispatch({
      type: ActionTypes.SAVE_NOTE_FOR_SELLER,
      payload: { note, sellerId },
    }),
  submitSellerContract: (data: ISellerContractData) =>
    dispatch({
      type: ActionTypes.SUBMIT_SELLER_CONTRACT,
      payload: { data },
    }),
    cancelSellerContract: (data: ISellerContractData) =>
        dispatch({
            type: ActionTypes.CANCEL_SELLER_CONTRACT,
            payload: { data },
        }),
    handleDeniedContract: (data: ISellerContractData) =>
        dispatch({
            type: ActionTypes.DENIED_SELLER_CONTRACT,
            payload: { data },
        }),
});

export default connect<
  IStateToProps,
  IDispatchToProps,
  IProps,
  IApplicationState
>(
  mapStateToProps,
  mapDispatchToProps
)(SellerOverview);
