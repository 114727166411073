import { SagaIterator } from '@redux-saga/core';
import { Container } from 'inversify';
import { getContext, apply, put } from '@redux-saga/core/effects';
import IPackageService from '../../domain/services/IPackageService';
import PackageService from '../../domain/services/PackageService';
import { GetPackagesAction, UpgradeWarrantyPackageAction } from '../actions/PackagesAction';
import ActionTypes from '../actions/ActionTypes';
import ReactGA from 'react-ga';

export function* getPackages(action: GetPackagesAction): SagaIterator {
    const container: Container = yield getContext('container');
    const packagesApi = container.get<IPackageService>(PackageService);
    try {
        const response = yield apply(packagesApi, packagesApi.getPackagesByEngineDisplacement, [action.payload]);
        yield put({ type: ActionTypes.GET_PACKAGES_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_PACKAGES_ERROR, payload: err })
    }
}

export function* getExtraPackages(action: GetPackagesAction): SagaIterator {
    const container: Container = yield getContext('container');
    const packagesApi = container.get<IPackageService>(PackageService);
    try {
        const response = yield apply(packagesApi, packagesApi.getExtraPackagesByEngineDisplacement, [action.payload]);
        yield put({ type: ActionTypes.GET_EXTRA_PACKAGES_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_EXTRA_PACKAGES_ERROR, payload: err })
    }
}

export function* upgradeWarrantyPackage(action: UpgradeWarrantyPackageAction): SagaIterator {
    const container: Container = yield getContext('container');
    const packagesApi = container.get<IPackageService>(PackageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(packagesApi, packagesApi.upgradeWarrantyPackage, [action.payload.packageId, action.payload.extraPackages, action.payload.warrantyId]);
        yield put({ type: ActionTypes.UPGRADE_WARRANTY_PACKGAGE_SUCCESS, payload: response })
        location.reload();
        yield put({ type: ActionTypes.LOADING_END });
        ReactGA.event({
            category: 'Trgovac',
            action: 'Trgovac je dodao jos ekstra polica',
            label: action.payload.extraPackages.length.toString()
          });
    } catch (err) {
        yield put({ type: ActionTypes.UPGRADE_WARRANTY_PACKGAGE_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });

    }

}