import React from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table'
import { Trans, useTranslation } from 'react-i18next';
import styles from './damagesSellerTable.module.scss';
import Button from '@material-ui/core/Button';
import { DamageStatus } from '../../domain/enums/DamageStatus';
import { IDamageSellerTable } from '../../types/Damage/IDamageSellerTable';

interface IProps {
    rows: IDamageSellerTable[],
    goToWarrantyReview: (warrantyId: number) => void
}

const DamagesSellerTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    const mapPropsToTable = (): IDamageSellerTable[] => {
        let newRows = new Array<IDamageSellerTable>();

        props.rows.forEach((row: IDamageSellerTable) => {
            let newRow: IDamageSellerTable = {
                ...row,
                status: t(row.status)
            }
            newRows.push(newRow);
        });
        return newRows;
    }

    function damageStatusStyle(rowData: IDamageSellerTable) {
        const propsRow = findById(rowData.id);
        return <div className={styles[propsRow.status]}>{rowData.status}</div>
    }
    const onRowClick = (event?: React.MouseEvent, rowData?: IDamageSellerTable, toggleDetailPanel?: (panelIndex?: number) => void) => {
        props.goToWarrantyReview(rowData!.warrantyId);
    }

    const findById = (id: number): IDamageSellerTable => {
        return props.rows.filter((row: IDamageSellerTable) => row.id === id)[0];
    }

    return <Paper className={styles.Root}>
        <MaterialTable
            localization={{
                body: {
                    emptyDataSourceMessage: "Nema podataka za prikaz."
                },
                pagination: {
                    previousTooltip: 'Prethodna',
                    nextTooltip: 'Sljedeća',
                    firstTooltip: 'Početna',
                    lastTooltip: 'Posljednja'
                }
            }}
            onRowClick={onRowClick}
            title="Pregled šteta"
            columns={[
                { title: 'ID štete', field: 'id' },
                { title: 'Šifra jamstva', field: 'warrantyCode' },
                { title: 'Broj šasije auta', field: 'chassis' },
                { title: 'Kupac', field: 'buyer' },
                {
                    title: 'Status štete', field: 'status',
                    render: (rowData: IDamageSellerTable) => damageStatusStyle(rowData)
                },
                { title: 'Radni nalog', field: 'radniNalog' },
                { title: 'Zaključak', field: 'zakljucak' },
                { title: 'Datum prijave', field: 'reportedDate' },

            ]}
            data={[...mapPropsToTable()]}
        />
    </Paper>
}

export default DamagesSellerTable;
