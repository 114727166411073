import { injectable, inject } from 'inversify';
import ThirdPartyTypes from './../../di/ThirdPartyTypes';
import { AxiosInstance } from 'axios';
import ISellerService from './ISellerService';
import ILegalEntity from '../../types/ILegalEntitiy';
import ISellerProps from "./../../types/ISellerProps";
import { legalEntities, saveSeller, deleteSeller, activateSeller, sellerApiURL, saveNoteForSellerApi, submitSellerContract, cancelSellerContract, deniedSellerContract } from '../../constants/apiRoutes';
import { ISeller } from '../../types/ISeller';
import { EditorState } from "draft-js";
import ISellerContractData from '../../types/ISellerContractData';

@injectable()
export default class SellerService implements ISellerService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getLegalEntities(): Promise<ILegalEntity[]> {
        const response = await this.apiClient.get(legalEntities);
        return response.data;
    }

    public async saveSeller(seller: ISellerProps): Promise<null> {
        const response = await this.apiClient.post(saveSeller, seller)
        return response.data;
    }

    public async deleteSeller(seller: ISellerProps): Promise<null> {
        const response = await this.apiClient.post(deleteSeller, seller)
        return response.data;
    }

    public async activateSeller(seller: ISellerProps): Promise<null> {
        const response = await this.apiClient.post(activateSeller, seller)
        return response.data;
    }

    public async getAll(): Promise<ISellerProps[]> {
        const response = await this.apiClient.get(sellerApiURL + '/all');
        return response.data;
    }

    public async getAllTable(page: number, pageSize: number, filters: string): Promise<ISellerProps[]> {
        const response = await this.apiClient.get(sellerApiURL + '/allTable' + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getAllUnactiveTable(page: number, pageSize: number, filters: string): Promise<ISellerProps[]> {
        const response = await this.apiClient.get(sellerApiURL + '/allUnactiveTable' + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getOtherActiveSellersTable(page: number, pageSize: number, filters: string): Promise<ISellerProps[]> {
        const response = await this.apiClient.get(sellerApiURL + '/otherActiveTable' + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getSellersAdminTotalCount(): Promise<number> {
        const response = await this.apiClient.get(sellerApiURL + '/sellersAdminTotalCount');
        return response.data;
    }

    public async getSellersUnactiveAdminTotalCount(): Promise<number> {
        const response = await this.apiClient.get(sellerApiURL + '/sellersUnactiveAdminTotalCount');
        return response.data;
    }

    public async getOtherActiveSellersTotalCount(): Promise<number> {
        const response = await this.apiClient.get(sellerApiURL + '/otherActiveSellersTotalCount');
        return response.data;
    }

    public async getSellerById(sellerId: string): Promise<ISellerProps> {
        const response = await this.apiClient.get(sellerApiURL + '/' + sellerId);
        return response.data;
    }

    public async getDropdownData(): Promise<ISeller[]> {
        return (await this.apiClient.get<ISeller[]>(sellerApiURL)).data
    }

    public async saveNoteForSeller(note: string, sellerId: string): Promise<null> {
        const response = await this.apiClient.post(saveNoteForSellerApi, {note, id: sellerId})
        return response.data;
    }

    public async submitSellerContract(data: ISellerContractData): Promise<null> {
        const response = await this.apiClient.post(submitSellerContract, data)
        return response.data;
    }

    public async cancelSellerContract(data: ISellerContractData): Promise<null> {
        const response = await this.apiClient.post(cancelSellerContract, data)
        return response.data;
    }

    public async deniedSellerContract(data: ISellerContractData): Promise<null> {
        const response = await this.apiClient.post(deniedSellerContract, data)
        return response.data;
    }


    //marketing
    public async getMarketingSellersTable(page: number, pageSize: number, filters: string): Promise<ISellerProps[]> {
        const response = await this.apiClient.get(sellerApiURL + '/marketingSellersTable' + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getMarketingSellersTableTotalCount(): Promise<number> {
        const response = await this.apiClient.get(sellerApiURL + '/marketingSellersTableTotalCount');
        return response.data;
    }
    
}