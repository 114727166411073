import React from 'react';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import { ActionNotification } from '../../components/utilities/ActionNotification';
import IActionNotification from '../../types/utilities/IActionNotification';
import { Dispatch } from 'redux';
import ActionTypes from './../../redux/actions/ActionTypes';
import { CloseNotificationAction } from '../../redux/actions/ActionNotificationAction';

interface IStateToProps {
    ActionNotification?: IActionNotification
}

interface IDispatchToProps {
    close?: () => void
}

interface IProps extends IStateToProps, IDispatchToProps { }

const ActionNotificationContainer: React.FC<IProps> = (props: IProps) => {
    return <ActionNotification close={props.close!} {...props.ActionNotification!} />
}


const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    ActionNotification: state.actionNotification
})


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    close: () => dispatch<CloseNotificationAction>({
        type: ActionTypes.NOTIFICATION_CLOSE
    })
})
export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(ActionNotificationContainer); 
