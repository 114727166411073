import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import IDamageWarrantyTable from '../../types/Damage/IDamageWarrantyTable';
import DamagesWarrantyTable from '../../components/tables/DamagesWarrantyTable';
import { GetDamagesWarrantyAction } from '../../redux/actions/DamageAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    damages: IDamageWarrantyTable[],
}

interface IDispatchToProps {
    getDamages: (warrantyId: number) => void,
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => void
}

interface IProps extends IStateToProps, IDispatchToProps {
    match: {
        params: {
            warrantyId: number
        }
    }
}

const DamageWarrantyOverview: React.FC<IProps> = props => {
    useEffect(() => {
        const { warrantyId } = props.match.params;
        props.getDamages(warrantyId);
    }, []);

    return (props.damages ? <DamagesWarrantyTable rows={props.damages} goToDamagePage={props.goToDamagePage} warrantyId={props.match.params.warrantyId} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damages: state.damages.data.warranty
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getDamages: (warrantyId: number) => dispatch<GetDamagesWarrantyAction>({
        type: ActionTypes.GET_DAMAGES_WARRANTY,
        payload: { warrantyId }
    }),
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: `/damage/${carId}/${warrantyId}/${damageId}` }
    })

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(DamageWarrantyOverview);