import {
    sellerReducer, legalEntitiesReducer, getSellersReducer, sellerDropdowndDataReducer, sellersAdminTotalCountReducer, getSellersTableReducer,
    sellersUnactiveAdminTotalCountReducer, getSellersUnactiveTableReducer, marketingSellersTotalCountReducer, getMarketingSellersTableReducer,
    getOtherActiveSellersTableReducer, otherActiveSellersTotalCountReducer
} from './sellerReducer';
import { combineReducers } from 'redux';
import initStateReducer from './initState';
import loginReducer from './loginReducer';
import { userAuthReducer, currentUserReducer, confirmData } from './userReducer';
import { packageSelectReducer, extraPackageSelectReducer, getPackageReducer } from './packageReducer';
import {
    postCarReducer, carDataReducer, carBuyerReducer, carReviewReducer, isCarDetailsMissing, sellerCarData,
    carDetailsReducer, carDropdowndDataReducer, getCarProblemExtraPackageReducer, carReducer, carChassisReducer,
    getCarsDropdownReducer, postCarOnlyReducer, postCarOnlyAndContinueReducer
} from './carReducer';
import { warrantyStatusesReducer, updateWarrantyStatusReducer } from './warrantyStatusesReducer';
import {
    warrantiesDataReducer, warrantyReducer, warrantyContractReducer, warrantyAdminTableTotalCountReducer,
    warrantyAdminBuyerTableTotalCountReducer, warrantyAdminSellerTableTotalCountReducer, warrantyAdminArchiveTableTotalCountReducer
} from './warrantyReducer';
import registerReducer from './registerReducer';
import { adminTableReducer, adminArchiveTableReducer } from "./adminTableReducer";
import { damageReducer, sellerNameReducer, buyerNameReducer, carNameReducer } from "./damageReducer";
import { getDamagesReducer, damagesAdminTableTotalCountReducer } from './damagesTableReducer';
import { getCarsReducer, carsAdminTableTotalCountReducer, carsAdminOnlyCarsTableTotalCountReducer, carsAdminSoldOnlyTableTotalCountReducer } from './carsTableReducer';
import loadingReducer from './loadingReducer';
import actionNotificationReducer from './actionNotificationReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import carPhotosReducer from './carPhotosReducer';
import { getBuyersReducer, buyerReducer, buyersAdminTotalCountReducer, getLegalBuyersReducer } from './buyerReducer';
import {
    thisMonthStatisticsReducer, soldStatisticsReducer, marketingThisMonthStatisticsReducer, getStatisticsMarketingWarrantiesReducer,
    getStatisticsWarrantiesReducer, lastYearThisMonthStatisticsReducer
} from './statisticsReducer';
import { allInvoicesReducer, lastInvoiceReducer, invoiceReducer, yearsReducer } from './invoicingReducer';
import { getCurrenciesReducer } from './currencyReducer';

export default combineReducers({
    application: initStateReducer,
    loading: loadingReducer,
    actionNotification: actionNotificationReducer,
    login: loginReducer,
    register: registerReducer,
    user: userAuthReducer,
    postCar: postCarReducer,
    postCarOnly: postCarOnlyReducer,
    postCarOnlyAndContinue: postCarOnlyAndContinueReducer,
    packagesData: getPackageReducer,
    selectedPackage: packageSelectReducer,
    selectedExtraPackages: extraPackageSelectReducer,
    carData: carDataReducer,
    warranties: warrantiesDataReducer,
    carWarranty: warrantyReducer,
    currentCar: carReducer,
    carBuyer: carBuyerReducer,
    warrantyStatuses: warrantyStatusesReducer,
    updatedWarrantyStatus: updateWarrantyStatusReducer,
    getAdminTableData: adminTableReducer,
    getAdminArchiveTableData: adminArchiveTableReducer,
    seller: sellerReducer,
    buyer: buyerReducer,
    legalEntities: legalEntitiesReducer,
    carDamage: damageReducer,
    chassisCar: carChassisReducer,
    damages: getDamagesReducer,
    cars: getCarsReducer,
    forgotPassword: forgotPasswordReducer,
    carPhotos: carPhotosReducer,
    currentUser: currentUserReducer,
    confirmData: confirmData,
    buyers: getBuyersReducer,
    sellers: getSellersReducer,
    carReview: carReviewReducer,
    isCarDetailsMissing,
    thisMonthStatistics: thisMonthStatisticsReducer,
    soldWarrantiesStatistics: soldStatisticsReducer,
    sellerCars: sellerCarData,
    carDetails: carDetailsReducer,
    carDropdownData: carDropdowndDataReducer,
    sellerDropdownData: sellerDropdowndDataReducer,
    invoiceList: allInvoicesReducer,
    lastInvoice: lastInvoiceReducer,
    currencies: getCurrenciesReducer,
    invoice: invoiceReducer,
    carProblemExtraPackages: getCarProblemExtraPackageReducer,
    sellerName: sellerNameReducer,
    buyerName: buyerNameReducer,
    carName: carNameReducer,
    carsDropdown: carDropdowndDataReducer,
    carsAdminTableTotalCount: carsAdminTableTotalCountReducer,
    carsAdminOnlyCarsTableTotalCount: carsAdminOnlyCarsTableTotalCountReducer,
    carsAdminSoldOnlyTableTotalCount: carsAdminSoldOnlyTableTotalCountReducer,
    warrantyAdminTableTotalCount: warrantyAdminTableTotalCountReducer,
    warrantyAdminBuyerTableTotalCount: warrantyAdminBuyerTableTotalCountReducer,
    warrantyAdminSellerTableTotalCount: warrantyAdminSellerTableTotalCountReducer,
    warrantyAdminArchiveTableTotalCount: warrantyAdminArchiveTableTotalCountReducer,
    damagesAdminTableTotalCount: damagesAdminTableTotalCountReducer,
    buyersAdminTableTotalCount: buyersAdminTotalCountReducer,
    sellersAdminTableTotalCount: sellersAdminTotalCountReducer,
    sellersTable: getSellersTableReducer,
    sellersUnactiveAdminTableTotalCount: sellersUnactiveAdminTotalCountReducer,
    sellersUnactiveTable: getSellersUnactiveTableReducer,
    years: yearsReducer,
    marketingSellersTable: getMarketingSellersTableReducer,
    marketingSellersTableTotalCount: marketingSellersTotalCountReducer,
    marketingThisMonthStatistics: marketingThisMonthStatisticsReducer,
    otherActiveSellersTable: getOtherActiveSellersTableReducer,
    otherActiveSellersTableTotalCount: otherActiveSellersTotalCountReducer,
    statisticsMarketingWarrantiesTable: getStatisticsMarketingWarrantiesReducer,
    statisticsWarrantiesTable: getStatisticsWarrantiesReducer,
    legalBuyers: getLegalBuyersReducer,
    lastYearThisMonthStatistics: lastYearThisMonthStatisticsReducer
}) 
