import { injectable, inject } from 'inversify';
import ThirdPartyTypes from './../../di/ThirdPartyTypes';
import { AxiosInstance } from 'axios';
import IStatisticsService from './IStatisticsService';
import { IStatisticsMarketingWarranties } from '../../types/IStatisticsMarketingWarranties';
import { IStatisticsWarranties } from '../../types/IStatisticsWarranties';
import { statistics, allSoldWarrantiesCount, allSoldWarranties } from '../../constants/apiRoutes';


@injectable()
export default class StatisticsService implements IStatisticsService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getAllSoldWarrantiesCount(startDate: string, endDate: string, sellerName: string): Promise<any> {
        const response = await this.apiClient.get(allSoldWarrantiesCount + '?startDate=' + startDate + '&endDate=' + endDate + '&sellerName=' + sellerName);
        return response.data;
    }

    public async getSoldWarranties(startDate: string, endDate: string, sellerName: string, skip: number, take: number): Promise<any> {
        const seller = sellerName.length > 0 ? sellerName : 'all';
        const response = await this.apiClient.get(allSoldWarranties + '?startDate=' + startDate + '&endDate=' + endDate + '&sellerName=' + seller + '&skip=' + skip + "&take=" + take);
        return response.data;
    }

    public async getMarketingThisMonthSoldStatistic(startDate: string, endDate: string): Promise<any> {
        const response = await this.apiClient.get(statistics + '/marketingThisMonthSoldStatistic' + '?startDate=' + startDate + '&endDate=' + endDate);
        return response.data;
    }

    public async getStatisticsMarketingWarrantiesTable(): Promise<IStatisticsMarketingWarranties[]> {
        const response = await this.apiClient.get(statistics + '/marketingWarranties');
        return response.data;
    }

    public async getStatisticsWarrantiesTable(): Promise<IStatisticsWarranties[]> {
        const response = await this.apiClient.get(statistics + '/warranties');
        return response.data;
    }

    public async getLastYearThisMonthStatistic(startDate: string, endDate: string): Promise<any> {
        const response = await this.apiClient.get(statistics + '/lastYearThisMonthStatistic' + '?startDate=' + startDate + '&endDate=' + endDate);
        return response.data;
    }

}