import React, { useEffect, useState } from 'react';
import AdminWarrantyTable from '../../components/tables/AdminWarrantyTable'
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import IAdminAttrs from './../../types/IAdminAttrs'
import { connect } from 'react-redux';
import { GetAdminTableData, GetWarrantyAdminTableTotalCountAction } from '../../redux/actions/WarrantyAction';
import { Grid } from '@material-ui/core';
import style from "./../../components/warrantyReview/warrantyView.module.scss"
import ICarData from '../../types/ICarData';
import { GetCarDataAction } from '../../redux/actions/CarAction';
import { ThisMonthTotalWarrantiesSold } from '../../components/cards/thisMonthTotalWarrantiesSold';
import { TotalWarranties } from '../../components/cards/totalWarranties';
import SoldWarrantiesTable from '../../components/tables/SoldWarrantiesTable';
import { ISoldWarranties } from '../../types/ISoldWarranties';
import ISellerProps from '../../types/ISellerProps';
import { GetAllSellersAction } from '../../redux/actions/SellerAction';
import { ActionMeta, ValueType } from "react-select/src/types";
import ReactGA from 'react-ga';
import IMissingCarDetailsProps from '../../types/IMissingCarDetailsProps';
import IBuyerProps from '../../types/IBuyerProps';

interface IStateToProps {
    allWarranties: { data: IAdminAttrs[], loading: boolean },
    carData: ICarData,
    buyer: IBuyerProps,
    isCarDetailsMissing: boolean,
    totalCount: number
}

interface IDispatchToProps {
    getAdminTableData: (page: number, pageSize: number, filters: string) => void,
    getCarData: () => void,
    viewWarranty: (warrantyId: number, carId: number) => void,
    editWarranty: (warrantyId: number, carId: number) => void,
    goToDamagePage: (carId: number, warrantyId: number) => void,
    sellCar: (buyer: IBuyerProps) => void,
    getBuyerByOIB: (oib: string) => void,
    getBuyerByEmail: (email: string) => void,
    getBuyerData: (carId: number, warrantyId: number) => void,
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void,
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => void,
    getTotalCount: () => void,

}

interface IProps extends IStateToProps, IDispatchToProps { }

const AllWarrantiesOverview: React.FC<IProps> = props => {

    useEffect(() => {
        ReactGA.pageview("Admin | sva jamstva");
        props.getAdminTableData(1, 10, "");
        props.getCarData();
        props.getTotalCount();
    }, []);

    const goToEditWarranty = (overviewData: IAdminAttrs) => {
        props.editWarranty(overviewData.warranty.warrantyId, overviewData.warranty.carId)
    }

    const viewWarranty = (dataRow: IAdminAttrs) => {
        props.viewWarranty(dataRow.warranty.warrantyId, dataRow.warranty.carId)
    }

    return (
        <React.Fragment>
            <Grid container direction="row" style={{ maxWidth: 'max-content', display: 'block' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        <TotalWarranties totalWarranties={props.totalCount} />
                    </Grid>
                </Grid>
                <AdminWarrantyTable  {...props} viewWarranty={viewWarranty} goToDamagePage={props.goToDamagePage} goToEditWarranty={goToEditWarranty} rows={props.allWarranties}
                    totalCount={props.totalCount} getAdminTableData={props.getAdminTableData} />
            </Grid>

        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    allWarranties: state.getAdminTableData,
    carData: state.carData.data,
    buyer: state.carBuyer,
    isCarDetailsMissing: state.isCarDetailsMissing.isCarDetailsMissing,
    totalCount: state.warrantyAdminTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getAdminTableData: (page: number, pageSize: number, filters: string) => dispatch<GetAdminTableData>({
        type: ActionTypes.GET_ADMIN_TABLE_DATA,
        payload: { page, pageSize, filters }
    }),
    getCarData: () => dispatch<GetCarDataAction>({
        type: ActionTypes.GET_CAR_DATA
    }),
    getTotalCount: () => dispatch<GetWarrantyAdminTableTotalCountAction>({
        type: ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT
    }),
    editWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    viewWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    goToDamagePage: (carId: number, warrantyId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/damage/' + carId + "/" + warrantyId }
        })
    },  
    sellCar: (buyer: IBuyerProps) => dispatch({
        type: ActionTypes.SELL_CAR,
        payload: buyer
    }),
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => dispatch({
        type: ActionTypes.EDIT_MISSING_CAR_DETAILS,
        payload: missingCarDetails
    }),
    getBuyerData: (carId: number, warrantyId: number) => dispatch({
        type: ActionTypes.GET_BUYER_BY_CAR_ID,
        payload: { carId, warrantyId }
    }),
    getBuyerByOIB: (oib: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_OIB,
        payload: oib
    }),
    getBuyerByEmail: (email: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_EMAIL,
        payload: email
    }),
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => dispatch({
        type: ActionTypes.SET_IS_MISSING_CAR_DETAILS,
        payload: { isCarDetailsMissing: isCarDetailsMissing }
    }),
    
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(AllWarrantiesOverview);