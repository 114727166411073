import React, { Component } from 'react';
import { IDamageProps } from '../../../types/Damage/IDamageProps';
import styles from './damageForm.module.scss';
import { Paper, Modal, Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, Tooltip, Box } from '@material-ui/core';
import IStatus from './../../../types/IStatus';
import { Trans } from 'react-i18next';
import FileUpload from './fileUpload';
import { IFile } from '../../../types/IFile';
import IAzureFile from '../../../types/IAzureFile';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormConstants from './../../../constants/FormConstants';
import { DamageStatus } from '../../../domain/enums/DamageStatus';
import NumberFormat from 'react-number-format';
import { getNumberFromThousandsString } from '../../../utils/Converter';
import { ServiceRadioButtons } from './damageServiceRadioButtons';
import { WarrantyRadioButtons } from './damageWarrantyRadioButtons';
import { BackButton } from './../../buttons/BackButton';
import { Link } from 'react-router-dom';
import { NewDamageModal } from '../../../components/forms/damageForm/NewDamageModal';
import { DeleteDamageModal } from '../../../components/forms/damageForm/DeleteDamageModal';


interface IProps extends IDamageProps {
    statuses?: IStatus[],
    submit: (data: IDamageProps) => void,
    fileNotUploadedMessage: () => void,
    deleteDamage?: (damageId: number) => void
}

interface IState extends IProps {
    isServiceIndependent?: boolean,
    warrantyCoversDamage?: boolean,
    showModal?: boolean,
    showDeleteModal?: boolean
}

export class DamageForm extends React.Component<IProps>{
    state: IState = { ...this.props }

    private handleSelectChange(el: any) {
        const { value } = el.target;
        const name = this.state.statuses!.find((s: IStatus) => s.id === value)!.name;
        if (value >= 0) this.setState({ status: { name, id: value } });
    }

    private filesChange(files?: IFile[], azureFiles?: IAzureFile[]) {
        if (files) this.setState({ files })
        if (azureFiles) this.setState({ azureFiles })
    }

    private handleChange(el: any) {
        const name = el.target.name;
        const value = el.target.value;
        this.setState({ [name]: name === 'cost' ? getNumberFromThousandsString(value) : value });
    }

    private onSubmit(state: IState, damageHasFiles: number | undefined) {
        if (damageHasFiles && damageHasFiles > 0)
            this.props.submit({
                description: state.description,
                buyer: state.buyer,
                seller: state.seller,
                status: state.status,
                id: state.id,
                cost: getNumberFromThousandsString(state.cost!),
                files: state.files,
                azureFiles: state.azureFiles,
                isServiceIndependent: state.isServiceIndependent,
                warrantyCoversDamage: state.warrantyCoversDamage,
                carDistance: getNumberFromThousandsString(state.carDistance!),
                serviceHandler: state.serviceHandler!,
                radniNalog: state.radniNalog,
                zakljucak: state.zakljucak
            })
        else this.props.fileNotUploadedMessage();
    }

    private showModal() {
        this.setState({ showModal: true });
    }

    private showDeleteModal() {
        this.setState({ showDeleteModal: true });
    }

    private handleDelete() {
        if (this.props.deleteDamage && this.state.id) {
            this.props.deleteDamage(this.state.id);
        }
    }

    render() {
        //default status value
        if (!this.state.status) this.setState({ status: this.state.statuses![0] })


        const damageHasFiles = (this.state.files && this.state.files.length)
            || (this.state.azureFiles && this.state.azureFiles.length);



        return (
            <Paper className={styles.Root}>
                <ValidatorForm
                    ref="form"
                    id={FormConstants.DAMAGE_SUBMIT_FORM}
                    onSubmit={() => this.onSubmit(this.state, damageHasFiles)}
                >
                    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={3}>
                        <Grid container item xs={12} md={4} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="center" >Kupac</Typography>
                            </Grid>
                            {this.state.buyer!.legalEntity ?
                                <Grid item xs={12}>
                                    <TextField label="Naziv" value={this.state.buyer!.legalEntityName} disabled fullWidth />
                                </Grid>
                                :
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextField label="Ime" value={this.state.buyer!.firstName} disabled fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Prezime" value={this.state.buyer!.lastName} disabled fullWidth />
                                    </Grid>
                                </React.Fragment>}
                            <Grid item xs={12}>
                                <TextField label="OIB" value={this.state.buyer!.oib} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Kontakt broj" value={this.state.buyer!.phoneNumber} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Email" value={this.state.buyer!.email} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Država" value={this.state.buyer!.country} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Grad" value={this.state.buyer!.city} disabled fullWidth />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="center">Auto</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Datum prijave štete" value={this.state.reported} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Godina proizvodnje" value={this.state.manufactureYear} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Marka" value={this.state.brand} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Model" value={this.state.model} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Paket" value={this.state.package} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Šifra jamstva" value={this.state.warrantyCode} disabled fullWidth />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="center">Prodavač</Typography>
                            </Grid>
                            {this.state.seller!.legalEntityBool ?
                                <Grid item xs={12}>
                                    <TextField label="Naziv" value={this.state.seller!.legalEntityName} disabled fullWidth />
                                </Grid>
                                :
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextField label="Ime" value={this.state.seller!.firstName} disabled fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Prezime" value={this.state.seller!.lastName} disabled fullWidth />
                                    </Grid>
                                </React.Fragment>}
                            <Grid item xs={12}>
                                <TextField label="Email" value={this.state.seller!.email} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="OIB" value={this.state.seller!.oib} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Kontakt broj" value={this.state.seller!.phoneNumber} disabled fullWidth />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={12}>
                            <Grid item xs={12} md={4} className={styles.CenterButton}>
                                <Button variant="contained" color="primary" target="_blank" component={Link} to={'/buyers/' + this.state.buyer!.email + '/warranties'}>Pregled kupca</Button>
                            </Grid>
                            <Grid item xs={12} md={4} className={styles.CenterButton}>
                                <Button variant="contained" color="primary" target="_blank" component={Link} to={'/warranty/' + this.state.warrantyCode + '/step=1'} >Pregled auta</Button>
                            </Grid>
                            <Grid item xs={12} md={4} className={styles.CenterButton}>
                                <Button variant="contained" color="primary" target="_blank" component={Link} to={'/seller/' + this.state.seller!.id} >Pregled prodavača</Button>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={12}>
                            <Grid item xs={12} md={12} className={styles.CenterButton}>
                                <Button variant="contained" color="inherit" target="_blank" component={Link} to={'/warranty_damage/' + this.state.warrantyCode} >Pregled svih šteta po jamstvu</Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={10} container className={styles.MarginTop} >
                            <Grid item xs={12} container justify="space-between">
                                {window.location.pathname == "/damage/" + this.state.carId + '/' + this.state.warrantyId ?
                                    <Grid item >
                                        <Typography variant="subtitle1" align="center" className={styles.Text}>KREIRAJTE NOVU ŠTETU</Typography>
                                    </Grid>
                                    :
                                    <Grid item >
                                        <Typography variant="subtitle1" align="center" className={styles.Text}>UREDITE POSTOJEĆU ŠTETU</Typography>
                                    </Grid>
                                }
                                {window.location.pathname != "/damage/" + this.state.carId + '/' + this.state.warrantyId &&
                                    <Grid item className={styles.CenterButton} >
                                        <Button variant="contained" color="secondary" onClick={this.showModal.bind(this)} >Nova šteta</Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={5} className={styles.MarginTop}>
                            <ServiceRadioButtons
                                isIndependentChanged={(isIndependent: boolean) => this.setState({
                                    isServiceIndependent: isIndependent
                                })} independent={this.state.isServiceIndependent || false} />
                        </Grid>
                        <Grid item xs={5} className={styles.MarginTop}>
                            <TextValidator label="Naziv servisera"
                                value={this.state.serviceHandler}
                                validators={["required"]}
                                errorMessages={[
                                    "Molimo unesite naziv servisera."
                                ]} name="serviceHandler" onChange={this.handleChange.bind(this)} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <WarrantyRadioButtons
                                warrantyCoversDamage={(isCovered: boolean) => this.setState({
                                    warrantyCoversDamage: isCovered
                                })} damageCovered={this.state.warrantyCoversDamage || false} />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberFormat

                                label="Cijena (€) štete"
                                name="cost"
                                validators={["required", "minNumber:1"]}
                                errorMessages={[
                                    "Ovo polje je obavezno.",
                                    "Cijena mora biti minimalno 1 €."
                                ]}
                                customInput={TextValidator}
                                thousandSeparator={' '}
                                onChange={this.handleChange.bind(this)}
                                value={this.state.cost ? getNumberFromThousandsString(this.state.cost!) : undefined}
                                fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberFormat

                                label="Kilometraža auta"
                                name="carDistance"
                                validators={["required", "minNumber:1"]}
                                errorMessages={[
                                    "Ovo polje je obavezno.",
                                    "Kilometraža mora biti minimalno 1 km."
                                ]}
                                customInput={TextValidator}
                                thousandSeparator={' '}
                                onChange={this.handleChange.bind(this)}
                                value={this.state.carDistance ? getNumberFromThousandsString(this.state.carDistance!) : undefined}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={styles.FormControl}>
                                <InputLabel htmlFor="status">Status prijave štete</InputLabel>
                                <Select
                                    inputProps={{
                                        name: 'status',
                                        id: 'status',
                                    }}
                                    value={this.state.status ? this.state.status.id : null}
                                    onChange={this.handleSelectChange.bind(this)}
                                >
                                    {this.state.statuses!.map((status: IStatus) =>
                                        <MenuItem value={status.id} ><Trans>{status.name}</Trans></MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <TextValidator label="Opis štete..." value={this.state.description} multiline={true} rows={3} rowsMax={10}
                                validators={["required"]}
                                errorMessages={[
                                    "Molimo opišite štetu."
                                ]} name="description" onChange={this.handleChange.bind(this)} fullWidth />
                        </Grid>
                        {this.state.status && (this.state.status.id === 3 || this.state.status.id === 2) ?
                            <Grid item xs={10}>
                                <TextValidator label="Zaključak štete..." value={this.state.zakljucak} multiline={true} rows={2} rowsMax={5}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Molimo napišite zaključak"
                                    ]} name="zakljucak" onChange={this.handleChange.bind(this)} fullWidth />
                            </Grid>
                            :
                            null}
                        <Grid item xs={10} container>
                            <Grid item xs={12} container justify="space-between">
                                <Grid item>
                                    <FileUpload files={this.state.files} azureFiles={this.state.azureFiles} filesChange={this.filesChange.bind(this)} />
                                </Grid>
                                <Grid item>
                                    <TextValidator
                                        label="Radni nalog"
                                        onChange={this.handleChange.bind(this)}
                                        name="radniNalog"
                                        value={this.state.radniNalog}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={10} container justify="space-between" spacing={2} className={styles.MarginTop}>
                            <Grid item>
                                <BackButton />
                            </Grid>
                            {window.location.pathname == "/damage/" + this.state.carId + '/' + this.state.warrantyId ?
                                <Grid item>
                                    <Button variant="contained" color="primary" type="submit">Spremi novu štetu</Button>
                                </Grid>
                                :
                                <Grid item>
                                    <Button variant="contained" color="primary" type="submit">Spremi promjene</Button>
                                </Grid>
                            }
                        </Grid>
                        {this.state.id ?
                            <Grid item xs={10} container className={styles.MarginTop}>
                                <Button variant="contained" color="inherit" onClick={this.showDeleteModal.bind(this)}>Obriši štetu</Button>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </ValidatorForm>
                {
                    this.state.showModal &&
                    <Box>
                        <Modal open={this.state.showModal} className={styles.Modal} onClose={() => this.setState({ showModal: false })}>
                            <Paper className={styles.RootModal}>
                                <div>
                                    <NewDamageModal closeModal={() => this.setState({ showModal: false })} carId={this.state.carId} warrantyId={this.state.warrantyId} reportedDamages={this.state.noOfReportedDamages} approvedDamages={this.state.noOfApprovedDamages}
                                        declinedDamages={this.state.noOfDeclinedDamages} solvedDamages={this.state.noOfSolvedDamages}></NewDamageModal>
                                </div>
                            </Paper >
                        </Modal>
                    </Box>
                }
                {this.state.showDeleteModal &&
                    <Box>
                        <Modal open={this.state.showDeleteModal} className={styles.Modal} onClose={() => this.setState({ showDeleteModal: false })}>
                            <Paper className={styles.RootModal}>
                                <div>
                                    <DeleteDamageModal closeModal={() => this.setState({ showDeleteModal: false })} submitYesModal={this.handleDelete.bind(this)}></DeleteDamageModal>
                                </div>
                            </Paper >
                        </Modal>
                    </Box>
                }
            </Paper>
        )
    }
}