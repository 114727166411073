import {
    SaveWarrantyAction, SaveWarrantyOnlyAction, DeleteWarrantyAction, GetWarrantiesAction, UpdateWarrantyStatusAction,
    GetContractWarrantyAction, ChangeValidDateAction, SendWarrantyEmailAction, ChangeSellerAction, ChangeBuyerAction,
    ChangeWarrantyDurationAction
} from "../actions/WarrantyAction";
import { SagaIterator } from '@redux-saga/core';
import { all } from "@redux-saga/core/effects";
import { Container } from 'inversify';
import { getContext, apply, put, delay } from '@redux-saga/core/effects';
import IWarrantyService from '../../domain/services/IWarrantyService';
import WarrantyService from '../../domain/services/WarrantyService';
import ActionTypes from '../actions/ActionTypes';
import { GetWarrantyStatusesDataAction, GetWarrantyAction } from './../actions/WarrantyAction';
import IWarranty from "../../types/IWarranty";
import { NotificationStyle } from "../../domain/enums/NotificationStyle";
import ReactGA from 'react-ga';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';

export function* saveWarrantyData(action: SaveWarrantyAction): SagaIterator {
    const container: Container = yield getContext('container');
    const saveWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(saveWarrantyApi, saveWarrantyApi.saveWarranty, [action.payload])
        yield put({ type: ActionTypes.SAVE_WARRANTY_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Jamstvo je uspješno spremljeno. ' + response,
                style: NotificationStyle.Success
            }
        });
        yield put({ type: ActionTypes.GET_WARRANTY })
        yield delay(2000);
        if ((!isAdmin() && !isSuperAdmin())) {
            // if seller go to overview page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/overview' } })
        } else {
            // if admin is submitting go to jamstva page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/jamstva' } })
        }
        // Clear redux
        yield put({ type: ActionTypes.SAVE_PHOTOS })
        yield put({ type: ActionTypes.POST_CAR_DATA })
        yield put({ type: ActionTypes.SAVE_PACKAGE })
        yield put({ type: ActionTypes.SAVE_EXTRA_PACKAGE })

        ReactGA.event({
            category: 'Trgovac',
            action: 'Spremanje novog jamstva',
        });

    } catch (err) {
        yield put({ type: ActionTypes.SAVE_WARRANTY_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Došlo je do pogreške prilikom spremanja jamstva.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* saveWarrantyOnly(action: SaveWarrantyOnlyAction): SagaIterator {
    const container: Container = yield getContext('container');
    const saveWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(saveWarrantyApi, saveWarrantyApi.saveWarrantyOnly, [action.payload])
        yield put({ type: ActionTypes.SAVE_WARRANTY_ONLY_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Jamstvo je uspješno spremljeno. ' + response,
                style: NotificationStyle.Success
            }
        });
        yield put({ type: ActionTypes.GET_WARRANTY })
        yield delay(2000);
        if ((!isAdmin() && !isSuperAdmin())) {
            // if seller go to overview page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/overview' } })
        } else {
            // if admin is submitting go to jamstva page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/jamstva' } })
        }
        // Clear redux
        yield put({ type: ActionTypes.SAVE_PHOTOS })

        ReactGA.event({
            category: 'Trgovac',
            action: 'Spremanje novog jamstva',
        });

    } catch (err) {
        yield put({ type: ActionTypes.SAVE_WARRANTY_ONLY_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Došlo je do pogreške prilikom spremanja jamstva.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* deleteWarrantyData(action: DeleteWarrantyAction): SagaIterator {
    const container: Container = yield getContext('container');
    const deleteWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(deleteWarrantyApi, deleteWarrantyApi.deleteWarranty, [action.payload]);
        yield put({ type: ActionTypes.DELETE_WARRANTY_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Jamstvo je uspješno obrisano.',
                style: NotificationStyle.Success
            }
        });
        yield put({ type: ActionTypes.GET_WARRANTY })
        // Clear redux
        yield put({ type: ActionTypes.SAVE_PHOTOS })
        yield put({ type: ActionTypes.SAVE_PACKAGE })
        yield put({ type: ActionTypes.SAVE_EXTRA_PACKAGE })
        yield delay(2000);
        if ((!isAdmin() && !isSuperAdmin())) {
            // if seller go to overview page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/overview' } })
        } else {
            // if admin is submitting go to jamstva page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/jamstva' } })
        }


    } catch (err) {
        yield put({ type: ActionTypes.DELETE_WARRANTY_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Došlo je do pogreške prilikom brisanja jamstva.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* changeSeller(action: ChangeSellerAction): SagaIterator {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.changeSeller, [action.payload]);
        yield put({ type: ActionTypes.CHANGE_SELLER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Trgovac je uspješno promijenjen.',
                style: NotificationStyle.Success
            }
        });
        // Clear redux
        if ((!isAdmin() && !isSuperAdmin())) {
            // if seller go to overview page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/overview' } })
        } else {
            // if admin is submitting go to jamstva page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/jamstva' } })
        }


    } catch (err) {
        yield put({ type: ActionTypes.CHANGE_SELLER_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Došlo je do pogreške prilikom promjene trgovca.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* changeBuyer(action: ChangeBuyerAction): SagaIterator {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.changeBuyer, [action.payload]);
        yield put({ type: ActionTypes.CHANGE_BUYER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac je uspješno promijenjen.',
                style: NotificationStyle.Success
            }
        });
        // Clear redux
        if ((!isAdmin() && !isSuperAdmin())) {
            // if seller go to overview page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/overview' } })
        } else {
            // if admin is submitting go to jamstva page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/jamstva' } })
        }


    } catch (err) {
        yield put({ type: ActionTypes.CHANGE_BUYER_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Došlo je do pogreške prilikom promjene kupca.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getWarrantyData(action: GetWarrantiesAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        const response: IWarranty = yield apply(getWarrantyApi, getWarrantyApi.getWarranties, [])
        yield put({ type: ActionTypes.GET_WARRANTIES_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTIES_ERROR, payload: err })
    }
}

export function* getWarrantyStatuses(action: GetWarrantyStatusesDataAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(getWarrantyApi, getWarrantyApi.getStatuses, []);
        yield put({ type: ActionTypes.GET_WARRANTY_STATUSES_SUCCESS, payload: response })
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.LOADING_END });
        yield put({ type: ActionTypes.GET_WARRANTY_STATUSES_ERROR, payload: err })
    }
}

export function* updateWarrantyStatus(action: UpdateWarrantyStatusAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(getWarrantyApi, getWarrantyApi.updateWarranty, [action.payload]);
        yield put({ type: ActionTypes.UPDATE_WARRANTY_STATUS_SUCCESS, payload: response })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Jamstvo je uspješno ažurirano.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: {
                url: '/jamstva'
            }
        });
        yield put({
            type: ActionTypes.GET_WARRANTY, payload: action.payload.id
        });
    } catch (err) {
        yield put({ type: ActionTypes.LOADING_END });
        yield put({ type: ActionTypes.UPDATE_WARRANTY_STATUS_ERROR, payload: err })
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Jamstvo nije uspješno ažurirano.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Jamstvo nije uspješno ažurirano.',
                style: NotificationStyle.Error
            }
        });
    }
}

export function* getWarrantyForContract(action: GetContractWarrantyAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        const response: IWarranty = yield apply(getWarrantyApi, getWarrantyApi.getWarrantyForContract, [action.payload]);
        yield put({ type: ActionTypes.GET_WARRANTY_FOR_CONTRACT_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTY_FOR_CONTRACT_ERROR, payload: err })
    }
}

export function* getWarranty(action: GetWarrantyAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getWarrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        // if there is no payload then user is creating new warranty.
        if (!action.payload) {
            yield put({ type: ActionTypes.GET_WARRANTY_SUCCESS, payload: null })
            yield put({ type: ActionTypes.POST_CAR_DATA, payload: null })
            yield put({ type: ActionTypes.SAVE_PACKAGE })
        }
        else {
            const response: IWarranty = yield apply(getWarrantyApi, getWarrantyApi.getWarranty, [action.payload]);
            yield put({ type: ActionTypes.GET_WARRANTY_SUCCESS, payload: response })
            let carData = response.carData!;
            carData.carId = response.carId;
            let packageData = { id: response!.allSelectedPackagesIds!.package!.id };
            let extraPackageData = response.allSelectedPackagesIds!.extraPackages;
            yield put({ type: ActionTypes.CLEAR_SELECTED_EXTRA_PACKAGES })

            yield put({ type: ActionTypes.GET_EXTRA_PACKAGES, payload: response.carData! });

            if (extraPackageData && extraPackageData.length > 0) {
                for (let i = 0; i < extraPackageData.length; i++) {
                    yield put({ type: ActionTypes.SAVE_EXTRA_PACKAGE, payload: extraPackageData[i] })
                }
            }
            yield all([
                // If warranty is successfully fetched update redux with all data
                put({ type: ActionTypes.GET_PACKAGES, payload: response.carData! }),
                //yield put({ type: ActionTypes.SAVE_PACKAGE, payload: { id: response.packageId } })
                put({ type: ActionTypes.POST_CAR_DATA, payload: carData }),
                put({ type: ActionTypes.SAVE_PACKAGE, payload: packageData }),
                put({ type: ActionTypes.GET_CAR_MODELS, payload: carData.brandId })
            ])

        }
    } catch (err) {
        yield put({ type: ActionTypes.GET_WARRANTY_ERROR, payload: err })
    } finally {
        // if changing from empty form to filled form, redux photos should be empty
        yield put({ type: ActionTypes.SAVE_PHOTOS, payload: {} })
    }
}

export function* changeValidDate(action: ChangeValidDateAction) {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.changeValidDate, [action.payload.warrantyId, action.payload.validFrom, action.payload.validUntil]);
        yield put({ type: ActionTypes.CHANGE_VALID_DATE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Datumi su uspješno promijenjeni.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: {
                url: '/jamstva'
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Datumi su uspješno promijenjeni.',
                style: NotificationStyle.Success
            }
        });
        } catch (err) {
            yield put({ type: ActionTypes.CHANGE_VALID_DATE_ERROR, payload: err });
            yield put({ type: ActionTypes.LOADING_END });
            yield put({
                type: ActionTypes.NOTIFICATION_SHOW, payload: {
                    message: 'Datumi nisu uspješno promijenjeni.',
                    style: NotificationStyle.Error
                }
            });
            yield delay(2000);
            yield put({
                type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                    message: 'Datumi nisu uspješno promijenjeni.',
                    style: NotificationStyle.Error
                }
            });
        }
}

export function* sendWarrantyEmail(action: SendWarrantyEmailAction) {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.sendWarrantyEmail, [action.payload.warrantyId]);
        yield put({ type: ActionTypes.SEND_WARRANTY_EMAIL_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Email uspješno poslan.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Email uspješno poslan.',
                style: NotificationStyle.Success
            }
        });
    } catch (err) {
        yield put({ type: ActionTypes.SEND_WARRANTY_EMAIL_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Email nije poslan.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Email nije poslan.',
                style: NotificationStyle.Error
            }
        });
    }

    
}

export function* changeWarrantyDuration(action: ChangeWarrantyDurationAction): SagaIterator {
    const container: Container = yield getContext('container');
    const warrantyApi = container.get<IWarrantyService>(WarrantyService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(warrantyApi, warrantyApi.changeWarrantyDuration, [action.payload]);
        yield put({ type: ActionTypes.CHANGE_WARRANTY_DURATION_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Uspješno promijenjeno trajanje jamstva.',
                style: NotificationStyle.Success
            }
        });
        // Clear redux
        if ((!isAdmin() && !isSuperAdmin())) {
            // if seller go to overview page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/overview' } })
        } else {
            // if admin is submitting go to jamstva page
            yield put({ type: ActionTypes.GO_TO_PAGE, payload: { url: '/jamstva' } })
        }


    } catch (err) {
        yield put({ type: ActionTypes.CHANGE_WARRANTY_DURATION_ERROR, payload: err })
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Došlo je do pogreške prilikom promjene trajanja jamstva.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}




