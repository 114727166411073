import React, { HTMLAttributes } from 'react';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps } from 'react-select/src/components/Menu';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import Typography from '@material-ui/core/Typography';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { Omit } from '@material-ui/types';
import styles from './DropdownAutocomplete.module.scss';
import { style } from '@material-ui/system';


export interface OptionType {
    label: string;
    value: string;
}
type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
    return <div className={styles.Input} ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            className={styles.Control}
            InputProps={{
                inputComponent,
                inputProps: {
                    ref: innerRef,
                    className: styles.Input,
                    children,
                    ...innerProps,
                },
            }
            }
            {...TextFieldProps}
        />
    );
}

function Option(props: OptionProps<OptionType>) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }
            }
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
    Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>;
function Placeholder(props: MuiPlaceholderProps) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={styles.Placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props: SingleValueProps<OptionType>) {
    return (
        <Typography  {...props.innerProps} className={styles.Value}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
    return <div className={styles.Container}> {props.children} </div>;
}


function Menu(props: MenuProps<OptionType>) {
    return (
        <Paper className={styles.Menu} square {...props.innerProps}>
            {props.children}
        </Paper>
    );
}
export const components = {
    Control,
    Menu,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};
