import React from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import style from './userData.module.scss';

interface IProps {
    disabled: boolean,
    confirmData: () => void,
    goToLogin: () => void
}

const AcceptHelpButtons = (props: IProps) => {
    return (
        <div className={style.Root}>
            <Grid container direction="row" alignItems="center" justify="center" xs={12} spacing={2} className={style.ContactGrid}>
                <Grid item xs={12} md={12} >
                    <p className={style.NoMargin}>U slučaju neispravnih podataka, kontaktirajte nas na naš info mail: </p>
                </Grid>
                <Grid item xs={12} md={12}>
                    <p className={style.NoMargin}>info@g1klub.hr</p>
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="flex-end" alignContent="flex-end">
                <Grid item className={style.ButtonGrid}>
                    <Button className={style.Button} variant="outlined" color="secondary" onClick={props.goToLogin}>Odustani</Button>
                </Grid>
                <Grid item className={style.ButtonGrid}>
                    <Button className={style.Button} disabled={props.disabled} variant="contained" color="primary" onClick={props.confirmData}>Potvrdi</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default AcceptHelpButtons;
