import { Reducer } from "redux";
import ActionTypes from '../actions/ActionTypes';
import IDamageAdminTable from '../../types/Damage/IDamageAdminTable';
import IDamageBuyerTable from '../../types/Damage/IDamageBuyerTable';
import { IDamageSellerTable } from './../../types/Damage/IDamageSellerTable';
import IDamageSellerByAdminTable from '../../types/Damage/IDamageSellerByAdminTable';
import IDamageCarTable from '../../types/Damage/IDamageCarTable';

const initialDamagesData: ITableData = {
    loading: false, data: {
        admin: new Array<IDamageAdminTable>(),
        seller: new Array<IDamageSellerTable>(),
        buyer: new Array<IDamageBuyerTable>(),
        sellerByAdmin: new Array<IDamageSellerByAdminTable>(),
        car: new Array<IDamageCarTable>()
    }
}

interface ITableData {
    loading: boolean, data: {
        admin?: IDamageAdminTable[],
        seller?: IDamageSellerTable[],
        buyer?: IDamageBuyerTable[],
        sellerByAdmin?: IDamageSellerByAdminTable[],
        car?: IDamageCarTable[]
    }
}

export const getDamagesReducer: Reducer<ITableData> = (state = initialDamagesData, action) => {
    switch (action.type) {
        case ActionTypes.GET_DAMAGES_ADMIN: return { loading: true, data: { admin: [], seller: [] } }
        case ActionTypes.GET_DAMAGES_ADMIN_SUCCESS: return { loading: false, data: { admin: action.payload } }
        case ActionTypes.GET_DAMAGES_ADMIN_ERROR: return { loading: false, errors: action.payload, data: { admin: [] } }

        case ActionTypes.GET_DAMAGES_SELLER: return { loading: true, data: { admin: [], seller: [] } }
        case ActionTypes.GET_DAMAGES_SELLER_SUCCESS: return { loading: false, data: { seller: action.payload } }
        case ActionTypes.GET_DAMAGES_SELLER_ERROR: return { loading: false, errors: action.payload, data: { seller: [] } }

        case ActionTypes.GET_DAMAGES_BUYER: return { loading: true, data: { admin: [], seller: [], buyer: [] } }
        case ActionTypes.GET_DAMAGES_BUYER_SUCCESS: return { loading: false, data: { buyer: action.payload } }
        case ActionTypes.GET_DAMAGES_BUYER_ERROR: return { loading: false, errors: action.payload, data: { buyer: [] } }

        case ActionTypes.GET_DAMAGES_WARRANTY: return { loading: true, data: { admin: [], seller: [], buyer: [], warranty: [] } }
        case ActionTypes.GET_DAMAGES_WARRANTY_SUCCESS: return { loading: false, data: { warranty: action.payload } }
        case ActionTypes.GET_DAMAGES_WARRANTY_ERROR: return { loading: false, errors: action.payload, data: { warranty: [] } }

        case ActionTypes.GET_DAMAGES_SELLERBYADMIN: return { loading: true, data: { admin: [], seller: [], buyer: [], warranty: [], sellerByAdmin: [] } }
        case ActionTypes.GET_DAMAGES_SELLERBYADMIN_SUCCESS: return { loading: false, data: { sellerByAdmin: action.payload } }
        case ActionTypes.GET_DAMAGES_SELLERBYADMIN_ERROR: return { loading: false, errors: action.payload, data: { sellerByAdmin: [] } }

        case ActionTypes.GET_DAMAGES_CAR: return { loading: true, data: { admin: [], seller: [], buyer: [], warranty: [], sellerByAdmin: [], car: [] } }
        case ActionTypes.GET_DAMAGES_CAR_SUCCESS: return { loading: false, data: { car: action.payload } }
        case ActionTypes.GET_DAMAGES_CAR_ERROR: return { loading: false, errors: action.payload, data: { car: [] } }

        default: return state;

    }
}

type DamagesAdminTableTotalCountData = { data: number };

export const damagesAdminTableTotalCountReducer: Reducer<DamagesAdminTableTotalCountData> = (state: DamagesAdminTableTotalCountData = { data: 0 }, action: any): DamagesAdminTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}