
import {
    GetAllSoldStatistic, GetSoldStatistic, GetMarketingThisMonthSoldStatistic, GetStatisticsMarketingWarrantiesTableAction,
    GetStatisticsWarrantiesTableAction, GetLastYearThisMonthStatistic
} from './../actions/StatisticsAction';
import { Container } from 'inversify';
import { getContext, apply, put, delay } from '@redux-saga/core/effects';
import IStatisticsService from './../../domain/services/IStatisticsService';
import StatisticsService from './../../domain/services/StatisticsService';
import ActionTypes from './../actions/ActionTypes';

export function* getThisMonthSoldStatistics(action: GetAllSoldStatistic) {
    const container: Container = yield getContext('container');
    const statisticsApi = container.get<IStatisticsService>(StatisticsService);
    try {
        const data = yield apply(statisticsApi, statisticsApi.getAllSoldWarrantiesCount, [action.payload.startDate, action.payload.endDate, action.payload.sellerName]);
        yield put({
            type: ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC_SUCCESS, payload: data
        });
    } catch (err) {
        yield put({ type: ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC_ERROR, payload: err });
    }
}

export function* getSoldStatistics(action: GetSoldStatistic) {
    const container: Container = yield getContext('container');
    const statisticsApi = container.get<IStatisticsService>(StatisticsService);
    try {
        const data = yield apply(statisticsApi, statisticsApi.getSoldWarranties, [action.payload.startDate, action.payload.endDate, action.payload.sellerName, action.payload.skip, action.payload.take]);
        yield put({
            type: ActionTypes.GET_SOLD_STATISTIC_SUCCESS, payload: data
        });
    } catch (err) {
        yield put({ type: ActionTypes.GET_SOLD_STATISTIC_ERROR, payload: err });
    }
}

export function* getMarketingThisMonthSoldStatistic(action: GetMarketingThisMonthSoldStatistic) {
    const container: Container = yield getContext('container');
    const statisticsApi = container.get<IStatisticsService>(StatisticsService);
    try {
        const data = yield apply(statisticsApi, statisticsApi.getMarketingThisMonthSoldStatistic, [action.payload.startDate, action.payload.endDate]);
        yield put({
            type: ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC_SUCCESS, payload: data
        });
    } catch (err) {
        yield put({ type: ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC_ERROR, payload: err });
    }
}

export function* getStatisticsMarketingWarrantiesTable(action: GetStatisticsMarketingWarrantiesTableAction) {
    const container: Container = yield getContext('container');
    const statisticsApi = container.get<IStatisticsService>(StatisticsService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(statisticsApi, statisticsApi.getStatisticsMarketingWarrantiesTable, []);
        yield put({ type: ActionTypes.GET_STATISTICS_MARKETING_WARRANTIES_TABLE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_STATISTICS_MARKETING_WARRANTIES_TABLE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getStatisticsWarrantiesTable(action: GetStatisticsWarrantiesTableAction) {
    const container: Container = yield getContext('container');
    const statisticsApi = container.get<IStatisticsService>(StatisticsService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(statisticsApi, statisticsApi.getStatisticsWarrantiesTable, []);
        yield put({ type: ActionTypes.GET_STATISTICS_WARRANTIES_TABLE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_STATISTICS_WARRANTIES_TABLE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getLastYearThisMonthStatistic(action: GetLastYearThisMonthStatistic) {
    const container: Container = yield getContext('container');
    const statisticsApi = container.get<IStatisticsService>(StatisticsService);
    try {
        const data = yield apply(statisticsApi, statisticsApi.getLastYearThisMonthStatistic, [action.payload.startDate, action.payload.endDate]);
        yield put({
            type: ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC_SUCCESS, payload: data
        });
    } catch (err) {
        yield put({ type: ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC_ERROR, payload: err });
    }
}
