import React, { useRef, useEffect } from 'react';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import NewCarForm from '../forms/newCarForm/NewCarForm';
import { Paper, Button, Grid } from '@material-ui/core';
import styles from './stepper.module.scss';
import ICarProps from '../../types/ICarProps';
import IPackageSelection from '../../types/IPackageSelection';
import ISelectedPackage from '../../types/ISelectedPackage';
import PackageSelection from '../packageSelection/PackageSelection';
import ICarData from '../../types/ICarData';
import MobileStepper from '@material-ui/core/MobileStepper';
import IWarrantyStatus from '../../types/IStatus';
import WarrantyReview from './../warrantyReview/WarrantyReview';
import { Roles } from '../../domain/enums/Roles';
import IWarranty from '../../types/IWarranty';
import FormConstants from './../../constants/FormConstants';
import { getUserRole } from '../../utils/AuthFunctions';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import IPhotoUploadProps from '../../types/IPhotoUploadProps';
import PhotoUploadForm from '../forms/photoUploadForm/PhotoUploadForm';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';
import IActionNotification from '../../types/utilities/IActionNotification';
import WarrantyStatus from './../../domain/enums/WarrantyStatus';
import ICarReview from "../../types/ICarReview";
import { initialCarReview } from '../../redux/reducers/carReducer';
import { ShowNotificationAction } from '../../redux/actions/ActionNotificationAction';
import { StepperHelper } from '../../utils/StepperHelper';
import ISellerProps from '../../types/ISellerProps';
import { IDamageProps } from '../../types/Damage/IDamageProps';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';

const maxSteps = 4;

interface IDispatchToProps {
    redirectToPage: () => void,
    pictureCaptured: (notification: IActionNotification) => void,
    showPicErrorSnack: () => void,
}


interface IProps extends ICarData, IDispatchToProps {
    postCar: ICarProps,
    postPhotoUpload: IPhotoUploadProps,
    currentWarranty: IWarranty,
    saveCar: (data: ICarProps) => void,
    savePhotos: (data: IPhotoUploadProps) => void,
    selectedPackage: ISelectedPackage,
    packagesData: IPackageSelection,
    selectedExtraPackages: { data: ISelectedPackage[] },
    saveSelectedPackage: (data: ISelectedPackage) => void,
    removeExtraPackage: (data: ISelectedPackage) => void,
    saveExtraSelectedPackages: (data: ISelectedPackage) => void,
    saveWarranty: (data: IWarranty) => void,
    deleteWarranty: (data: IWarranty) => void,
    warrantyStatuses: IWarrantyStatus[],
    updateWarrantyStatus: (data: IWarranty, isReview: boolean) => void,
    getModelsByBrand?: (brandId: number) => void,
    carReview: ICarReview,
    reviewCar: (data: ICarReview) => void,
    getCarReview: (carId: number) => void,
    history: any,
    step: number,
    sellers: ISellerProps[],
    getChassisDamage?: (chassisNumber: string) => void,
    chassisDamage: IDamageProps,
    getCarProblemExtraPackage: (car: ICarProps) => void,
    carProblemExtraPackages: { data: ICarProblemExtraPackage[] },
    changeValidDate: (warrantyId: string, validFrom: string, validUntil: string) => void,
    sendWarrantyMail: (warrantyId: string) => void
}

let isAdmin: boolean;
let isSuperAdmin: boolean;
let isDisabled: boolean;

const WarrantyStepper: React.FC<IProps> = (props: IProps) => {
    const [carReview, setCarReview] = React.useState(initialCarReview);
    const [currentPhotosLen, setCurrentPhotosLen] = React.useState(0);
    const [currentWarranty, setCurrentWarranty] = React.useState(props.currentWarranty!);
    let statusForCheck = props.currentWarranty ? props.currentWarranty.status ? props.currentWarranty.status.name : "" : "";
    const newCarFormRef = useRef<NewCarForm>(null);
    const newPhotoUploadRef = useRef<PhotoUploadForm>(null);

    useEffect(() => {
        setCurrentWarranty(props.currentWarranty);
        if (props.currentWarranty && props.currentWarranty.carId) props.getCarReview(props.currentWarranty.carId!);
        updateVariables();
        // In case user is reviewing warannty, he should not be able to make changes
        // so redirect him on last step.
        if (isDisabled && StepperHelper.getStep() === 1
            && props.currentWarranty && props.currentWarranty.id === StepperHelper.getWarrantyIdFromUrl()
            //added props.currentWarranty.status! because it renders without status and cannot update it 
            && props.currentWarranty.status!) // Check only if warranty from props is loaded and matches with URL

        {
            props.history.replace(StepperHelper.stepUrl(4))
        }
    }, [props.currentWarranty]);

    useEffect(() => {
        // if upgrade mode redirect to package sellection which is step 3
        if (StepperHelper.isUpgradeMode()) {
            // if (StepperHelper.getStep() !== 3) props.history.replace('/upgrade/' + StepperHelper.getUpgradeUrl(3))
        } else {
            // Validate Stepper if URL changes manually
            if (StepperHelper.getStep() === 3) { // check how many pictures are loaded
                if (currentPhotosLen < 6) {
                    props.history.push(StepperHelper.decreasedStepUrl());
                    props.showPicErrorSnack();
                }
            }
        }
    }, [location.href])

    function updateVariables() {
        statusForCheck = props.currentWarranty ? props.currentWarranty.status ? props.currentWarranty.status.name : "" : "";
        isAdmin = getUserRole() === Roles.Admin;
        isSuperAdmin = getUserRole() === Roles.SuperAdmin;
        isDisabled = false;//StepperHelper.isWarrantyForReview(statusForCheck);
    }
    function getStepContent() {
        switch (props.step) {
            case 1:
                if (props.carBrands && props.engineTypes) return <NewCarForm carReview setCarReview={setCarReview} status={props.currentWarranty && props.currentWarranty.status} handleNext={handleNext} {...props.postCar} {...props} chassisDamage={props.chassisDamage} isReview={isDisabled} ref={newCarFormRef} />;
                else return null;
            case 2:
                return <PhotoUploadForm savePhotosToRedux={saveToStore} photosNumber={(photosLen: number) => setCurrentPhotosLen(photosLen)} isReview={isDisabled} onCapture={props.pictureCaptured} handleNext={handleNext} postCar={props.postCar} {...props.postPhotoUpload} ref={newPhotoUploadRef} />;
            case 3:
                return <PackageSelection {...props} savePackage={props.saveSelectedPackage} saveExtraPackage={props.saveExtraSelectedPackages} packagesData={props.packagesData} removeExtraPackage={props.removeExtraPackage} postCar={props.postCar} carProblemExtraPackages={props.carProblemExtraPackages} />;
            case 4:
                {
                    const warrantyReview = <WarrantyReview {...props.currentWarranty} carData={props.currentWarranty && props.currentWarranty.carData} setCarReview={setCarReview}
                        warrantyDataChanged={setCurrentWarranty} pictures={props.postPhotoUpload.pictures} urls={props.postPhotoUpload.urls} packagesData={props.packagesData}
                        carProblemExtraPackages={props.carProblemExtraPackages} 
                        statuses={props.warrantyStatuses ? Object.values(props.warrantyStatuses) : []} {...props} {...props.postCar} isReview={(
                            (statusForCheck && statusForCheck !== WarrantyStatus.InProcess) && (!isAdmin && !isSuperAdmin)) || (statusForCheck !== WarrantyStatus.Submitted && (isAdmin || isSuperAdmin))}
                        save={() => handleNext()}
                        delete={() => handleDelete()}
                        changeValidDate={props.changeValidDate}
                        sendWarrantyMail={props.sendWarrantyMail}
                        saveWarrantyStatus={() => saveWarrantyStatus()} />;

                    if (props.currentWarranty) {
                        // updating Warranty
                        if (props.carBrands && props.engineTypes && props.packagesData && props.selectedPackage) return warrantyReview;
                        else return null;
                    }
                }
        }
    }

    function handleNext() {
        if (props.step === 1) {
            const formIsValid = newCarFormRef.current!.checkSubmit();
            const selectIsValid = newCarFormRef.current!.validateForm();
            if (!(selectIsValid && formIsValid)) return;


            const carData = newCarFormRef.current!.getStateData();
            props.getCarProblemExtraPackage(carData);
        }

        if (props.step === 2 && currentPhotosLen < 6) {
            props.showPicErrorSnack();
            return;
        }
        
        saveToStore();

        if (props.step === maxSteps) {
            let saveWarrantyValidation = true;
            if ((isAdmin || isSuperAdmin) && (localStorage.getItem("carSeller") == undefined || Object.keys(localStorage.getItem("carSeller")!).length == 0 || (JSON.parse(localStorage.getItem("carSeller")!) && JSON.parse(localStorage.getItem("carSeller")!).label === " "))) {
                saveWarrantyValidation = false;
                alert("Molimo odaberite trgovca pri vrhu stranice.")
            }
            // if package was not selected, select first by default
            const selectedPackage: ISelectedPackage = props.selectedPackage && props.selectedPackage.id ? props.selectedPackage : { id: props.packagesData.packages[0].packageId }
            if (saveWarrantyValidation) {
                props.saveWarranty({
                    carData: props.postCar,
                    allSelectedPackagesIds: {
                        package: selectedPackage,
                        extraPackages: props.selectedExtraPackages.data
                    },
                    carId: props.postCar.carId,
                    id: props.currentWarranty ? props.currentWarranty.id : undefined,
                    carPhotos: props.postPhotoUpload.pictures,
                    sellerId: (isAdmin || isSuperAdmin) && localStorage.getItem("carSeller") ? JSON.parse(localStorage.getItem("carSeller")!).value : null
                })
            }
        } else props.history.push(StepperHelper.increasedStepUrl())
        if (props.step !== 4) window.scrollTo(0, 0);
    }

    function handleDelete() {
        props.deleteWarranty({
            carId: props.postCar.carId,
            id: props.currentWarranty ? props.currentWarranty.id : undefined,
            sellerId: (isAdmin || isSuperAdmin) && localStorage.getItem("carSeller") ? JSON.parse(localStorage.getItem("carSeller")!).value : null
        })
    }

    function handleBack() {
        saveToStore();
        props.history.push(StepperHelper.decreasedStepUrl())
    }

    function saveWarrantyStatus() {
        if (getUserRole() === Roles.Admin && props.currentWarranty) {
            // var update = !WarrantyStatusChecker.AdminViewOrUpdate(statusForCheck); //checker - true if view, false if updated
            props.updateWarrantyStatus({ ...currentWarranty, id: props.currentWarranty.id }, true);
            props.reviewCar({ ...carReview, carId: props.currentWarranty.carId! });
        }
    }
    function saveToStore() {
        switch (props.step) {
            case 1:
                const carData = newCarFormRef.current!.getStateData();
                if (props.postCar) carData.images = props.postCar.images;
                props.saveCar(carData);
            case 2:
                let photoData;
                if (newPhotoUploadRef.current) {
                    photoData = newPhotoUploadRef.current!.getStateData();
                    props.savePhotos(photoData)
                }
        }
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" style={{ marginBottom: 25 }}>
            <Paper className={styles.Stepper}>
                <Grid item xs className={styles.StepperPagination}>
                    {!isDisabled &&
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            variant="dots"
                            activeStep={props.step - 1}
                            nextButton={
                                <Button size="small" onClick={handleNext} type="submit"
                                    form={FormConstants.CAR_PURCHASE_FORM} disabled={props.step === maxSteps}>
                                    Naprijed
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={props.step === 1}>
                                    <KeyboardArrowLeft />
                                    Nazad
                                </Button>
                            }
                        />}
                </Grid>
                <Typography className={styles.Content}>{getStepContent()}</Typography>
                <Grid item xs className={styles.MobileStepperPagination}>
                    {!isDisabled &&
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            variant="dots"
                            activeStep={props.step - 1}
                            nextButton={
                                <Button size="small" onClick={handleNext} type="submit"
                                    form={FormConstants.CAR_PURCHASE_FORM} disabled={props.step === maxSteps}>
                                    Naprijed
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={props.step === 1}>
                                    <KeyboardArrowLeft />
                                    Nazad
                                </Button>
                            }
                        />}
                </Grid>
            </Paper>
        </Grid>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    redirectToPage: () => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: (isAdmin || isSuperAdmin) ? '/jamstva' : '/overview' }
        })
    },
    pictureCaptured: (notification: IActionNotification) => {
        dispatch({
            type: ActionTypes.NOTIFICATION_SHOW,
            payload: { ...notification }
        })
    },
    showPicErrorSnack: () => dispatch<ShowNotificationAction>({
        type: ActionTypes.NOTIFICATION_SHOW,
        payload: {
            message: "Molimo učitajte 6 slika automobila.",
            style: NotificationStyle.Error
        }
    }),

})


export default connect<null, IDispatchToProps, null, IApplicationState>(null, mapDispatchToProps)(WarrantyStepper)
