import { Reducer } from "redux";
import ActionTypes from './../actions/ActionTypes';
import IWarrantyOverview from './../../types/IWarrantyOverview';
import IActivateWarranty from "../../types/IActivateWarranty";

const initialWarrantiesData: { loading: boolean, data: IWarrantyOverview[]} = { loading: false, data: new Array<IWarrantyOverview>()}


export const warrantiesDataReducer: Reducer<{loading: boolean, data: IWarrantyOverview[]}> = (state = initialWarrantiesData, action) => {
    switch(action.type) {
        case ActionTypes.GET_WARRANTIES: return {loading: true, data: []}
        case ActionTypes.GET_WARRANTIES_SUCCESS: return {loading: false, data: action.payload}
        case ActionTypes.GET_WARRANTIES_ERROR: return {loading: false, errors: action.payload, data: []}
        default: return state;
    }
} 

type NullableWarranty = number | null | IActivateWarranty;
export const warrantyReducer: Reducer<NullableWarranty> = (state: NullableWarranty = null, action): NullableWarranty => {
    switch(action.type) {
        case ActionTypes.GET_WARRANTY: return {...action.payload}
        case ActionTypes.GET_WARRANTY_SUCCESS: return {...action.payload}
        case ActionTypes.GET_WARRANTY_ERROR: return {...action.payload}
        case ActionTypes.GET_WARRANTY_FOR_CONTRACT: return {...action.payload}
        case ActionTypes.GET_WARRANTY_FOR_CONTRACT_SUCCESS: return {...action.payload}
        case ActionTypes.GET_WARRANTY_FOR_CONTRACT_ERROR: return {...action.payload}
        default: return state;
    }
}

type WarrantyData = IActivateWarranty | null; 
export const warrantyContractReducer: Reducer<WarrantyData> = (state: WarrantyData = null, action): WarrantyData => {
    switch(action.type) {
        case ActionTypes.GET_WARRANTY_FOR_CONTRACT: return {...action.payload}
        case ActionTypes.GET_WARRANTY_FOR_CONTRACT_SUCCESS: return {...action.payload}
        case ActionTypes.GET_WARRANTY_FOR_CONTRACT_ERROR: return {...action.payload}
        default: return state;
    }
}

type WarrantyAdminTableTotalCountData = { data: number };

export const warrantyAdminTableTotalCountReducer: Reducer<WarrantyAdminTableTotalCountData> = (state: WarrantyAdminTableTotalCountData = { data: 0 }, action: any): WarrantyAdminTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}

type WarrantyAdminBuyerTableTotalCountData = { data: number };

export const warrantyAdminBuyerTableTotalCountReducer: Reducer<WarrantyAdminBuyerTableTotalCountData> = (state: WarrantyAdminBuyerTableTotalCountData = { data: 0 }, action: any): WarrantyAdminBuyerTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_WARRANTY_ADMIN_BUYER_TABLE_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_WARRANTY_ADMIN_BUYER_TABLE_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_WARRANTY_ADMIN_BUYER_TABLE_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}

type WarrantyAdminSellerTableTotalCountData = { data: number };

export const warrantyAdminSellerTableTotalCountReducer: Reducer<WarrantyAdminSellerTableTotalCountData> = (state: WarrantyAdminSellerTableTotalCountData = { data: 0 }, action: any): WarrantyAdminSellerTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}

type WarrantyAdminArchiveTableTotalCountData = { data: number };

export const warrantyAdminArchiveTableTotalCountReducer: Reducer<WarrantyAdminArchiveTableTotalCountData> = (state: WarrantyAdminArchiveTableTotalCountData = { data: 0 }, action: any): WarrantyAdminArchiveTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}