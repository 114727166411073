import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import { FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';

const HAK = 'HAK';

interface IProps {
    isIndependentChanged: (independent: boolean) => void,
    independent: boolean
}

export const ServiceRadioButtons: React.FC<IProps> = (props: IProps) => {
    const [isIndependent, setIsIndependent] = React.useState(props.independent);

    useEffect(() => setIsIndependent(props.independent), [props]);

    function handleChange(event: React.ChangeEvent<unknown>) {
        const independent = (event.target as HTMLInputElement).value !== HAK;
        setIsIndependent(independent);
        props.isIndependentChanged(independent);
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Servis:</FormLabel>
            <RadioGroup aria-label="position" name="position" value={isIndependent ? 'independent' : HAK} onChange={handleChange} row>
                <FormControlLabel
                    value="start"
                    control={<Radio
                        checked={!isIndependent}
                        onChange={handleChange}
                        value={HAK}
                        name={HAK}
                    />}
                    label="HAK"
                    labelPlacement="start"
                />
                <FormControlLabel
                    value="start"
                    control={<Radio
                        checked={isIndependent}
                        onChange={handleChange}
                        value="independent"
                        name="independent"
                    />}
                    label="Samostalan"
                    labelPlacement="start"
                />
            </RadioGroup>
        </FormControl>
    );
}
