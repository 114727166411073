import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import styles from './publicForms.module.scss';

export interface IProps {
    children: ReactNode,
    className?: string,
    md?: boolean,
    lg?: boolean,
    hideTitle?: boolean
}

const PublicFormLayout: React.FC<IProps> = (props: IProps) => {
    return (
        <div className={styles.Root}>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className={props.className}
            >
                <Grid item xs={12} md={props.md ? 9 : 6} lg={props.lg ? 7 : 4}>
                    {!props.hideTitle && <h2>G1 - Povjerenje u dobre ruke</h2>}
                    {props.children}
                </Grid>
            </Grid>
        </div >
    )
}

export default PublicFormLayout;