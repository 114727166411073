import { Redirect, Route } from "react-router";
import Navbar from '../../components/app/NavBar';
import Footer from '../../components/app/Footer';
import ContentLayout from '../../components/app/ContentLayout';
import Background from '../../components/app/Background';
import React from 'react';
import { isAuthenticated } from './../../utils/AuthFunctions';
import styles from './routes.module.scss';
import ActionNotificationContainer from './../../containers/utilities/ActionNotificationContainer';
import Loading from './../../containers/utilities/Loading';

const PublicRoute = ({
    component: Component,
    ...rest
}: any) => (
        <div className={styles.PublicBackground}>
            <Loading />
            <ActionNotificationContainer />
            <Route {...rest} render={(props: any) => <Component {...props} />} />
        </div>
    );


export default PublicRoute;