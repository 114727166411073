import { Reducer } from "redux";
import ActionTypes from '../actions/ActionTypes';
import ICarAdminTable from '../../types/ICarAdminTable';
import ICarSellerTable from '../../types/ICarSellerTable';
import ICarProps from '../../types/ICarProps';

const initialCarsData: ITableData = {
    loading: false, data: {
        admin: new Array<ICarAdminTable>(),
        seller: new Array<ICarSellerTable>()
    }
}

interface ITableData {
    loading: boolean, data: {
        admin?: ICarAdminTable[],
        seller? : ICarSellerTable[]
    }
}

export const getCarsReducer: Reducer<ITableData> = (state = initialCarsData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CARS_ADMIN: return { loading: true, data: { admin: [] } }
        case ActionTypes.GET_CARS_ADMIN_SUCCESS: return { loading: false, data: { admin: action.payload } }
        case ActionTypes.GET_CARS_ADMIN_ERROR: return { loading: false, errors: action.payload, data: { admin: [] } }
        case ActionTypes.GET_CARS_SELLER: return { loading: true, data: { seller: [] } }
        case ActionTypes.GET_CARS_SELLER_SUCCESS: return { loading: false, data: { seller: action.payload } }
        case ActionTypes.GET_CARS_SELLER_ERROR: return { loading: false, errors: action.payload, data: { seller: [] } }
        case ActionTypes.GET_CARS_ADMIN_ONLY_CARS: return { loading: true, data: { admin: [] } }
        case ActionTypes.GET_CARS_ADMIN_ONLY_CARS_SUCCESS: return { loading: false, data: { admin: action.payload } }
        case ActionTypes.GET_CARS_ADMIN_ONLY_CARS_ERROR: return { loading: false, errors: action.payload, data: { admin: [] } }
        case ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS: return { loading: true, data: { admin: [] } }
        case ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS_SUCCESS: return { loading: false, data: { admin: action.payload } }
        case ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS_ERROR: return { loading: false, errors: action.payload, data: { admin: [] } }

        default: return state;

    }
}

type AdminTableTotalCountData = { data: number };

export const carsAdminTableTotalCountReducer: Reducer<AdminTableTotalCountData> = (state: AdminTableTotalCountData = { data: 0 }, action: any): AdminTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}

type AdminOnlyCarsTableTotalCountData = { data: number };

export const carsAdminOnlyCarsTableTotalCountReducer: Reducer<AdminOnlyCarsTableTotalCountData> = (state: AdminOnlyCarsTableTotalCountData = { data: 0 }, action: any): AdminOnlyCarsTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}

type AdminSoldOnlyTableTotalCountData = { data: number };

export const carsAdminSoldOnlyTableTotalCountReducer: Reducer<AdminSoldOnlyTableTotalCountData> = (state: AdminSoldOnlyTableTotalCountData = { data: 0 }, action: any): AdminSoldOnlyTableTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}