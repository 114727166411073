import React from 'react';
import { Grid, Box, Button, Typography, Modal, Paper } from '@material-ui/core';
import IReviewMode from '../../../types/IReviewModeProps';
import { ValidatorForm } from 'react-material-ui-form-validator';
import IPhotoUploadProps from '../../../types/IPhotoUploadProps';
import ImageUploader from 'react-images-upload';
import photoStyles from './photoUploadForm.module.scss';
import ICarProps from '../../../types/ICarProps';
import styles from './photoUploadForm.module.scss';
import IActionNotification from '../../../types/utilities/IActionNotification';
import { isAdmin, isSuperAdmin } from '../../../utils/AuthFunctions';
import { NotificationStyle } from '../../../domain/enums/NotificationStyle';
const Webcam = require("react-webcam");
import ICar from '../../../types/ICar';
import { StepperHelper } from '../../../utils/StepperHelper';
import { SellCarWithoutWarrantyModal } from '../../../components/forms/newCarForm/SellCarWithoutWarrantyModal';
import stylesModal from '../../../components/forms/newCarForm/sellCarWithoutWarrantyModal.module.scss';

interface IProps extends IPhotoUploadProps, IReviewMode, IState {
    ref?: React.RefObject<PhotoUploadForm | undefined>,
    handleNext?: () => void,
    handleSaveAndContinue?: (oneYear: boolean) => void,
    handleSellWithoutWarranty?: () => void,
    handleWarrantyDurationModal?: () => void,
    savePhotosToRedux: () => void,
    postCar: ICarProps,
    photosNumber: (photos: number) => void,
    onCapture: (norification: IActionNotification) => void,
    currentCar?: ICarProps
}

interface IState {
    showSellCarWithoutWarrantyModal?: boolean
}

export default class PhotoUploadForm extends React.Component<IProps> {
    state = {
        pictures: this.props.pictures ? this.props.pictures : [],
        submitValid: false,
        urls: this.props.postCar ? this.props.postCar.images ? this.props.postCar.images : [] : [],
        mappedPictures: [] as any,
        showCameraWindow: false,
        webcam: { getScreenshot() { return "" } },
        showSellCarWithoutWarrantyModal: false
    }

    public validateForm(): boolean {
        const state = this.state;
        let formValid = true;

        return formValid;
    }

    public getStateData(): IPhotoUploadProps {
        return {
            pictures: this.state.pictures,
            urls: this.state.urls!
        }
    };

    checkSubmit(): boolean {
        return this.state.submitValid;
    }

    handleSubmit(props: IProps) {
        this.setState((prevState: any) => ({
            ...prevState,
            submitValid: true
        }))
        if (props.handleNext) props.handleNext();
    }

    handleSaveAndContinue(props: IProps) {
        this.setState((prevState: any) => ({
            ...prevState,
            submitValid: true
        }))
        if (props.handleSaveAndContinue && props.postCar.isOneYearWarranty !== undefined) props.handleSaveAndContinue(props.postCar.isOneYearWarranty);
    }

    handleWarrantyDurationModal(props: IProps) {
        this.setState((prevState: any) => ({
            ...prevState,
            submitValid: true
        }))
        if (props.handleWarrantyDurationModal) props.handleWarrantyDurationModal();
    }

    handleSellWithoutWarranty(props: IProps) {
        this.setState((prevState: any) => ({
            ...prevState,
            submitValid: true
        }))
        if (props.handleSellWithoutWarranty) props.handleSellWithoutWarranty();
    }



    showSellCarWithoutWarrantyModal() {

        this.setState({ showSellCarWithoutWarrantyModal: true });

    }



    private handleSellCarWithoutWarrantyModal() {

        if (this.props.handleSellWithoutWarranty) this.props.handleSellWithoutWarranty();

    }


    public onDrop(addedPictures: File[]) {
        //console.log(addedPictures);
        var newPics = this.state.pictures;
        addedPictures.forEach((picture: File) => {
            var myReader: FileReader = new FileReader();
            //console.log("Ispis: " + picture);
            myReader.onloadend = (e) => {

                var exists: boolean = false;

                newPics.forEach((p: any) => {
                    if (p.name == picture.name && p.data == myReader.result) {
                        exists = true;
                    }
                })

                if (!exists) {
                    newPics.push({ name: picture.name, data: myReader.result, file: picture });
                    this.setState({ pictures: newPics }, () => {
                        if (this.props.savePhotosToRedux) this.props.savePhotosToRedux()
                    });
                }
            }
            myReader.readAsDataURL(picture);
        })

        while (addedPictures.length !== 0) { addedPictures.pop(); }
    }

    //camera
    public handleCWchange(event: any) {
        event.preventDefault()
        this.setState({
            showCameraWindow: this.state.showCameraWindow ? false : true
        });
    }

    public setCameraRef(webcam: any) {
        //console.log(webcam);
        this.state.webcam = webcam;
    };

    public capture(event: any) {
        event.preventDefault()
        //console.log(this.state.webcam);
        var imageSrc = this.state.webcam.getScreenshot();
        var imageFile = this.dataURLtoFile(imageSrc, 'null.jpg');
        //console.log(imageSrc);
        this.props.onCapture({
            message: 'Slika uspješno dodana',
            style: NotificationStyle.Success
        });
        this.setState({
            pictures: this.state.pictures.concat({ name: "null.jpg", data: imageSrc, file: imageFile })
        }, () => { if (this.props.savePhotosToRedux) this.props.savePhotosToRedux() });
        //console.log(this.state.pictures);
    }

    public dataURLtoFile(dataurl: any, filename: any) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    //gallery
    public deleteUrl(u: never) {
        var newUrls = this.state.urls!;
        newUrls.forEach((item, index) => {
            if (item === u) newUrls.splice(index, 1);
        });

        this.setState({
            urls: newUrls
        }, () => {
            if (this.props.savePhotosToRedux) this.props.savePhotosToRedux();
        });
        if (this.props.savePhotosToRedux) this.props.savePhotosToRedux();
    }

    public deletePicture(p: any) {
        var newPictures = this.state.pictures;
        newPictures.forEach((item, index) => {
            if (item === p) newPictures.splice(index, 1);
        });

        this.setState({
            pictures: newPictures
        }, () => {
            if (this.props.savePhotosToRedux) this.props.savePhotosToRedux();
        });
        if (this.props.savePhotosToRedux) this.props.savePhotosToRedux();
    }

    public checkExistingPics(u: any) {
        if (u != null) {
            //console.log("ISPIS: " + u.length);
            if (u.length > 0) {
                return {};
            }
            else return { display: 'none' };
        }
        else return { display: 'none' };
    }

    public checkAddedPics(u: any) {
        if (u != null) {
            //console.log("ISPIS: " + u.length);
            if (u.length > 0) {
                return {};
            }
            else return { display: 'none' };
        }
        else return { display: 'none' };
    }

    render() {

        const state = this.state;
        const cwStyle: React.CSSProperties = this.state.showCameraWindow ? { position: 'fixed', backgroundColor: '#ffffff', top: 0, left: 0, width: '100%', height: '100%' } : { display: 'none' };
        const mainStyle = this.state.showCameraWindow ? { display: 'none' } : {};
        const existingPicsStyle = this.checkExistingPics(state.urls); //(state.urls || state.urls.length > 0) ? {} : { display: 'none' };
        const addedPicsStyle = this.checkAddedPics(state.pictures);
        const galleryStyle = { width: window.innerWidth, height: window.innerHeight, overflowY: 'scroll' } as React.CSSProperties;
        const centeredStyle = { display: 'flex' };
        const videoConstraints = {
            facingMode: { exact: "environment" }
        };
        this.props.photosNumber(state.urls.length + state.pictures.length);

        return (<Box p={1} m={3}>
            <ValidatorForm
                ref="form"
                id="photosForm"
                style={mainStyle}
                onSubmit={() => this.handleSubmit(this.props)}
            >
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs={12} container justify="center" alignItems="center">
                        {((isAdmin() || isSuperAdmin()) || this.props.isReview) &&
                            <Grid item container justify="center" alignItems="center">
                                <h3>Pregled slika vozila</h3>
                            </Grid>}
                        {!this.props.isReview &&
                            <div>
                                <Grid item container justify="center" alignItems="center">
                                    <h3>Unos slika vozila</h3>
                                </Grid>
                                <Grid item container justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography>Potrebno je predati minimalno 6 slika. Slike trebaju prikazivati svaku stranu automobila, broj šasije te sliku upaljene kontrolne table.</Typography>
                                    </Grid>
                                </Grid>
                            </div>}
                    </Grid>
                    {(!this.props.isReview) && <Grid item xs={12} container direction="row" alignItems="center" justify="center" spacing={5} style={{ margin: 0 }}>
                        <Grid xs={12} md={5} container alignContent="center" direction="column" style={{ paddingTop: 20, marginBottom: 20 }}>
                            <Button size="medium" className={"chooseFileButton"} onClick={this.handleCWchange.bind(this)} variant="contained">Otvori kameru</Button>
                        </Grid>
                        <Grid item xs={12} md={7} container direction="column">
                            <ImageUploader
                                withIcon={true}
                                buttonText='Odaberi slike s uređaja'
                                withPreview={false}
                                onChange={this.onDrop.bind(this)}
                                imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                maxFileSize={5242880}
                                label='Maksimalna veličina: 5mb. Dozvoljeni tipovi: .jpg, .png, .gif, .jpeg'
                                fileSizeError='Slika je prevelika'
                                fileTypeError='Neispravan tip odabrane slike'
                            />
                        </Grid>
                    </Grid>}
                    <Grid item xs={12} container style={existingPicsStyle} justify="center" alignItems="center">
                        <Grid item justify="center" alignItems="center">
                            <div className={photoStyles.fileContainerWrapped}>
                                {state.urls.map(
                                    (u: any) => (
                                        <div className={photoStyles.uploadPictureContainer}>
                                            {!this.props.isReview && <div className={photoStyles.deleteImage} onClick={() => { this.deleteUrl(u as never) }}>X</div>}
                                            <img src={u} className={photoStyles.uploadPicture} />
                                        </div>
                                    )
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justify="center" alignItems="center">
                        <Grid item justify="center" alignItems="center">
                            <div className={photoStyles.fileContainerWrapped}>
                                {state.pictures.map(
                                    (p: any) => (
                                        <div className={photoStyles.uploadPictureContainer}>
                                            {!this.props.isReview && <div className={photoStyles.deleteImage} onClick={() => { this.deletePicture(p) }}>X</div>}
                                            <img src={URL.createObjectURL(p.file)} className={photoStyles.uploadPicture} />
                                        </div>
                                    )
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>

            <Grid style={cwStyle} className={styles.Root}>
                <Grid style={galleryStyle} className={styles.Gallery} container justify="center" alignItems="center" spacing={3}>
                    <Grid item container xs={12} md={6} justify="center" alignItems="center">
                        <Webcam item
                            audio={false}
                            videoConstraints={videoConstraints}
                            height={window.innerHeight * 0.8}
                            ref={this.setCameraRef.bind(this)}
                            screenshotFormat="image/jpeg"
                            width={window.innerWidth * 0.8}
                            screenshotQuality={1}
                            imageSmoothing={true}
                        />
                        <br />
                        <Grid item container justify="center" alignItems="center" className={styles.PhotoButtonHolder}>
                            <Grid item xs={6} className={styles.Center}>
                                <Button color="secondary" size="medium" onClick={this.handleCWchange.bind(this)} variant="contained">Zatvori kameru</Button>
                            </Grid>
                            <Grid item xs={6} className={styles.Center} >
                                <Button color="primary" size="medium" onClick={this.capture.bind(this)} variant="contained">Uslikaj</Button>
                                <br />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} container justify="center" alignItems="center">
                        <Grid item justify="center" alignItems="center">
                            <div className={photoStyles.fileContainerWrapped}>
                                {state.pictures.map(
                                    (p: any) => (
                                        <div className={photoStyles.uploadPictureContainer}>
                                            {!this.props.isReview && <div className={photoStyles.deleteImage} onClick={() => { this.deletePicture(p) }}>X</div>}
                                            <img src={(window.URL ? URL : webkitURL).createObjectURL(p.file)} className={photoStyles.uploadPicture} />
                                        </div>
                                    )
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs={12} container justify="center" alignItems="center">
                    {this.props.currentCar && this.props.pictures && this.props.pictures.length > 5 && !this.props.postCar.carId &&
                        <Button className={styles.SaveButton} color="primary" variant="contained" onClick={() => this.handleSubmit(this.props)}>Spremi auto</Button>
                    }
                    {this.props.postCar && ((this.props.postCar.images && this.props.postCar.images.length > 5) || (this.props.pictures && this.props.pictures.length > 5)) && this.props.postCar.carId &&
                        <Button className={styles.SaveButton} color="primary" variant="contained" onClick={() => this.handleSubmit(this.props)}>Spremi promjene</Button>
                    }
                    {this.props.currentCar && this.props.pictures && this.props.pictures.length > 5 && !this.props.postCar.carId &&
                        <Button className={styles.SaveButton} color="primary" variant="outlined" onClick={() => this.handleWarrantyDurationModal(this.props)}>Spremi auto i kreiraj jamstvo</Button>
                    }
                    {!StepperHelper.containsWarranty() && this.props.postCar && this.props.postCar.images && this.props.postCar.images.length > 5 && this.props.postCar.carId &&
                        <Button className={styles.SaveButton} color="primary" variant="outlined" onClick={() => this.handleWarrantyDurationModal(this.props)}>Spremi promjene i kreiraj jamstvo</Button>
                    }
                    {StepperHelper.containsWarranty() && this.props.postCar && !this.props.postCar.newWarranty && ((this.props.postCar.images && this.props.postCar.images.length > 5) || (this.props.pictures && this.props.pictures.length > 5)) && this.props.postCar.carId &&
                        <Button className={styles.SaveButton} color="primary" variant="outlined" onClick={() => this.handleSaveAndContinue(this.props)}>Spremi promjene i uredi jamstvo</Button>
                    }
                    {!StepperHelper.containsWarranty() && this.props.postCar && !this.props.postCar.carSaleId && this.props.postCar.images && this.props.postCar.images.length > 5 && this.props.postCar.carId && !this.props.postCar.carSaleId &&
                        <Button className={styles.SaveButton} color="inherit" variant="outlined" onClick={() => this.showSellCarWithoutWarrantyModal()}>Prodaj auto bez jamstva</Button>
                    }
                    {this.props.postCar && this.props.postCar.newWarranty && this.props.postCar && this.props.postCar.images && this.props.postCar.images.length > 5 && this.props.postCar.carId &&
                        <Button className={styles.SaveButton} color="inherit" variant="outlined" onClick={() => this.handleWarrantyDurationModal(this.props)}>Spremi promjene i kreiraj novo jamstvo</Button>
                    }
                </Grid>
            </Grid>
            {
                this.state.showSellCarWithoutWarrantyModal &&
                <Box>
                    <Modal open={this.state.showSellCarWithoutWarrantyModal} className={stylesModal.Modal} onClose={() => this.setState({ showSellCarWithoutWarrantyModal: false })}>
                        <Paper className={stylesModal.RootModal}>
                            <div>
                                <SellCarWithoutWarrantyModal closeModal={() => this.setState({ showSellCarWithoutWarrantyModal: false })}
                                    submitYesModal={this.handleSellCarWithoutWarrantyModal.bind(this)}> </SellCarWithoutWarrantyModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
        </Box >

        )
    }
}

