
import { injectable, inject } from 'inversify';
import IPackageService from './IPackageService';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import { AxiosInstance } from 'axios';
import IPackageSelection from '../../types/IPackageSelection';
import { warrantyApiURL } from '../../constants/apiRoutes';
import ICarProps from '../../types/ICarProps';

@injectable()
export default class PackageService implements IPackageService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getPackagesByEngineDisplacement(car: ICarProps): Promise<IPackageSelection> {
        const response = await this.apiClient.post<IPackageSelection>(warrantyApiURL + "/packages/", car);
        return response.data;
    }
    
    public async getExtraPackagesByEngineDisplacement(car: ICarProps): Promise<IPackageSelection> {
        const response = await this.apiClient.post<IPackageSelection>(warrantyApiURL + "/extra-packages/", car);
        return response.data;
    }

    public async upgradeWarrantyPackage(packageId: number, extraPackages: number[], warrantyId: number): Promise<{packageId: number, extraPackages: number[], warrantyId: number}> {
        const response = await this.apiClient.post<{packageId: number, extraPackages: number[], warrantyId: number}>(warrantyApiURL + "/UpdatePackagesForWarranty/", {WarrantyId: warrantyId, ExtraPackages: extraPackages, PackageId: packageId});
        return response.data;
    } 
}