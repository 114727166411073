import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import ICarAdminTable from '../../types/ICarAdminTable';
import { GetCarsAdminSoldOnlyCarsAction, DeactivateCarAction, ActivateCarAction, GetCarsAdminSoldOnlyTotalCountAction } from '../../redux/actions/CarAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';
import CarsAdminSoldOnlyCarsTable from '../../components/tables/CarsAdminSoldOnlyCarsTable';

interface IStateToProps {
    cars: ICarAdminTable[],
    totalCount: number
}

interface IDispatchToProps {
    getCarsSoldOnlyCars: (page: number, pageSize: number, filters: string) => void,
    goToCarDetails: (carId: number) => void,
    deactivate: (carId: number) => void,
    activate: (carId: number) => void,
    getTotalCount: () => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const CarAdminSoldOnlyCarsOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getCarsSoldOnlyCars(1, 10, "");
        props.getTotalCount();
    }, []);

    return (props.cars ? <CarsAdminSoldOnlyCarsTable rows={props.cars} goToCarDetails={props.goToCarDetails} deactivate={props.deactivate} activate={props.activate} totalCount={props.totalCount}
                                getCarsSoldOnlyCars={props.getCarsSoldOnlyCars}/> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    cars: state.cars.data.admin,
    totalCount: state.carsAdminSoldOnlyTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getCarsSoldOnlyCars: (page: number, pageSize: number, filters: string) => dispatch<GetCarsAdminSoldOnlyCarsAction>({
        type: ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS,
        payload: { page, pageSize, filters }
    }),
    getTotalCount: () => dispatch<GetCarsAdminSoldOnlyTotalCountAction>({
        type: ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT
    }),
    goToCarDetails: (carId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/car/' + carId + "/step=1" }
    }),
    deactivate: (carId: number) => dispatch<DeactivateCarAction>({
        type: ActionTypes.DEACTIVATE_CAR,
        payload: { carId }
    }),
    activate: (carId: number) => dispatch<ActivateCarAction>({
        type: ActionTypes.ACTIVATE_CAR,
        payload: { carId }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(CarAdminSoldOnlyCarsOverview);