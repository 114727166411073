import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import ISellerProps from "../../types/ISellerProps";
import OtherActiveSellersTable from "./../../components/tables/OtherActiveSellersTable";
import {
    GetOtherActiveSellersTableAction,
    GetOtherActiveSellersTotalCountAction,
} from "./../../redux/actions/SellerAction";
import GoToPageAction from "../../redux/actions/GoToPageAction";
import ReactGA from "react-ga";

interface IStateToProps {
    sellers: ISellerProps[];
    totalCount: number;
}

interface IDispatchToProps {
    getOtherActiveSellersTable: (page: number, pageSize: number, filters: string) => void;
    goToSellerDetails: (sellerId: string) => void;
    getTotalCount: () => void;
}

interface IProps extends IDispatchToProps, IStateToProps { }

const OtherActiveSellersOverview: React.FC<IProps> = (props) => {
    useEffect(() => {
        ReactGA.pageview("Admin | pregled svih trgovaca");
        props.getTotalCount();
        props.getOtherActiveSellersTable(1, 10, "");
    }, []);

    return (
        <OtherActiveSellersTable
            goToSellerDetails={props.goToSellerDetails}
            rows={props.sellers}
            totalCount={props.totalCount}
            getOtherActiveSellers={props.getOtherActiveSellersTable}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    sellers: state.otherActiveSellersTable.data,
    totalCount: state.otherActiveSellersTableTotalCount.data,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getOtherActiveSellersTable: (page: number, pageSize: number, filters: string) =>
        dispatch<GetOtherActiveSellersTableAction>({
            type: ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE,
            payload: { page, pageSize, filters },
        }),
    getTotalCount: () =>
        dispatch<GetOtherActiveSellersTotalCountAction>({
            type: ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT,
        }),
    goToSellerDetails: (sellerId: string) =>
        dispatch<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: "/seller/" + sellerId },
        }),
});

export default connect<
    IStateToProps,
    IDispatchToProps,
    IProps,
    IApplicationState
>(
    mapStateToProps,
    mapDispatchToProps
)(OtherActiveSellersOverview);