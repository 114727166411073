import { Redirect, Route } from "react-router";
import Navbar from '../../components/app/NavBar';
import Footer from '../../components/app/Footer';
import ContentLayout from '../../components/app/ContentLayout';
import Background from '../../components/app/Background';
import React from 'react';
import { isAuthenticated } from './../../utils/AuthFunctions';
import Loading from './../../containers/utilities/Loading';
import ActionNotificationContainer from '../../containers/utilities/ActionNotificationContainer';

const PrivateRoute = ({
    component: Component,
    ...rest
}: any) => (
        <Background>
            <Loading />
            <ActionNotificationContainer />
            <Navbar>
                <ContentLayout>
                    <Route {...rest}
                        render={(props: any) =>
                            isAuthenticated()
                                ? <Component {...props} />
                                : <Redirect to="/login" />
                        } />
                </ContentLayout>
            </Navbar>
            <Footer />
        </Background>
    );


export default PrivateRoute;