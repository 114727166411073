import React from 'react';
import styles from './legal.module.scss';
import PublicFormLayout from '../authentication/PublicFormLayout';
import { BackButton } from './../buttons/BackButton';


const TOS = () => {
    return (
        <PublicFormLayout className={styles.Layout} md={true} lg={true}>
            <div className={styles.Root}>
                <p> <p style={{fontSize: 22}}><strong>Opći uvjeti poslovanja</strong></p>
                    <p>Poštovani,</p>
                    <p>s općim uvjetima Vas želimo preciznije upoznati s našim proizvodom, njegovim pravilima te pozvati vas da zajedno u dobroj vjeri radimo na poboljšanju na&scaron;e usluge.</p>
                    <p>Na početku vas želimo izvijestiti da su Vaše obaveze u slučaju kvara na vozilu:</p>
                    <p className={styles.Paragraph}>a) odmah zaustavite vozilo ukoliko primijetite bilo kakvu nepravilnost u radu ili vas na to upozori signalizacija na kontrolnoj tabli te postupiti prema
                        uputama TOČKA NA J-G1</p>
                    <p className={styles.Paragraph}>b) čuvate dokaze (račune) o uredno izvr&scaron;enim servisnim intervencijama na vozili(redovnim i izvanrednim)</p>
                    <p className={styles.Paragraph}>c) nikako i nikada se ne upuštati u samostalno otklanjanje kvara na vozilu prije nego kontaktirate i konzultirate HAK ili TOČKA NA J-G1</p>
                    <p>&nbsp;</p>
                    <p><b>Članak 1.</b></p>
                    <p>Nosioc projekta G1 za koordinacije produženog auto jamstva je firma TOČKA NA J d.o.o, Unska cesta 2c iz Zagreba.</p>
                    <p>Ovaj proizvod se odnosi na vozila ukupne mase 3.5 tone za koja nije proteklo više od 10 godina od dana prve registracije ili nisu prešla više od 230 000 km u trenutku aktivacije
                        produženog auto jamstva, a pravo na produženo jamstvo vrijedi najviše do prijeđenih 250 000 km.</p>
                    <p>Pravo na ovaj proizvod prestaje ako se vozilo uništi ili proda osobi koja s njim obavlja djelatnosti za koje produženo auto jamstvo nije predviđeno(rent a car, taxi, itd.)</p>
                    <p><b>Članak 2.</b></p>
                    <p>Prodavatelj vozila je povjerio koordinaciju upravljanja produženom jamstvom tvrtki TOČKA NA J-G1 koji nije stranka u eventualnom parničnom postupku oko prodanog vozila.</p>
                    <p>Prodavatelj jamči da vozilo za koje je ugovoreno produženo auto jamstvo ima istinite prijeđene kilometre te nema skrivenu tehničku grešku koja je postojala prije ugovaranja produženog
                        auto jamstva.</p>
                    <p><b>Članak 3.</b></p>
                    <p>Aktivacija jamstva mora se realizirati u isto vrijeme kada je prodano vozilo. TOČKA NA J-G1 može u svakom trenutku tražiti na uvid dokumentaciju povezanu s izdanim produženim auto
                        jamstvom. Proizvod ima ograničeno trajanje na rok od 12 mjeseci s mogućnošću obnove, ali uz prethodnu najavu i odobrenje G1.</p>
                    <p><b>Članak 4.</b></p>
                    <p>KVAR je nepravilno djelovanje dijela vozila zbog greške ili nedostatka.</p>
                    <p>POPRAVAK je putem servisa ponovno uspostavljanje upotrebljivosti vozila.</p>
                    <p><b>Članak 5.</b></p>
                    <p>U slučaju kvara tvrtka TOČKA NA J-G1 ima pravo:</p>
                    <p className={styles.Paragraph}>a) zatražiti poseban pregled vozila</p>
                    <p className={styles.Paragraph}>b) nastupiti kao posrednik u nabavi auto dijelova potrebnih za otklanjanje kvara</p>
                    <p className={styles.Paragraph}>c) zahtijevati dodatan pregled dijelova koje treba zamijeniti</p>
                    <p className={styles.Paragraph}>d) uporabiti dijelove koji prethodno mogu biti korišteni, pregledani, popravljeni ili obnovljeni</p>
                    <p>Upotreba novih i originalnih tj. zamjenskih dijelova može uzrokovati podizanje kvalitete i vrijednosti vozila. U navedenom slučaju, iznos kojeg plaća vlasnik vozila jednak je
                        postotku vrijednosti vozila koja se poveća za trošak zamjene. Za računanje promjene vrijednosti vozila u trenutku zahtjeva za popravkom upotrebljava se sustav Eurotax.</p>
                    <p>Napravljene izmjene nemaju utjecaj na produženo trajanje proizvoda izvan ugovorenog roka.</p>
                    <p>Troškovi rada priznaju se sukladno normama i smjernicama propisane od strane proizvođača vozila.</p>
                    <p>&nbsp;</p>
                    <p><b>Članak 6.</b></p>
                    <p>Dijelovi s jamstvom kod OSNOVNOG JAMSTVA PRODUŽENOG AUTO JAMSTVA</p>
                    <p className={styles.Paragraph}>a) MOTOR: glava motora, brtvilo oglave, garnitura brtvila(u slučaju popravka glave motora), cilindarska košuljica, klipovi, klipnjače, radilica,
                        bregasto vratilo i odstojnici, karike-klipni prstenovi, ležajevi klipnjače(leteći ležajevi), ležajevi vratila motora, ispušni i usisni ventili(osim kod blokiranja motora zbog
                        pregrijavanja ili zagrijavanja) pret komora, klackalice ventila, pumpa za ulje, donji dio kućišta motora. Zupčasti remen(uz obavezu zamjene po tvorničkim servisnim intervalima
                        ili najkasnije svakih 5 godina ili prijeđenih 100 000 km te uz obavezu čuvanja dokaza-računa)</p>
                    <p className={styles.Paragraph}>b) RUČNI MJENJAČ: pogonski i prijenosni zupčanici, kućište, svi podmazani rotacijski dijelovi, sinkroni) (uz obavezu zamjene ulja po tvorničkim
                        servisnim intervalima ili najkasnije svakih 5 godina ili prijeđenih 100 000 km te uz obavezu čuvanja dokaza-računa)</p>
                    <p className={styles.Paragraph}>c) SUSTAV KOČENJA: sustav ulje za kočnice, servo sustav, vakuumska pumpa, kočiona čeljust (mehanička)</p>
                    <p className={styles.Paragraph}>d) OSNOVNO PLUS: 1 brizgaljka goriva(dizna), hladnjak rashladne tekućine,ventilator rashladne tekućine, termostat, pumpa rashladne tekućine,
                        elektropokretač, alternator, pogonsko vratilo, ležaj kotača, diferencijal, senzor broja okretaja ABS, glavni kočioni cilindar, pomoćni kočioni cilindar, prsten ABS</p>
                    <p>&nbsp;</p>
                    <p><b>Članak 7.</b></p>
                    <p>Uz Osnovno pravo na produženo auto jamstvo, moguće je izabrati jedan ili više DODATAKA uz nadoplatu koji čine dodatno jamstveno pokriće za određene dijelove.</p>
                    <p>Postoje 3 DODATKA uz nadoplatu:</p>
                    <p className={styles.Paragraph}>DODATAK 1: turbo punjač, pumpa goriva električna, common rail mehanička pumpa goriva, EGR ventil, hladnjak EGR ventila, brizgaljke goriva-sve,
                        kompresor klima uređaja, kondenzator klima uređaja, ventil regulacije pritiska goriva</p>
                    <p className={styles.Paragraph}>DODATAK 2: brizgaljka Ad Blue, pumpa Adblue, spremnik AdBlue, NoX sensor, lambda sonda, katalizator, DPF filter, senzor diferencijalnog tlaka DPF</p>
                    <p className={styles.Paragraph}>DODATAK 3: AUTOMATSKI MJENJAČ - pretvarač zaokretnog momenta(wandler), garnitura ventila, epiciklički pokretač, kučište, pumpa za ulje,
                        (osim kočnica i lamela/kvačilo) (uz obaveznu izmjenu ulja i filtera ulja po tvorničkom servisnim intervalima ili najkasnije svakih 5 godina ili prijeđenih 100 000 kn te uz
                        obavezno čuvanje dokaza-računa)</p>
                    <p>Navedeni dodaci uz nadoplatu odnose se na vozila ukupne mase 3.5 tone za koje nije proteklo više od 10 godina od dana prve registracije ili nisu prešli više od 180 000 km u
                        trenutku aktivacije prava na produženo auto jamstvo, a pravo na produženo auto jamstvo vrijedi najviše do prijeđenih 200 000 km.</p>
                    <p>&nbsp;</p>
                    <p><b>Članak 8.</b></p>
                    <p>Svi dijelovi koji nisu uključeni u članku 6.te sav potro&scaron;ni materijal kao npr. gumena brtvila, kvačilo, maziva, tekućine, filteri, disk pločice i ostalo...... izuzeti su
                        iz jamstvenog pokrića,</p>
                    <p><b>Članak 9.</b></p>
                    <p>Najveći dopušteni iznos po jednom predmetu popravka(kvaru) iznosi 15000 kn s PDV-om, iskoristivo dva puta u vremenu trajanja produženog jamstva.</p>
                    <p>Tvrtka G1 odobriti će popravak vozila nakon što postigne dogovor sa servisom te uz pismenu ili usmenu suglasnost vlasnika vozila. U dopušteni iznos po predmetu popravka ne ulaze
                        troškovi prehrane, noćenja i prijevoza. Naknada štete zbog mirovanja vozila u vrijeme otklanjanja kvara ne ulazi u dopušteni iznos popravka.</p>
                    <p><b>Članak 10.</b></p>
                    <p>Ako kvarovi nisu povezani, njihova ukupna maksimalna vrijednost pridonosi vrijednosti zbroja kvarova. Ukoliko se za vrijeme popravka vozila dogode novi štetni događaji koji nisu
                        povezani s predmetnim popravkom, G1 ne snosi nikakvu odgovornost. Ukoliko se dogodi štetni događaj koji je u pokriću jamstva, a dogodio se zbog štetnog događaja koji nije u pokriću jamstva, popravak istog neće biti priznat u jamstvo.</p>
                    <p><b>Članak 11.</b></p>
                    <p>Kako bi jamstvo bilo iskoristivo i važeće, za vrijeme trajanja prava na produženo auto jamstvo G1 kluba, OBAVEZNA je izmjena ulja(tip ulja koji propisuje proizvođač) i filtera ulja
                        jednom godišnje u vremenu trajanja prava na produžena auto jamstvo ili svakih max. 15000 prevoženih kilometara od posljednje izmjene ulja i filtera ulja. Uz to, vozilo mora OBAVEZNO
                        ispunjavati i sve izvaredne servisne intervale (sve izvaredne izmjene ulja u mjenjaču, remenja ,lanaca i ostalog...) prema preporukama proizvođača za određenu marku vozila
                        kako je navedeno u servisnoj knjižici. Održavanje mora biti obavljeno kod ovlaštenog servisera za marku vozila za koju je izdano produženo jamstvo ili u TOČKA NA J-G1 servisnoj mreži.
                        Za svaku izvršenu aktivnost održavanja vozila potrebno je imati dokaz u vidu plaćenog računa, izdanog od strane servisera, kojeg prema potrebi TOČKA NA J-G1 može zatražiti na uvid.
                        Na računu mora biti naznačena registarska oznaka vozila za koje se odnosi, datum izvršenja usluge, broj šasije te opis izvršenih radova i utrošenog materijala.
                        Ukoliko prilikom preuzimanja vozila u auto salonu, ne postoji nikakav dokaz o servisnoj povijesti vozila, obavezno je potrebno napraviti izmjenu ulja i filtera ulja te o
                        tome imati sačuvani dokaz u vidu važečeg izdanog računa od strane ovlaštenog servisera ili G1 servisne mreže. Ukoliko vozilo u jamstvenom pokriću ima ugrađeni plinski pogon,
                        obavezno je najaviti u TOČKA NA J-G1.</p>
                    <p><b>Članak 12.</b></p>
                    <p>Usluga popravka neće biti ostvarena za kvarove koji nastupe zbog:</p>
                    <p className={styles.Paragraph}> a) nepravilne i nemarne upotrebe vozila,</p>
                    <p className={styles.Paragraph}> b) nesreće na cesti i/ili namjernih događaja,</p>
                    <p className={styles.Paragraph}> c) prethodno nestručno izvedenih popravaka,</p>
                    <p className={styles.Paragraph}> d) gubitka različitih tekućina,</p>
                    <p className={styles.Paragraph}> e) krivo utočenog goriva,</p>
                    <p className={styles.Paragraph}> f) nepoštivanja odredbi o redovnom i izvanrednom održavanju iz članka 11. ovih općih uvjeta,</p>
                    <p className={styles.Paragraph}> g) elemenata koje jamstvo ne pokriva,</p>
                    <p className={styles.Paragraph}> h) vanjskih elemenata, npr. strujni udari, udari groma, oštećenja uslijed pokušaja krađe,</p>
                    <p className={styles.Paragraph}> i) ne zaustavljanja vozila signaliziranog na kontrolnoj tabli,</p>
                    <p className={styles.Paragraph}> j) udaraca, izlijetanja s kolnika, požara, sudara ili fizičkih oštećenja,</p>
                    <p className={styles.Paragraph}> k) prije utvrđenih grešaka na vozilu,</p>
                    <p className={styles.Paragraph}> l) neovlaštenog modificiranja software-a vozila.</p>
                    <p><b>Članak 13.</b></p>
                    <p>Produženo jamstvo ne osigurava usluge u slučaju kada:</p>
                    <p className={styles.Paragraph}> a) nema mogućnosti dobave dijelova za popravak vozila,</p>
                    <p className={styles.Paragraph}> b) se vozilo ne nalazi na predviđenom mjestu za preuzimanje,</p>
                    <p className={styles.Paragraph}> c) vozilo nije u tehničkom stanju prema propisima proizvođača,</p>
                    <p className={styles.Paragraph}> d) se vozilo koristi za taxi, rent a car, auto škole, za natjecanje ili u ekstremnim uvjetima,</p>
                    <p className={styles.Paragraph}> e) se utvrde netočne izjave o stanju vozila prilikom ugovaranja produženog jamstva,</p>
                    <p className={styles.Paragraph}> f) se ne uvaže procedure propisane za uslugu produženog jamstva od strane TOČKA NA J-G1,</p>
                    <p className={styles.Paragraph}> g) korisnik ne dostavi potrebnu dokumentaciju na zahtjev TOČKA NA J-G1 najkasnije u roku 3 radna dana,</p>
                    <p className={styles.Paragraph}> h) korisnik neovlašteno modificira software motora.</p>
                    <p><b>Članak 14.</b></p>
                    <p>Korisnik produženog jamstva mora najkasnije u roku od 2 dana od nastanka kvara na vozilu obavijestiti firmu TOČKA NA J-G1 te će vlasnika vozila uputiti u postupak otklanjanja kvara na vozilu.</p>
                    <p>Važno je zapamtiti da TOČKA NA J-G1 neće snositi teret popravka vozila ako prethodno nije odobrilo izvršenje popravka vozila prema predloženom radnom nalogu servisera, niti je moguće ostvariti povrat troškova nastalih bez prethodnog odobrenja TOČKA NA J-G1.</p>
                    <p><b>Članak 15.</b></p>
                    <p>Uslugu pomoći na cesti korisnik produženog jamstva ima pravo iskoristiti jednom u vremenu trajanja produženog jamstva. Ima pravo samo ukoliko je vozilo u nepokretnom stanju, osim u slučaju kada je u pitanju kvar na akumulatoru i to iskoristiti u vremenu trajanja produženog jamstva, na način propisan općim uvjetima u slučaju kada je vozilo ostalo na cesti, nepokretno u kvaru, potrebno je nazvati operativni centar HAK-a na broj +385 14693786 te ih obavijestiti o mjestu na kojem se nalazi vozilo. Zbog sigurnosne provjere, prilikom poziva, potrebno je znati broj koji vam je dodijelio TOČKA NA J-G1, a koji se nalazi na početku ovih općih uvjeta te broj šasije vozila koji je tražena intervencija. Nakon provjere, operativni centar će organizirati prijevoz/šlep do najbliže automehaničarske radionice iz servisne mreže TOČKA NA J-G1. Usluga pomoći i otklanjanja kvarova predviđena je u slučaju kada je moguća neposredna intervencija na vozilu u kvaru. Usluga ne uključuje dodatni prijevoz nakon intervencije.</p>
                    <p><b>Članak 16.</b></p>
                    <p>Ukoliko korisnik produženog jamstva za vrijeme trajanja produženog jamstva odluči prodati vozilo za koje je izdano jamstvo, produženo jamstvo ostaje vrijediti na vozilu do isteka, uz uvjete da se novi vlasnik vozila i budući korisnik produženog jamstva mora najkasnije u roku od 5 dana od preuzimanja vozila javiti u firmu TOČKA NA J-G1, u suprotnom, nakon isteka navedenog roka, produženo jamstvo je nevažeće.</p>
                    <p><b>Članak 17.</b></p>
                    <p>Reklamacije za eventualno nezadovoljstvo s obavljenom uslugom mogu se proslijediti putem e-maila info@G1klub.hr</p>
                    <p><b>Članak 18.</b></p>
                    <p>U slučaju spora nadležan je sud u mjestu sjedištu firme TOČKA NA J-G1.</p>
                    <p><b>Članak 19.</b></p>
                    <p>Moguća deklarativna presuda o ništavnosti pojedinog članka ovog ugovora ne utječe na ugovor u potpunosti koji ostaje važeći u ostalim člancima.</p>
                    <p><b>Članak 20.</b></p>
                    <p>Svojim potpisom ove potvrde potvrđujete da ste primili navedeni dokument. Informacija o obradi podataka također vam je dostupna na web stranici www.G1klub.com</p>

                
                    <BackButton />
                </p>
            </div>
        </PublicFormLayout>
    )
}

export default TOS;