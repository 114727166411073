import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import img from '../../resources/img/warranty.jpg';
import classes from './thisMonthTotalWarrantiesSold.module.scss';

interface IProps {
    totalArchiveWarranties: number,
}


export const TotalArchiveWarranties: React.FC<IProps> = props => {
  
  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            Upisana istekla jamstva
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          u sustavu  
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <IconButton aria-label="broj jamstva">
          {props.totalArchiveWarranties}
          </IconButton>
        </div>
      </div>
      <CardMedia
        className={classes.cover}
        image={img}
        title="Broj prodanih jamstva u mjesecu"
      />
    </Card>
  );
}