import React, { useEffect } from 'react';
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

interface IProps {
    setAutomaticGearBox: (automatic: boolean) => void,
    automatic: boolean | undefined,
    disabled: boolean
}

export const AutomaticGearBoxCheckButton: React.FC<IProps> = (props: IProps) => {
    const [isAutomatic, setIsAutomatic] = React.useState(props.automatic);

    useEffect(() => setIsAutomatic(props.automatic), [props]);

    function handleChange(event: React.ChangeEvent<unknown>) {
        const automatic = (event.target as HTMLInputElement).checked;
        setIsAutomatic(automatic);
        props.setAutomaticGearBox(automatic);
    }

    return (
        <FormGroup row>
            <FormControlLabel label="Automatski mjenjač"
                labelPlacement="end"
                control={<Checkbox
                    disabled={props.disabled}
                    checked={isAutomatic}
                    onChange={handleChange}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />}
            />
        </FormGroup>
    );
}
