import React from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table'
import { Trans, useTranslation } from 'react-i18next';
import { IStatisticsWarranties } from '../../types/IStatisticsWarranties';
import styles from './statisticsWarrantiesTable.module.scss';

interface IProps {
    rows: IStatisticsWarranties[],
}

const StatisticsWarrantiesTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    const mapPropsToTable = (): IStatisticsWarranties[] => {
        let newRows = new Array<IStatisticsWarranties>();

        props.rows.forEach((row: IStatisticsWarranties) => {
            let newRow: IStatisticsWarranties = {
                ...row,
            }
            newRows.push(newRow);
        });
        return newRows;
    }

    return <Paper className={styles.Root}>
        <MaterialTable
            options={{
                paging: true,
                initialPage: 1,
                paginationType: 'normal',
            }}

            localization={{
                body: {
                    emptyDataSourceMessage: "Nema podataka za prikaz."
                },
                pagination: {
                    previousTooltip: 'Prethodna',
                    nextTooltip: 'Sljedeća',
                    firstTooltip: 'Početna',
                    lastTooltip: 'Posljednja'
                }
            }}
            title="Pregled statistike svih jamstava"
            columns={[
                { title: 'Godina', field: 'year' },
                { title: 'Mjesec', field: 'month' },
                { title: 'Broj prodanih jamstava', field: 'totalWarrantiesSold' },
            ]}
            data={[...mapPropsToTable()]}
        />
    </Paper>
}

export default StatisticsWarrantiesTable;
