import React, { useState, useEffect, useRef } from "react";
import { Modal, Paper, Box, Button, Input, Grid } from "@material-ui/core";
import styles from "./adminArchiveWarrantyTable.module.scss";
import MaterialTable from "material-table";
import Chip from "@material-ui/core/Chip";
import IAdminAttrs from "../../types/IAdminAttrs";
import IBuyerProps from "../../types/IBuyerProps";
import IMissingCarDetailsProps from "../../types/IMissingCarDetailsProps";
import WarrantyStatusChecker from "../../utils/warrantyStatusChecker";
import { CarStatus } from "../../domain/enums/CarStatus";
import { useTranslation } from "react-i18next";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import ListItem from "@material-ui/core/ListItem";
import { ListItemIcon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { warrantyPdf } from "../../constants/apiRoutes";
import IFilterModel from "../../types/IFilterModel";

interface IProps {
  rows: { data: IAdminAttrs[]; loading: boolean };
  getAdminArchiveTableData: (
    page: number,
    pageSize: number,
    filters: string
  ) => void;
  viewWarranty: (rowData: IAdminAttrs) => void;
  goToEditWarranty: (rowData: IAdminAttrs) => void;
  goToDamagePage: (carId: number) => void;
  editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void;
  getBuyerData: (carId: number, warrantyId: number) => void;
  buyer: IBuyerProps;
  getBuyerByOIB: (oib: string) => void;
  setCarDetailsMissing: (isCarDetailsMissing: boolean) => void;
  isCarDetailsMissing: boolean;
  totalCount: number;
}

const AdminArchiveWarrantyTable: React.FC<IProps> = (props: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterArray, setFilterArray] = useState<IFilterModel[]>([
    { key: "sellerName", value: "" },
    { key: "warranty.code", value: "" },
    { key: "warranty.car.chassisNumber", value: "" },
  ]);
  const [filters, setFilters] = useState<string>("");
  const [columnFocus, setColumnFocus] = useState<string>("");
  const tableRef = useRef();
  const { t } = useTranslation();
  var x = 1;

  const [sellingCarId, changeSellingCarId] = useState();
  const [carIsSold, setCarIsSold] = useState(false);

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const a = tableRef.current as any;
      a.onQueryChange();
    }
  }, [props.rows.data]);

  const mapPropsToTable = (): IAdminAttrs[] => {
    let newRows = new Array<IAdminAttrs>();
    props.rows.data.forEach((row: IAdminAttrs) => {
      const viewOrUpdate = WarrantyStatusChecker.AdminViewOrUpdate(
        row.warranty.warrantyStatus
      );

      let newRow: IAdminAttrs = {
        ...row,
        warranty: {
          ...row.warranty,
          warrantyStatus: t(row.warranty.warrantyStatus),
          carStatus: t(row.warranty.carStatus),
        },
        damage_action: "Šteta",
        warranty_action: viewOrUpdate ? "Pregledaj" : "Uredi",
      };
      newRows.push(newRow);
    });
    return newRows;
  };

  const editWarranty = (rowData: IAdminAttrs): void => {
    //props.goToEditWarranty(rowData);

    window.open(
      "/car/" +
        rowData.warranty.carId +
        "/warranty/" +
        rowData.warranty.warrantyId +
        "/step=1"
    );
  };

  function actionButton(rowData: IAdminAttrs) {
    const editButton = (
      <Button
        color="default"
        variant="contained"
        size="small"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          editWarranty(rowData)
        }
      >
        {" "}
        Uredi{" "}
      </Button>
    );
    return editButton;
  }

  function chip(rowData: IAdminAttrs) {
    const propsRow = findByWarrantyId(rowData.warranty.warrantyId);
    const className = WarrantyStatusChecker.ButtonClassName(
      propsRow.warranty.warrantyStatus
    );
    return (
      <Chip className={className} label={rowData.warranty.warrantyStatus} />
    );
  }
  const findByWarrantyId = (id: number): IAdminAttrs => {
    return props.rows.data.filter(
      (row: IAdminAttrs) => row.warranty.warrantyId === id
    )[0];
  };

  const carStatus = (rowData: IAdminAttrs) => {
    const isSold =
      rowData.warranty.carStatus === CarStatus.Sold ||
      rowData.warranty.carStatus === "Prodano";
    return (
      <div>
        {rowData.warranty.carStatus}
        {isSold && (
          <ListItem
            button
            component={Link}
            target="_blank"
            to={
              "/" + warrantyPdf + "?warrantyId=" + rowData.warranty.warrantyId
            }
            style={{ display: "contents" }}
          >
            <ListItemIcon>
              <PictureAsPdf />
            </ListItemIcon>
          </ListItem>
        )}
      </div>
    );
  };

  const damageWarrantyButton = (rowData: IAdminAttrs) => (
    <Link
      to={"/warranty_damage/" + rowData.warranty.warrantyId}
      style={{
        display: "-webkit-inline-box",
        color: "unset",
        textDecoration: "none",
      }}
    >
      <Button variant="contained" size="small">
        Pregled
      </Button>
    </Link>
  );

  const createFilters = () => {
    let newFilters = "";

    const sellerName = filterArray.find(
      (x: IFilterModel): boolean => x.key === "sellerName"
    )!.value;
    const warrantyId = filterArray.find(
      (x: IFilterModel): boolean => x.key === "warranty.code"
    )!.value;
    const chassisNumber = filterArray.find(
      (x: IFilterModel): boolean => x.key === "warranty.car.chassisNumber"
    )!.value;

    if (sellerName !== "") {
      newFilters = newFilters + "Seller.LegalEntityName@=*" + sellerName;
    }

    if (warrantyId !== "") {
      newFilters = newFilters + ",WarrantyId==" + warrantyId;
    }

    if (chassisNumber !== "") {
      newFilters = newFilters + ",Car.ChassisNumber@=*" + chassisNumber;
    }

    if (newFilters.substring(0, 1) === ",")
      newFilters = newFilters.substring(1);

    setFilters(newFilters);

    // if (
    //   (sellerName !== "" && sellerName.length > 2) ||
    //   (chassisNumber !== "" && chassisNumber.length === 17) ||
    //   (warrantyId !== "" && warrantyId.length === 5) ||
    //   (sellerName === "" && chassisNumber === "" && warrantyId === "")
    // )
    //   props.getAdminArchiveTableData(page + 1, pageSize, newFilters);
  };

  const setFilterValue = (field: string, value: string) => {
    filterArray.find((x: IFilterModel): boolean => x.key === field)!.value =
      value;

    createFilters();
  };

  return (
    <Paper className={styles.Root}>
      <Grid
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() =>
            props.getAdminArchiveTableData(page + 1, pageSize, filters)
          }
          color="inherit"
          variant="contained"
        >
          TRAŽI
        </Button>
      </Grid>
      <MaterialTable
        tableRef={tableRef}
        isLoading={props.rows.loading}
        localization={{
          body: {
            emptyDataSourceMessage: "Nema podataka za prikaz.",
          },
          pagination: {
            previousTooltip: "Prethodna",
            nextTooltip: "Sljedeća",
            firstTooltip: "Početna",
            lastTooltip: "Posljednja",
          },
        }}
        title="Pregled svih isteklih jamstva"
        columns={[
          { title: "Trgovac", field: "sellerName", filtering: true },
          { title: "Br. jamstva", field: "warranty.code", filtering: true },
          {
            title: "Šasija",
            field: "warranty.car.chassisNumber",
            filtering: true,
          },
          { title: "Marka", field: "warranty.brand", filtering: false },
          { title: "Model", field: "warranty.model", filtering: false },
          {
            title: "Kilometri",
            field: "warranty.car.distance",
            filtering: false,
          },
          {
            title: "Status",
            filtering: false,
            render: (rowData: IAdminAttrs) => carStatus(rowData),
          },
          {
            title: "Datum prodaje",
            field: "warranty.validFrom",
            filtering: false,
          },
          {
            title: "Status jamstva",
            field: "warranty.warrantyStatus",
            filtering: false,
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length && chip(rowData),
          },
          {
            title: "Akcija jamstva",
            field: "warranty_action",
            filtering: false,
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length && actionButton(rowData),
          },
          {
            title: "Pregled šteta",
            filtering: false,
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length && damageWarrantyButton(rowData),
          },
        ]}
        options={{
          filtering: true,
          search: false,
          paging: true,
          initialPage: page,
          paginationType: "normal",
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        components={{
          FilterRow: (rowProps) => {
            const { columns } = rowProps;

            return (
              <>
                <tr>
                  {columns
                    .filter((c: any): boolean => c.filtering === true)
                    .map((col: any) => {
                      if (col.field) {
                        return (
                          <td>
                            <Input
                              autoFocus={columnFocus === col.field}
                              key={col.field}
                              id={col.field}
                              value={
                                filterArray.find(
                                  (x: IFilterModel): boolean =>
                                    x.key === col.field
                                )!.value
                              }
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                              onChange={(e) => {
                                setFilterValue(col.field, e.target.value);
                                setColumnFocus(col.field);
                              }}
                            />
                          </td>
                        );
                      }
                    })}
                </tr>
              </>
            );
          },
        }}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            resolve({
              data: mapPropsToTable(),
              page: page,
              totalCount: props.totalCount,
            });
          })
        }
        onChangePage={(newPage: number) => {
          setPage(newPage);
          props.getAdminArchiveTableData(newPage + 1, pageSize, filters);
        }}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
          props.getAdminArchiveTableData(page + 1, newPageSize, filters);
        }}
      />
    </Paper>
  );
};

export default AdminArchiveWarrantyTable;
