import React, { useEffect } from 'react';
import SellerRegistrationForm from '../../components/forms/sellerRegistrationForm/SellerRegistrationForm'
import ISellerProps from "./../../types/ISellerProps";
import ILegalEntity from "./../../types/ILegalEntitiy";
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { GetLegalEntities } from '../../redux/actions/SellerAction';
import { GetSellerByIdAction, ClearSellerAction } from './../../redux/actions/SellerAction';
import { connect } from 'react-redux';
import { URLHelper } from './../../utils/URLHelper';
import ReactGA from 'react-ga';

interface IProps extends IDispatchToProps, IStateToProps { }

interface IDispatchToProps {
    getLegalEntities: () => void,
    saveSeller: (seller: ISellerProps) => void,
    activateSeller: (seller: ISellerProps) => void,
    deleteSeller: (seller: ISellerProps) => void,
    getSeller: (sellerId: string) => void,
    clearSeller: () => void
}

interface IStateToProps {
    legalEntities: ILegalEntity[],
    seller: ISellerProps
}

const SellerRegistration: React.FC<IProps> = (props: IProps) => {

    useEffect(() => {
        ReactGA.pageview("Admin | registracija trgovca");
        props.clearSeller();
        props.getLegalEntities();
        if(URLHelper.getSellerId() !== 'create_seller') 
            props.getSeller(URLHelper.getSellerId()!);
        
    }, []);

    const handleSubmit = (seller: ISellerProps) => {
        props.saveSeller(seller);
    }

    const handleDelete = (seller: ISellerProps) => {
        props.deleteSeller(seller);
    }

    const handleActivate= (seller: ISellerProps) => {
        props.activateSeller(seller);
    }

    return (<SellerRegistrationForm submit={handleSubmit}
                                    delete={handleDelete}
                                    activate={handleActivate}
                                    seller={props.seller}
                                    {...props.seller}
                                    legalEntities={props.legalEntities}
                                    isEditForm={URLHelper.getSellerId() !== 'create_seller'} />)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    legalEntities: state.legalEntities.entities,
    seller: state.seller.seller,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getLegalEntities: () => dispatch<GetLegalEntities>({
        type: ActionTypes.GET_LEGAL_ENTITIES
    }),
    saveSeller: (seller: ISellerProps) => dispatch({
        type: ActionTypes.SAVE_SELLER,
        payload: { seller }
    }),
    deleteSeller: (seller: ISellerProps) => dispatch({
        type: ActionTypes.DELETE_SELLER,
        payload: { seller }
    }),
    activateSeller: (seller: ISellerProps) => dispatch({
        type: ActionTypes.ACTIVATE_SELLER,
        payload: { seller }
    }),
    getSeller: (sellerId: string) => dispatch<GetSellerByIdAction>({
        type: ActionTypes.GET_SELLER,
        payload: sellerId
    }),
    clearSeller: () => dispatch<ClearSellerAction>({
        type: ActionTypes.CLEAR_SELLER
    })
})


export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(SellerRegistration);