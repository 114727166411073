import WarrantyStatus from "../domain/enums/WarrantyStatus";

export class StepperHelper {
    static getStep(): number {
        const url = window.location.href;
        const step = url.split('step=')[1];
        return +step;
    }
    static increasedStepUrl(): string {
        return `step=${this.getStep() + 1}`;
    }
    static decreasedStepUrl(): string {
        return `step=${this.getStep() - 1}`;
    }
    static stepUrl(step: number): string {
        return `step=${step}`
    }
    static getWarrantyIdFromUrl(): number | undefined {
        const url = window.location.href;
        const parts = url.split('warranty/')[1].split('/');
        if (parts.length > 1) return +parts[0];
        return undefined;
    }
    static getCarIdFromUrl(): number | undefined {
        const url = window.location.href;
        const parts = url.split('car/')[1].split('/');
        if (parts.length > 1) return +parts[0];
        return undefined;
    }
    static getCarIdFromExpandedUrl(): number | undefined {
        const url = window.location.href;
        const parts = url.split('car/')[1].split('/');
        if (parts.length > 1) return +parts[0];
        return undefined;
    }
    static getWarrantyIdFromExpandedUrl(): number | undefined {
        const url = window.location.href;
        const parts = url.split('warranty/')[1].split('/');
        if (parts.length > 1) return +parts[0];
        return undefined;
    }
    static isWarrantyForReview(status: string) {
        return (status === WarrantyStatus.Submitted
            || status === WarrantyStatus.Approved
            || status === WarrantyStatus.Active)
            && !this.isUpgradeMode()
    }
    static isUpgradeMode(): boolean {
        return location.href.includes('upgrade');
    }
    static containsWarranty(): boolean {
        const url = window.location.href;

        return url.includes("warranty");
    }

    static getUpgradeUrl(step: number) {
        const stepUrl = location.href.split('upgrade/')[1].split('step=')[0];
        return stepUrl + "step=" + step;
    }

    static isInitialUpgrade() {
        return this.isUpgradeMode() && StepperHelper.getStep() === 3;
    }
}