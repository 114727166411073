import React from 'react';
import { IPhotoFile } from '../../types/IPhotoFile';
import ImageGallery from 'react-image-gallery';
import styles from './photoList.module.scss'
import { Grid } from '@material-ui/core';

interface IProps {
    photos?: string[], // photos are saved in db
    photoFiles?: IPhotoFile[] // photos are saved in redux store
}

const PhotoList: React.FC<IProps> = (props: IProps) => {
    let photos: { original: string, thumbnail: string }[] = []
    if (props.photos) props.photos.forEach((url: string) => photos.push({ original: url, thumbnail: url, }))
    if (props.photoFiles) {
        props.photoFiles.forEach((file: IPhotoFile) => {
            const tempUrl = (window.URL ? URL : webkitURL).createObjectURL(file.file);
            photos.push({ original: tempUrl, thumbnail: tempUrl });
        })
    }

    return photos.length ?
        <div className={styles.GalleryContainer}>
            <ImageGallery
                showBullets={true}
                showIndex={true}
                showPlayButton={false}
                items={photos} />
        </div>
        : null
}

export default PhotoList;