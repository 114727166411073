import jwt_decode from 'jwt-decode';
import { Roles } from '../domain/enums/Roles';
import { roleProp } from './../constants/RoleConstants';

export function isAdmin() {
    return getUserRole() === Roles.Admin;
}

export function isSuperAdmin() {
    return getUserRole() === Roles.SuperAdmin;
}

export function isBuyer() {
    return getUserRole() === Roles.Buyer;
}

export function isSeller() {
    return getUserRole() === Roles.Seller;
}

export function isMarketing() {
    return getUserRole() === Roles.Marketing;
}

export function getToken(): string | null {
    let user: any = localStorage.getItem('user');
    if (user) {
        user = JSON.parse(user);
        if (user.token) return user.token;
    }
    return null;
}

export function isAuthenticated(): boolean {
    return getToken() !== null;
}

export function isTokenExpired(token: string): boolean {
    const expiration = (jwt_decode(token) as any).exp;
    return expiration < Date.now() / 1000;
}

export function getUserRole() {
    const token = getToken();
    if (!token) return null;
    var payload: any = jwt_decode(token);
    var role = payload[roleProp]
    return role;
}