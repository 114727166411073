import { Reducer } from "redux";
import ActionTypes from "../actions/ActionTypes";
import { IStatisticsMarketingWarranties } from '../../types/IStatisticsMarketingWarranties';
import { IStatisticsWarranties } from '../../types/IStatisticsWarranties';

const initValues = {
    thisMonth: {
        totalSoldWarranties: 'X'
    },
    loading: false
}

export const thisMonthStatisticsReducer: Reducer<any> = (state: any = initValues, action): any => {
    switch (action.type) {
        case ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC: return { thisMonth: { totalSoldWarranties: 'X' }, loading: true };
        case ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC_SUCCESS: return { thisMonth: action.payload, loading: false };
        case ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC_ERROR: return { ...initValues };
        default: return state;
    }
}

const initSoldValues = {
    data: {
        totalSoldWarranties: 0,
        warrantiesBySeller: []
    },
    loading: false
}
export const soldStatisticsReducer: Reducer<any> = (state: any = initSoldValues, action): any => {
    switch (action.type) {
        case ActionTypes.GET_SOLD_STATISTIC: return {
            ...state, loading: true, data: {
                totalSoldWarranties: 0, warrantiesBySeller: []
            }
        };
        case ActionTypes.GET_SOLD_STATISTIC_SUCCESS: return { data: action.payload, loading: false };
        case ActionTypes.GET_SOLD_STATISTIC_ERROR: return { ...initSoldValues };
        default: return state;
    }
}

const initMarketingValues = {
    thisMonth: {
        totalSoldWarranties: 'X'
    },
    loading: false
}


export const marketingThisMonthStatisticsReducer: Reducer<any> = (state: any = initMarketingValues, action): any => {
    switch (action.type) {
        case ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC: return { thisMonth: { totalSoldWarranties: 'X' }, loading: true };
        case ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC_SUCCESS: return { thisMonth: action.payload, loading: false };
        case ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC_ERROR: return { ...initValues };
        default: return state;
    }


}

const initLastYearThisMonthValues = {
    thisMonth: {
        totalSoldWarranties: 'X'
    },
    loading: false
}

export const lastYearThisMonthStatisticsReducer: Reducer<any> = (state: any = initLastYearThisMonthValues, action): any => {
    switch (action.type) {
        case ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC: return { thisMonth: { totalSoldWarranties: 'X' }, loading: true };
        case ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC_SUCCESS: return { thisMonth: action.payload, loading: false };
        case ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC_ERROR: return { ...initValues };
        default: return state;
    }
}

const initialStatisticsMarketingWarrantiesData: IStatisticsMarketingWarrantiesData = {
    loading: false, data: new Array<IStatisticsMarketingWarranties>()
        
}

interface IStatisticsMarketingWarrantiesData {
    loading: boolean, data: IStatisticsMarketingWarranties[]
}

export const getStatisticsMarketingWarrantiesReducer: Reducer<IStatisticsMarketingWarrantiesData> = (state = initialStatisticsMarketingWarrantiesData, action) => {
    switch (action.type) {
        case ActionTypes.GET_STATISTICS_MARKETING_WARRANTIES_TABLE: return { loading: true, data: [] }
        case ActionTypes.GET_STATISTICS_MARKETING_WARRANTIES_TABLE_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_STATISTICS_MARKETING_WARRANTIES_TABLE_ERROR: return { loading: false, errors: action.payload, data: [] }

        default: return state;

    }
}

const initialStatisticsWarrantiesData: IStatisticsWarrantiesData = {
    loading: false, data: new Array<IStatisticsWarranties>()

}

interface IStatisticsWarrantiesData {
    loading: boolean, data: IStatisticsWarranties[]
}

export const getStatisticsWarrantiesReducer: Reducer<IStatisticsWarrantiesData> = (state = initialStatisticsWarrantiesData, action) => {
    switch (action.type) {
        case ActionTypes.GET_STATISTICS_WARRANTIES_TABLE: return { loading: true, data: [] }
        case ActionTypes.GET_STATISTICS_WARRANTIES_TABLE_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_STATISTICS_WARRANTIES_TABLE_ERROR: return { loading: false, errors: action.payload, data: [] }

        default: return state;

    }
}




