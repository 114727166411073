import { Reducer } from "redux";
import { IAuthenticationProps } from "../../types/IAuthenticationProps";
import ActionTypes from "../actions/ActionTypes";
import { ILoginProps } from './../../types/ILoginPprops';

const initialLoginValues = {
    data: {
        email: '',
        password: ''
    },
    loading: false
}

const loginReducer: Reducer<ILoginProps> = (state: ILoginProps = initialLoginValues, action): ILoginProps => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUBMIT: return { data: action.payload, loading: true };
        case ActionTypes.LOGIN_SUCCESS: return { data: { email: action.payload.email }, loading: false };
        case ActionTypes.LOGIN_ERROR: return { data: { error: "Unijeli ste pogrešan email ili zaporku", email: state.data.email, password: state.data.password }, loading: false };
        default: return state;
    }
}

export default loginReducer;


