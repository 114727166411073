import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { GetAllBuyers, GetBuyersAdminTotalCountAction } from './../../redux/actions/BuyerAction';
import BuyersTable from '../../components/tables/AdminBuyersOverview';
import IBuyerProps from '../../types/IBuyerProps';
import GoToPageAction from '../../redux/actions/GoToPageAction';
import ReactGA from 'react-ga';

interface IStateToProps {
    buyers: IBuyerProps[],
    totalCount: number
}

interface IDispatchToProps {
    getBuyers: (page: number, pageSize: number, filters: string) => void,
    goToBuyerDetails: (buyerId: string) => void,
    getTotalCount: () => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const BuyersOverview: React.FC<IProps> = props => {

    useEffect(() => {
        ReactGA.pageview("Admin | pregled kupaca");
        props.getBuyers(1, 10, "");
        props.getTotalCount();
    }, []);

    return (<BuyersTable rows={props.buyers} totalCount={props.totalCount} goToBuyerDetails={props.goToBuyerDetails} getBuyers={props.getBuyers} />)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    buyers: state.buyers.data,
    totalCount: state.buyersAdminTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getBuyers: (page: number, pageSize: number, filters: string) => dispatch<GetAllBuyers>({
        type: ActionTypes.GET_BUYERS,
        payload: { page, pageSize, filters }
    }),
    getTotalCount: () => dispatch<GetBuyersAdminTotalCountAction>({
        type: ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT
    }),
    goToBuyerDetails: (buyerId: string) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/buyer/' + buyerId }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(BuyersOverview);