import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar, MTablePagination } from "material-table";
import { useTranslation } from "react-i18next";
import {
    ISoldWarranties,
    IWarrantiesBySeller
} from "../../types/ISoldWarranties";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import { ListItemIcon, Button } from "@material-ui/core";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import { warrantyPdf } from "../../constants/apiRoutes";
import WarrantyStatusChecker from "../../utils/warrantyStatusChecker";
import Chip from "@material-ui/core/Chip";
import DateFnsUtils from "@date-io/date-fns";
import styles from "./soldWarrantiesTable.module.scss";
import { ValueType } from "react-select/src/types";
import UpgradeWarrantyPackage from "../../containers/warranty/UpgradeWarrantyPackage";
import Select from "react-select";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import ISellerProps from "../../types/ISellerProps";
import WarrantyInvoice from "../../containers/invoicing/WarrantyInvoicing";
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
import { ICurrency } from "../../types/ICurrency";
import { isSuperAdmin } from '../../utils/AuthFunctions';


interface IProps {
    soldWarranties: { data: ISoldWarranties; loading: boolean };
    startDate: Date;
    endDate: Date;
    handleStartDateChange: (date: Date) => void;
    handleEndDateChange: (date: Date) => void;
    handleSearch: (skip: number, take: number) => void;
    handleSelectSellerChange: (
        value: ValueType<{ label: string; value: string }>
    ) => void;
    sellers: ISellerProps[];
    selctedSeller: ValueType<{ label: string; value: string }>;
    lastInvoice: string;
    currencies: ICurrency[];
}

const SoldWarrantiesTable: React.FC<IProps> = (props: IProps) => {

    const { t } = useTranslation();

    const startDate = props.startDate;

    const endDate = props.endDate;

    const sellers = props.sellers;

    const lastInvoice = props.lastInvoice;

    const selctedSeller = props.selctedSeller;

    const handleStart = (date: Date) => {
        props.handleStartDateChange(date);
    };

    const handleEnd = (date: Date) => {
        props.handleEndDateChange(date);
    };

    const handleSelectSeller = (value: any) => {
        props.handleSelectSellerChange(value);
    };

    const handleSearch = () => {
        props.handleSearch(0, numberRowPerPage);
    };

    const [hideInvoiceColumn, setHideInvoiceColumn] = useState(true);

    // pagination logic
    const [numberRowPerPage, setNumberRowPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);

    const showHideInvoice = () => {
        setHideInvoiceColumn(!hideInvoiceColumn);
    }

    const mapPropsToTable = (): IWarrantiesBySeller[] => {
        let newRows = new Array<IWarrantiesBySeller>();

        props.soldWarranties.data.warrantiesBySeller &&
            props.soldWarranties.data.warrantiesBySeller.forEach(
                (row: IWarrantiesBySeller) => {
                    let newRow: IWarrantiesBySeller = {
                        ...row
                    };
                    newRows.push(newRow);
                }
            );
        return newRows;
    };

    const exportPdf = (rowData: IWarrantiesBySeller) => {
        return (
            <WarrantyInvoice
                key={rowData.sellerId}
                sellerName={rowData.sellerName}
                sellerId={rowData.sellerId}
                startDate={startDate.toLocaleDateString("en-US")}
                endDate={endDate.toLocaleDateString("en-US")}
                lastInvoice={"Učitavam.."}
                currencies={props.currencies}
                invoice={null}
            />
        );
    };

    return (
        <Paper>
            <MaterialTable
                isLoading={props.soldWarranties.loading}
                localization={{
                    body: {
                        emptyDataSourceMessage: "Nema podataka za prikaz."
                    }
                }}
                title={
                    "Prodana jamstva " +
                    props.soldWarranties.data.totalSoldWarranties
                }
                columns={[
                    { title: "Naziv trgovca", field: "sellerName" },
                    {
                        title: "Broj prodanih jamstva",
                        field: "sellerSoldWarranties",
                        defaultSort: "desc"
                    },
                    { title: "Generiraj račun", render: exportPdf, hidden: hideInvoiceColumn }
                ]}
                options={{
                    sorting: true,
                    search: true,
                    // paging: false,
                    headerStyle: {
                        boxShadow: '#none !important',
                    }
                }}
                detailPanel={(rowData: IWarrantiesBySeller) => {
                    return (
                        <Table
                            aria-label="simple table"
                            className={styles.warrantyDetailsTable}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Broj šasije</TableCell>
                                    <TableCell>Šifra jamstva</TableCell>
                                    <TableCell>Status jamstva</TableCell>
                                    <TableCell>Auto</TableCell>
                                    <TableCell>Vrijedi od</TableCell>
                                    <TableCell>Polica</TableCell>
                                    <TableCell>Cijena bez PDV</TableCell>
                                    <TableCell>Pravo na jamstvo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowData.warranties.map(row => {
                                    var basicPackage = "";
                                    basicPackage =
                                        row.allPackages && row.allPackages.package
                                            ? row.allPackages.package.packageName
                                            : "";
                                    var extraPackages = "";
                                    var extraPackagesPrice = 0;
                                    if (
                                        row.allPackages!.extraPackages &&
                                        row.allPackages!.extraPackages.length > 0
                                    ) {
                                        extraPackages =
                                            "+ " + row.allPackages!.extraPackages.length + " dodatka";
                                        for (let i = 0; i < row.allPackages!.extraPackages.length; i++) {
                                            extraPackagesPrice += row.allPackages!.extraPackages[i].price;
                                        }
                                    }
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.carData!.chassisNumber}</TableCell>
                                            <TableCell>{row.code}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    className={WarrantyStatusChecker.ButtonClassName(
                                                        row.status!.name
                                                    )}
                                                    label={t(row.status!.name)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {row.carData!.brand} {row.carData!.model}
                                            </TableCell>
                                            <TableCell>
                                                {row.validFrom === "01.01.0001."
                                                    ? "Treba aktivirati!"
                                                    : row.validFrom
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                        <span
                                                            style={{
                                                                display: "block",
                                                                fontSize: "12px",
                                                                height: "18px"
                                                            }}
                                                        >
                                                            {basicPackage} &nbsp;
                            </span>
                                                        <span
                                                            style={{ fontSize: "12px", fontStyle: "italic" }}
                                                        >
                                                            {extraPackages}
                                                        </span>
                                                    </div>
                                                    <UpgradeWarrantyPackage
                                                        car={row.carData}
                                                        currentPackage={row.allPackages!.package}
                                                        currentExtraPackages={
                                                            row.allPackages!.extraPackages
                                                        }
                                                        warrantyId={row.id}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {row.allPackages!.package!.price + extraPackagesPrice}{" "} {row.allPackages!.package!.currencyId === 0 ? "HRK" : "EUR"}
                      </TableCell>
                                            <TableCell>
                                                <ListItem
                                                    button
                                                    component={Link}
                                                    target="_blank"
                                                    to={warrantyPdf + "?warrantyId=" + row.id}
                                                >
                                                    <ListItemIcon>
                                                        <PictureAsPdf />
                                                    </ListItemIcon>
                                                </ListItem>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    );
                }}
                data={[...mapPropsToTable()]}
                components={{
                    Container: props => <Paper {...props} elevation={0} />,
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{ paddingLeft: 35, marginTop: -20 }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd.MM.yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Početak"
                                        value={startDate}
                                        onChange={date => handleStart(date!)}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        style={{ marginRight: 25 }}
                                        disabled={props.isReview}
                                        onBlur=""
                                        onFocus=""
                                        ref={null}
                                        innerRef={null}
                                        rows=""
                                        rowsMax=""
                                    />
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd.MM.yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Kraj"
                                        value={endDate}
                                        onChange={date => handleEnd(date!)}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        disabled={props.isReview}
                                        onBlur=""
                                        onFocus=""
                                        ref={null}
                                        innerRef={null}
                                        rows=""
                                        rowsMax=""
                                        style={undefined}
                                    />

                                    <Select
                                        placeholder={"Svi trgovci"}
                                        value={selctedSeller}
                                        onChange={handleSelectSeller}
                                        options={
                                            sellers &&
                                            sellers.map((m: ISellerProps) => ({
                                                label: m.fullName,
                                                value: m.id
                                            }))
                                        }
                                        className={styles.selectSeller}
                                        isClearable
                                    />
                                </MuiPickersUtilsProvider>
                                <Button
                                    color="primary"
                                    className={styles.search}
                                    variant="outlined"
                                    onClick={handleSearch}
                                >
                                    Traži
                </Button>
                                {isSuperAdmin() && <Button
                                    color="primary"
                                    className={styles.invoice}
                                    variant="outlined"
                                    onClick={showHideInvoice}
                                >
                                    Računi
                                </Button>}
                                {!hideInvoiceColumn && <span>Zadnji: {lastInvoice}</span>}
                            </div>
                        </div>
                    )
                }}
            />
            {/*  <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={23}
          rowsPerPage={numberRowPerPage}
          page={currentPage}
          onChangePage={(e: any, newPage: any) => {
            setCurrentPage(newPage);
            props.handleSearch(newPage * numberRowPerPage, numberRowPerPage);
          }}
          onChangeRowsPerPage={(event: any) => {
            setNumberRowPerPage(event.target.value);
            props.handleSearch(currentPage * event.target.value, event.target.value);

            }}
          labelRowsPerPage={"Trgovaca po stranici"}
          /> */}
        </Paper>
    );
};

export default SoldWarrantiesTable;
