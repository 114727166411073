export default class DateHelper {
    static dateDiffInDays(date1: string | Date, date2: string | Date): number {
        const dt1 = new Date(date1);
        const dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }
    static dateDiffInYears(date1: string | Date, date2: string | Date): number {
        return Math.floor((this.dateDiffInDays(date1, date2)) / 365);
    }
    static subtractYears(date: string | Date, years: number): Date {
        const d = new Date(date);
        d.setFullYear(d.getFullYear() - years);
        return d;
    }
}