import { Reducer } from 'redux';
import { IInvoiceTable } from '../../types/IInvoiceTable';
import ActionTypes from '../actions/ActionTypes';
import { IInvoiceData } from '../../types/IInvoiceData';
import { IYearDropdown } from '../../types/IYearDropdown';

type InvoicesData = { data: {invoices: IInvoiceTable[] }, loading: boolean } | null;

export const allInvoicesReducer: Reducer<InvoicesData> = (state: InvoicesData = { data: { invoices: [] }, loading: false }, action: any): InvoicesData => {
    switch (action.type) {
        case ActionTypes.GET_INVOICES: return { data: { invoices: [] }, loading: true }
        case ActionTypes.GET_INVOICES_SUCCESS: return { data: { invoices: action.payload }, loading: false }
        case ActionTypes.GET_INVOICES_ERROR: return { data: { invoices: [] }, loading: false }

        default: return state;
    }
}

type LastInvoiceData = { data: string };

export const lastInvoiceReducer: Reducer<LastInvoiceData> = (state: LastInvoiceData = { data: '' }, action: any): LastInvoiceData => {
    switch (action.type) {
        case ActionTypes.GET_LAST_INVOICE: return {  data: '' }
        case ActionTypes.GET_LAST_INVOICE_SUCCESS: return {  data: action.payload } 
        case ActionTypes.GET_LAST_INVOICE_ERROR: return { data: '' }

        default: return state;
    }
}

type InvoiceData = { data: { invoice: IInvoiceData | null }, loading: boolean } | null;


export const invoiceReducer: Reducer<InvoiceData> = (state: InvoiceData = { data: { invoice: null }, loading: false }, action: any): InvoiceData => {
    switch (action.type) {
        case ActionTypes.GET_INVOICE: return { data: { invoice: null }, loading: true }
        case ActionTypes.GET_INVOICE_SUCCESS: return { data: { invoice: action.payload }, loading: false }
        case ActionTypes.GET_INVOICE_ERROR: return { data: { invoice: null }, loading: false }

        default: return state;
    }
}

type YearsData = { data: IYearDropdown[], loading: boolean } | null;

export const yearsReducer: Reducer<YearsData> = (state: YearsData = { data: [], loading: false }, action: any): YearsData => {
    switch (action.type) {
        case ActionTypes.GET_YEARS: return { data: [] , loading: true }
        case ActionTypes.GET_YEARS_SUCCESS: return { data:  action.payload , loading: false }
        case ActionTypes.GET_YEARS_ERROR: return { data: [], loading: false }

        default: return state;
    }
}


