import React, { useEffect } from 'react';
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

interface IProps {
    setFactoryWarrantyBool: (automatic: boolean) => void,
    hasFactoryWarranty: boolean | undefined,
    disabled: boolean
}

export const FactoryWarrantyCheckButton: React.FC<IProps> = (props: IProps) => {
    const [hasFactoryWarranty, setHasFactoryWarranty] = React.useState(props.hasFactoryWarranty);

    useEffect(() => setHasFactoryWarranty(props.hasFactoryWarranty), [props]);

    function handleChange(event: React.ChangeEvent<unknown>) {
        const hasFactoryWarranty = (event.target as HTMLInputElement).checked;
        setHasFactoryWarranty(hasFactoryWarranty);
        props.setFactoryWarrantyBool(hasFactoryWarranty);
    }

    return (
        <FormGroup row>
            <FormControlLabel label="Tvorničko jamstvo je važeće"
                labelPlacement="end"
                control={<Checkbox
                    disabled={props.disabled}
                    checked={hasFactoryWarranty}
                    onChange={handleChange}
                    color="primary"
                    inputProps={{
                        'aria-label': 'secondary checkbox',
                    }}
                />}
            />
        </FormGroup>
    );
}
