import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from './../../types/applicationState';
import BuyerContract from '../../components/dashboards/buyerSale/BuyerContract';
import { Box } from '@material-ui/core';
import style from './../../components/dashboards/buyerSale/buyerContract.module.scss';
import ActionTypes from '../../redux/actions/ActionTypes';
import IActivateWarranty from '../../types/IActivateWarranty';
import { ActivateWarranty, CheckWarrantiesForActivation  } from '../../redux/actions/BuyerAction';
import { isAuthenticated } from '../../utils/AuthFunctions';
import GoToPageAction from '../../redux/actions/GoToPageAction';
import IWarranty from '../../types/IWarranty';
import { GetContractWarrantyAction} from '../../redux/actions/WarrantyAction';
import IMatch from './../../types/IMatch';

interface IStateToProps {
    warranty: IWarranty
}

interface IDispatchToProps {
    activateWarranty: (activationData: IActivateWarranty) => void,
    goToLogin: () => void,
    getWarranty: (data: IActivateWarranty) => void,
    checkWarranties: (activationData: IActivateWarranty) => void
}

interface IProps extends IStateToProps, IDispatchToProps, IMatch { }

const BuyerSale: React.FC<IProps> = (props: IProps) => {
    const [warranty, setWarranty] = useState<any>();
    const [activationData, setActivationData] = useState<any>();

    useEffect(() => setWarranty(props.warranty), [props.warranty]);

    useEffect(() => {
        if (!isAuthenticated()) window.location.href = '/login';
        const urlParts = window.location.href.split('/');
        const data: IActivateWarranty = {
            email: urlParts[urlParts.length - 2],
            warrantyId: urlParts[urlParts.length - 1]
        }
        console.log(data);
        props.getWarranty(data);
        setActivationData({
            email: urlParts[urlParts.length - 2],
            warrantyId: urlParts[urlParts.length - 1]
        })
    }, [props.match.url]);

    return <Box className={style.Box}>
        <BuyerContract checkWarranties={()=> props.checkWarranties(activationData)} activateWarranty={() => props.activateWarranty(activationData)} warranty={warranty}></BuyerContract>
    </Box>;
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    warranty: state.carWarranty
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    activateWarranty: (data: IActivateWarranty) => dispatch<ActivateWarranty>({
        type: ActionTypes.ACTIVATE_WARRANTY,
        payload: data
    }),
    goToLogin: () => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: "/login" }
    }),
    getWarranty: (data: IActivateWarranty) => {
        dispatch<GetContractWarrantyAction>({
            type: ActionTypes.GET_WARRANTY_FOR_CONTRACT,
            payload: data
        })
    },
    checkWarranties : (data: IActivateWarranty) => {
        dispatch<CheckWarrantiesForActivation>({
            type: ActionTypes.CHECK_WARRANTIES_FOR_ACTIVATION,
            payload: data.email
        })
    }
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(BuyerSale);