import React, { ChangeEvent } from 'react';
import { Grid, List, ListItem, ListItemText, ListItemIcon, Divider, ListItemSecondaryAction, IconButton, Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import styles from './fileUpload.module.scss';
import { IFile } from './../../../types/IFile';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import IAzureFile from '../../../types/IAzureFile';

interface IProps {
    filesChange: (files?: IFile[], azureFiles?: IAzureFile[]) => void,
    files?: Array<IFile>,
    azureFiles?: Array<IAzureFile>,
}

class FileUpload extends React.Component<IProps>{
    state = {
        files: this.props.files,
        azureFiles: this.props.azureFiles
    };

    static getDerivedStateFromProps(props: IProps, state: IProps): IProps {
        if (props.azureFiles && !state.azureFiles) return { ...state, azureFiles: props.azureFiles }
        return state
    }


    private manageUploadedFile(base64: string | ArrayBuffer | null, file: File) {
        const newFile: IFile = {
            name: file.name,
            file: file,
            data: base64
        }
        const files = this.state.files ? this.state.files.concat(newFile) : (new Array<IFile>()).concat(newFile);
        this.props.filesChange(files, undefined);
        this.setState({ files });
    }

    private handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        event.persist();
        if (event.target.files) Array.from(event.target.files).forEach((file: File) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // clear input value
                const input: any = document.getElementById("file");
                input.value = '';

                this.manageUploadedFile(reader.result, file)
            }
            reader.readAsDataURL(file);
        });
    }

    private deleteFile(file?: IFile, azureFile?: IAzureFile) {
        if (file) {
            const files = this.state.files!.filter((f: IFile) => f.name !== file.name);
            this.props.filesChange(files, undefined);
            this.setState({ files })
        }
        if (azureFile) {
            const azureFiles = this.state.azureFiles!.filter((f: IAzureFile) => f.url !== azureFile.url);
            this.props.filesChange(undefined, azureFiles);
            this.setState({ azureFiles })
        }
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <input accept="image/*,.pdf,.doc,.docx,.xls,.xlsx" id="file" multiple={true} type="file"
                        onChange={this.handleFileChange.bind(this)} className={styles.InputUpload} />
                    <label htmlFor="file">
                        <Button variant="contained" component="span" onClick={(e: any) => e.stopPropagation()}>
                            Učitaj dokument
                            </Button>
                    </label>
                </Grid>
                <Grid>
                    <List className={styles.List}>
                        {this.state.files && this.state.files.map((file: IFile) =>
                            <div key={file.name}>
                                <ListItem>
                                    <ListItemIcon>
                                        <UploadIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.deleteFile(file, undefined)} edge="end" aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </div>
                        )}
                        {this.state.azureFiles && this.state.azureFiles.map((file: IAzureFile) =>
                            <div key={file.url}>
                                <ListItem button component="a" href={file.url}>
                                    <ListItemIcon>
                                        <DownloadIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                    ></ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.deleteFile(undefined, file)} edge="end" aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </div>
                        )}
                    </List>
                </Grid>
            </Grid>
        );
    }
}

export default FileUpload;