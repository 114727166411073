import React, { useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import MaterialTable from 'material-table'
import styles from './invoicesTable.module.scss';
import { IInvoiceTable } from '../../types/IInvoiceTable';
import { IYearDropdown } from '../../types/IYearDropdown';
import moment from "moment";
import Select from "react-select";
import { getInvoices } from '../../redux/sagas/invoicing';

interface IProps {
    rows: IInvoiceTable[],
    loading: boolean,
    getInvoices: (year: string) => void,
    years: IYearDropdown[]
}

const InvoicesTable: React.FC<IProps> = (props: IProps) => {
    var date = new Date();
    const [year, setYear] = useState<any>({ label: date.getFullYear().toString(), value: date.getFullYear().toString() });


    const mapPropsToTable = (): IInvoiceTable[] => {
        let newRows = new Array<IInvoiceTable>();

        props.rows.forEach((row: IInvoiceTable) => {
            let newRow: IInvoiceTable = {
                ...row,
            }
            newRows.push(newRow);
        });
        return newRows;
    }

    function invoiceLink(rowData: IInvoiceTable) {
        return <a href={rowData.invoicePdfUrl} target="_blank">Pregled</a>
    }
    function renderDateWithTime(date: string) {
        return moment(date).format("DD.MM.YYYY HH:mm") !== "Invalid date" ? moment(date).format("DD.MM.YYYY HH:mm") : date;
    }
    function renderDate(date: string) {
        return moment(date).format("DD.MM.YYYY");
    }

    return <Paper className={`${styles.Root} InvoiceTable`}>
            <Select
                placeholder="Godina"
                value={year}
                onChange={(el: any) => {
                    setYear(el);
                    props.getInvoices(el.value);
                }}
                className={styles.dropdown}
                options={
                    props.years.map((x: IYearDropdown) => ({
                        label: x,
                        value: x
                    }))
                }
                isClearable={false}
            />
            <MaterialTable
                isLoading={props.loading}
                localization={{
                    body: {
                        emptyDataSourceMessage: "Nema podataka za prikaz."
                    },
                    pagination: {
                        previousTooltip: 'Prethodna',
                        nextTooltip: 'Sljedeća',
                        firstTooltip: 'Početna',
                        lastTooltip: 'Posljednja'
                    }
                }}
                title="Pregled izdanih računa"
                columns={[
                    { title: 'Broj računa', field: 'invoiceNumber' },
                    { title: 'Klijent', field: 'name' },
                    { title: 'OIB', field: 'oib' },
                    {
                        title: 'PDF', field: 'invoicePdfUrl',
                        render: (rowData: IInvoiceTable) => invoiceLink(rowData)
                    },
                    {
                        title: 'Datum izdavanja', field: 'issueDate',
                        render: (rowData: IInvoiceTable) => renderDateWithTime(rowData.issueDate!)
                    },
                    {
                        title: 'Datum dospijeća', field: 'dueDate',
                        render: (rowData: IInvoiceTable) => renderDate(rowData.dueDate!)
                    },
                ]}
                data={[...mapPropsToTable()]}
                options={{
                    filtering: true
                }}
            />

    </Paper>
}

export default InvoicesTable;
