import { Reducer } from "redux";
import ActionTypes from '../actions/ActionTypes';
import { ICurrency } from '../../types/ICurrency';

const initialCurrencyData: ICurrencyData = {
    loading: false,
    data: []
}

interface ICurrencyData {
    loading: boolean,
    data: ICurrency[]
}

export const getCurrenciesReducer: Reducer<ICurrencyData> = (state = initialCurrencyData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CURRENCIES: return { loading: true, data: [] }
        case ActionTypes.GET_CURRENCIES_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_CURRENCIES_ERROR: return { loading: false, errors: action.payload, data: [] }

        default: return state;
    }
}