import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './chassisNumberModal.module.scss';

interface IProps {
    closeModal: () => void,
    chassisNumber?: string,
    reportedDamages?: number,
    approvedDamages?: number,
    declinedDamages?: number,
    solvedDamages?: number
}

export class ChassisNumberModal extends React.Component<IProps> {


    handleSubmitYes = () => {
        this.props.closeModal();
    }

    handleSubmitNo = () => {
        this.props.closeModal();
    }


    render() {
        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="flex-start" justify="center" >
                    <Grid item xs={12}>
                        <Grid spacing={2}>
                            <Typography className={styles.MainText} variant="h6" align="center">Auto s brojem šasije {this.props.chassisNumber} ima:</Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="subtitle1" align="center" >{this.props.reportedDamages} prijavljenih šteta</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" >{this.props.approvedDamages} odobrenih šteta</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" >{this.props.declinedDamages} odbijenih šteta</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" >{this.props.solvedDamages} riješenih šteta</Typography>
                        </Grid>
                        <Grid className={styles.Question} spacing={2}>
                            <Typography className={styles.Button} variant="h6" align="center">Jeste li sigurni da želite kreirati novo jamstvo?</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={12} spacing={8} justify="center">
                        <Grid item xs={12} md={6} spacing={2}>
                            <Button className={styles.Button} variant="contained" color="primary" style={{ float: "right" }} onClick={this.handleSubmitYes.bind(this)}>DA</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button className={styles.Button} variant="contained" color="secondary" component={Link} to={'/'} onClick={this.handleSubmitNo.bind(this)}>NE</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </React.Fragment>
        )
    }
}