import { NotificationStyle } from './../../domain/enums/NotificationStyle';
import { Container } from 'inversify';
import {
    GetAllBuyers, ActivateWarranty, CheckWarrantiesForActivation, SaveBuyer, DeleteBuyer, GetBuyerByIdAction,
    SendActivationEmailBuyerAction, ActivateBuyer, GetBuyersAdminTotalCountAction, GetLegalBuyersAction
} from '../actions/BuyerAction';
import { getContext, put, apply, delay } from '@redux-saga/core/effects';
import IBuyerService from './../../domain/services/IBuyerService';
import BuyerService from './../../domain/services/BuyerService';
import ActionTypes from './../actions/ActionTypes';
import { SagaIterator } from '@redux-saga/core';
import ReactGA from 'react-ga';

export function* getAllBuyers(action: GetAllBuyers): SagaIterator {
    const container: Container = yield getContext('container');
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerService, buyerService.getAll, [action.payload.page, action.payload.pageSize, action.payload.filters]);
        yield put({ type: ActionTypes.GET_BUYERS_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYERS_ERROR, payload: err })
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getLegalBuyers(action: GetLegalBuyersAction): SagaIterator {
    const container: Container = yield getContext('container');
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerService, buyerService.getLegalBuyers, []);
        yield put({ type: ActionTypes.GET_LEGAL_BUYERS_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_LEGAL_BUYERS_ERROR, payload: err })
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* checkWarrantiesForActivation (action: CheckWarrantiesForActivation):SagaIterator {
    const container: Container = yield getContext('container');
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        const buyerResponse = yield apply(buyerService, buyerService.getApprovedWarranties, [action.payload]);
        const warrantyId = buyerResponse.length>0? buyerResponse[0].warrantyId : undefined;
        const activateUrl = '/activate-warranty/'+action.payload+'/'+warrantyId;
        yield put({type: ActionTypes.GO_TO_PAGE, payload: {url: warrantyId? activateUrl: '/' }})
    }
    catch(err) {
        yield put({type: ActionTypes.CHECK_WARRANTIES_FOR_ACTIVATION_ERROR, payload: err})
    }
}

export function* activateWarranty(action: ActivateWarranty): SagaIterator {
    const container: Container = yield getContext('container');
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        yield put({type: ActionTypes.LOADING_START});
        const response = yield apply(buyerService, buyerService.activateWarranty, [action.payload]);
        yield put({type: ActionTypes.ACTIVATE_WARRANTY_SUCCESS, payload: response});
        yield put({type: ActionTypes.LOADING_END});
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Jamstvo je uspješno aktivirano.',
                style: NotificationStyle.Success
            }
        });
        yield delay(3000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE
        })
        const buyerResponse = yield apply(buyerService, buyerService.getApprovedWarranties, [action.payload.email]);
        const warrantyId = buyerResponse.length>0? buyerResponse[0].warrantyId : undefined;
        const activateUrl = '/activate-warranty/'+action.payload.email+'/'+warrantyId;
        yield put({type: ActionTypes.GO_TO_PAGE, payload: {url: warrantyId? activateUrl: '/' }})
    }
    catch(err) {
        yield put({type: ActionTypes.ACTIVATE_WARRANTY_ERROR});
        yield put({type: ActionTypes.LOADING_END});
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Aktivacija jamstva nije uspjela.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE
        })
    }
}

export function* saveBuyer(action: SaveBuyer) {
    const container: Container = yield getContext('container');
    const buyerApi = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerApi, buyerApi.saveBuyer, [action.payload.buyer]);
        yield put({ type: ActionTypes.SAVE_BUYER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac je uspješno registriran',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Kupac je uspješno registriran',
                style: NotificationStyle.Success
            }
        });
        ReactGA.event({
            category: 'Admin',
            action: 'Spremanje novog kupca',
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: {
                url: '/'
            }
        })
    } catch (err) {
        yield put({ type: ActionTypes.SAVE_BUYER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac nije registriran.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Kupac nije registriran.',
                style: NotificationStyle.Error
            }
        });
    }
}

export function* deleteBuyer(action: DeleteBuyer) {
    const container: Container = yield getContext('container');
    const buyerApi = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerApi, buyerApi.deleteBuyer, [action.payload.buyer]);
        yield put({ type: ActionTypes.DELETE_BUYER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac je uspješno deaktiviran.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Kupac je uspješno deaktiviran.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: {
                url: '/buyers'
            }
        })
    } catch (err) {
        yield put({ type: ActionTypes.DELETE_BUYER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac nije deaktiviran.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Kupac nije deaktiviran.',
                style: NotificationStyle.Error
            }
        });
    }
}

export function* activateBuyer(action: ActivateBuyer) {
    const container: Container = yield getContext('container');
    const buyerApi = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerApi, buyerApi.activateBuyer, [action.payload.buyer]);
        yield put({ type: ActionTypes.ACTIVATE_BUYER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac je uspješno aktiviran.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Kupac je uspješno aktiviran.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: {
                url: '/buyers'
            }
        })
    } catch (err) {
        yield put({ type: ActionTypes.ACTIVATE_BUYER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Kupac nije aktiviran.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Kupac nije aktiviran.',
                style: NotificationStyle.Error
            }
        });
    }
}

export function* getBuyerById(action: GetBuyerByIdAction): SagaIterator {
    const container: Container = yield getContext('container');
    const buyerService = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerService, buyerService.getBuyerById, [action.payload]);
        yield put({ type: ActionTypes.GET_BUYER_SUCCESS, payload: response });

    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYER_ERROR, payload: err })
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: `Kupac s ID-jem; ${action.payload} ne postoji.`,
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* sendActivationEmail(action: SendActivationEmailBuyerAction) {
    const container: Container = yield getContext('container');
    const buyerApi = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerApi, buyerApi.sendActivationEmail, [action.payload.email]);
        yield put({ type: ActionTypes.SEND_ACTIVATION_EMAIL_BUYER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Email uspješno poslan.',
                style: NotificationStyle.Success
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Email uspješno poslan.',
                style: NotificationStyle.Success
            }
        });
    } catch (err) {
        yield put({ type: ActionTypes.SEND_ACTIVATION_EMAIL_BUYER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Email nije poslan.' + err,
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Email nije poslan.' + err,
                style: NotificationStyle.Error
            }
        });
    }
}

export function* getBuyersAdminTotalCount(action: GetBuyersAdminTotalCountAction) {
    const container: Container = yield getContext('container');
    const buyerApi = container.get<IBuyerService>(BuyerService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(buyerApi, buyerApi.getBuyersAdminTotalCount, []);
        yield put({ type: ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

