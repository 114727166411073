import React, { useState, useEffect } from 'react';
import WarrantyTable from './../tables/WarrantyTable';
import IWarrantyOverview from './../../types/IWarrantyOverview';
import { Grid, Modal, Paper, Box } from '@material-ui/core';
import { BuyerForm } from '../forms/buyerForm/BuyerForm';
import IBuyerProps from '../../types/IBuyerProps';
import styles from './sellerDashboard.module.scss';
import { initialBuyerProp } from '../../redux/reducers/carReducer';
import { CarStatus } from '../../domain/enums/CarStatus';
import WarrantyStatus from '../../domain/enums/WarrantyStatus';
import MissingCarDetailsForm from '../forms/missingCarDetailsForm/MissingCarDetailsForm';
import IMissingCarDetailsProps from '../../types/IMissingCarDetailsProps';
import { BuyerEmailModal } from '../forms/buyerForm/BuyerEmailModal';
import stylesEmailModal from '../forms/buyerForm/buyerEmailModal.module.scss';

interface IProps {
    goToEditWarranty: (rowData: IWarrantyOverview) => void,
    goToWarrantyPage: () => void,
    warranties: IWarrantyOverview[],
    sellCar: (buyer: IBuyerProps) => void,
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void,
    getBuyerData: (carId: number, warrantyId: number) => void,
    buyer: IBuyerProps,
    getBuyerByOIB: (oib: string) => void,
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => void,
    isCarDetailsMissing: boolean,
    warrantiesLoading: boolean,
    getBuyerByEmail: (email: string) => void

}

export const SellerDashboard: React.FC<IProps> = (props: IProps) => {
    const [showBuyerModal, setShowBuyerModal] = useState(false);
    const [sellingWarrantyId, changeSellingWarrantyId] = useState();
    const [isWarrantyEditable, setIsWarrantyEditable] = useState(true);
    const [carIsSold, setCarIsSold] = useState(false);
    const [carBuyer, setCarBuyer] = useState(props.buyer);
    const [showCheckBuyerEmailModal, setShowCheckBuyerEmailModal] = useState(false);
    const [buyerEmail, setBuyerEmail] = useState("");

    useEffect(() => {
        // car is for sale and data from backend  are available
        if (Object.keys(props.buyer).length && sellingWarrantyId) {
            setCarBuyer(props.buyer);
            setShowBuyerModal(true);
        }
        if (props.buyer.carSoldSuccess) {
            setShowBuyerModal(false);
            updateDashboard();
        }
    }, [props.buyer])
     
    const goToEditWarranty = (rowData: IWarrantyOverview) => {
        props.goToEditWarranty(rowData);
    }

    const buyerSubmit = (buyer: IBuyerProps) => {
        buyer.warrantyId = sellingWarrantyId;
        buyer.buyerEditing = carIsSold;
        props.sellCar(buyer);
    }

    const updateDashboard = () => {
        props.warranties.forEach((w: IWarrantyOverview) => {
            if (w.warrantyId === sellingWarrantyId) {
                w.carStatus = CarStatus.Sold;
                changeSellingWarrantyId(undefined);
            }
        })
    }

    const showSellCarForm = (warrantyId: number | any, isCarAvailable: boolean) => {
        changeSellingWarrantyId(warrantyId);
        setCarIsSold(!isCarAvailable)
        const warranty = props.warranties.find((w: IWarrantyOverview) => w.warrantyId === warrantyId);
        if (!isCarAvailable) {
            if(warranty) props.getBuyerData(warranty.carId, warranty.warrantyId);
            setShowCheckBuyerEmailModal(false);
        } else {
            setCarBuyer({ ...initialBuyerProp, warrantyId});
            setShowCheckBuyerEmailModal(true);
        }
        
        if (warranty) {
            setIsWarrantyEditable(warranty.warrantyStatus !== WarrantyStatus.Active)
            props.setCarDetailsMissing(warranty.arrivedFromNjuskalo && warranty.car.chassisNumber.length !== 17 || (warranty.car.chassisNumber.toUpperCase() === 'XXXXXXXXXXXXXXXXX' && warranty.car.chassisNumber.length === 17));
        }
    }


    const buyerEmailSubmit = (email: string) => {
        setBuyerEmail(email);
        props.getBuyerByEmail(email);
    }

    return (
        <Box>
            <Grid item xs={12}>
                <WarrantyTable warrantiesLoading={props.warrantiesLoading} showSellCarForm={showSellCarForm} goToWarrantyPage={props.goToWarrantyPage} goToEditWarranty={goToEditWarranty} rows={props.warranties} />
            </Grid>
            {showBuyerModal &&
                <Grid item xs={12}>
                    <Modal open={showBuyerModal} className={styles.Modal} onClose={() => setShowBuyerModal(false)}>
                    <Paper className={styles.Root}>
                        {props.isCarDetailsMissing ? <MissingCarDetailsForm onSubmit={props.editMissingCarDetails} carId={carBuyer.carId} /> : <div/> }

                        <div className={props.isCarDetailsMissing ? styles.buyerFormReadonly : ''}>
                            <BuyerForm isEditable={isWarrantyEditable} carIsSold={carIsSold} closeModal={() => setShowBuyerModal(false)} submit={buyerSubmit} {...props} buyer={carBuyer} buyerEmail={buyerEmail}></BuyerForm>
                        </div>
                        </Paper >
                    </Modal>
                </Grid>
            }
            {
                showCheckBuyerEmailModal &&
                <Box>
                    <Modal open={showCheckBuyerEmailModal} className={stylesEmailModal.Modal} onClose={() => setShowCheckBuyerEmailModal(false)}>
                        <Paper className={stylesEmailModal.RootModal}>
                            <div >
                                <BuyerEmailModal closeModal={() => setShowCheckBuyerEmailModal(false)} submitEmailModal={buyerEmailSubmit} {...props}></BuyerEmailModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
        </Box>
    )
}
