
import {
    GetDamageAction, GetSellerNameAction, DeleteDamageAction, GetBuyerNameAction, GetCarNameAction, GetChassisDamageAction, GetDamageStatusesAction,
    SaveDamageAction, GetDamagesAdminAction, GetDamagesCarAction, GetActiveDamageAction, GetDamagesBuyerAction, GetDamagesWarrantyAction, GetDamagesSellerByAdminAction,
    GetDamagesAdminTotalCountAction
} from './../actions/DamageAction';
import { Container } from 'inversify';
import { getContext, apply, put } from '@redux-saga/core/effects';
import IDamageService from './../../domain/services/IDamageService';
import DamageService from './../../domain/services/DamageService';
import ActionTypes from './../actions/ActionTypes';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';

export function* getDamage(action: GetDamageAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamage, [action.payload.carId, action.payload.damageId]);
        yield put({ type: ActionTypes.GET_DAMAGE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getActiveDamage(action: GetActiveDamageAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getActiveDamage, [action.payload.carId, action.payload.warrantyId]);
        yield put({ type: ActionTypes.GET_ACTIVE_DAMAGE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_ACTIVE_DAMAGE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamageStatuses(action: GetDamageStatusesAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        const response = yield apply(damageApi, damageApi.getDamageStatuses, []);
        yield put({ type: ActionTypes.GET_DAMAGE_STATUSES_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGE_STATUSES_ERROR, payload: err });
    }
}


export function* saveDamage(action: SaveDamageAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.saveDamage, [action.payload]);
        yield put({ type: ActionTypes.SAVE_DAMAGE_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Šteta je uspješno spremljena.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: (isAdmin() || isSuperAdmin()) ? '/admin_damages' : '/seller_damages' }
        })
    } catch (err) {
        yield put({ type: ActionTypes.SAVE_DAMAGE_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjela prijava štete.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesAdmin(action: GetDamagesAdminAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesAdmin, []);
        yield put({ type: ActionTypes.GET_DAMAGES_ADMIN_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_ADMIN_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesAdminTotalCount(action: GetDamagesAdminTotalCountAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesAdminTotalCount, []);
        yield put({ type: ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesSeller(action: GetDamagesAdminAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesSeller, []);
        yield put({ type: ActionTypes.GET_DAMAGES_SELLER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_SELLER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesBuyer(action: GetDamagesBuyerAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesBuyer, [action.payload.email]);
        yield put({ type: ActionTypes.GET_DAMAGES_BUYER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_BUYER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesWarranty(action: GetDamagesWarrantyAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesWarranty, [action.payload.warrantyId]);
        yield put({ type: ActionTypes.GET_DAMAGES_WARRANTY_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_WARRANTY_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesSellerByAdmin(action: GetDamagesSellerByAdminAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesSellerByAdmin, [action.payload.id]);
        yield put({ type: ActionTypes.GET_DAMAGES_SELLERBYADMIN_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_SELLERBYADMIN_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getChassisDamage(action: GetChassisDamageAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getChassisDamage, [action.payload.chassisNumber]);
        yield put({ type: ActionTypes.GET_CHASSIS_DAMAGE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CHASSIS_DAMAGE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getDamagesCar(action: GetDamagesCarAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.getDamagesCar, [action.payload.carId]);
        yield put({ type: ActionTypes.GET_DAMAGES_CAR_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_DAMAGES_CAR_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getSellerName(action: GetSellerNameAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        const response = yield apply(damageApi, damageApi.getSellerName, [action.payload.id]);
        yield put({ type: ActionTypes.GET_SELLER_NAME_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_SELLER_NAME_ERROR, payload: err });
    }
}

export function* getBuyerName(action: GetBuyerNameAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        const response = yield apply(damageApi, damageApi.getBuyerName, [action.payload.email]);
        yield put({ type: ActionTypes.GET_BUYER_NAME_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYER_NAME_ERROR, payload: err });
    }
}

export function* getCarName(action: GetCarNameAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        const response = yield apply(damageApi, damageApi.getCarName, [action.payload.id]);
        yield put({ type: ActionTypes.GET_CAR_NAME_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_NAME_ERROR, payload: err });
    }
}

export function* deleteDamage(action: DeleteDamageAction) {
    const container: Container = yield getContext('container');
    const damageApi = container.get<IDamageService>(DamageService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(damageApi, damageApi.deleteDamage, [action.payload.damageId]);
        yield put({ type: ActionTypes.DELETE_DAMAGE_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Šteta je uspješno obrisana.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: (isAdmin() || isSuperAdmin()) ? '/admin_damages' : '/seller_damages' }
        })
    } catch (err) {
        yield put({ type: ActionTypes.DELETE_DAMAGE_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjelo brisanje štete.',
                style: NotificationStyle.Error
            }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}


