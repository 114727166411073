import { Reducer } from 'redux';
import InitStateSuccessAction from '../actions/InitStateSuccessAction';
import ActionTypes from '../actions/ActionTypes';
import { IApplicationProps } from '../../types/IApplicationProps';

const initialState: IApplicationProps = {
    clientId: '',
    language: 'en-GB',
};

const initStateReducer: Reducer<IApplicationProps, InitStateSuccessAction> = (state = initialState, action: InitStateSuccessAction) => {
    if (action.type === ActionTypes.INIT_STATE_SUCCESS) {
        return {...action.payload};
    }

    return state;
};

export default initStateReducer



