import { injectable, inject } from 'inversify';
import ThirdPartyTypes from "../../di/ThirdPartyTypes";
import { AxiosInstance } from 'axios';
import { invoicePdf, allInvoices, lastInvoice, currentInvoice } from './../../constants/apiRoutes';
import { IInvoiceData } from "../../types/IInvoiceData";
import IInvoiceService from "./IInvoiceService";

@injectable()
export default class InvoiceService implements IInvoiceService {

    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async sendWarrantyPdf(data: IInvoiceData): Promise<any> {
        const response = await this.apiClient.post(invoicePdf, data);
        return response.data;
    }

    public async getInvoices(year: string): Promise<any> {
        const response = await this.apiClient.get(allInvoices + "/" + year);
        return response.data;
    }

    public async getYears(): Promise<any> {
        const response = await this.apiClient.get(allInvoices + "/years");
        return response.data;
    }

    public async lastInvoice(): Promise<any> {
        const response = await this.apiClient.get(lastInvoice);
        return response.data;
    }


    public async getInvoice(invoiceNumber: string): Promise<any> {
        const apiNumber = invoiceNumber.replace("/", "-").replace("/", "-");
        const response = await this.apiClient.get(currentInvoice + "/" + apiNumber);
        return response.data;
    }

}