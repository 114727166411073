import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import MarketingSellersTable from "./../../components/tables/MarketingSellersTable";
import {
    GetMarketingSellersTableAction, GetMarketingSellersTableTotalCountAction
} from "./../../redux/actions/SellerAction";
import ReactGA from "react-ga";
import ISellerProps from "../../types/ISellerProps";

interface IStateToProps {
    marketingSellers: ISellerProps[];
    totalCount: number;
}

interface IDispatchToProps {
    getMarketingSellersTable: (page: number, pageSize: number, filters: string) => void;
    getTotalCount: () => void;
}

interface IProps extends IDispatchToProps, IStateToProps { }

const MarketingSellersOverview: React.FC<IProps> = (props) => {
    useEffect(() => {
        ReactGA.pageview("Pregled svih trgovaca");
        props.getTotalCount();
        props.getMarketingSellersTable(1, 10, "");
    }, []);

    return (
        <MarketingSellersTable
            rows={props.marketingSellers}
            totalCount={props.totalCount}
            getMarketingSellers={props.getMarketingSellersTable}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    marketingSellers: state.marketingSellersTable.data,
    totalCount: state.marketingSellersTableTotalCount.data,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getMarketingSellersTable: (page: number, pageSize: number, filters: string) =>
        dispatch<GetMarketingSellersTableAction>({
            type: ActionTypes.GET_MARKETING_SELLERS_TABLE,
            payload: { page, pageSize, filters },
        }),
    getTotalCount: () =>
        dispatch<GetMarketingSellersTableTotalCountAction>({
            type: ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT,
        }),
});

export default connect<
    IStateToProps,
    IDispatchToProps,
    IProps,
    IApplicationState
>(
    mapStateToProps,
    mapDispatchToProps
)(MarketingSellersOverview);
