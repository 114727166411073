import React, { useState, useEffect } from 'react';
import { Paper, Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import styles from './sellerNote.module.scss';
import { convertFromRaw, convertToRaw } from 'draft-js';
import {EditorState} from 'draft-js'

interface IProps extends IDispatchToProps, IStateToProps { }

interface IDispatchToProps {
    saveNoteForSeller: (note: string, sellerId: string) => void,
}

interface IStateToProps {
    note: any,
    sellerId: string,
}

const SellerNote: React.FC<IProps> = (props: IProps) => {
    var DBEditorState = EditorState.createEmpty();

    let [note, setNote] = useState(DBEditorState);

    useEffect(() => {
        if(props.note && props.note.length > 0) {
            let raw = convertFromRaw(JSON.parse(props.note));
            DBEditorState = EditorState.createWithContent(raw);
        }
    setNote(DBEditorState);
    }, [props.note])

    const handleSubmit = () => {
        props.saveNoteForSeller(JSON.stringify(convertToRaw(note!.getCurrentContent())), props.sellerId);
    }

    const handleEditor = (editorState: EditorState) => {
        setNote(editorState);
    }
    
    return (
        <Grid container alignItems="center" direction="row" spacing={1}>
            <Paper>
                <Grid item direction="row" xs={12}>
                    <h2 style={{ color: 'black', display: 'block', fontSize: 22, paddingLeft: 30 }}>Zabilješka</h2>
                </Grid>

                <Grid item xs={12} spacing={3}>
                    <ValidatorForm
                        onSubmit={() => { }}
                    >
                        <Editor
                            editorState={note}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName={styles.editorWrapper}
                            editorClassName={styles.editor}
                            onEditorStateChange={handleEditor}
                        />
                          <Grid container item justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item className={styles.GridButton}>
                                <Button variant="contained" color="primary" type="submit" onClick={() => handleSubmit()}>Spremi</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            </Paper>
        </Grid>
    )
}


export default SellerNote