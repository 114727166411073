import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import ICarProps from '../../types/ICarProps';
import IPackage from '../../types/IPackage';
import { GetPackagesAction, UpgradeWarrantyPackageAction } from '../../redux/actions/PackagesAction';
import { UpgradeWarrantyPackageLayout } from './../../components/upgradeWarrantyPackage/UpgradeWarrantyPackageLayout';
import IPackageSelection from '../../types/IPackageSelection';
import ISelectedPackage from '../../types/ISelectedPackage';
import ReactGA from 'react-ga';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';
import { GetCarProblemExtraPackageAction } from '../../redux/actions/CarAction';

interface IStateToProps {
    allPackages?: IPackageSelection,
    car?: ICarProps,
    currentPackage?: IPackage,
    currentExtraPackages?: IPackage[]
    warrantyId?: number,
    carProblemExtraPackages?: { data: ICarProblemExtraPackage[] }
}

interface IDispatchToProps {
    getAvailablePackages?: (car: ICarProps) => void,
    upgradeWarrantyPackage?: (packageId: number, extraPackages: number[], warrantyId: number) => void,
    getCarProblemExtraPackage?: (car: ICarProps) => void
}

interface IProps extends IDispatchToProps, IStateToProps { }


const UpgradeWarrantyPackage: React.FC<IProps> = props => {

    useEffect(() => {
        ReactGA.pageview("Nadogradnja police view");
    }, [])

    const [currentPackage, setCurrentPackage] = React.useState(props.currentPackage ? props.currentPackage!.packageId : 0);
    const [currentExtraPackages, setExtraCurrentPackages] = React.useState(props.currentExtraPackages!);

    const findAvailablePackages = () => {
        props.getAvailablePackages!(props.car!);
        props.getCarProblemExtraPackage!(props.car!);
    }

    const packageChanged = (packageId: number) => {
        setCurrentPackage(packageId);
    }

    const extraPackageChanged = (id: number) => {
        if(!!currentExtraPackages.find(obj => obj.id == id)) {
            let ep = currentExtraPackages.filter(obj => obj.id !== id);
            setExtraCurrentPackages(ep);
        } else {
            let tmp = JSON.parse(JSON.stringify(currentExtraPackages));
            tmp.push({id: id, packageName: '', name: '', packageDescription: '', price: 0, priceWithVAT: 0});
            setExtraCurrentPackages(tmp);
        }
    }

    const handleUpgrade = () => {
        let ep: number[] = [];
        if(currentExtraPackages && currentExtraPackages.length > 0) {
            for(let i=0; i<currentExtraPackages.length; i++) {
               ep.push(Number(currentExtraPackages[i].id));
            }
        }
        props.upgradeWarrantyPackage!(currentPackage, ep, props.warrantyId!)
    }

    return (<UpgradeWarrantyPackageLayout
        packages={props.allPackages}
        car={props.car}
        currentPackage={currentPackage}
        currentExtraPackages={currentExtraPackages}
        packageChanged={packageChanged}
        extraPackageChanged={extraPackageChanged}
        findAvailablePackages={findAvailablePackages}
        handleUpgrade={handleUpgrade}
        carProblemExtraPackages={props.carProblemExtraPackages}    />)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    allPackages: state.packagesData.data,
    carProblemExtraPackages: state.carProblemExtraPackages
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getAvailablePackages: (car: ICarProps) => {
        dispatch<GetPackagesAction>({
            type: ActionTypes.GET_PACKAGES,
            payload: car
        });
        dispatch<GetPackagesAction>({
            type: ActionTypes.GET_EXTRA_PACKAGES,
            payload: car
        })
    },
    upgradeWarrantyPackage: (packageId: number, extraPackages: number[], warrantyId: number) => {
        dispatch<UpgradeWarrantyPackageAction>({
            type: ActionTypes.UPGRADE_WARRANTY_PACKGAGE,
            payload: { packageId, extraPackages, warrantyId }
        })
    },
    getCarProblemExtraPackage: (car: ICarProps) => {
        dispatch<GetCarProblemExtraPackageAction>({
            type: ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE,
            payload: car
        })
    },
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(UpgradeWarrantyPackage);