import React from 'react';
import { Button, Grid } from '@material-ui/core';
import styles from './sendMailBuyerModal.module.scss';

interface IProps {
    closeModal: () => void,
    submitYesModal: () => void
}

export class SendMailBuyerModal extends React.Component<IProps> {


    handleSubmitYes = () => {
        this.props.submitYesModal();
        this.props.closeModal();
    }

    handleSubmitNo = () => {
        this.props.closeModal();
    }


    render() {
        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="flex-start" justify="center" >
                    <Grid className={styles.Text}>
                        <h4>Jeste li sigurni da želite ponovno kupcu poslati aktivacijski email za njegovo zadnje kupnjeno jamstvo?</h4>
                    </Grid>
                    <Grid container item xs={12} md={12} spacing={4} justify="center">
                        <Grid item xs={12} md={6} spacing={2}>
                            <Button variant="contained" color="primary" style={{ float: "right" }} onClick={this.handleSubmitYes.bind(this)}>DA</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="secondary" onClick={this.handleSubmitNo.bind(this)}>NE</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </React.Fragment>
        )
    }
}