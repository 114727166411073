import React, { SyntheticEvent, useState, useEffect } from 'react'
import styles from './actionNotification.module.scss';
import { SnackbarContent, Snackbar } from '@material-ui/core';
import IActionNotification from './../../types/utilities/IActionNotification';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { getCLIOptions } from 'jest-runtime';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';

interface IProps extends IActionNotification {
    close?: () => void
}

export const ActionNotification: React.FC<IProps> = (props: IProps) => {
    const [open, setOpen] = useState(props.show);

    useEffect(() => {
        setOpen(props.show)
    }, [props])

    function handleClose(event?: SyntheticEvent) {
        setOpen(false);
        props.close!();
    }

    function getIcon() {
        switch (props.style) {
            case NotificationStyle.Success: return <CheckCircleIcon className={styles.Icon} />
            case NotificationStyle.Error: return <ErrorIcon className={styles.Icon} />
            case NotificationStyle.Info: return <InfoIcon className={styles.Icon} />
        }
    }
    return open ? <div className={styles.Root}>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={props.style && styles[props.style]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={styles.Message}>
                        {getIcon()}
                        {props.message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                ]}
            />

        </Snackbar>
    </div> : null;
}