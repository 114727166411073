import  IFilterRequest from '../types/IFilterRequest';

export class URLHelper {
    static getSellerId(): string | null {
        const urlParts = window.location.href.split('/');
        return urlParts[urlParts.length - 1];
    }

    static getBuyerId(): string | null {
        const urlParts = window.location.href.split('/');
        return urlParts[urlParts.length - 1];
    }

    static getQueryStringFilterCars(filter: IFilterRequest): string {
        const keys = Object.keys(filter);
        return '?' + Object.values(filter)
            .map((value: string, index: number) => {
                var data = (value !== undefined && value.toString()) ? encodeURIComponent(keys[index]) + '=' + encodeURIComponent(value) : null;
                return data;
            }
            )
            .filter(Boolean)
            .join('&')
    }

    static getQueryStringParams = (query: any) => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params: any, param: any) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    };

    static getLastURLPathPart(): string | undefined {
        return window.location.href.split('/').pop()!.split('?')[0];
    }
}