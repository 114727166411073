import WarrantyStatus from '../domain/enums/WarrantyStatus';
import style from './../components/tables/adminTable.module.scss';

export default class WarrantyStatusChecker {

    static AdminViewOrUpdate(status: string): boolean {
        switch(status) {
            case WarrantyStatus.Approved: 
            case WarrantyStatus.Declined: 
            case WarrantyStatus.Active:
            case WarrantyStatus.InProcess: 
            case WarrantyStatus.Inactive: 
                return true;
            case WarrantyStatus.Submitted: 
                return false;
        }
        return false;
    }

    static SellerViewOrSell (status: string): boolean {
        switch (status) {
            //admin - view of warranty
            case WarrantyStatus.Approved:
                return true;
        }
        return false;
    }
    static ButtonClassName(status: string) {
        switch (status) {
            case WarrantyStatus.Approved: return style.Approved;
            case WarrantyStatus.Declined: return style.Declined;
            case WarrantyStatus.Active: return style.Active;
            case WarrantyStatus.InProcess: return style.InProccess;
            case WarrantyStatus.Inactive: return style.Inactive;
            case WarrantyStatus.Submitted: return style.Submitted;
        }
    }

    static IsActive(status: string) {
        return status === WarrantyStatus.Active;
    }
}