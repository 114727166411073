import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import img from '../../resources/img/total-sold.png';
import classes from './thisMonthTotalWarrantiesSold.module.scss';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';

interface IProps {
    thisMonthTotalSoldWarranties: {
        lastMonth: number,
        thisMonth: number
    },
    currentMonth: number,
    loading: boolean,
}

export const MarketingThisMonthSoldTotalWarranties: React.FC<IProps> = props => {

    var diff = props.thisMonthTotalSoldWarranties.thisMonth - props.thisMonthTotalSoldWarranties.lastMonth;
    var percent = '';
    if (props.thisMonthTotalSoldWarranties.lastMonth !== 0)
        percent = ((diff / props.thisMonthTotalSoldWarranties.lastMonth) * 100).toFixed(1);
    return (
        <Card className={classes.card}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        Prodana Njuškalo jamstva
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {props.currentMonth} mj.
                    </Typography>
                </CardContent>
                <div className={classes.controls}>
                    <IconButton aria-label="broj jamstva">
                        {props.thisMonthTotalSoldWarranties.thisMonth}
                        {!props.loading && diff !== 0 && props.thisMonthTotalSoldWarranties.lastMonth !== 0 && <span className={classes.lastMonthSold} style={{ color: diff > 0 ? 'green' : 'red' }}>{Number(percent) > 0 ? '+' : ''}{percent}%, {diff > 0 ? '+' : ''}{diff}</span>}
                    </IconButton>
                </div>
            </div>
            <CardMedia
                className={classes.cover}
                image={img}
                title="Broj prodanih jamstva u mjesecu"
            />
        </Card>
    );
}