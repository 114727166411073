import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken, isTokenExpired } from './AuthFunctions';
import UserService from './../domain/services/UserService';
const userService = new UserService();
export default function addAuthHeader(axios: AxiosInstance) {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
        const accessToken = getToken();

        if (accessToken) {
            if (config.method !== 'OPTIONS') {
                config.headers.authorization = `Bearer ${accessToken}`;
            }
            // logout user if token is expired
            if (isTokenExpired(accessToken)) userService.logout();
        }
        return config;
    }, err => { return err; })
}
