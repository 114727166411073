import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from './changeBuyerModal.module.scss';

interface IProps {
    closeModal: () => void,
    submitYesModal: (email: string) => void
}

interface IState {
    email: string
}

export class ChangeBuyerModal extends React.Component<IProps> {

    state: IState = {
        email: ""
    };


    private formRef = React.createRef<ValidatorForm>();

    handleSubmitEmail = () => {
        this.props.submitYesModal(this.state.email);
        this.props.closeModal();
    }

    private handleChange(el: any) {
        const { value } = el.target;

        this.setState({ email: value });
    }

    render() {
        return (
            <React.Fragment>
                <ValidatorForm
                    onSubmit={() => this.handleSubmitEmail()}
                    ref={this.formRef}
                    className={styles.Form}
                >
                    <Grid container direction="row" alignItems="flex-start" justify="center" >
                        <Grid>
                            <h4>Unesite e-mail adresu postojećeg kupca na kojeg želite prebaciti ovo jamstvo</h4>
                        </Grid>
                        <TextValidator
                            label="Email"
                            name="email"
                            onChange={this.handleChange.bind(this)}
                            disabled={false}
                            value={this.state.email}
                            validators={['required', 'isEmail']}
                            errorMessages={['Ovo polje je obavezno.', 'Neispravan email']}
                            style={{ width: "70%" }}
                        />
                        <Grid container item xs={12} md={12} justify="center">
                            <Grid item>
                                <Button className={styles.Button} variant="contained" color="primary" type="submit">OK</Button>
                            </Grid>

                        </Grid>
                    </Grid>

                </ValidatorForm>
            </React.Fragment>
        )
    }
}