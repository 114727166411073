import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import ILoading from '../../types/utilities/ILoading';
import styles from './appLoading.module.scss';
import { Modal } from '@material-ui/core';

interface IProps extends ILoading { }

export const AppLoading: React.FC<IProps> = (props: IProps) => {
    return props.show ? <Modal open={props.show} disableBackdropClick={true}><CircularProgress className={styles.Root} /></Modal> : null
}