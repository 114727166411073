import React from 'react';
import styles from './legal.module.scss';
import PublicFormLayout from '../authentication/PublicFormLayout';
import { BackButton } from '../buttons/BackButton';


const SellerContract = () => {
    return (
        <PublicFormLayout className={styles.Layout} md={true} lg={true}>
            <div className={styles.Root}>
                <p>TOČKA NA J KOORDINACIJA d.o.o., Unska 2c, Zagreb, OIB: 57739667576, koju zastupa direktor Ante Bogdanić (u daljnjem tekstu Izvršitelj usluge)</p>
                <p>i</p>
                <p>__________________________________________________________________________________________________________________</p>
                <p style={{ textAlign: "center", fontSize: "10px" }}>Pravna osoba</p>
                <p>_________________________________________________________________,&nbsp;________________________________________________</p>
                <p style={{ fontSize: "10px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Adresa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OIB</p>
                <p>__________________________________________________________________________________________________________________</p>
                <p style={{ fontSize: "10px", textAlign: "center", }}>E-mail i telefon</p>
                <p>koju zastupa direktor/ica ________________________________________________________________________________________</p>
                <p style={{ textAlign: "center"}}>(u daljnjem tekstu Naručitelj usluge),</p>
                <p>sklopili su:</p>
                <h3 style={{ textAlign: "center" }}>UGOVOR O KOORDINACIJI PARAVA PRODUŽENOG JAMSTVA</h3>
                <p style={{ textAlign: "center", marginTop: "30px"}}>Članak 1.</p>
                <p>Pravna osoba Točka na J Koordinacija d.o.o., nositelj ugovora, vlasnik je produkta G1 klub koji će se u daljnjem tekstu koristiti kao koordinator prava na produženo auto jamstvo.</p>
                <p>Pravna osoba Točka na J Koordinacija d.o.o., svoj produkt G1 pravo na produženo auto jamstvo, prodaje prodavatelju vozila na komisionu prodaju.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 2.</p>
                <p>Pravna osoba Točka na J Koordinacija d.o.o., će koordinirati pravo na produženo auto jamstvo prema općim uvjetima poslovanja koji su prilog ovog ugovora.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 3.</p>
                <p>Prodavatelj će zajedno s prodajom svojih vozila uklopiti i pravo na produženo auto jamstvo s kojim koordinira pravna osoba Točka na J Koordinacija d.o.o.</p>
                <p>Prodavatelj se obavezuje na svako vozilo koje prodaje, a koje zadovoljava uvjete za dobivanje prava na produženo auto jamstvo, uklopiti pravo na produženo auto jamstvo te o tome izvijestiti pravnu osobu Točka na J koordinacija d.o.o.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 4.</p>
                <p>U slučaju posebnih prodajnih i promotivnih akcija prodavatelj dozvoljava pravnoj osobi Točka na J Koordinacija d.o.o. posebno isticanje i pozicioniranje njegovog imena i oznaka u promotivne svrhe kao i korištenja njegovih oglašenih artikala.</p>
                <p>Pravna osoba Točka na J koordinacija d.o.o. se obavezuje u svakoj prodajnoj i promotivnoj akciji čuvati ime i ugled prodavatelja te ga o svakoj mogućoj aktivnosti navedenog tipa posebno izvijestiti pismenim putem najmanje 8 dana prije početka akcije.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 5.</p>
                <p>Prodavatelj se obavezuje da će kupcu vozila pojasniti sve o njegovom pravu na produženo auto jamstvo te način na koji aktivira koordinaciju prava na produženo jamstvo zajedno sa svim prednostima kupovine vozila s pravom na produženo auto jamstvo.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 6.</p>
                <p>Pravna osoba Točka na J Koordinacija d.o.o. zadržava pravo uskraćivanja usluge koordinacije prava produženog auto jamstva u prvih 10 dana od aktivacije prava na produženo jamstva i prijeđenih 1000 kilometara od broja kilometara upisanih i uslikanih u G1 aplikaciju, ukoliko posumnja u ispravnost prodanog vozila na kojem je pravo na produženo auto jamstvo. Takvo vozilo ima pravo isključivo samo na uslugu HAK pomoć na cesti, koja je sastavni dio produkta Koordinacije prava na produženo auto jamstvo.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 7.</p>
                <p>Ukoliko na prodanom vozilu koje je predmet koordinacije prava, postoji još važeća garancija proizvođača vozila, G1 pravo na produženo auto jamstvo bit će uključeno u trenutku isteka garancije proizvođača vozila i trajat će prema ugovorenom roku.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 8.</p>
                <p>Prodavatelj se obavezuje da će preuzeti odgovornost u slučaju otkrivanja skrivenih grešaka na vozilu koje nisu predviđene općim uvjetima poslovanja G1 prava na produženo auto jamstvo. Prodavatelj se obvezuje provjeriti ispravnost vozila i točnost navedenih i prikazanih prijeđenih kilometara te otkloniti sve nedostatke na vozilu ukoliko je potrebno prije aktiviranja prava na produženo auto jamstvo.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 9.</p>
                <p>Ugovor je sklopljen bez vremenskog ograničenja i svaka strana ga može raskinuti pismenim putem preporučenom poštom s povratnicom, a otkazni rok traje 30 dana od dana uručenja pismenog otkaza, osim u slučaju iz članka 11.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 10.</p>
                <p>Pravna osoba Točka na J koordinacija d.o.o. ima produkt Osnovnog jamstva s 4 posebna dodatka koji se mogu nadopuniti uz Osnovno jamstvo, a što je prepušteno na volju i odabir prodavatelju ili kupcu.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 11.</p>
                <p>Prodavatelj se obvezuje da će račun platiti u roku od petnaest dana (15) od dana njegovog zaprimanja, na IBAN firme Točka na J koordinacija d.o.o.</p>
                <p>Ukoliko prodavatelj ne plati račun najkasnije u roku od devedeset dana (90) od dana njegovog izdavanja ili pismenim putem ne zatraži odobrenje produženje roka plaćanja, pravna osoba Točka na J koordinacija d.o.o. ima pravo raskinuti ugovor u roku od tri (3) dana te o istom pismenim putem izvjestiti Prodavatelja kao i sve korisnike koji su pravo na G1 produženo auto jamstvo stekli kupovinom vozila kod navedenog prodavatelja.</p>
                <p>Raskidom ovog ugovora, sve obaveze prema korisnicima prava na G1 produženo auto jamstvo, koje su obuhvaćene neplaćenim računima, a koji su predmet i povod za raskid ovog ugovora, prelaze u obavezu Prodavatelja.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 12.</p>
                <p>Prodavatelj je suglasan da prihvaćanjem prvog izdanog računa od strane pravne osobe Točka na J koordinacija d.o.o. i unos u poslovne knjige Prodavatelja, ujedno znači i prihvaćanje svih odredbi ovog ugovora, bez obzira na potpis.</p>
                <p style={{ textAlign: "center", marginTop: "30px" }}>Članak 13.</p>
                <p>U slučaju spora nadležan je sud u mjestu koje je sjedište pravne osobe Točka na J Koordinacija d.o.o.</p>
                <p style={{ marginTop: "60px" }}>U ______________________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BROJ UGOVORA _____________________________________</p>
                <p style={{ marginTop: "60px", marginBottom: "80px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Naručitelj:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Izvršitelj:</p>
                    
                <BackButton />
            </div>
        </PublicFormLayout>
    )
}

export default SellerContract;