import React, { useEffect, useState } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { MarketingThisMonthSoldTotalWarranties } from '../../components/cards/MarketingThisMonthSoldTotalWarranties';
import { ISoldWarranties } from '../../types/ISoldWarranties';
import ReactGA from 'react-ga';

interface IStateToProps {
    marketingThisMonthTotalSoldWarranties: {
        lastMonth: number,
        thisMonth: number
    },
    marketingThisMonthTotalSoldWarrantiesLoading: boolean,
}

interface IDispatchToProps {
    getMarketingThisMonthSoldStatistics: (startDate: string, endDate: string) => void,

}

interface IProps extends IStateToProps, IDispatchToProps { }

const MarketingSoldOverview: React.FC<IProps> = props => {

    var date = new Date();
    const [firstDay, setFirstDay] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [lastDay, setLastDay] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));



    useEffect(() => {
        ReactGA.pageview("Prodana jamstva");
        props.getMarketingThisMonthSoldStatistics(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"))
    }, []);


    return (
        <React.Fragment>
            <Grid container direction="row" style={{ display: 'block' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        <MarketingThisMonthSoldTotalWarranties loading={props.marketingThisMonthTotalSoldWarrantiesLoading} thisMonthTotalSoldWarranties={props.marketingThisMonthTotalSoldWarranties} currentMonth={date.getMonth() + 1} />
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    marketingThisMonthTotalSoldWarranties: state.marketingThisMonthStatistics.thisMonth.totalSoldWarranties,
    marketingThisMonthTotalSoldWarrantiesLoading: state.marketingThisMonthStatistics.loading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMarketingThisMonthSoldStatistics: (startDate: string, endDate: string) => {
        dispatch({
            type: ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC,
            payload: { startDate, endDate }
        })
    }
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(MarketingSoldOverview);
