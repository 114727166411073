import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import ICarAdminTable from '../../types/ICarAdminTable';
import { GetCarsAdminOnlyCarsAction, DeactivateCarAction, ActivateCarAction, GetCarsAdminOnlyCarsTotalCountAction } from '../../redux/actions/CarAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';
import CarsAdminOnlyCarsTable from '../../components/tables/CarsAdminOnlyCarsTable';

interface IStateToProps {
    cars: ICarAdminTable[],
    totalCount: number
}

interface IDispatchToProps {
    getCarsOnlyCars: (page: number, pageSize: number, filters: string) => void,
    goToCarDetails: (carId: number) => void,
    deactivate: (carId: number) => void,
    activate: (carId: number) => void,
    getTotalCount: () => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const CarAdminOnlyCarsOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getCarsOnlyCars(1, 10, "");
        props.getTotalCount();
    }, []);

    return (props.cars ? <CarsAdminOnlyCarsTable rows={props.cars} goToCarDetails={props.goToCarDetails} deactivate={props.deactivate} activate={props.activate}
        totalCount={props.totalCount} getCarsOnlyCars={props.getCarsOnlyCars} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    cars: state.cars.data.admin,
    totalCount: state.carsAdminOnlyCarsTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getCarsOnlyCars: (page: number, pageSize: number, filters: string) => dispatch<GetCarsAdminOnlyCarsAction>({
        type: ActionTypes.GET_CARS_ADMIN_ONLY_CARS,
        payload: { page, pageSize, filters }
    }),
    getTotalCount: () => dispatch<GetCarsAdminOnlyCarsTotalCountAction>({
        type: ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT
    }),
    goToCarDetails: (carId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/car/' + carId + "/step=1" }
    }),
    deactivate: (carId: number) => dispatch<DeactivateCarAction>({
        type: ActionTypes.DEACTIVATE_CAR,
        payload: { carId }
    }),
    activate: (carId: number) => dispatch<ActivateCarAction>({
        type: ActionTypes.ACTIVATE_CAR,
        payload: { carId }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(CarAdminOnlyCarsOverview);