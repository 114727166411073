enum FormConstants {
  CAR_PURCHASE_FORM = "CAR PURCHASE FORM",
  DAMAGE_SUBMIT_FORM = "DAMAGE SUBMIT FORM",
  EMAIL_FORM = "EMAIL FORM",
  CONFIRM_PASSWORD_FORM = "CONFIRM PASSWORD FORM",
  MISSING_CAR_DETAILS_FORM = "MISSING CAR DETAILS FORM",
  EDIT_CAR_PORTA_DATA_FORM = "EDIT_CAR_PORTA_DATA_FORM",
}

export default FormConstants;
