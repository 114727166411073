
import { injectable, inject } from 'inversify';
import { AxiosInstance } from 'axios';
import IWaranntyService from './IWarrantyService';
import IWarranty from '../../types/IWarranty';
import ThirdPartyTypes from './../../di/ThirdPartyTypes';
import { warrantyApiURL, warrantyEditApiURL, warrantyDeleteApiURL, warrantyEditOnlyApiURL, warrantySaveOnlyApiURL } from '../../constants/apiRoutes';
import UserService from './UserService';
import IWarrantyOverview from '../../types/IWarrantyOverview';
import { IWarrantyBuyer } from '../../types/IWarrantyBuyer';
import ICarReview from "../../types/ICarReview";
import IWarrantyStatus from '../../types/IStatus';
import IActivateWarranty from '../../types/IActivateWarranty';

@injectable()
export default class WarrantyService implements IWaranntyService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;
    @inject(UserService)
    private userService!: UserService;

    public async saveWarranty(data: IWarranty): Promise<any> {
        const apiUrl = data.carId ? warrantyEditApiURL : warrantyApiURL;
        const response = await this.apiClient.post<IWarranty>(apiUrl, data)

        return response.data;
    }

    public async saveWarrantyOnly(data: IWarranty): Promise<any> {
        const apiUrl = (data.id && data.carData && !data.carData.newWarranty) ? warrantyEditOnlyApiURL : warrantySaveOnlyApiURL;
        const response = await this.apiClient.post<IWarranty>(apiUrl, data)

        return response.data;
    }

    public async deleteWarranty(data: IWarranty): Promise<any> {
        const apiUrl = data.id ? warrantyDeleteApiURL : warrantyApiURL;
        const response = await this.apiClient.post<IWarranty>(apiUrl, data)

        return response.data;
    }

    public async changeSeller(data: IWarranty): Promise<any> {
        const response = await this.apiClient.post<IWarranty>(warrantyApiURL + '/changeSeller', data)

        return response.data;
    }

    public async changeBuyer(data: IWarrantyBuyer): Promise<any> {
        const response = await this.apiClient.post<IWarrantyBuyer>(warrantyApiURL + '/changeBuyer', data)

        return response.data;
    }

    public async getWarranties(): Promise<IWarrantyOverview[]> {
        const response = await this.apiClient.get<IWarrantyOverview[]>(warrantyApiURL)
        return response.data;
    }

    
    public async getStatuses(): Promise<IWarrantyStatus[]> {
        const response = await this.apiClient.get<IWarrantyStatus[]>(warrantyApiURL + '/statuses');
        return response.data;
    }

    public async updateWarranty(data: IWarranty): Promise<any> {
        const response = await this.apiClient.post<IWarranty>(warrantyApiURL + '/edit/status', data);
        return response.data;
    }

    public async getWarranty(id: number): Promise<any> {
        const response = await this.apiClient.get<IWarranty>(warrantyApiURL + '/' + id);
        return response.data;
    }

    public async getWarrantyForContract(data: IActivateWarranty): Promise<any> {
        const response = await this.apiClient.get<IWarranty>(warrantyApiURL + "/contract/" + data.email + "/" + data.warrantyId);
        return response.data;
    }

    public async changeValidDate(warrantyId: string, validFrom: string, validUntil: string): Promise<null> {
        const response = await this.apiClient.post(warrantyApiURL + "/changeValidDate/" + warrantyId + "/" + validFrom + "/" + validUntil);
        return response.data;
    }

    public async sendWarrantyEmail(warrantyId: string): Promise<null> {
        const response = await this.apiClient.post(warrantyApiURL + "/sendWarrantyEmail/" + warrantyId);
        return response.data;
    }

    public async changeWarrantyDuration(data: IWarranty): Promise<any> {
        const response = await this.apiClient.post<IWarranty>(warrantyApiURL + '/changeWarrantyDuration', data)

        return response.data;
    }

}