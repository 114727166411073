import React, { useEffect, useState } from 'react';
import AdminArchiveWarrantyTable from '../../components/tables/AdminArchiveWarrantyTable'
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import IAdminAttrs from './../../types/IAdminAttrs'
import { connect } from 'react-redux';
import { GetAdminArchiveTableData, GetWarrantyAdminArchiveTableTotalCountAction } from '../../redux/actions/WarrantyAction';
import { Grid } from '@material-ui/core';
import style from "./../../components/warrantyReview/warrantyView.module.scss"
import ICarData from '../../types/ICarData';
import { GetCarDataAction } from '../../redux/actions/CarAction';
import { TotalArchiveWarranties } from '../../components/cards/totalArchiveWarranties';
import ReactGA from 'react-ga';
import IMissingCarDetailsProps from '../../types/IMissingCarDetailsProps';
import IBuyerProps from '../../types/IBuyerProps';


interface IStateToProps {
    archiveWarranties: { data: IAdminAttrs[], loading: boolean },
    carData: ICarData,
    buyer: IBuyerProps,
    isCarDetailsMissing: boolean,
    totalCount: number
}

interface IDispatchToProps {
    getAdminArchiveTableData: (page: number, pageSize: number, filters: string) => void
    getCarData: () => void
    viewWarranty: (warrantyId: number, carId: number) => void
    editWarranty: (warrantyId: number, carId: number) => void,
    goToDamagePage: (carId: number) => void,
    getBuyerByOIB: (oib: string) => void,
    getBuyerData: (carId: number, warrantyId: number) => void,
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void,
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => void,
    getTotalCount: () => void,
}

interface IProps extends IStateToProps, IDispatchToProps { }

const ArchiveWarrantiesOverview: React.FC<IProps> = props => {

    useEffect(() => {
        ReactGA.pageview("Admin | sva jamstva");
        props.getAdminArchiveTableData(1, 10, "");
        props.getCarData();
        props.getTotalCount();
    }, []);

    const goToEditWarranty = (overviewData: IAdminAttrs) => {
        props.editWarranty(overviewData.warranty.warrantyId, overviewData.warranty.carId)
    }

    const viewWarranty = (dataRow: IAdminAttrs) => {
        props.viewWarranty(dataRow.warranty.warrantyId, dataRow.warranty.carId)
    }

    return (
        <React.Fragment>
            <Grid container direction="row" style={{ maxWidth: 'max-content', display: 'block' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        <TotalArchiveWarranties totalArchiveWarranties={props.totalCount} />
                    </Grid>
                </Grid>
                <AdminArchiveWarrantyTable  {...props} viewWarranty={viewWarranty} goToDamagePage={props.goToDamagePage} goToEditWarranty={goToEditWarranty} rows={props.archiveWarranties}
                    totalCount={props.totalCount} getAdminArchiveTableData={props.getAdminArchiveTableData}/>
            </Grid>

        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    archiveWarranties: state.getAdminArchiveTableData,
    carData: state.carData.data,
    buyer: state.carBuyer,
    isCarDetailsMissing: state.isCarDetailsMissing.isCarDetailsMissing,
    totalCount: state.warrantyAdminArchiveTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getAdminArchiveTableData: (page: number, pageSize: number, filters: string) => dispatch<GetAdminArchiveTableData>({
        type: ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA,
        payload: { page, pageSize, filters }
    }),
    getCarData: () => dispatch<GetCarDataAction>({
        type: ActionTypes.GET_CAR_DATA
    }),
    getTotalCount: () => dispatch<GetWarrantyAdminArchiveTableTotalCountAction>({
        type: ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT
    }),
    editWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    viewWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    goToDamagePage: (carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/damage/' + carId }
        })
    },
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => dispatch({
        type: ActionTypes.EDIT_MISSING_CAR_DETAILS,
        payload: missingCarDetails
    }),
    getBuyerData: (carId: number, warrantyId: number) => dispatch({
        type: ActionTypes.GET_BUYER_BY_CAR_ID,
        payload: { carId, warrantyId }
    }),
    getBuyerByOIB: (oib: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_OIB,
        payload: oib
    }),
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => dispatch({
        type: ActionTypes.SET_IS_MISSING_CAR_DETAILS,
        payload: { isCarDetailsMissing: isCarDetailsMissing }
    }),

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(ArchiveWarrantiesOverview);