import React, { useEffect } from 'react';
import AdminSellerWarrantyTable from '../../components/tables/AdminSellerWarrantyTable'
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import IAdminAttrs from './../../types/IAdminAttrs'
import { connect } from 'react-redux';
import { GetWarrantyForSellerAction, GetWarrantyAdminSellerTableTotalCountAction } from '../../redux/actions/WarrantyAction';
import { GetSellerNameAction } from '../../redux/actions/DamageAction';
import { Grid } from '@material-ui/core';
import ICarData from '../../types/ICarData';
import { GetCarDataAction } from '../../redux/actions/CarAction';
import ReactGA from 'react-ga';
import IMissingCarDetailsProps from '../../types/IMissingCarDetailsProps';
import IBuyerProps from '../../types/IBuyerProps';
import ISellerProps from '../../types/ISellerProps';

interface IStateToProps {
    allWarranties: { data: IAdminAttrs[], loading: boolean },
    carData: ICarData,
    buyer: IBuyerProps,
    isCarDetailsMissing: boolean,
    sellerName: string
}


interface IDispatchToProps {
    getWarrantiesForSeller: (id: string) => void
    getCarData: () => void
    viewWarranty: (warrantyId: number, carId: number) => void
    editWarranty: (warrantyId: number, carId: number) => void,
    goToDamagePage: (carId: number, warrantyId: number) => void,
    sellCar: (buyer: IBuyerProps) => void,
    getBuyerByOIB: (oib: string) => void,
    getBuyerByEmail: (email: string) => void,
    getBuyerData: (carId: number, warrantyId: number) => void,
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void,
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => void,
    getSellerName: (id: string) => void,
    getTotalCount: (id: string) => void,
}

interface IProps extends IStateToProps, IDispatchToProps {
    match: {
        params: {
            id: string,
        }
    }
}

const WarrantiesForSeller: React.FC<IProps> = props => {

    useEffect(() => {
        const { id } = props.match.params;
        ReactGA.pageview("Admin | jamstva za trgovca");
        props.getWarrantiesForSeller(id);
        props.getCarData();
        props.getSellerName(id);
    }, []);

    const goToEditWarranty = (overviewData: IAdminAttrs) => {
        props.editWarranty(overviewData.warranty.warrantyId, overviewData.warranty.carId)
    }

    const viewWarranty = (dataRow: IAdminAttrs) => {
        props.viewWarranty(dataRow.warranty.warrantyId, dataRow.warranty.carId)
    }

    return (
        <React.Fragment>
            <Grid container direction="row" style={{ maxWidth: 'max-content', display: 'block' }}>
                <AdminSellerWarrantyTable  {...props} viewWarranty={viewWarranty} goToDamagePage={props.goToDamagePage}
                    goToEditWarranty={goToEditWarranty} rows={props.allWarranties} sellerName={props.sellerName} />
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    allWarranties: state.getAdminTableData,
    carData: state.carData.data,
    buyer: state.carBuyer,
    isCarDetailsMissing: state.isCarDetailsMissing.isCarDetailsMissing,
    sellerName: state.sellerName.data
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getWarrantiesForSeller: (id: string) => dispatch<GetWarrantyForSellerAction>({
        type: ActionTypes.GET_WARRANTIES_FOR_SELLER,
        payload: { id }
    }),
    getCarData: () => dispatch<GetCarDataAction>({
        type: ActionTypes.GET_CAR_DATA
    }),
    getTotalCount: (id: string) => dispatch<GetWarrantyAdminSellerTableTotalCountAction>({
        type: ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT,
        payload: id
    }),
    editWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    viewWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    goToDamagePage: (carId: number, warrantyId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/damage/' + carId + "/" + warrantyId}
        })
    },
    sellCar: (buyer: IBuyerProps) => dispatch({
        type: ActionTypes.SELL_CAR,
        payload: buyer
    }),
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => dispatch({
        type: ActionTypes.EDIT_MISSING_CAR_DETAILS,
        payload: missingCarDetails
    }),
    getBuyerData: (carId: number, warrantyId: number) => dispatch({
        type: ActionTypes.GET_BUYER_BY_CAR_ID,
        payload: { carId, warrantyId }
    }),
    getBuyerByOIB: (oib: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_OIB,
        payload: oib
    }),
    getBuyerByEmail: (email: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_EMAIL,
        payload: email
    }),
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => dispatch({
        type: ActionTypes.SET_IS_MISSING_CAR_DETAILS,
        payload: { isCarDetailsMissing: isCarDetailsMissing }
    }),
    getSellerName: (id: string) => dispatch<GetSellerNameAction>({
        type: ActionTypes.GET_SELLER_NAME,
        payload: { id }
    }),

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(WarrantiesForSeller);