import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import IDamageSellerByAdminTable from '../../types/Damage/IDamageSellerByAdminTable';
import DamagesSellerByAdminTable from '../../components/tables/DamagesSellerByAdminTable';
import { GetDamagesSellerByAdminAction, GetSellerNameAction } from '../../redux/actions/DamageAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    damages: IDamageSellerByAdminTable[],
    sellerName: string
}

interface IDispatchToProps {
    getDamages: (id: string) => void,
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => void,
    getSellerName: (id: string) => void
}

interface IProps extends IStateToProps, IDispatchToProps {
    match: {
        params: {
            id: string
        }
    }
}

const DamageSellerByAdminOverview: React.FC<IProps> = props => {
    useEffect(() => {
        const { id } = props.match.params;
        props.getDamages(id);
        props.getSellerName(id);
    }, []);

    return (props.damages ? <DamagesSellerByAdminTable rows={props.damages} goToDamagePage={props.goToDamagePage} sellerName={props.sellerName} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damages: state.damages.data.sellerByAdmin,
    sellerName: state.sellerName.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getDamages: (id: string) => dispatch<GetDamagesSellerByAdminAction>({
        type: ActionTypes.GET_DAMAGES_SELLERBYADMIN,
        payload: { id }
    }),
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: `/damage/${carId}/${warrantyId}/${damageId}` }
    }),
    getSellerName: (id: string) => dispatch<GetSellerNameAction>({
        type: ActionTypes.GET_SELLER_NAME,
        payload: { id }
    }),

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(DamageSellerByAdminOverview);