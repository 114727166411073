import React, { useEffect } from 'react';
import { DamageForm } from '../../components/forms/damageForm/damageForm';
import { IApplicationState } from './../../types/applicationState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IDamageProps } from '../../types/Damage/IDamageProps';
import { GetDamageStatusesAction, SaveDamageAction, GetActiveDamageAction } from './../../redux/actions/DamageAction';
import ActionTypes from './../../redux/actions/ActionTypes';
import IStatus from './../../types/IStatus';
import { LinearProgress } from '@material-ui/core';
import { ShowNotificationAction } from '../../redux/actions/ActionNotificationAction';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';

interface IStateToProps {
    damage: IDamageProps,
    statuses: IStatus[]
}

interface IDispatchToProps {
    getActiveDamage: (carId: number, warrantyId: number) => void,
    getDamageStatuses: () => void,
    saveDamage: (data: IDamageProps) => void,
    fileNotUploadedMessage: () => void
}

interface IProps extends IDispatchToProps, IStateToProps {
    match: {
        params: {
            carId: number,
            warrantyId: number
        }
    }
}

const NewDamagePage: React.FC<IProps> = (props: IProps) => {
    useEffect(() => {
        const { carId, warrantyId } = props.match.params;

        props.getActiveDamage(carId, warrantyId);

        props.getDamageStatuses();
    }, []);

    const saveDamage = (data: IDamageProps) => {
        props.saveDamage({ ...data, carId: props.match.params.carId, warrantyId: props.match.params.warrantyId })
    }

    const dataLoaded = props.damage && props.damage.buyer && props.statuses && props.statuses.length;

    return (dataLoaded ?
        <DamageForm fileNotUploadedMessage={props.fileNotUploadedMessage} {...props.damage} carId={props.match.params.carId} submit={saveDamage} statuses={props.statuses} />
        : <div><LinearProgress style={{ flexGrow: 1 }} ></LinearProgress></div>

    )
}


const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damage: state.carDamage.damage,
    statuses: state.carDamage.statuses
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getActiveDamage: (carId: number, warrantyId: number) => dispatch<GetActiveDamageAction>({
        type: ActionTypes.GET_ACTIVE_DAMAGE,
        payload: { carId, warrantyId }
    }),
    getDamageStatuses: () => dispatch<GetDamageStatusesAction>({
        type: ActionTypes.GET_DAMAGE_STATUSES
    }),
    saveDamage: (data: IDamageProps) => dispatch<SaveDamageAction>({
        type: ActionTypes.SAVE_DAMAGE,
        payload: data
    }),
    fileNotUploadedMessage: () => dispatch<ShowNotificationAction>({
        type: ActionTypes.NOTIFICATION_SHOW,
        payload: {
            message: 'Molimo učitajte dokument',
            style: NotificationStyle.Error
        }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(NewDamagePage); 
