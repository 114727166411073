import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { BuyerRegistrationForm } from '../../components/forms/buyerForm/BuyerRegistrationForm'
import IBuyerProps from "./../../types/IBuyerProps";
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { GetBuyerByIdAction, SendActivationEmailBuyerAction, GetLegalBuyersAction } from './../../redux/actions/BuyerAction';
import { connect } from 'react-redux';
import { URLHelper } from './../../utils/URLHelper';
import ReactGA from 'react-ga';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface IProps extends IDispatchToProps, IStateToProps { }

interface IDispatchToProps {
    saveBuyer: (buyer: IBuyerProps) => void,
    deleteBuyer: (buyer: IBuyerProps) => void,
    activateBuyer: (buyer: IBuyerProps) => void,
    getBuyer: (buyerId: string) => void,
    sendActivationMail: (email: string) => void,
    getLegalBuyers: () => void,
}

interface IStateToProps {
    buyer: IBuyerProps,
    legalBuyers: IBuyerProps[],
}

const BuyerOverview: React.FC<IProps> = (props: IProps) => {

    useEffect(() => {
        ReactGA.pageview("Admin | registracija kupca");
        if (URLHelper.getBuyerId() !== 'create_buyer')
            props.getBuyer(URLHelper.getBuyerId()!);
        props.getLegalBuyers();

    }, []);

    const handleSubmit = (buyer: IBuyerProps) => {
        props.saveBuyer(buyer);
    }

    const handleDelete = (buyer: IBuyerProps) => {
        props.deleteBuyer(buyer);
    }

    const handleActivate = (buyer: IBuyerProps) => {
        props.activateBuyer(buyer);
    }

    return (

        <BuyerRegistrationForm
            submit={handleSubmit}
            delete={handleDelete}
            activate={handleActivate}
            buyer={props.buyer}
            {...props.buyer}
            getBuyerById={props.getBuyer}
            sendActivationMail={props.sendActivationMail}
            isEditable={URLHelper.getBuyerId() !== 'create_buyer'}
            legalBuyers={props.legalBuyers} />)

}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    buyer: state.buyer.buyer,
    legalBuyers: state.legalBuyers.data
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveBuyer: (buyer: IBuyerProps) => dispatch({
        type: ActionTypes.SAVE_BUYER,
        payload: { buyer }
    }),
    deleteBuyer: (buyer: IBuyerProps) => dispatch({
        type: ActionTypes.DELETE_BUYER,
        payload: { buyer }
    }),
    activateBuyer: (buyer: IBuyerProps) => dispatch({
        type: ActionTypes.ACTIVATE_BUYER,
        payload: { buyer }
    }),
    getBuyer: (buyerId: string) => dispatch<GetBuyerByIdAction>({
        type: ActionTypes.GET_BUYER,
        payload: buyerId
    }),
    sendActivationMail: (email: string) => dispatch<SendActivationEmailBuyerAction>({
        type: ActionTypes.SEND_ACTIVATION_EMAIL_BUYER,
        payload: { email }
    }),
    getLegalBuyers: () => dispatch<GetLegalBuyersAction>({
        type: ActionTypes.GET_LEGAL_BUYERS
    }),
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(BuyerOverview);