import React, { useEffect, useState } from 'react';
import CarStepper from '../../components/stepper/CarStepper';
import ICarProps from '../../types/ICarProps';
import { Dispatch } from 'redux';
import {
    PostCarAction, GetCarAction, PostCarOnlyAction, GetCarDataAction, GetModelsByBrandAction, ReviewCar,
    CurrentCarReview, GetCarProblemExtraPackageAction, ChangeSellerEmailAction, GetChassisCarAction,
    GetCarsDropdownAdminAction, GetCarsDropdownSellerAction, SellCarWithoutWarrantyAction, PostCarOnlyAndContinueAction
} from '../../redux/actions/CarAction';
import ActionTypes from '../../redux/actions/ActionTypes';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import ISelectedPackage from '../../types/ISelectedPackage';
import IWarrantyData from '../../types/IWarranty';
import {
    SaveWarrantyAction, SaveWarrantyOnlyAction, DeleteWarrantyAction, UpdateWarrantyStatusAction, GetWarrantyAction,
    ChangeValidDateAction, SendWarrantyEmailAction, ChangeSellerAction, ChangeBuyerAction, ChangeWarrantyDurationAction
} from '../../redux/actions/WarrantyAction';
import { SaveSelectedPackageAction, GetPackagesAction } from '../../redux/actions/PackagesAction';
import ICarData from '../../types/ICarData';
import { GetWarrantyStatusesDataAction } from './../../redux/actions/WarrantyAction';
import IWarrantyStatus from '../../types/IStatus';
import IWarranty from '../../types/IWarranty';
import { IWarrantyBuyer } from '../../types/IWarrantyBuyer';
import IUrl from './../../types/IUrl';
import IPhotoUploadProps from '../../types/IPhotoUploadProps';
import { SaveCarPhotosAction } from './../../redux/actions/CarPhotosAction';
import GoToPageAction from './../../redux/actions/GoToPageAction';
import ICarReview from '../../types/ICarReview';
import { StepperHelper } from '../../utils/StepperHelper';
import { isAdmin, isSuperAdmin } from '../../utils/AuthFunctions';
import IPackageSelection from '../../types/IPackageSelection';
import ISellerProps from '../../types/ISellerProps';
import { GetAllSellersAction } from '../../redux/actions/SellerAction';
import { Paper, Button, Grid, Fab, Box, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { GetChassisDamageAction } from './../../redux/actions/DamageAction';
import { IDamageProps } from '../../types/Damage/IDamageProps';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';
import ICar from '../../types/ICar';

interface IStateToProps {
    postCar: ICarProps,
    selectedPackage: ISelectedPackage,
    selectedExtraPackages: { data: ISelectedPackage[] },
    packagesData: IPackageSelection,
    carData: ICarData,
    warrantyStatuses: IWarrantyStatus[],
    carWarranty: IWarranty,
    currentCar: ICarProps,
    carPhotos: IPhotoUploadProps,
    carReview: ICarReview,
    history?: any,
    sellers: ISellerProps[],
    cars: ICarProps[],
    chassisDamage: IDamageProps,
    chassisCar: ICarProps,
    carProblemExtraPackages: { data: ICarProblemExtraPackage[] }
}

interface IDispatchToProps {
    saveCar: (data: ICarProps) => void,
    saveCarOnly: (data: ICar) => void,
    saveCarOnlyAndContinue: (data: ICar) => void,
    sellCarWithoutWarranty: (data: ICar) => void,
    saveSelectedPackage: (data: ISelectedPackage) => void,
    saveExtraSelectedPackages: (data: ISelectedPackage) => void,
    removeExtraPackage: (data: ISelectedPackage) => void,
    saveWarranty: (data: IWarranty) => void,
    saveWarrantyOnly: (data: IWarranty) => void,
    deleteWarranty: (data: IWarranty) => void,
    changeSeller: (data: IWarranty) => void,
    changeBuyer: (data: IWarrantyBuyer) => void,
    getCarData: () => void,
    getWarrantyStatusesData: () => void,
    updateWarrantyStatus: (data: IWarranty, isReview: boolean) => void,
    getModelsByBrand?: (brandId: number) => void,
    getWarranty: (id: number) => void,
    getCar: (id: number) => void,
    savePhotos: (data: IPhotoUploadProps) => void,
    reviewCar: (data: ICarReview) => void,
    getCarReview: (carId: number) => void,
    clearExtraPackagesSelection: () => void,
    getAllSellers: () => void,
    getCarsAdmin: () => void,
    getCarsSeller: () => void,
    goToImportCars: () => void,
    getChassisDamage?: (chassisNumber: string) => void,
    getChassisCar?: (chassisNumber: string) => void,
    changeSellerEmail?: (chassisNumber: string) => void,
    getCarProblemExtraPackage?: (car: ICarProps) => void,
    changeValidDate: (warrantyId: string, validFrom: string, validUntil: string) => void,
    sendWarrantyMail: (warrantyId: string) => void,
    changeWarrantyDuration: (data: IWarranty) => void,
}


interface IProps extends IDispatchToProps, IStateToProps, IUrl {
}

const CarFormPage: React.FC<IProps> = (props: IProps) => {

    const [carId, setCarId] = useState<number | undefined>();
    const [step, setStep] = useState<number | undefined>();

    useEffect(() => {
        let carId = StepperHelper.getCarIdFromUrl();
        const step = StepperHelper.getStep();

        if(StepperHelper.containsWarranty()){
            carId = StepperHelper.getCarIdFromExpandedUrl();
        };

        setCarId(carId);
        setStep(step);

        const isCurrentCarNull = !props.currentCar || (props.currentCar && Object.keys(props.currentCar).length === 0
            && Object.getPrototypeOf(props.currentCar) === Object.prototype)

        if (isCurrentCarNull // new warranty creation
            // ||  request for warranty with id in URL
            // ||  if URL still is not changed, but props contains warranty ID
            || (StepperHelper.isInitialUpgrade() && props.packagesData.packages.length === 0)) { // if warranty is upgrading
            props.clearExtraPackagesSelection();
            props.getCarData();
            props.getWarrantyStatusesData();
            props.getAllSellers();
            props.getCar(carId!);
            if ((isAdmin() || isSuperAdmin())) {
                props.getCarsAdmin()
            } else {
                props.getCarsSeller()
            }

        }
    }, [props.match]);

    useEffect(() => {
        let warrantyId = undefined;
        
        if(StepperHelper.containsWarranty() && props.postCar && !props.postCar.newWarranty){
            warrantyId = StepperHelper.getWarrantyIdFromExpandedUrl();
        };

        if (!props.carWarranty // new warranty creation
            || (warrantyId != props.carWarranty.id) // request for warranty with id in URL
            || (warrantyId === undefined && props.carWarranty && props.carWarranty.id) // if URL still is not changed, but props contains warranty ID
            || (StepperHelper.isInitialUpgrade() && props.packagesData.packages.length === 0)) { // if warranty is upgrading
            props.clearExtraPackagesSelection();
            props.getWarranty(warrantyId!);
            props.getCarData();
            props.getWarrantyStatusesData();
        }
    }, [props.match]);

    useEffect(() => {

    }, [props.chassisDamage]);
    if (StepperHelper.isUpgradeMode()) {
        return props.packagesData.packages.length > 0 ? <CarStepper
            // @ts-ignore
            history={props.history} step={StepperHelper.getStep()} carReview={props.carReview}
            // @ts-ignore
            reviewCar={props.reviewCar} getCarReview={props.getCarReview}  {...props.carData} sellers={props.sellers} postCar={carId && step == 1 ? props.currentCar : props.postCar} cars={props.cars}
            // @ts-ignore
            saveCar={props.saveCar} getModelsByBrand={props.getModelsByBrand} postPhotoUpload={props.carPhotos} sendWarrantyMail={props.sendWarrantyMail}
            // @ts-ignore
            savePhotos={props.savePhotos} selectedPackage={props.selectedPackage} saveSelectedPackage={props.saveSelectedPackage} saveCarOnly={props.saveCarOnly} saveCarOnlyAndContinue={props.saveCarOnlyAndContinue}
            // @ts-ignore
            packagesData={props.packagesData} saveExtraSelectedPackages={props.saveExtraSelectedPackages} removeExtraPackage={props.removeExtraPackage} currentCar={props.currentCar}
            // @ts-ignore
            selectedExtraPackages={props.selectedExtraPackages} saveWarranty={props.saveWarranty} saveWarrantyOnly={props.saveWarrantyOnly} deleteWarranty={props.deleteWarranty} currentWarranty={props.carWarranty}
            // @ts-ignore
            warrantyStatuses={props.warrantyStatuses} updateWarrantyStatus={props.updateWarrantyStatus} getChassisDamage={props.getChassisDamage} chassisDamage={props.chassisDamage}
            // @ts-ignore
            getCarProblemExtraPackage={props.getCarProblemExtraPackage} carProblemExtraPackages={props.carProblemExtraPackages} changeValidDate={props.changeValidDate}
            // @ts-ignore
            getChassisCar={props.getChassisCar} changeSellerEmail={props.changeSellerEmail} chassisCar={props.chassisCar} sellCarWithoutWarranty={props.sellCarWithoutWarranty}
            // @ts-ignore
            changeSeller={props.changeSeller} changeBuyer={props.changeBuyer} changeWarrantyDuration={props.changeWarrantyDuration}
        />
            : <div />;
    } else {
        return <div style={{ width: '100%' }}>
            {(isAdmin() || isSuperAdmin()) && <Fab style={{ marginBottom: 15, float: 'right' }} onClick={() => props.goToImportCars()} variant="extended" color="primary" aria-label="delete">
                Uvezi vozila
                <AddIcon />
            </Fab>}
            <CarStepper
                // @ts-ignore
                history={props.history} step={StepperHelper.getStep()} carReview={props.carReview} sellCarWithoutWarranty={props.sellCarWithoutWarranty}
                // @ts-ignore
                reviewCar={props.reviewCar} getCarReview={props.getCarReview} {...props.carData} sellers={props.sellers} cars={props.cars}
                // @ts-ignore
                postCar={carId && step == 1 ? props.currentCar : props.postCar} saveCar={props.saveCar} getModelsByBrand={props.getModelsByBrand}
                // @ts-ignore
                postPhotoUpload={props.carPhotos} savePhotos={props.savePhotos} selectedPackage={props.selectedPackage} currentCar={props.currentCar}
                // @ts-ignore
                selectedExtraPackages={props.selectedExtraPackages} saveSelectedPackage={props.saveSelectedPackage} saveCarOnly={props.saveCarOnly} saveCarOnlyAndContinue={props.saveCarOnlyAndContinue}
                // @ts-ignore
                saveExtraSelectedPackages={props.saveExtraSelectedPackages} removeExtraPackage={props.removeExtraPackage} sendWarrantyMail={props.sendWarrantyMail}
                // @ts-ignore
                packagesData={props.packagesData} saveWarranty={props.saveWarranty} deleteWarranty={props.deleteWarranty} currentWarranty={props.carWarranty}
                // @ts-ignore
                warrantyStatuses={props.warrantyStatuses} updateWarrantyStatus={props.updateWarrantyStatus} getChassisDamage={props.getChassisDamage} chassisDamage={props.chassisDamage}
                // @ts-ignore
                getCarProblemExtraPackage={props.getCarProblemExtraPackage} carProblemExtraPackages={props.carProblemExtraPackages} changeValidDate={props.changeValidDate}
                // @ts-ignore
                getChassisCar={props.getChassisCar} changeSellerEmail={props.changeSellerEmail} chassisCar={props.chassisCar} saveWarrantyOnly={props.saveWarrantyOnly}
                // @ts-ignore
                changeSeller={props.changeSeller} changeBuyer={props.changeBuyer} changeWarrantyDuration={props.changeWarrantyDuration}
            />
        </div>
    }

}


const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    postCar: state.postCar,
    selectedPackage: state.selectedPackage,
    selectedExtraPackages: state.selectedExtraPackages,
    packagesData: state.packagesData.data,
    carData: state.carData.data,
    warrantyStatuses: state.warrantyStatuses,
    carWarranty: state.carWarranty,
    currentCar: state.currentCar,
    carPhotos: state.carPhotos,
    carReview: state.carReview,
    sellers: state.sellers.data,
    chassisDamage: state.carDamage.damage,
    chassisCar: state.chassisCar,
    carProblemExtraPackages: state.carProblemExtraPackages,
    cars: state.carsDropdown.data

})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    saveCar: (data: ICarProps) => {
        dispatch<PostCarAction>({
            type: ActionTypes.POST_CAR_DATA,
            payload: data
        })
    },
    saveCarOnly: (data: ICar) => {
        dispatch<PostCarOnlyAction>({
            type: ActionTypes.POST_CAR_ONLY,
            payload: data
        })
    },
    saveCarOnlyAndContinue: (data: ICar) => {
        dispatch<PostCarOnlyAndContinueAction>({
            type: ActionTypes.POST_CAR_ONLY_AND_CONTINUE,
            payload: data
        })
        dispatch<GetPackagesAction>({
            type: ActionTypes.GET_PACKAGES,
            payload: data.carData!
        })
        dispatch<GetPackagesAction>({
            type: ActionTypes.GET_EXTRA_PACKAGES,
            payload: data.carData!
        })
    },
    sellCarWithoutWarranty: (data: ICar) => {
        dispatch<SellCarWithoutWarrantyAction>({
            type: ActionTypes.SELL_CAR_WITHOUT_WARRANTY,
            payload: data
        })
    },
    saveSelectedPackage: (data: ISelectedPackage) => dispatch<SaveSelectedPackageAction>({
        type: ActionTypes.SAVE_PACKAGE,
        payload: data
    }),
    saveExtraSelectedPackages: (data: ISelectedPackage) => dispatch<SaveSelectedPackageAction>({
        type: ActionTypes.SAVE_EXTRA_PACKAGE,
        payload: data
    }),
    removeExtraPackage: (data: ISelectedPackage) => dispatch<SaveSelectedPackageAction>({
        type: ActionTypes.REMOVE_EXTRA_PACKAGE,
        payload: data
    }),
    saveWarranty: (data: IWarrantyData) => dispatch<SaveWarrantyAction>({
        type: ActionTypes.SAVE_WARRANTY,
        payload: data
    }),
    saveWarrantyOnly: (data: IWarrantyData) => dispatch<SaveWarrantyOnlyAction>({
        type: ActionTypes.SAVE_WARRANTY_ONLY,
        payload: data
    }),
    deleteWarranty: (data: IWarrantyData) => dispatch<DeleteWarrantyAction>({
        type: ActionTypes.DELETE_WARRANTY,
        payload: data
    }),
    changeSeller: (data: IWarrantyData) => dispatch<ChangeSellerAction>({
        type: ActionTypes.CHANGE_SELLER,
        payload: data
    }),
    changeBuyer: (data: IWarrantyBuyer) => dispatch<ChangeBuyerAction>({
        type: ActionTypes.CHANGE_BUYER,
        payload: data
    }),
    getCarData: () => dispatch<GetCarDataAction>({
        type: ActionTypes.GET_CAR_DATA
    }),
    getAllSellers: () => dispatch<GetAllSellersAction>({
        type: ActionTypes.GET_SELLERS
    }),
    getCarsAdmin: () => dispatch<GetCarsDropdownAdminAction>({
        type: ActionTypes.GET_CARS_DROPDOWN_ADMIN
    }),
    getCarsSeller: () => dispatch<GetCarsDropdownSellerAction>({
        type: ActionTypes.GET_CARS_DROPDOWN_SELLER
    }),
    getWarrantyStatusesData: () => dispatch<GetWarrantyStatusesDataAction>({
        type: ActionTypes.GET_WARRANTY_STATUSES
    }),
    updateWarrantyStatus: (data: IWarranty, isReview: boolean) => {
        !isReview ? dispatch<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/jamstva' }
        })
            : dispatch<UpdateWarrantyStatusAction>({
                type: ActionTypes.UPDATE_WARRANTY_STATUS,
                payload: data
            })
    },
    getWarranty: (id: number) => dispatch<GetWarrantyAction>({
        type: ActionTypes.GET_WARRANTY,
        payload: id
    }),
    getCar: (id: number) => dispatch<GetCarAction>({
        type: ActionTypes.GET_CAR,
        payload: id
    }),
    getModelsByBrand: (id: number) => dispatch<GetModelsByBrandAction>({
        type: ActionTypes.GET_CAR_MODELS,
        payload: id
    }),
    savePhotos: (data: IPhotoUploadProps) => {
        dispatch<SaveCarPhotosAction>({
            type: ActionTypes.SAVE_PHOTOS,
            payload: data
        })
    },
    reviewCar: (data: ICarReview) => {
        dispatch<ReviewCar>({
            type: ActionTypes.REVIEW_CAR,
            payload: data
        })
    },
    getCarReview: (carId: number) => {
        dispatch<CurrentCarReview>({
            type: ActionTypes.GET_CURRENT_CAR_REVIEW,
            payload: carId
        })
    },
    clearExtraPackagesSelection: () => {
        dispatch<any>({
            type: ActionTypes.CLEAR_SELECTED_EXTRA_PACKAGES,
        })
    },
    goToImportCars: () => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/uvoz' }
    }),
    getChassisDamage: (chassisNumber: string) => dispatch<GetChassisDamageAction>({
        type: ActionTypes.GET_CHASSIS_DAMAGE,
        payload: { chassisNumber }
    }),
    getChassisCar: (chassisNumber: string) => dispatch<GetChassisCarAction>({
        type: ActionTypes.GET_CHASSIS_CAR,
        payload: { chassisNumber }
    }),
    changeSellerEmail: (chassisNumber: string) => dispatch<ChangeSellerEmailAction>({
        type: ActionTypes.CHANGE_SELLER_EMAIL,
        payload: { chassisNumber }
    }),
    getCarProblemExtraPackage: (car: ICarProps) => {
        dispatch<GetCarProblemExtraPackageAction>({
            type: ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE,
            payload: car
        })
    },
    changeValidDate: (warrantyId: string, validFrom: string, validUntil: string) => dispatch<ChangeValidDateAction>({
        type: ActionTypes.CHANGE_VALID_DATE,
        payload: { warrantyId, validFrom, validUntil }
    }),
    sendWarrantyMail: (warrantyId: string) => dispatch<SendWarrantyEmailAction>({
        type: ActionTypes.SEND_WARRANTY_EMAIL,
        payload: { warrantyId }
    }),
    changeWarrantyDuration: (data: IWarrantyData) => dispatch<ChangeWarrantyDurationAction>({
        type: ActionTypes.CHANGE_WARRANTY_DURATION,
        payload: data
    }),
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(CarFormPage);
