import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import { IStatisticsWarranties } from "../../types/IStatisticsWarranties";
import StatisticsWarrantiesTable from "./../../components/tables/StatisticsWarrantiesTable";
import {
    GetStatisticsMarketingWarrantiesTableAction, GetStatisticsWarrantiesTableAction
} from "./../../redux/actions/StatisticsAction";
import ReactGA from "react-ga";

interface IStateToProps {
    data: IStatisticsWarranties[];
}

interface IDispatchToProps {
    getTable: () => void;
}

interface IProps extends IDispatchToProps, IStateToProps { }

const StatisticsWarrantiesOverview: React.FC<IProps> = (props) => {
    useEffect(() => {
        ReactGA.pageview("Admin | pregled statistike Njuškalo jamstava");
        props.getTable();
    }, []);

    return (
        <StatisticsWarrantiesTable
            rows={props.data}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    data: state.statisticsWarrantiesTable.data,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getTable: () =>
        dispatch<GetStatisticsWarrantiesTableAction>({
            type: ActionTypes.GET_STATISTICS_WARRANTIES_TABLE,
        }),
});

export default connect<
    IStateToProps,
    IDispatchToProps,
    IProps,
    IApplicationState
>(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsWarrantiesOverview);
