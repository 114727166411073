import React, { useEffect } from "react";
import { Button, Modal, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { Grid, LinearProgress, Box } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import styles from "./exportInvoiceForSeller.module.scss";
import { IInvoiceData } from "../../types/IInvoiceData";
import { ICurrency } from "../../types/ICurrency";
import moment from "moment";
import { invoicePdf } from "../../constants/apiRoutes";
import SelectDropdown from 'react-select';
import { components } from '../UI/dropdowns/DropdownAutocomplete.utils';
import { IDropdownItem } from "../../types/IDropdownItem";
import { InvoiceModalForm } from '../forms/invoiceForm/InvoiceModalForm';
import { IApplicationState } from '../../types/applicationState';
import { PDVCheckBox } from './PDVCheckBox';

interface IProps extends IState {
    sendWarrantyInvoice?: (data: IInvoiceData) => void,
    getLastInvoice?: () => void,
    lastInvoice: string,
    nextInvoice: string,
    currencies: ICurrency[],
    sellerName: string,
    getInvoice?: (invoiceNumber: string) => void,
    invoice: IInvoiceData | null;
}

interface IState extends IInvoiceData {
    open?: boolean | string;
    hasError?: boolean;
    generate?: boolean;
}


const ExportInvoiceForSellerComponent: React.FC<IProps> = (props: IProps) => {


    const formRef = React.createRef<ValidatorForm>();
    const [open, setOpen] = React.useState(false);
    const [generate, setGenerate] = React.useState(false);
    const [invoiceNumber, setInvoiceNumber] = React.useState(props.nextInvoice);
    const [issueDate, setIssueDate] = React.useState(props.issueDate);
    const [currency, setCurrency] = React.useState(props.currencies[0]);
    const [showModal, setShowModal] = React.useState(false);
    const [PDV, setPDV] = React.useState(props.pdv);
    const { invoice } = props;

     useEffect(() => {
         updateData();
     }, [invoice]);

    const handleClickOpen = (id: string) => {
        if (id == props.sellerId) {
            setOpen(true);
        }
    };

    const updateData = () => {
        if (invoice) {
            //setCurrency(props.currencies[invoice.currencyId]);
            setIssueDate(invoice.issueDate);
        }
        
    }

    const handleClose = () => {
        setOpen(false);
        props.getLastInvoice!();
    };

    const handleInvoiceNumberChange = (el: any) => {
        const { value } = el.target;
        setGenerate(false);
        setInvoiceNumber(value);
    }

    const handleIssueDateChange = (el: any) => {
        const { value } = el.target;
        setGenerate(false);
        setIssueDate(value);
    }

    const handleCurrencyChange = (el: any) => {
        const { value } = el;
        setGenerate(false);
        const seletedCurrency = props.currencies.find(c => {
            return c.id === value
        });
        if (seletedCurrency) {
            setCurrency(seletedCurrency);
        }
    }

    const viewInvoice = () => {
        if (invoiceNumber!.length > 0 && issueDate!.length > 0) {
            setGenerate(true);
        }
        else {
            alert("Potreban je broj računa!")
        }
    }

    const checkModal = () => {
        if (invoiceNumber != props.nextInvoice) {
            setShowModal(true);
        } else {
            sendPdf();
        }
    }

    const handleYesModal = () => {
        sendPdf();
    }

    const sendPdf = () => {
        if (invoiceNumber.length > 0 && issueDate && issueDate.length > 0) {
            const data: IInvoiceData = {
                sellerId: props.sellerId,
                sellerName: props.sellerName,
                startDate: props.startDate,
                endDate: props.endDate,
                invoiceNumber: invoiceNumber,
                issueDate: issueDate,
                currencyId: currency.id,
                pdv: PDV
            }
            if (props.sendWarrantyInvoice) {
                props.sendWarrantyInvoice(data);
            }
            setOpen(false);
        }
        else {
            alert("Potreban je broj računa!")
        }
    }

    return (
        <React.Fragment>
            <div onClick={() => handleClickOpen(props.sellerId!)} style={{ cursor: "pointer" }} id={props.sellerId}>
                Generiraj &nbsp; &nbsp;
        <IconButton aria-label="delete" size="small" style={{ margin: -2 }}>
                    <AddIcon fontSize="inherit" />
                </IconButton>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={styles.Root}
            >
                <DialogTitle id="alert-dialog-title">
                    {props.sellerName +
                        " za " +
                        moment(props.startDate).format("DD.MM.YYYY") +
                        " - " +
                        moment(props.endDate).format("DD.MM.YYYY")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ValidatorForm
                            onSubmit={() => { console.log("") }}
                            ref={formRef}
                        >
                            <Grid item xs={12} container direction="row" sm={12}>
                                <Grid item sm={3} className={styles.GridItem}>
                                    <TextValidator
                                        label="Broj računa"
                                        onChange={handleInvoiceNumberChange}
                                        name="invoiceNumber"
                                        value={invoiceNumber}
                                        validators={["required"]}
                                        errorMessages={["Treba broj računa!"]}
                                    />
                                    <span className={styles.lastInvoice}>Posljednji račun: {props.lastInvoice}</span>
                                </Grid>
                                <Grid item sm={3} className={styles.GridItem}>
                                    <TextValidator
                                        label="Datum izdavanja"
                                        onChange={handleIssueDateChange}
                                        name="issueDate"
                                        value={issueDate}
                                        validators={["required"]}
                                        errorMessages={["Potreban datum izdavanja!"]}
                                    />
                                </Grid>
                                <Grid item sm={2} className={styles.GridItem}>
                                    <div className="MuiFormControl-root MuiTextField-root">
                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true">Valuta</label>
                                        <span style={{ paddingTop: "12px" }}>
                                            <SelectDropdown
                                                isClearable={false}
                                                value={{ label: currency.abbreviation, value: currency.id } as IDropdownItem}
                                                noOptionsMessage={() => "Valuta nije u sustavu."}
                                                placeholder="Valuta"
                                                options={props.currencies.map((c: ICurrency) => ({
                                                    label: c.abbreviation,
                                                    value: c.id,
                                                })) as IDropdownItem[]}
                                                components={components}
                                                onChange={(el: any) => handleCurrencyChange(el)}                                                
                                            />
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item sm={2}>
                                    <PDVCheckBox PDV={PDV} setPDV={(pdv: boolean) => setPDV(pdv)} />
                                </Grid>
                                <Grid item sm={2} className={styles.GridItem}>
                                    <Button
                                        color="primary"
                                        className={styles.generateButton}
                                        variant="outlined"
                                        onClick={viewInvoice}
                                    >
                                        Pregledaj račun
                                    </Button>
                                </Grid>
                            </Grid>
                            <div className={styles.iframeContainer}>
                                {generate && (
                                    <Grid item xs={12} container direction="column" sm={12}>
                                        <iframe
                                            className={styles.iframePlaceholder}
                                            title="Račun"
                                            height="600px"
                                            src={
                                                invoicePdf +
                                                "?invoiceNumber=" +
                                                invoiceNumber +
                                                "&issueDate=" +
                                                issueDate +
                                                "&sellerName=" +
                                                props.sellerName +
                                                "&sellerId=" +
                                                props.sellerId +
                                                "&startDate=" +
                                                props.startDate +
                                                "&endDate=" +
                                                props.endDate +
                                                "&currencyId=" +
                                                currency.id +
                                                "&pdv=" +
                                                PDV
                                            }
                                        ></iframe>
                                    </Grid>
                                )}
                            </div>
                            <DialogActions style={{ marginTop: 50 }}>
                                <Grid item>
                                    <Button onClick={handleClose} color="primary">
                                        {" "}
                      Odustani{" "}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={checkModal} color="primary" variant="contained"> Pošalji </Button>
                                </Grid>
                            </DialogActions>
                        </ValidatorForm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {
                showModal &&
                <Box>
                    <Modal open={showModal} className={styles.Modal} onClose={() => setShowModal(false)}>
                        <Paper className={styles.RootModal}>
                            <div>
                                <InvoiceModalForm closeModal={() => setShowModal(false)} submitYesModal={handleYesModal} invoiceNumber={invoiceNumber}></InvoiceModalForm>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
        </React.Fragment>
        
    );
}

export default ExportInvoiceForSellerComponent;