import React, { useState, useEffect, useRef } from "react";
import { Grid, Modal, Paper, Box, Button, Input } from "@material-ui/core";
import styles from "./adminTable.module.scss";
import stylesEmailModal from "../forms/buyerForm/buyerEmailModal.module.scss";
import MaterialTable from "material-table";
import Chip from "@material-ui/core/Chip";
import IAdminAttrs from "../../types/IAdminAttrs";
import IBuyerProps from "../../types/IBuyerProps";
import IMissingCarDetailsProps from "../../types/IMissingCarDetailsProps";
import WarrantyStatusChecker from "../../utils/warrantyStatusChecker";
import WarrantyStatus from "../../domain/enums/WarrantyStatus";
import { DamageStatus } from "../../domain/enums/DamageStatus";
import { CarStatus } from "../../domain/enums/CarStatus";
import { useTranslation } from "react-i18next";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import ListItem from "@material-ui/core/ListItem";
import { List, ListItemIcon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { warrantyPdf } from "../../constants/apiRoutes";
import { numeral } from "../../utils/AppFormatter";
import IWarrantyOverview from "../../types/IWarrantyOverview";
import { initialBuyerProp } from "../../redux/reducers/carReducer";
import MissingCarDetailsForm from "../forms/missingCarDetailsForm/MissingCarDetailsForm";
import { BuyerForm } from "../forms/buyerForm/BuyerForm";
import { BuyerEmailModal } from "../forms/buyerForm/BuyerEmailModal";
import IFilterModel from "../../types/IFilterModel";

interface IProps {
  rows: { data: IAdminAttrs[]; loading: boolean };
  getAdminTableData: (page: number, pageSize: number, filters: string) => void;
  viewWarranty: (rowData: IAdminAttrs) => void;
  goToEditWarranty: (rowData: IAdminAttrs) => void;
  goToDamagePage: (carId: number, warrantyId: number) => void;
  sellCar: (buyer: IBuyerProps) => void;
  editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void;
  getBuyerData: (carId: number, warrantyId: number) => void;
  buyer: IBuyerProps;
  getBuyerByOIB: (oib: string) => void;
  getBuyerByEmail: (email: string) => void;
  setCarDetailsMissing: (isCarDetailsMissing: boolean) => void;
  isCarDetailsMissing: boolean;
  totalCount: number;
}

const AdminWarrantyTable: React.FC<IProps> = (props: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterArray, setFilterArray] = useState<IFilterModel[]>([
    { key: "sellerName", value: "" },
    { key: "warranty.code", value: "" },
    { key: "warranty.car.chassisNumber", value: "" },
  ]);
  const [filters, setFilters] = useState<string>("");
  const [columnFocus, setColumnFocus] = useState<string>("");
  const tableRef = useRef();
  const { t } = useTranslation();
  var x = 1;

  const [showBuyerModal, setShowBuyerModal] = useState(false);
  const [showCheckBuyerEmailModal, setShowCheckBuyerEmailModal] =
    useState(false);
  const [sellingWarrantyId, changeSellingWarrantyId] = useState();
  const [isWarrantyEditable, setIsWarrantyEditable] = useState(true);
  const [carIsSold, setCarIsSold] = useState(false);
  const [carBuyer, setCarBuyer] = useState(props.buyer);
  const [buyerEmail, setBuyerEmail] = useState("");
  const [dynamicTitle, setDynamicTitle] = useState(
    "Pregled svih aktivnih jamstva"
  );

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const a = tableRef.current as any;
      a.onQueryChange();
    }
  }, [props.rows.data]);

  useEffect(() => {
    // car is for sale and data from backend  are available
    if (Object.keys(props.buyer).length && sellingWarrantyId) {
      setCarBuyer(props.buyer);
      setShowBuyerModal(true);
    }
    if (props.buyer.carSoldSuccess) {
      setShowBuyerModal(false);
      updateDashboard();
    }
  }, [props.buyer]);

  const mapPropsToTable = (): IAdminAttrs[] => {
    let newRows = new Array<IAdminAttrs>();
    props.rows.data.forEach((row: IAdminAttrs) => {
      const viewOrUpdate = WarrantyStatusChecker.AdminViewOrUpdate(
        row.warranty.warrantyStatus
      );

      let newRow: IAdminAttrs = {
        ...row,
        warranty: {
          ...row.warranty,
          warrantyStatus: t(row.warranty.warrantyStatus),
          carStatus: t(row.warranty.carStatus),
        },
        damage_action: "Šteta",
        warranty_action: viewOrUpdate ? "Pregledaj" : "Uredi",
      };
      newRows.push(newRow);
    });
    return newRows;
  };

  const editWarranty = (rowData: IAdminAttrs): void => {
    //props.goToEditWarranty(rowData);

    window.open(
      "/car/" +
        rowData.warranty.carId +
        "/warranty/" +
        rowData.warranty.warrantyId +
        "/step=1"
    );
  };
  const viewWarranty = (rowData: IAdminAttrs): void => {
    props.viewWarranty(rowData);
  };

  function actionButton(rowData: IAdminAttrs) {
    const editButton = (
      <Button
        color="default"
        variant="contained"
        size="small"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          editWarranty(rowData)
        }
      >
        {" "}
        Uredi{" "}
      </Button>
    );
    return editButton;
  }

  function damageButton(rowData: IAdminAttrs) {
    const propsRow = findByWarrantyId(rowData.warranty.warrantyId);
    const shouldDisable =
      propsRow.warranty.carStatus === CarStatus.Available ||
      (propsRow.warranty.warrantyStatus !== WarrantyStatus.Approved &&
        propsRow.warranty.warrantyStatus !== WarrantyStatus.Active);
    return (
      <Button
        color="primary"
        variant="contained"
        disabled={shouldDisable}
        size="small"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          props.goToDamagePage(
            rowData.warranty.carId,
            rowData.warranty.warrantyId
          )
        }
      >
        {" "}
        {rowData.damage_action}{" "}
      </Button>
    );
  }

  function chip(rowData: IAdminAttrs) {
    const propsRow = findByWarrantyId(rowData.warranty.warrantyId);
    const className = WarrantyStatusChecker.ButtonClassName(
      propsRow.warranty.warrantyStatus
    );
    return (
      <Chip className={className} label={rowData.warranty.warrantyStatus} />
    );
  }
  const findByWarrantyId = (id: number): IAdminAttrs => {
    return props.rows.data.filter(
      (row: IAdminAttrs) => row.warranty.warrantyId === id
    )[0];
  };

  const carStatus = (rowData: IAdminAttrs) => {
    const isSold =
      rowData.warranty.carStatus === CarStatus.Sold ||
      rowData.warranty.carStatus === "Prodano";
    return (
      <div>
        {rowData.warranty.carStatus}
        {isSold && (
          <ListItem
            button
            component={Link}
            target="_blank"
            to={
              "/" + warrantyPdf + "?warrantyId=" + rowData.warranty.warrantyId
            }
            style={{ display: "contents" }}
          >
            <ListItemIcon>
              <PictureAsPdf />
            </ListItemIcon>
          </ListItem>
        )}
      </div>
    );
  };

  const showSellCarForm = (
    warrantyId: number | any,
    isCarAvailable: boolean
  ) => {
    changeSellingWarrantyId(warrantyId);
    setCarIsSold(!isCarAvailable);
    const adminData = props.rows.data.find(
      (a: IAdminAttrs) => a.warranty.warrantyId === warrantyId
    );
    if (!isCarAvailable) {
      if (adminData)
        props.getBuyerData(
          adminData.warranty.carId,
          adminData.warranty.warrantyId
        );
      setShowCheckBuyerEmailModal(false);
    } else {
      setCarBuyer({ ...initialBuyerProp, warrantyId });
      setShowCheckBuyerEmailModal(true);
    }
    if (adminData) {
      setIsWarrantyEditable(
        adminData.warranty.warrantyStatus !== WarrantyStatus.Active
      );
      props.setCarDetailsMissing(
        (adminData.warranty.arrivedFromNjuskalo &&
          adminData.warranty.car.chassisNumber.length !== 17) ||
          (adminData.warranty.car.chassisNumber.toUpperCase() ===
            "XXXXXXXXXXXXXXXXX" &&
            adminData.warranty.car.chassisNumber.length === 17)
      );
    }
  };

  const sellCar = (rowData: IWarrantyOverview): void =>
    showSellCarForm(
      rowData.warrantyId,
      rowData.carStatus == CarStatus.Available
    );

  const carButton = (rowData: IAdminAttrs) => {
    const propsRow = findByWarrantyId(rowData.warranty.warrantyId);
    const isAvailable = propsRow.warranty.carStatus === CarStatus.Available;
    const warrantyApproved =
      propsRow.warranty.warrantyStatus === WarrantyStatus.Approved ||
      propsRow.warranty.warrantyStatus === WarrantyStatus.Active;
    return (
      <div style={{ display: "-webkit-inline-box" }}>
        <Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            sellCar(propsRow.warranty)
          }
          disabled={!warrantyApproved}
          variant="contained"
          size="small"
        >
          {isAvailable ? "Prodaj" : "Kupac"}
        </Button>
      </div>
    );
  };

  const buyerSubmit = (buyer: IBuyerProps) => {
    buyer.warrantyId = sellingWarrantyId;
    buyer.buyerEditing = carIsSold;
    props.sellCar(buyer);
  };

  const buyerEmailSubmit = (email: string) => {
    setBuyerEmail(email);
    props.getBuyerByEmail(email);
  };

  const updateDashboard = () => {
    props.rows.data.forEach((a: IAdminAttrs) => {
      if (a.warranty.warrantyId === sellingWarrantyId) {
        a.warranty.carStatus = CarStatus.Sold;
        changeSellingWarrantyId(undefined);
      }
    });
  };

  const damageWarrantyButton = (rowData: IAdminAttrs) => (
    <Link
      to={"/warranty_damage/" + rowData.warranty.warrantyId}
      style={{
        display: "-webkit-inline-box",
        color: "unset",
        textDecoration: "none",
      }}
    >
      <Button variant="contained" size="small">
        Pregled
      </Button>
    </Link>
  );

  const createFilters = () => {
    let newFilters = "";

    const sellerName = filterArray.find(
      (x: IFilterModel): boolean => x.key === "sellerName"
    )!.value;
    const warrantyId = filterArray.find(
      (x: IFilterModel): boolean => x.key === "warranty.code"
    )!.value;
    const chassisNumber = filterArray.find(
      (x: IFilterModel): boolean => x.key === "warranty.car.chassisNumber"
    )!.value;

    if (sellerName !== "") {
      newFilters = newFilters + "Seller.LegalEntityName@=*" + sellerName;
    }

    if (warrantyId !== "") {
      newFilters = newFilters + ",WarrantyId==" + warrantyId;
    }

    if (chassisNumber !== "") {
      newFilters = newFilters + ",Car.ChassisNumber@=*" + chassisNumber;
    }

    if (newFilters.substring(0, 1) === ",")
      newFilters = newFilters.substring(1);

    setFilters(newFilters);

    // if (
    //   (sellerName !== "" && sellerName.length > 2) ||
    //   (chassisNumber !== "" && chassisNumber.length === 17) ||
    //   (warrantyId !== "" && warrantyId.length === 5) ||
    //   (sellerName === "" && chassisNumber === "" && warrantyId === "")
    // )
    //   props.getAdminTableData(page + 1, pageSize, newFilters);
  };

  const setFilterValue = (field: string, value: string) => {
    filterArray.find((x: IFilterModel): boolean => x.key === field)!.value =
      value;

    createFilters();
  };

  return (
    <Paper className={styles.Root}>
      <Grid
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => props.getAdminTableData(page + 1, pageSize, filters)}
          color="inherit"
          variant="contained"
        >
          TRAŽI
        </Button>
      </Grid>
      <MaterialTable
        tableRef={tableRef}
        isLoading={props.rows.loading}
        localization={{
          body: {
            emptyDataSourceMessage: "Nema podataka za prikaz.",
          },
          pagination: {
            previousTooltip: "Prethodna",
            nextTooltip: "Sljedeća",
            firstTooltip: "Početna",
            lastTooltip: "Posljednja",
          },
        }}
        title={dynamicTitle}
        columns={[
          { title: "Trgovac", field: "sellerName", filtering: true },
          { title: "Br. jamstva", field: "warranty.code", filtering: true },
          {
            title: "Šasija",
            field: "warranty.car.chassisNumber",
            filtering: true,
          },
          {
            title: "Datum prodaje",
            field: "warranty.validFrom",
            filtering: false,
          },
          { title: "Marka", field: "warranty.brand", filtering: false },
          { title: "Model", field: "warranty.model", filtering: false },
          {
            title: "Kilometri",
            field: "warranty.car.distance",
            filtering: false,
          },
          {
            title: "Status",
            render: (rowData: IAdminAttrs) => carStatus(rowData),
            filtering: false,
          },
          {
            title: "Status jamstva",
            field: "warranty.warrantyStatus",
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length > 0 && chip(rowData),
            filtering: false,
          },
          {
            title: "Akcija jamstva",
            field: "warranty_action",
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length > 0 && actionButton(rowData),
            filtering: false,
          },
          {
            title: "Akcija štete",
            field: "damage_action",
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length > 0 && damageButton(rowData),
            filtering: false,
          },
          {
            title: "Prodaja",
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length > 0 && carButton(rowData),
            filtering: false,
          },
          {
            title: "Pregled šteta",
            render: (rowData: IAdminAttrs) =>
              props.rows.data.length > 0 && damageWarrantyButton(rowData),
            filtering: false,
          },
        ]}
        options={{
          filtering: true,
          search: false,
          paging: true,
          initialPage: page,
          paginationType: "normal",
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            resolve({
              data: mapPropsToTable(),
              page: page,
              totalCount: props.totalCount,
            });
          })
        }
        components={{
          FilterRow: (rowProps) => {
            const { columns } = rowProps;

            return (
              <>
                <tr>
                  {columns
                    .filter((c: any): boolean => c.filtering === true)
                    .map((col: any) => {
                      if (col.field) {
                        return (
                          <td>
                            <Input
                              autoFocus={columnFocus === col.field}
                              key={col.field}
                              id={col.field}
                              value={
                                filterArray.find(
                                  (x: IFilterModel): boolean =>
                                    x.key === col.field
                                )!.value
                              }
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                              onChange={(e) => {
                                setFilterValue(col.field, e.target.value);
                                setColumnFocus(col.field);
                              }}
                            />
                          </td>
                        );
                      }
                    })}
                </tr>
              </>
            );
          },
        }}
        onChangePage={(newPage: number) => {
          setPage(newPage);
          props.getAdminTableData(newPage + 1, pageSize, filters);
        }}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
          props.getAdminTableData(page + 1, newPageSize, filters);
        }}
      />
      {showBuyerModal && (
        <Box>
          <Modal
            open={showBuyerModal}
            className={styles.Modal}
            onClose={() => setShowBuyerModal(false)}
          >
            <Paper className={stylesEmailModal.RootModal}>
              <div>
                <BuyerForm
                  isEditable={isWarrantyEditable}
                  carIsSold={carIsSold}
                  closeModal={() => setShowBuyerModal(false)}
                  submit={buyerSubmit}
                  buyerEmail={buyerEmail}
                  {...props}
                  buyer={carBuyer}
                ></BuyerForm>
              </div>
            </Paper>
          </Modal>
        </Box>
      )}
      {showCheckBuyerEmailModal && (
        <Box>
          <Modal
            open={showCheckBuyerEmailModal}
            className={stylesEmailModal.Modal}
            onClose={() => setShowCheckBuyerEmailModal(false)}
          >
            <Paper className={stylesEmailModal.RootModal}>
              <div>
                <BuyerEmailModal
                  closeModal={() => setShowCheckBuyerEmailModal(false)}
                  submitEmailModal={buyerEmailSubmit}
                  {...props}
                ></BuyerEmailModal>
              </div>
            </Paper>
          </Modal>
        </Box>
      )}
    </Paper>
  );
};

export default AdminWarrantyTable;
