import {
    GetCarDataAction, GetCarAction, DeactivateCarAction, ActivateCarAction, GetCarProblemExtraPackageAction, PostCarOnlyAction, ChangeSellerEmailAction, GetChassisCarAction,
    GetCarsAdminAction, GetCarsSellerAction, GetCarsAdminOnlyCarsAction, SellCarAction, GetBuyerByCarIdAction, GetBuyerByOibAction, GetBuyerByEmailAction, GetModelsByBrandAction,
    ReviewCar, CurrentCarReview, GetCarDetailsByCarIdAction, UpdateCarDetailsAction, GetBrandsAction, GetCarModelsByBrandAction, FilterCarsAction, ImportCarAction,
    GetCarsDropdownAdminAction, GetCarsDropdownSellerAction, GetCarsAdminSoldOnlyCarsAction, SellCarWithoutWarrantyAction, PostCarOnlyAndContinueAction, GetCarsAdminTotalCountAction,
    GetCarsAdminOnlyCarsTotalCountAction, GetCarsAdminSoldOnlyTotalCountAction
} from './../actions/CarAction';
import { Container } from 'inversify';
import { getContext, apply, put, delay } from '@redux-saga/core/effects';
import ICarService from './../../domain/services/ICarService';
import CarService from './../../domain/services/CarService';
import ActionTypes from './../actions/ActionTypes';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';
import { warrantyPdf, instructionsToActivate } from '../../constants/apiRoutes';
import { URLHelper } from '../../utils/URLHelper';
import ReactGA from 'react-ga';
import { SagaIterator } from '@redux-saga/core';
import ICarProps from '../../types/ICarProps';

export function* getCarData(action: GetCarDataAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const carBrands = yield apply(carApi, carApi.getCarBrands, []);
        const engineTypes = yield apply(carApi, carApi.getEngineTypes, []);
        yield put({
            type: ActionTypes.GET_CAR_DATA_SUCCESS, payload: {
                carBrands, engineTypes
            }
        });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_DATA_ERROR, payload: err });
    }
}

export function* sellCar(action: SellCarAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START })
        const response = yield apply(carApi, carApi.sellCar, [action.payload]);
        yield put({ type: ActionTypes.SELL_CAR_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END })
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: action.payload.buyerEditing ? 'Podaci kupca su uspješno ažurirani' : 'Jamstvo za automobil je uspješno prodano' + response,
                style: NotificationStyle.Success
            }
        });
        window.open(warrantyPdf + '?warrantyId=' + action.payload.warrantyId, '_blank');

        yield delay(4000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: action.payload.buyerEditing ? 'Podaci kupca su uspješno ažurirani' : 'Auto je uspješno prodan',
                style: NotificationStyle.Success
            }
        });
        ReactGA.event({
            category: 'Trgovac',
            action: 'Auto prodano',
          });
    } catch (err) {
        yield put({ type: ActionTypes.SELL_CAR_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END })
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Pogreška prilikom prodaje jamstva za automobil',
                style: NotificationStyle.Error
            }
        });
    }
}

export function* getBuyerByCarId(action: GetBuyerByCarIdAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.getBuyerByCarId, [action.payload.carId, action.payload.warrantyId]);
        yield put({ type: ActionTypes.GET_BUYER_BY_CAR_ID_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYER_BY_CAR_ID_ERROR, payload: err });
    }
}

export function* getBuyerByOib(action: GetBuyerByOibAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.getBuyerByOib, [action.payload]);
        yield put({ type: ActionTypes.GET_BUYER_BY_OIB_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYER_BY_OIB_ERROR, payload: err });
    }
}

export function* getBuyerByEmail(action: GetBuyerByEmailAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.getBuyerByEmail, [action.payload]);
        yield put({ type: ActionTypes.GET_BUYER_BY_EMAIL_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BUYER_BY_EMAIL_ERROR, payload: err });
    }
}

export function* reviewCar(action: ReviewCar) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.reviewCar, [action.payload]);
        yield put({ type: ActionTypes.REVIEW_CAR_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.REVIEW_CAR_ERROR, payload: err });
    }
}

export function* currentCarReview(action: CurrentCarReview) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.currentCarReview, [action.payload]);
        yield put({ type: ActionTypes.GET_CURRENT_CAR_REVIEW_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CURRENT_CAR_REVIEW_ERROR, payload: err });
    }
}


export function* getModelsByBrand(action: GetModelsByBrandAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const carModels = yield apply(carApi, carApi.getCarModelsByBrand, [action.payload]);
        yield put({ type: ActionTypes.GET_CAR_MODELS_SUCCESS, payload: carModels });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_MODELS_ERROR, payload: err });
    }
}

export function* changeShowOnPortal(action: any) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.changeShowOnPortal, [action.payload.value, action.payload.carId]);
       
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Status auta na portalu osvježen. Promjene će biti vidljive kroz sat do dva.',
                style: NotificationStyle.Success
            }
        });
        ReactGA.event({
            category: 'Portal',
            action: 'Status auta na portalu osvježen',
            label: action.payload.carId
          });

        } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_FOR_SELLER_ERROR, payload: err });
    }
}

export function* hideAllCarsOnPortal(action: any) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });

        const response = yield apply(carApi, carApi.hideAllCarsOnPortal, [action.payload.sellerId]);
        yield put({ type: ActionTypes.LOADING_END });

        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Vozila za odabranog trgovca više nisu na portalu.',
                style: NotificationStyle.Success
            }
        });

        yield put({ type: ActionTypes.FILTER_CARS, payload: '?' + window.location.href.split("?")[1]});

        ReactGA.event({
            category: 'Portal',
            action: 'Vozila za odabranog trgovca više nisu na portalu.',
            label: action.payload.carId
          });

        } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_FOR_SELLER_ERROR, payload: err });
    }
}


export function* getCarDetailsByCarId(action: GetCarDetailsByCarIdAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarDetailsByCarId, [action.payload]);
        yield put({ type: ActionTypes.GET_CAR_DETAILS_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_DETAILS_ERROR, payload: err });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* updateCarDetails(action: UpdateCarDetailsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.updateCarDetails, [action.payload]);
        yield put({ type: ActionTypes.UPDATE_CAR_DETAILS_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Auto osvježen. Promjene na portalu će biti vidljive kroz sat do dva.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/portal' }
        })
        ReactGA.event({
            category: 'Portal',
            action: 'Podaci o autu osvježeni',
          });
    } catch (err) {
        yield put({ type: ActionTypes.UPDATE_CAR_DETAILS_ERROR, payload: err });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarBrands(action: GetBrandsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.getCarBrands, []);
        yield put({ type: ActionTypes.GET_CAR_BRANDS_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_BRANDS_ERROR, payload: err });
    }
}

export function* getCarModelsByBrand(action: GetCarModelsByBrandAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarModelsByBrand, [action.payload]);
        yield put({ type: ActionTypes.GET_CAR_MODELS_BY_BRAND_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_MODELS_BY_BRAND_SUCCESS, payload: err });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getEngineTypes(action: GetBrandsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        const response = yield apply(carApi, carApi.getEngineTypes, []);
        yield put({ type: ActionTypes.GET_ENGINE_TYPES_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_ENGINE_TYPES_ERROR, payload: err });
    }
}

export function* filterCarCards(action: FilterCarsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.filterCars, [action.payload]);
        yield put({ type: ActionTypes.FILTER_CARS_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.FILTER_CARS_ERROR, payload: err });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* loadMoreCarCards(action: FilterCarsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        let search = URLHelper.getQueryStringParams(window.location.search);
        if (search['skip'])
            search['skip'] = Number(search['skip']) + 20;
        else
            search['skip'] = 20;
        var query = URLHelper.getQueryStringFilterCars(search);
        history.pushState({}, '', query);
        // if seller screen
        if (window.location.pathname.includes("seller-details")) {
            var query = query + "&sellerId=" + window.location.pathname.split("/")[2];
        }
        const response = yield apply(carApi, carApi.filterCars, [query]);
        yield put({ type: ActionTypes.LOAD_MORE_FILTER_CARS_SUCCESS, payload: response });
        ReactGA.event({
            category: 'Portal',
            action: 'Dohvati još auta - load more',
          });
    } catch (err) {
        yield put({ type: ActionTypes.FILTER_CARS_ERROR, payload: err });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* importCars(action: ImportCarAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.importCar, [action.payload]);
        yield put({ type: ActionTypes.IMPORT_CARS_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Auti i jamstva učitana!',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/uvoz' }
        })
        ReactGA.event({
            category: 'Uvoz',
            action: action.payload.htmls.length + ' auta učitana za ' + action.payload.sellerId,
          });
    } catch (err) {
        yield put({ type: ActionTypes.IMPORT_CARS_ERROR, payload: err });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsAdmin(action: GetCarsAdminAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsAdmin, [action.payload.page, action.payload.pageSize, action.payload.filters]);
        yield put({ type: ActionTypes.GET_CARS_ADMIN_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_ADMIN_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsAdminTotalCount(action: GetCarsAdminTotalCountAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsAdminTotalCount, []);
        yield put({ type: ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsAdminOnlyCarsTotalCount(action: GetCarsAdminOnlyCarsTotalCountAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsAdminOnlyCarsTotalCount, []);
        yield put({ type: ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsAdminSoldOnlyTotalCount(action: GetCarsAdminSoldOnlyTotalCountAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsAdminSoldOnlyTotalCount, []);
        yield put({ type: ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsSeller(action: GetCarsSellerAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsSeller, []);
        yield put({ type: ActionTypes.GET_CARS_SELLER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_SELLER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsDropdownAdmin(action: GetCarsDropdownAdminAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsDropdownAdmin, []);
        yield put({ type: ActionTypes.GET_CARS_DROPDOWN_ADMIN_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_DROPDOWN_ADMIN_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsDropdownSeller(action: GetCarsDropdownSellerAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsDropdownSeller, []);
        yield put({ type: ActionTypes.GET_CARS_DROPDOWN_SELLER_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_DROPDOWN_SELLER_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsAdminOnlyCars(action: GetCarsAdminOnlyCarsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsAdminOnlyCars, [action.payload.page, action.payload.pageSize, action.payload.filters]);
        yield put({ type: ActionTypes.GET_CARS_ADMIN_ONLY_CARS_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_ADMIN_ONLY_CARS_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarsAdminSoldOnlyCars(action: GetCarsAdminSoldOnlyCarsAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarsAdminSoldOnlyCars, [action.payload.page, action.payload.pageSize, action.payload.filters]);
        yield put({ type: ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCarProblemExtraPackage(action: GetCarProblemExtraPackageAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getCarProblemExtraPackage, [action.payload]);
        yield put({ type: ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* deactivateCar(action: DeactivateCarAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        
        const response = yield apply(carApi, carApi.deactivateCar, [action.payload.carId]);
        yield put({ type: ActionTypes.DEACTIVATE_CAR_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Auto je uspješno deaktiviran.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/cars' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.DEACTIVATE_CAR_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjela deaktivacija auta.',
                style: NotificationStyle.Error
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/cars' }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* activateCar(action: ActivateCarAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.activateCar, [action.payload.carId]);
        yield put({ type: ActionTypes.ACTIVATE_CAR_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Auto je uspješno aktiviran.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/cars' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.ACTIVATE_CAR_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjela aktivacija auta.',
                style: NotificationStyle.Error
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/cars' }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* changeSellerEmail(action: ChangeSellerEmailAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.changeSellerEmail, [action.payload.chassisNumber]);
        yield put({ type: ActionTypes.CHANGE_SELLER_EMAIL_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Zahtjev je uspješno poslan.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.CHANGE_SELLER_EMAIL_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjelo slanje zahtjeva.',
                style: NotificationStyle.Error
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/' }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getChassisCar(action: GetChassisCarAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.getChassisCar, [action.payload.chassisNumber]);
        yield put({ type: ActionTypes.GET_CHASSIS_CAR_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_CHASSIS_CAR_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* postCarOnly(action: PostCarOnlyAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.postCarOnly, [action.payload]);
        yield put({ type: ActionTypes.POST_CAR_ONLY_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Auto je uspješno spremljen.',
                style: NotificationStyle.Success
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/' }
        });
        //clear cache
        yield put({ type: ActionTypes.SAVE_PHOTOS })
    } catch (err) {
        yield put({ type: ActionTypes.POST_CAR_ONLY_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjelo spremanje auta.',
                style: NotificationStyle.Error
            }
        });
        yield put({
             type: ActionTypes.GO_TO_PAGE, payload: { url: '/car/step=1' }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    } 
}

export function* postCarOnlyAndContinue(action: PostCarOnlyAndContinueAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.postCarOnlyAndContinue, [action.payload]);
        yield put({ type: ActionTypes.POST_CAR_ONLY_AND_CONTINUE_SUCCESS, payload: response });
        //yield put({
        //    type: ActionTypes.NOTIFICATION_SHOW, payload: {
        //        message: 'Auto je uspješno spremljen.',
        //        style: NotificationStyle.Success
        //    }
        //});
    } catch (err) {
        yield put({ type: ActionTypes.POST_CAR_ONLY_AND_CONTINUE_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjelo spremanje auta.',
                style: NotificationStyle.Error
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/car/step=1' }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* sellCarWithoutWarranty(action: SellCarWithoutWarrantyAction) {
    const container: Container = yield getContext('container');
    const carApi = container.get<ICarService>(CarService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(carApi, carApi.sellCarWithoutWarranty, [action.payload]);
        yield put({ type: ActionTypes.SELL_CAR_WITHOUT_WARRANTY_SUCCESS, payload: response });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Auto je uspješno prodan bez jamstva.',
                style: NotificationStyle.Success
            }
        });
        //clear cache
        yield put({ type: ActionTypes.SAVE_PHOTOS })
        yield put({
             type: ActionTypes.GO_TO_PAGE, payload: { url: '/' }
        });
    } catch (err) {
        yield put({ type: ActionTypes.SELL_CAR_WITHOUT_WARRANTY_ERROR, payload: err });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Neuspjela prodaja auta bez jamstva.',
                style: NotificationStyle.Error
            }
        });
        yield put({
            type: ActionTypes.GO_TO_PAGE, payload: { url: '/' }
        });
    } finally {
        yield put({ type: ActionTypes.LOADING_END });
    }
}

export function* getCar(action: GetCarAction): SagaIterator {
    const container: Container = yield getContext('container');
    const getCarApi = container.get<ICarService>(CarService);
    try {
        // if there is no payload then user is creating new warranty.
        if (!action.payload) {
            yield put({ type: ActionTypes.GET_CAR_SUCCESS, payload: null })
        }
        else if (action.payload === -1) {
            yield put({ type: ActionTypes.GET_CAR_SUCCESS, payload: null })
        }
        else {
            const response: ICarProps = yield apply(getCarApi, getCarApi.getCar, [action.payload]);
            yield put({ type: ActionTypes.GET_CAR_SUCCESS, payload: response })

        }
    } catch (err) {
        yield put({ type: ActionTypes.GET_CAR_ERROR, payload: err })
    } finally {
        // if changing from empty form to filled form, redux photos should be empty
        yield put({ type: ActionTypes.SAVE_PHOTOS, payload: {} })
    }
}