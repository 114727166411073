import React from "react";
import IPackage from "../../types/IPackage";
import { Card, CardContent, CardActionArea } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import style from "./packageSelection.module.scss";

interface IProps extends IPackage {
  PackageSelected: (id: number) => void;

  selected: boolean;
  disabled: boolean;
}

const PackageCard: React.FC<IProps> = (props) => {
  function handleClick() {
    props.PackageSelected(props.packageId ? props.packageId : props.id);
  }

  const card = (
    <Card className={props.selected ? style.Selected : ""}>
      <CardContent>
        <CardHeader
          title={props.packageName ? props.packageName : props.name}
        />
        <Typography variant="h5" component="div">
          {props.price} {props.currencyId === 0 ? "kn" : "€"}
        </Typography>
        <Typography variant="body2" component="div" align="justify">
          <p dangerouslySetInnerHTML={{ __html: props.packageDescription }} />
        </Typography>
        <Typography variant="body2" component="span">
          ( sa PDV-om {Math.round(props.priceWithVAT * 100) / 100} {props.currencyId === 0 ? "kn" : "€"}{" "}
          )
        </Typography>
      </CardContent>
    </Card>
  );

  if (props.disabled) return card;
  else return <CardActionArea onClick={handleClick}>{card}</CardActionArea>;
};

export default PackageCard;
