import React, { useEffect } from 'react';
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

interface IProps {
    setPDV: (pdv: boolean) => void,
    PDV?: boolean
}

export const PDVCheckBox: React.FC<IProps> = (props: IProps) => {
    const [isPDV, setIsPDV] = React.useState(props.PDV);

    useEffect(() => setIsPDV(props.PDV), [props]);

    function handleChange(event: React.ChangeEvent<unknown>) {
        const pdv = (event.target as HTMLInputElement).checked;
        setIsPDV(pdv);
        props.setPDV(pdv);
    }

    return (
        <FormGroup row>
            <FormControlLabel style={{ marginTop: "6px" }} label="Obračun PDV-a"
                labelPlacement="end"
                control={
                    <Checkbox
                        checked={isPDV}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{
                            'aria-label': 'secondary checkbox',
                        }}
                    />}
            />
        </FormGroup>
    );
}
