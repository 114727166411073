import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import IBuyerProps from '../../../types/IBuyerProps';
import { Button, SnackbarContent, Grid, Tooltip, Paper, Box, Modal } from '@material-ui/core';
import styles from './buyerRegistrationForm.module.scss';
import { initialBuyerProp } from '../../../redux/reducers/carReducer';
import Validator from '../../../utils/validation';
import { BuyerRadioButtons } from './BuyerRadioButtons';
import NumberFormat from 'react-number-format';
import { red } from '@material-ui/core/colors'
import { URLHelper } from '../../../utils/URLHelper';
import { DeleteBuyerModal } from '../../../components/forms/buyerForm/DeleteBuyerModal';
import { SendMailBuyerModal } from '../../../components/forms/buyerForm/SendMailBuyerModal';
import { isAdmin, isSuperAdmin } from '../../../utils/AuthFunctions';
import Select from "react-select";
import { ActionMeta, ValueType } from "react-select/src/types";

interface IProps {
    buyer: IBuyerProps,
    submit: (buyer: IBuyerProps) => void,
    getBuyerById: (id: string) => void,
    isEditable: boolean,
    delete: (buyer: IBuyerProps) => void,
    activate: (buyer: IBuyerProps) => void,
    sendActivationMail?: (email: string) => void,
    legalBuyers: IBuyerProps[],
}

interface IState extends IBuyerProps {
    disableControls?: boolean,
    oibIsValid?: boolean,
    resetForm?: boolean,
    initialLoad?: boolean,
    confirmEmail?: string,
    showDeleteModal?: boolean,
    showSendMailModal?: boolean,
    selectedLegalBuyer?: { label: string; value: string }
}

export class BuyerRegistrationForm extends React.Component<IProps> {
    state: IState = {
        ...initialBuyerProp,
        disableControls: false,
        oibIsValid: false,
        resetForm: false,
        initialLoad: true,
        confirmEmail: ""
    };

    private formRef = React.createRef<ValidatorForm>();

    componentDidMount() {
        ValidatorForm.addValidationRule('isOIB', (oib: string) => Validator.validateOIB(oib))
        ValidatorForm.addValidationRule('isPhoneNumber', (number: string) => Validator.validatePhoneNumber(number))
        ValidatorForm.addValidationRule('isEmailMatch', (email: string) => Validator.isEmailMatch(email, this.state.email));
    }

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {


        const buyerByOibSuccess = prevState.oibIsValid && nextProps.buyer.oib && prevState.oib === nextProps.buyer.oib;
        // Reset form
        if (prevState.resetForm || URLHelper.getBuyerId() === 'create_buyer') return { ...prevState, resetForm: false };

        // User data fetched successfully by oib 
        if (buyerByOibSuccess) return { ...nextProps.buyer, disableControls: !nextProps.isEditable && true, initialLoad: false };

        // Initial user load
        if (nextProps.buyer && nextProps.buyer.oib && prevState.initialLoad && nextProps.legalBuyers && nextProps.legalBuyers.length > 0) {
            return {
                ...nextProps.buyer, disableControls: !nextProps.isEditable && true, initialLoad: false,
                selectedLegalBuyer:
                    nextProps.buyer.parentId
                        ? {
                            label: nextProps.legalBuyers.find(
                                ({ id }) => id === nextProps.buyer.parentId
                            )!.legalEntityName,
                            value: nextProps.legalBuyers.find(
                                ({ id }) => id === nextProps.buyer.parentId
                            )!.id!,
                        }
                        : undefined,
            };
        }
    

        // Input elements changing values
        return {
            ...prevState, disableControls: false
        };
    }

    private handleChange(el: any) {
            const { name, value } = el.target;
            this.setState({ [name]: value });
    }

    handleSelectChange = (
        value: ValueType<{ label: string; value: string }>,
        _: ActionMeta
    ) => {
        this.setState({ selectedLegalBuyer: value });
    };

    public handleDelete() {
        this.setState({ showDeleteModal: true });
    }

    public handleActivationButton() {
        this.setState({ showSendMailModal: true });
    }

    private handleSubmit() {
        if (this.formRef) this.props.submit(this.getBuyerFromState());
    }

    public handleYesModal() {
        this.props.delete(this.getBuyerFromState());
    }

    public handleActivate() {
        this.props.activate(this.getBuyerFromState());
    }

    public handleSendYesModal() {
        if (this.props.sendActivationMail) {
            this.props.sendActivationMail(this.getBuyerFromState().email);
        }
    }

    public resetForm() {
        this.setState((prevState: IState) => ({ ...initialBuyerProp, carId: prevState.carId, oibIsValid: false, disableControls: false, resetForm: true, legalEntity: false }));
    }

    private getBuyerFromState(): IBuyerProps {
        const state = this.state;
        return {
            firstName: state.firstName, lastName: state.lastName, oib: state.oib,
            address: state.address, email: state.email, zipCode: state.zipCode,
            city: state.city, country: state.country, phoneNumber: state.phoneNumber,
            legalEntityName: state.legalEntityName, legalEntity: state.legalEntity,
            buyerEditing: state.buyerEditing, id: state.id,
            parentId:
                state.selectedLegalBuyer
                    ? state.selectedLegalBuyer.value
                    : undefined,
        };
    }

    render() {
        const { disableControls, ...formControls } = { ...this.state };
        return (
            <Paper className={styles.Root}>
                <React.Fragment>
                    <ValidatorForm
                        onSubmit={() => this.handleSubmit()}
                        ref={this.formRef}
                        className={styles.Form}
                    >
                        <Grid container alignItems="flex-start" direction="row" spacing={2}>
                            <Grid item xs={12} justify="flex-start" alignItems="flex-start"><p style={{ fontSize: 20, paddingLeft: 20 }}>Pregled podataka o kupcu</p>
                            </Grid>
                            <Grid item xs={12} direction="column" justify="center" alignItems="flex-start">
                                <BuyerRadioButtons disabled={disableControls!}
                                    legalEntity={this.state.legalEntity!}
                                    buyerEntityChanged={(legalEntity: boolean) => this.setState({ legalEntity })} />
                            </Grid>
                            <Grid container xs={12} md={6} direction="column" className={styles.OuterGrid}>
                                <Grid xs={12} item className={styles.Grid}>
                                    <TextValidator
                                        label="OIB"
                                        onChange={this.handleChange.bind(this)}
                                        name="oib"
                                        value={formControls.oib}
                                        validators={['required', 'isOIB']}
                                        errorMessages={['Ovo polje je obavezno.', 'Molimo unesite ispravan OIB']}
                                        disabled={disableControls}
                                        className={styles.BuyerInputs}
                                    />
                                </Grid>
                                {this.state.legalEntity ?
                                    <Grid item className={styles.Grid}>
                                        <TextValidator
                                            label="Naziv tvrtke"
                                            onChange={this.handleChange.bind(this)}
                                            name="legalEntityName"
                                            value={formControls.legalEntityName}
                                            validators={['required']}
                                            errorMessages={['Ovo polje je obavezno.']}
                                            disabled={disableControls}
                                            className={styles.BuyerInputs}
                                        />
                                    </Grid> :
                                    <div>
                                        <Grid item className={styles.Grid}>
                                            <TextValidator
                                                label="Ime"
                                                onChange={this.handleChange.bind(this)}
                                                name="firstName"
                                                value={formControls.firstName}
                                                validators={['required']}
                                                errorMessages={['Ovo polje je obavezno.']}
                                                disabled={disableControls}
                                                className={styles.BuyerInputs}
                                            />
                                        </Grid>
                                        <Grid item className={styles.Grid}>
                                            <TextValidator
                                                label="Prezime"
                                                onChange={this.handleChange.bind(this)}
                                                name="lastName"
                                                value={formControls.lastName}
                                                validators={['required']}
                                                disabled={disableControls}
                                                errorMessages={['Ovo polje je obavezno.']}
                                                className={styles.BuyerInputs}
                                            />
                                        </Grid>
                                    </div>
                                }
                                <Grid item className={styles.Grid}>
                                    <TextValidator
                                        label="Email"
                                        onChange={this.handleChange.bind(this)}
                                        name="email"
                                        value={formControls.email}
                                        validators={['required', 'isEmail']}
                                        disabled={disableControls || (!isAdmin() && !isSuperAdmin())}
                                        errorMessages={['Ovo polje je obavezno.', 'Neispravan email']}
                                        className={styles.BuyerInputs}
                                        style={{ width: "80%" }}
                                    />

                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} direction="column" className={styles.OuterGrid}>
                                <Grid item className={styles.Grid}>
                                    <TextValidator
                                        label="Adresa"
                                        onChange={this.handleChange.bind(this)}
                                        name="address"
                                        disabled={disableControls}
                                        value={formControls.address}
                                        validators={['required']}
                                        errorMessages={['Ovo polje je obavezno.']}
                                        className={styles.BuyerInputs}
                                    />
                                </Grid>
                                <Grid item className={styles.Grid}>
                                    <NumberFormat
                                        customInput={TextValidator}
                                        label="Poštanski broj"
                                        onChange={this.handleChange.bind(this)}
                                        disabled={disableControls}
                                        name="zipCode"
                                        thousandSeparator={' '}
                                        value={formControls.zipCode}
                                        validators={['required']}
                                        errorMessages={['Molimo unesite vrijednost.']}
                                        className={styles.BuyerInputs}
                                    />
                                </Grid>
                                <Grid item className={styles.Grid}>
                                    <TextValidator
                                        label="Grad"
                                        onChange={this.handleChange.bind(this)}
                                        name="city"
                                        disabled={disableControls}
                                        value={formControls.city}
                                        validators={['required']}
                                        errorMessages={['Ovo polje je obavezno.']}
                                        className={styles.BuyerInputs}

                                    />
                                </Grid>
                                <Grid item className={styles.Grid}>
                                    <TextValidator
                                        label="Država"
                                        onChange={this.handleChange.bind(this)}
                                        name="country"
                                        value={formControls.country}
                                        disabled={disableControls}
                                        validators={['required']}
                                        errorMessages={['Ovo polje je obavezno.']}
                                        className={styles.BuyerInputs}
                                    />
                                </Grid>
                                <Grid item className={styles.Grid}>
                                    <TextValidator
                                        label="Kontakt broj"
                                        onChange={this.handleChange.bind(this)}
                                        name="phoneNumber"
                                        disabled={disableControls}
                                        value={formControls.phoneNumber}
                                        validators={['required', 'isPhoneNumber']}
                                        errorMessages={['Ovo polje je obavezno.', 'Neispravan kontakt broj']}
                                        className={styles.BuyerInputs}

                                    />
                                </Grid>
                                {(isAdmin() || isSuperAdmin()) && !this.state.legalEntity && 
                                    <Grid item className={styles.Grid}>
                                        <Select
                                            name="selectedLegalBuyer"
                                                placeholder={"Kojoj tvrtci pripada kupac"}
                                            value={this.state.selectedLegalBuyer}
                                            onChange={this.handleSelectChange}
                                            options={
                                                this.props.legalBuyers &&
                                                this.props.legalBuyers.map((m: IBuyerProps) => ({
                                                    label: m.legalEntityName,
                                                        value: m.id!
                                                    }))
                                                }
                                                isClearable
                                            />
                                        </Grid>}
                            </Grid>
                        </Grid>
                        <React.Fragment>
                            <Grid item xs={12} container justify="center" alignItems="center" spacing={4} >
                                {this.state.initialLoad ?
                                    null
                                    :
                                        this.state.active == "DA" ? <Grid item >
                                            <Button className={styles.Button} variant="contained" color="secondary" onClick={this.handleDelete.bind(this)}>Deaktiviraj</Button>
                                    </Grid>
                                        :

                                        this.state.active == "NE" && <Grid item >
                                            <Button className={styles.Button} variant="contained" color="inherit" onClick={this.handleActivate.bind(this)}>Aktiviraj</Button>
                                        </Grid>
                                }
                                {this.state.initialLoad ?
                                    <Grid item >
                                        <Button className={styles.Button} variant="contained" color="primary" type="submit">Spremi</Button>
                                    </Grid>
                                    :
                                    <Grid item >
                                        <Button className={styles.Button} variant="contained" color="primary" type="submit">Uredi</Button>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} container justify="center" alignItems="center" >
                                {this.state.initialLoad || (isAdmin || isSuperAdmin) ?
                                    null
                                    :
                                    <Grid item xs={6} style={{ marginTop: '40px', marginBottom: '40px' }} direction="column" justify="center" alignItems="center">
                                        <SnackbarContent
                                            aria-describedby="client-snackbar"
                                            message={
                                                <span id="client-snackbar">
                                                    Email nije moguće promijeniti!
                                                </span>
                                            }
                                            style={{ backgroundColor: red[600], alignContent: "center" }}
                                            className={styles.Snackbar}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }} container justify="center" alignItems="center" >
                                {this.props.sendActivationMail ?
                                    <Grid item >
                                        <Button className={styles.Button} variant="contained" color="inherit" onClick={this.handleActivationButton.bind(this)}>Pošalji email za aktivaciju jamstva</Button>
                                    </Grid>
                                    :
                                    null
                                }
                            </Grid>


                        </React.Fragment>
                    </ValidatorForm>
                    {
                        this.state.showDeleteModal &&
                        <Box>
                            <Modal open={this.state.showDeleteModal} className={styles.Modal} onClose={() => this.setState({ showDeleteModal: false })}>
                                <Paper className={styles.RootModal}>
                                    <div>
                                        <DeleteBuyerModal closeModal={() => this.setState({ showDeleteModal: false })} submitYesModal={this.handleYesModal.bind(this)} ></DeleteBuyerModal>
                                    </div>
                                </Paper >
                            </Modal>
                        </Box>
                    }{
                        this.state.showSendMailModal &&
                        <Box>
                            <Modal open={this.state.showSendMailModal} className={styles.Modal} onClose={() => this.setState({ showSendMailModal: false })}>
                                <Paper className={styles.RootModal}>
                                    <div>
                                        <SendMailBuyerModal closeModal={() => this.setState({ showSendMailModal: false })} submitYesModal={this.handleSendYesModal.bind(this)} ></SendMailBuyerModal>
                                    </div>
                                </Paper >
                            </Modal>
                        </Box>
                    }
                </React.Fragment>
            </Paper>)
    }
}