import React, { useEffect } from 'react';
import ImportCarsForm from '../../components/forms/importCars/ImportCarsForm';
import ISellerProps from './../../types/ISellerProps';
import { IImportCar } from './../../types/IImportCar';
import ILegalEntity from './../../types/ILegalEntitiy';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { GetAllSellersAction } from '../../redux/actions/SellerAction';
import { ImportCarAction } from '../../redux/actions/CarAction';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

interface IProps extends IDispatchToProps, IStateToProps {}

interface IDispatchToProps {
  importCars: (data: IImportCar) => void;
  getAllSellers: () => void;
}

interface IStateToProps {
  sellers: ISellerProps[];
}

const ImportCars: React.FC<IProps> = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview('Admin | uvoz automobila');
    props.getAllSellers();
  }, []);

  const handleSubmit = (data: IImportCar) => {
    props.importCars(data);
  };

  return <ImportCarsForm submit={handleSubmit} sellers={props.sellers} />;
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
  sellers: state.sellers.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  importCars: (data: IImportCar) =>
    dispatch<ImportCarAction>({
      type: ActionTypes.IMPORT_CARS,
      payload: data,
    }),
  getAllSellers: () =>
    dispatch<GetAllSellersAction>({
      type: ActionTypes.GET_SELLERS,
    }),
});

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(ImportCars);
