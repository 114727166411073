import { Reducer } from "redux";
import ActionTypes from './../actions/ActionTypes';
import { IForgotPassword } from '../../types/IForgotPassword';

const initialValues: IForgotPassword = {
    email: '',
    loading: false
}

const forgotPasswordReducer: Reducer<IForgotPassword> = (state: IForgotPassword = initialValues, action): IForgotPassword => {
    switch (action.type) {
        case ActionTypes.EMAIL_SUBMIT: return { email: action.payload, loading: true };
        case ActionTypes.EMAIL_SUBMIT_SUCCESS: return { email: action.payload, loading: false, emailDelivered: true };
        case ActionTypes.EMAIL_SUBMIT_ERROR: return { email: state.email, loading: false };
        case ActionTypes.PASSWORD_CONFIRM_SUBMIT: return { email: action.payload, loading: true };
        case ActionTypes.PASSWORD_CONFIRM_SUCCESS: return { email: action.payload, loading: false };
        case ActionTypes.PASSWORD_CONFIRM_ERROR: return { email: state.email, loading: false };
        default: return state;
    }
}

export default forgotPasswordReducer;


