import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './NewDamageModal.module.scss';

interface IProps {
    closeModal: () => void,
    submitYesModal: () => void
}

export class DeleteDamageModal extends React.Component<IProps> {


    handleSubmitYes = () => {
        this.props.submitYesModal();
        this.props.closeModal();
    }

    handleSubmitNo = () => {
        this.props.closeModal();
    }


    render() {
        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="flex-start" justify="center" >
                    <Grid item xs={12}>
                        <Grid spacing={2}>
                            <Typography className={styles.Button} variant="h6" align="center">Jeste li sigurni da želite obrisati štetu?</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={12} spacing={8} justify="center">
                        <Grid item xs={12} md={6} spacing={2}>
                            <Button className={styles.Button} variant="contained" color="primary" style={{ float: "right" }} onClick={this.handleSubmitYes.bind(this)}>DA</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button className={styles.Button} variant="contained" color="secondary" onClick={this.handleSubmitNo.bind(this)}>NE</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </React.Fragment>
        )
    }
}