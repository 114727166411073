import { Reducer } from "redux";
import { IAuthenticationProps } from "../../types/IAuthenticationProps";
import ActionTypes from "../actions/ActionTypes";

const initialRegisterValues: IAuthenticationProps = {
    email: '',
    password: ''
}

const registerReducer: Reducer<IAuthenticationProps> = (state: IAuthenticationProps = initialRegisterValues, action): IAuthenticationProps => {
    switch (action.type) {
        case ActionTypes.REGISTER_SUBMIT: return { ...action.payload };
        case ActionTypes.REGISTER_SUCCESS: return { email: action.payload.email };
        case ActionTypes.REGISTER_ERROR: return { error: `Korisnik sa OIB-om ${state.oib} već postoji.`, email: state.email, password: state.password };
        default: return state;
    }
}

export default registerReducer;


