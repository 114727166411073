import React, { useEffect, useState } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { GetWarrantiesAction } from '../../redux/actions/WarrantyAction';
import { connect } from 'react-redux';
import ICarProps from '../../types/ICarProps';
import { SellerDashboard } from './../../components/dashboards/SellerDashboard';
import IBuyerProps from '../../types/IBuyerProps';
import IWarrantyOverview from './../../types/IWarrantyOverview';
import IWarranty from '../../types/IWarranty';
import IMissingCarDetailsProps from '../../types/IMissingCarDetailsProps';
import GoToPageAction from '../../redux/actions/GoToPageAction';
import ReactGA from 'react-ga';
import { Grid } from '@material-ui/core';
import { ThisMonthTotalWarrantiesSold } from './../../components/cards/thisMonthTotalWarrantiesSold';
import { isSeller } from './../../utils/AuthFunctions';


interface IStateToProps {
    warranties: IWarrantyOverview[],
    buyer: IBuyerProps,
    isCarDetailsMissing: boolean,
    warrantiesLoading: boolean,
    thisMonthtotalSoldWarranties: {
        lastMonth: number,
        thisMonth: number
    },
    thisMonthtotalSoldWarrantiesLoading: boolean,
}

interface IDispatchToProps {
    getWarranties: () => void,
    editWarranty: (warrantyId: number, carId: number) => void,
    sellCar: (buyer: IBuyerProps) => void,
    getBuyerByOIB: (oib: string) => void,
    getBuyerData: (carId: number, warrantyId: number) => void,
    goToWarrantyPage: () => void,
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => void,
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => void,
    getThisMonthSoldStatistics: (startDate: string, endDate: string, sellerName: string) => void,
    getBuyerByEmail: (email: string) => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const WarrantyOverview: React.FC<IProps> = props => {

    var date = new Date();
    const [firstDay, setFirstDay] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [lastDay, setLastDay] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    useEffect(() => {
        ReactGA.pageview("Trgovac | homepage");
        props.getWarranties();
        props.getThisMonthSoldStatistics(firstDay.toLocaleDateString("en-US"), lastDay.toLocaleDateString("en-US"), 'seller');
    }, []);

    const goToEditWarranty = (overviewData: IWarrantyOverview) => {
        props.editWarranty(overviewData.warrantyId, overviewData.carId)
    }

    return (
        <React.Fragment>
            <Grid container direction="row" style={{ display: 'block' }}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={6} style={{ marginBottom: 25, maxWidth: 362 }}>
                        {isSeller() && <ThisMonthTotalWarrantiesSold loading={props.thisMonthtotalSoldWarrantiesLoading} thisMonthtotalSoldWarranties={props.thisMonthtotalSoldWarranties} currentMonth={date.getMonth() + 1} />
                        }
                    </Grid>
                </Grid>
                <SellerDashboard {...props} goToEditWarranty={goToEditWarranty} setCarDetailsMissing={props.setCarDetailsMissing} />
                <br />
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    thisMonthtotalSoldWarranties: state.thisMonthStatistics.thisMonth.totalSoldWarranties,
    thisMonthtotalSoldWarrantiesLoading: state.thisMonthStatistics.loading,
    warranties: state.warranties.data,
    warrantiesLoading: state.warranties.loading,
    buyer: state.carBuyer,
    isCarDetailsMissing: state.isCarDetailsMissing.isCarDetailsMissing,
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getThisMonthSoldStatistics: (startDate: string, endDate: string, sellerName: string) => {
        dispatch({
            type: ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC,
            payload: { startDate, endDate, sellerName }
        })
    },
    getWarranties: () => dispatch<GetWarrantiesAction>({
        type: ActionTypes.GET_WARRANTIES
    }),
    editWarranty: (warrantyId: number, carId: number) => {
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
        })
    },
    sellCar: (buyer: IBuyerProps) => dispatch({
        type: ActionTypes.SELL_CAR,
        payload: buyer
    }),
    editMissingCarDetails: (missingCarDetails: IMissingCarDetailsProps) => dispatch({
        type: ActionTypes.EDIT_MISSING_CAR_DETAILS,
        payload: missingCarDetails
    }),
    getBuyerData: (carId: number, warrantyId: number) => dispatch({
        type: ActionTypes.GET_BUYER_BY_CAR_ID,
        payload: { carId, warrantyId }
    }),
    getBuyerByOIB: (oib: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_OIB,
        payload: oib
    }),
    goToWarrantyPage: () => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: '/warranty/step=1' }
    }),
    setCarDetailsMissing: (isCarDetailsMissing: boolean) => dispatch({
        type: ActionTypes.SET_IS_MISSING_CAR_DETAILS,
        payload: { isCarDetailsMissing: isCarDetailsMissing }
    }),
    getBuyerByEmail: (email: string) => dispatch({
        type: ActionTypes.GET_BUYER_BY_EMAIL,
        payload: email
    }),
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(WarrantyOverview);