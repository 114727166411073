import React from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table'
import ICarSellerTable from '../../types/ICarSellerTable';
import styles from './carsAdminTable.module.scss';

interface IProps {
    rows: ICarSellerTable[],
    goToCarDetails: (carId: number, warrantyId: string) => void,
}

const CarsSellerTable: React.FC<IProps> = (props: IProps) => {

    const onRowClick = (event?: React.MouseEvent, rowData?: ICarSellerTable, toggleDetailPanel?: (panelIndex?: number) => void) => {
        if (event && !event.currentTarget.baseURI.endsWith("damages") && !event.currentTarget.baseURI.includes("activate")) {
            props.goToCarDetails(rowData!.id!, rowData!.warrantyId!);
        }
    }

    return <Paper className={styles.Root}>
        <MaterialTable
            options={{
                paging: true,
                initialPage: 1,
                paginationType: 'normal',
            }}
            onRowClick={onRowClick}
            localization={{
                body: {
                    emptyDataSourceMessage: "Nema podataka za prikaz."
                },
                pagination: {
                    previousTooltip: 'Prethodna',
                    nextTooltip: 'Sljedeća',
                    firstTooltip: 'Početna',
                    lastTooltip: 'Posljednja'
                }
            }}
            title="Pregled svih vozila"
            columns={[
                { title: 'ID auta', field: 'id' },
                { title: 'Broj šasije', field: 'chassisNumber' },
                { title: 'Jamstvo', field: 'warrantyId' },
                { title: 'Marka auta', field: 'brand' },
                { title: 'Model auta', field: 'model' },
                { title: 'Kilometri', field: 'distance' },
                { title: 'Godina proizvodnje', field: 'manufactureYear' }
            ]}
            data={[...props.rows]}
        />
    </Paper>
}

export default CarsSellerTable;
