import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

interface IProps {
    closeModal: () => void,
    submitYesModal: () => void,
    isOneYearWarranty?: boolean
}

export class SaveWarrantyModal extends React.Component<IProps> {


    handleSubmitYes = () => {
        this.props.submitYesModal();
        this.props.closeModal();
    }

    handleSubmitNo = () => {
        this.props.closeModal();
    }


    render() {
        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="flex-start" justify="center" >
                    <Grid>
                        <Typography variant="subtitle1" align="center" >Jeste li sigurni da želite kreirati jamstvo u trajanju</Typography>
                        <Typography variant="subtitle1" align="center" >{this.props.isOneYearWarranty ?
                            <strong>12 mjeseci?</strong>
                            :
                            <strong>24 mjeseca?</strong>}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={12} spacing={4} justify="center">
                        <Grid item xs={12} md={6} spacing={2}>
                            <Button variant="contained" color="primary" style={{ float: "right", marginTop: "20px" }} onClick={this.handleSubmitYes.bind(this)}>DA</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="secondary" style={{ marginTop: "20px" }}onClick={this.handleSubmitNo.bind(this)}>NE</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </React.Fragment>
        )
    }
}