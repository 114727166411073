import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import IDamageTable from '../../types/Damage/IDamageAdminTable';
import DamagesTable from '../../components/tables/DamagesAdminTable';
import { GetDamagesAdminAction, GetDamagesAdminTotalCountAction } from '../../redux/actions/DamageAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    damages: IDamageTable[],
    totalCount: number
}

interface IDispatchToProps {
    getDamages: () => void,
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => void,
    getTotalCount: () => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const DamageAdminOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getDamages();
        props.getTotalCount();
    }, []);

    return (props.damages ? <DamagesTable rows={props.damages} goToDamagePage={props.goToDamagePage} totalCount={props.totalCount} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damages: state.damages.data.admin,
    totalCount: state.damagesAdminTableTotalCount.data
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getDamages: () => dispatch<GetDamagesAdminAction>({
        type: ActionTypes.GET_DAMAGES_ADMIN
    }),
    getTotalCount: () => dispatch<GetDamagesAdminTotalCountAction>({
        type: ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT
    }),
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: `/damage/${carId}/${warrantyId}/${damageId}` }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(DamageAdminOverview);