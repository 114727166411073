import IBuyerService from "./IBuyerService";
import { injectable, inject } from 'inversify';
import ThirdPartyTypes from "../../di/ThirdPartyTypes";
import { AxiosInstance } from 'axios';
import IBuyerProps from "../../types/IBuyerProps";
import { buyerApiURL, activateWarrantyApiURL, approvedWarrantiesApiURL, saveBuyerURL, deleteBuyerURL } from "../../constants/apiRoutes";
import IActivateWarranty from "../../types/IActivateWarranty";
import IWarranty from "../../types/IWarranty";

@injectable()
export default class BuyerService implements IBuyerService {

    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getAll(page: number, pageSize: number, filters: string): Promise<IBuyerProps[]> {
        const response = await this.apiClient.get<IBuyerProps[]>(buyerApiURL + "/all" + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getBuyersAdminTotalCount(): Promise<number> {
        const response = await this.apiClient.get(buyerApiURL + '/buyersAdminTotalCount');
        return response.data;
    }

    public async activateWarranty(data: IActivateWarranty) : Promise<any> {
        const response = await this.apiClient.post(activateWarrantyApiURL+"/"+data.email+"/"+data.warrantyId);
        return response.data;
    }

    public async getApprovedWarranties(email: string) : Promise<IWarranty[]> {
        const response = await this.apiClient.get<IWarranty[]>(approvedWarrantiesApiURL +"/"+email);
        return response.data;
    }

    public async getBuyerById(buyerId: string): Promise<IBuyerProps> {
        const response = await this.apiClient.get(buyerApiURL + '/buyerId/' + buyerId);
        return response.data;
    }

    public async saveBuyer(buyer: IBuyerProps): Promise<null> {
        const response = await this.apiClient.post(saveBuyerURL, buyer)
        return response.data;
    }

    public async deleteBuyer(buyer: IBuyerProps): Promise<null> {
        const response = await this.apiClient.post(deleteBuyerURL, buyer)
        return response.data;
    }

    public async activateBuyer(buyer: IBuyerProps): Promise<null> {
        const response = await this.apiClient.post(buyerApiURL + '/activateBuyer', buyer)
        return response.data;
    }

    public async sendActivationEmail(email: string): Promise<null> {
        const response = await this.apiClient.post(buyerApiURL + "/sendActivationEmail/" + email)
        return response.data;
    }

    public async getLegalBuyers(): Promise<IBuyerProps[]> {
        const response = await this.apiClient.get<IBuyerProps[]>(buyerApiURL + "/legalBuyers");
        return response.data;
    }
}