import React from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table'
import { Trans, useTranslation } from 'react-i18next';
import IDamageCarTable from '../../types/Damage/IDamageCarTable';
import styles from './damagesCarTable.module.scss';
import Button from '@material-ui/core/Button';
import { DamageStatus } from '../../domain/enums/DamageStatus';

interface IProps {
    rows: IDamageCarTable[],
    goToDamagePage: (damageId: number, carId: number, warrantyId: number) => void,
    carName: string
}

const DamagesCarTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    const mapPropsToTable = (): IDamageCarTable[] => {
        let newRows = new Array<IDamageCarTable>();

        props.rows.forEach((row: IDamageCarTable) => {
            const isApproved = row.status === DamageStatus.Approved;
            const isSolved = row.status === DamageStatus.Solved;
            let newRow: IDamageCarTable = {
                ...row,
                status: t(row.status),
                damageAction: isApproved || isSolved ? 'Pregledaj' : 'Uredi'
            }
            newRows.push(newRow);
        });
        return newRows;
    }

    function damageStatusStyle(rowData: IDamageCarTable) {
        const propsRow = findById(rowData.id);
        return <div className={styles[propsRow.status]}>{rowData.status}</div>
    }

    function damageButton(rowData: IDamageCarTable) {
        const propsRow = findById(rowData.id);
        const isApproved = propsRow.status === DamageStatus.Approved;
        const isSolved = propsRow.status === DamageStatus.Solved;
        return <Button color="primary" variant="contained" className={!isApproved && !isSolved ? styles.Edit : styles.View}
            size="small" onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.goToDamagePage(rowData.id, rowData.carId, rowData.warrantyId)}> {rowData.damageAction} </Button>
    }

    const findById = (id: number): IDamageCarTable => {
        return props.rows.filter((row: IDamageCarTable) => row.id === id)[0];
    }

    return <Paper className={styles.Root}>
        <MaterialTable
            options={{
                paging: true,
                initialPage: 1,
                paginationType: 'normal',
            }}

            localization={{
                body: {
                    emptyDataSourceMessage: "Nema podataka za prikaz."
                },
                pagination: {
                    previousTooltip: 'Prethodna',
                    nextTooltip: 'Sljedeća',
                    firstTooltip: 'Početna',
                    lastTooltip: 'Posljednja'
                }
            }}

            title={`Pregled šteta vozila ${props.carName}`}
            columns={[
                { title: 'ID štete', field: 'id' },
                { title: 'Broj šasije', field: 'chassisNumber' },
                { title: 'Trgovac', field: 'seller.fullName' },
                { title: 'Marka auta', field: 'brand' },
                { title: 'Model auta', field: 'model' },
                {
                    title: 'Status štete', field: 'status',
                    render: (rowData: IDamageCarTable) => damageStatusStyle(rowData)
                },
                { title: 'Troškovi (EUR) štete', field: 'cost' },
                { title: 'Datum prijave', field: 'reported' },
                {
                    title: 'Akcija štete',
                    field: "damageAction",
                    render: (rowData: IDamageCarTable) => damageButton(rowData)
                }
            ]}
            data={[...mapPropsToTable()]}
        />
    </Paper>
}

export default DamagesCarTable;
