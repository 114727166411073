import { Reducer } from "redux";
import ActionTypes from "../actions/ActionTypes";
import ILoading from './../../types/utilities/ILoading';

const initialLoadingValues: ILoading = {
    show: false
}

const loadingReducer: Reducer<ILoading> = (state: ILoading = initialLoadingValues, action): ILoading => {
    switch (action.type) {
        case ActionTypes.LOADING_START: return { show: true }
        case ActionTypes.LOADING_END: return { show: false }
        default: return state;
    }
}

export default loadingReducer;


