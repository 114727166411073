import { Reducer } from 'redux';
import ICarProps from '../../types/ICarProps';
import { PostCarAction, MissingCarDetailsAction, PostCarOnlyAction, PostCarOnlyAndContinueAction } from '../actions/CarAction';
import ActionTypes from '../actions/ActionTypes';
import ICarData from '../../types/ICarData';
import ICar from '../../types/ICar';
import IBuyerProps from '../../types/IBuyerProps';
import ICarReview from "../../types/ICarReview";
import { ICarCard } from '../../types/ICarCard';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';

type NullableNewCarProps = ICarProps | null;
const initialCarData: { loading: boolean, data: ICarData } = {
    loading: false, data: {
        carBrands: [], carModels: [], engineTypes: []
    }
}

export const postCarReducer: Reducer<NullableNewCarProps, PostCarAction> = (state: NullableNewCarProps = null, action: PostCarAction): NullableNewCarProps => {
    if (action.type === ActionTypes.POST_CAR_DATA) return { ...action.payload }
    return state;
}

type NullableNewCarOnlyProps = ICar | null;

export const postCarOnlyReducer: Reducer<NullableNewCarOnlyProps, PostCarOnlyAction> = (state: NullableNewCarOnlyProps = null, action: PostCarOnlyAction): NullableNewCarOnlyProps => {
    if (action.type === ActionTypes.POST_CAR_ONLY) return { ...action.payload }
    return state;
}

export const postCarOnlyAndContinueReducer: Reducer<NullableNewCarOnlyProps, PostCarOnlyAndContinueAction> = (state: NullableNewCarOnlyProps = null, action: PostCarOnlyAndContinueAction): NullableNewCarOnlyProps => {
    if (action.type === ActionTypes.POST_CAR_ONLY_AND_CONTINUE) return { ...action.payload }
    return state;
}

export const carDataReducer: Reducer<{ loading: boolean, data: ICarData | null }> = (state = initialCarData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CAR_DATA: return { loading: true, data: null }
        case ActionTypes.GET_CAR_DATA_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_CAR_DATA_ERROR: return { loading: false, data: null, errors: action.payload }
        case ActionTypes.GET_CAR_MODELS: return { loading: true, data: state.data }
        case ActionTypes.GET_CAR_MODELS_SUCCESS: return { loading: false, data: { ...state.data, carModels: action.payload } }
        default: return state;
    }
}

export const initialBuyerProp: IBuyerProps = {
    firstName: '', lastName: '', oib: '',
    address: '', email: '', zipCode: '',
    city: '', country: '', phoneNumber: '',
    legalEntityName: '', carId: undefined,
    legalEntity: false, warrantyId: undefined,
    parentId: ''
};

export const carBuyerReducer: Reducer<IBuyerProps> = (state = initialBuyerProp, action): IBuyerProps => {
    switch (action.type) {
        case ActionTypes.SELL_CAR: return { ...action.payload }
        case ActionTypes.SELL_CAR_SUCCESS: return { ...action.payload, carSoldSuccess: true }
        case ActionTypes.SELL_CAR_ERROR: return { ...action.payload, carSoldSuccess: false }
        case ActionTypes.GET_BUYER_BY_CAR_ID: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_CAR_ID_SUCCESS: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_CAR_ID_ERROR: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_OIB: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_OIB_SUCCESS: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_OIB_ERROR: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_EMAIL: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_EMAIL_SUCCESS: return { ...action.payload }
        case ActionTypes.GET_BUYER_BY_EMAIL_ERROR: return { ...action.payload }
        default: return state
    }
}

export const initialCarReview: ICarReview = {
    brandIsValid: true,
    modelIsValid: true,
    engineTypeIsValid: true,
    enginePowerIsValid: true,
    distanceIsValid: true,
    chassisNumberIsValid: true,
    manufactureYearIsValid: true,
    registrationYearIsValid: true,
    priceIsValid: true,
    engineDisplacementIsValid: true,
    maxTonnageIsValid: true
}

export const carReviewReducer: Reducer<ICarReview> = (state = initialCarReview, action): ICarReview => {
    switch (action.type) {
        case ActionTypes.REVIEW_CAR: return { ...action.payload, carReview: initialCarReview }
        case ActionTypes.REVIEW_CAR_SUCCESS: return { ...action.payload }
        case ActionTypes.REVIEW_CAR_ERROR: return { ...action.payload }
        case ActionTypes.GET_CURRENT_CAR_REVIEW: return { ...initialCarReview }
        case ActionTypes.GET_CURRENT_CAR_REVIEW_SUCCESS: return { ...action.payload }
        case ActionTypes.GET_CURRENT_CAR_REVIEW_ERROR: return { ...action.payload }
        default: return state
    }
}

const initialMissingData: { isCarDetailsMissing: boolean } = {
    isCarDetailsMissing: false
}


export const isCarDetailsMissing: Reducer<any, MissingCarDetailsAction> = (state = initialMissingData, action): {isCarDetailsMissing: boolean} => {
    switch (action.type) {
        case ActionTypes.SET_IS_MISSING_CAR_DETAILS: return { ...action.payload }
        default: return state
    }
}


type NullableCarCards = { data: {hasMore: boolean, cars: ICarCard[] }, errors?: string } | null;

export const sellerCarData: Reducer<NullableCarCards> = (state: NullableCarCards = { data: { hasMore: false, cars: [] } }, action: any): NullableCarCards => {
    switch (action.type) {
        case ActionTypes.FILTER_CARS: return { data: { hasMore: false, cars: [] } }
        case ActionTypes.FILTER_CARS_SUCCESS: return { data: action.payload }
        case ActionTypes.LOAD_MORE_FILTER_CARS_SUCCESS: return { ...state, data: {
           cars: [...state!.data.cars, ...action.payload.cars],
           hasMore: action.payload.hasMore
        }  }
        case ActionTypes.FILTER_CARS_ERROR: return {  data: { hasMore: false, cars: [] }, errors: action.payload }

        default: return state;
    }
}

const initialCarProblemExtraPackageData: ICarProblemExtraPackageData = {
    loading: false,
    data: []
}

interface ICarProblemExtraPackageData {
    loading: boolean,
    data: ICarProblemExtraPackage[]
}

export const getCarProblemExtraPackageReducer: Reducer<ICarProblemExtraPackageData> = (state = initialCarProblemExtraPackageData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE: return { loading: true, data: [] }
        case ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE_SUCCESS: return { loading: false, data: action.payload || [] }
        case ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE_ERROR: return { loading: false, errors: action.payload, data: [] }

        default: return state;
    }
}




type NullableCarDetailsData = {
    data: ICarCard | undefined,
    loading: boolean,
    error?: string
} | null;

export const carDetailsReducer: Reducer<NullableCarDetailsData> = (state: NullableCarDetailsData = { data: undefined, loading: false }, action): NullableCarDetailsData => {
    switch (action.type) {
        case ActionTypes.GET_CAR_DETAILS: return { data: undefined, loading: true }
        case ActionTypes.GET_CAR_DETAILS_SUCCESS: return { data: action.payload, loading: false }
        case ActionTypes.GET_CAR_DETAILS_ERROR: return { data: action.payload, loading: false }
        default: return state;
    }
}


const initialDropdownCarData: { data: ICarData } = {
    data: {
        carBrands: [], carModels: [], engineTypes: []
    }
}

export const carDropdowndDataReducer: Reducer<{ data: ICarData }> = (state = initialDropdownCarData, action) => {
    switch (action.type) {
        case ActionTypes.GET_ENGINE_TYPES_SUCCESS: return { data: { ...state.data, engineTypes: action.payload } }
        case ActionTypes.GET_ENGINE_TYPES_ERROR: return { data: { ...state.data, engineTypes: [] }, errors: action.payload }
        case ActionTypes.GET_CAR_BRANDS_SUCCESS: return { data: { ...state.data, carBrands: action.payload } }
        case ActionTypes.GET_CAR_BRANDS_ERROR: return { data: { ...state.data, carBrands: [] }, errors: action.payload }
        case ActionTypes.GET_CAR_MODELS_BY_BRAND_SUCCESS: return { data: { ...state.data, carModels: action.payload } }
        case ActionTypes.GET_CAR_MODELS_BY_BRAND_ERROR: return { data: { ...state.data, carModels: [] }, errors: action.payload }
        default: return state;
    }
}

type NullableCar = number | null;
export const carReducer: Reducer<NullableCar> = (state: NullableCar = null, action): NullableCar => {
    switch (action.type) {
        case ActionTypes.GET_CAR: return { ...action.payload }
        case ActionTypes.GET_CAR_SUCCESS: return { ...action.payload }
        case ActionTypes.GET_CAR_ERROR: return { ...action.payload }
        default: return state;
    }
}

type NullableChassisCar = ICarProps | null;
export const carChassisReducer: Reducer<NullableChassisCar> = (state: NullableChassisCar = null, action): NullableChassisCar => {
    switch (action.type) {
        case ActionTypes.GET_CHASSIS_CAR: return { ...action.payload }
        case ActionTypes.GET_CHASSIS_CAR_SUCCESS: return { ...action.payload }
        case ActionTypes.GET_CHASSIS_CAR_ERROR: return { ...action.payload }
        default: return state;
    }
}



const initialCarsDropdownData: { loading: boolean, data: ICarProps[] } = { loading: false, data: new Array<ICarProps>() }

export const getCarsDropdownReducer: Reducer<{ loading: boolean, data: ICarProps[] }> = (state = initialCarsDropdownData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CARS_DROPDOWN_ADMIN: return { loading: true, data: [] }
        case ActionTypes.GET_CARS_DROPDOWN_ADMIN_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_CARS_DROPDOWN_ADMIN_ERROR: return { loading: false, errors: action.payload, data: [] }
        case ActionTypes.GET_CARS_DROPDOWN_SELLER: return { loading: true, data: [] }
        case ActionTypes.GET_CARS_DROPDOWN_SELLER_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_CARS_DROPDOWN_SELLER_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}