import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import ExportInvoiceForSellerComponent from "../../components/exportInvoiceForSeller/ExportInvoiceForSellerComponent";
import { IInvoiceData } from "../../types/IInvoiceData";
import moment from "moment";
import 'moment/locale/hr'  // without this line it didn't work
import { ICurrency } from "../../types/ICurrency";
import { GetInvoiceAction } from '../../redux/actions/InvoiceAction';
moment.locale('hr')

interface IStateToProps {
    lastInvoice: string
    invoice: IInvoiceData | null;
}

interface IDispatchToProps {
    sendWarrantyInvoice?: (data: IInvoiceData) => void;
    getLastInvoice?: () => void;
    getInvoice?: (invoiceNumber: string) => void;
}

interface IProps extends IDispatchToProps, IStateToProps {
    sellerName: string,
    sellerId: string,
    startDate: string,
    endDate: string,
    currencies: ICurrency[]
}

const WarrantyInvoice: React.FC<IProps> = props => {
    const { invoice } = props;

    useEffect(() => {
        // console.log("da")
        //  props.getLastInvoice!();
    }, [])
    const today = moment.now();
    var issueDate = "";
    // helper za datum izdavanja
    // ako se izdaje u trenutnom mjesecu onda ostavi danasnji datum
    // ako se izdaje u iducem mjesecu, onda predlozi datum od proslog mjeseca (zadnji dan)
    // npr. izdajem racun 25.5, onda ce datum izdavanja bit 25.5
    // izdajem racun 3.6, datum izdavanja ce bit 30.5 
    // na ovaj nacin pdv ulazi u prosli misec -_> zrelja
    if (moment(today).date() > 15) {
        issueDate = moment(moment.now()).format('L').toString();
    } else {
        issueDate = moment().date(0).format('L').toString();
    }
    issueDate = issueDate + " " + moment().format('LT');

    var nextInvoice = "";
    if (props.lastInvoice !== "") {
        let firstNum = (Number(props.lastInvoice.split("/")[0]) + 1).toString().padStart(4, "0");
        nextInvoice = firstNum + "/" + props.lastInvoice.split("/")[1] + "/" + props.lastInvoice.split("/")[2].split(" ")[0];
    }

    var pdv = true;

    return (
        <ExportInvoiceForSellerComponent
            sellerName={props.sellerName}
            sellerId={props.sellerId}
            startDate={props.startDate}
            endDate={props.endDate}
            sendWarrantyInvoice={props.sendWarrantyInvoice}
            lastInvoice={props.lastInvoice}
            nextInvoice={nextInvoice}
            issueDate={issueDate}
            getLastInvoice={props.getLastInvoice}
            key={props.sellerId}
            currencies={props.currencies}
            currencyId={props.currencies[0].id}
            getInvoice={props.getInvoice}
            invoice={invoice}
            pdv={pdv}
        />
    )
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    lastInvoice: state.lastInvoice.data,
    invoice: state.invoice.data.invoice,
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    sendWarrantyInvoice: (data: IInvoiceData) =>
        dispatch({
            type: ActionTypes.SEND_INVOICE,
            payload: data
        }),
    getLastInvoice: () =>
        dispatch({
            type: ActionTypes.GET_LAST_INVOICE,
        }),
    getInvoice: (invoiceNumber: string) => dispatch<GetInvoiceAction>({
        type: ActionTypes.GET_INVOICE,
        payload: { invoiceNumber }
    }),

});

export default connect<
    IStateToProps,
    IDispatchToProps,
    IProps,
    IApplicationState
>(
    mapStateToProps,
    mapDispatchToProps
)(WarrantyInvoice);
