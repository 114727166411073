import React, { useRef, useState, useEffect } from "react";
import { Paper, Button, Input, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import ISellerProps from "./../../types/ISellerProps";
import styles from "./marketingSellersTable.module.scss";
import IFilterModel from "../../types/IFilterModel";

interface IProps {
    rows: ISellerProps[];
    getMarketingSellers: (page: number, pageSize: number, filters: string) => void;
    totalCount: number;
}

const MarketingSellersTable: React.FC<IProps> = (props: IProps) => {
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filterArray, setFilterArray] = useState<IFilterModel[]>([
        { key: "fullName", value: "" },
        { key: "email", value: "" },
    ]);
    const [filters, setFilters] = useState<string>("");
    const [columnFocus, setColumnFocus] = useState<string>("");
    const tableRef = useRef();

    useEffect(() => {
        if (tableRef && tableRef.current) {
            const a = tableRef.current as any;
            a.onQueryChange();
        }
    }, [props.rows]);


    const createFilters = () => {
        let newFilters = "";

        const name = filterArray.find(
            (x: IFilterModel): boolean => x.key === "fullName"
        )!.value;
        const email = filterArray.find(
            (x: IFilterModel): boolean => x.key === "email"
        )!.value;

        if (name !== "") {
            newFilters = newFilters + "FullName@=*" + name;
        }

        if (email !== "") {
            newFilters = newFilters + ",Email@=*" + email;
        }

        if (newFilters.substring(0, 1) === ",")
            newFilters = newFilters.substring(1);

        setFilters(newFilters);
    };

    const setFilterValue = (field: string, value: string) => {
        filterArray.find((x: IFilterModel): boolean => x.key === field)!.value =
            value;

        createFilters();
    };

    return (
        <Paper className={styles.Root}>
            <Grid
                style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            >
                <Button
                    onClick={() => props.getMarketingSellers(page + 1, pageSize, filters)}
                    color="inherit"
                    variant="contained"
                >
                    TRAŽI
                </Button>
            </Grid>
            <MaterialTable
                tableRef={tableRef}
                options={{
                    filtering: true,
                    search: false,
                    paging: true,
                    initialPage: page,
                    paginationType: "normal",
                    pageSize: pageSize,
                    pageSizeOptions: [5, 10, 20, 50, 100, 250],
                    rowStyle: (row: ISellerProps) => {
                        return {
                            backgroundColor: row.oznaka === true ? "#FCEAEA" : "#FFFFFFF",
                        };
                    },
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "Nema podataka za prikaz.",
                    },
                }}
                title="Pregled svih prodavača"
                columns={[
                    { title: "Ime trgovca", field: "fullName", filtering: true },
                    { title: "Email", field: "email", filtering: true },
                    { title: "Kontakt broj", field: "phoneNumber", filtering: false },
                ]}
                data={(query) =>
                    new Promise(async (resolve, reject) => {
                        resolve({
                            data: props.rows,
                            page: page,
                            totalCount: props.totalCount,
                        });
                    })
                }
                components={{
                    FilterRow: (rowProps) => {
                        const { columns } = rowProps;

                        return (
                            <>
                                <tr>
                                    {columns
                                        .filter((c: any): boolean => c.filtering === true)
                                        .map((col: any) => {
                                            if (col.field) {
                                                return (
                                                    <td>
                                                        <Input
                                                            autoFocus={columnFocus === col.field}
                                                            key={col.field}
                                                            id={col.field}
                                                            value={
                                                                filterArray.find(
                                                                    (x: IFilterModel): boolean =>
                                                                        x.key === col.field
                                                                )!.value
                                                            }
                                                            style={{ marginLeft: "5px", marginRight: "5px" }}
                                                            onChange={(e) => {
                                                                setFilterValue(col.field, e.target.value);
                                                                setColumnFocus(col.field);
                                                            }}
                                                        />
                                                    </td>
                                                );
                                            }
                                        })}
                                </tr>
                            </>
                        );
                    },
                }}
                onChangePage={(newPage: number) => {
                    setPage(newPage);
                    props.getMarketingSellers(newPage + 1, pageSize, filters);
                }}
                onChangeRowsPerPage={(newPageSize: number) => {
                    setPageSize(newPageSize);
                    props.getMarketingSellers(page + 1, newPageSize, filters);
                }}
            />
        </Paper>
    );
};

export default MarketingSellersTable;
