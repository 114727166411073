import React from 'react';
import styles from './legal.module.scss';
import PublicFormLayout from '../authentication/PublicFormLayout';
import { BackButton } from '../buttons/BackButton';


const PrivacyPolicy = () => {
    return (
        <PublicFormLayout className={styles.Layout} md={true} lg={true}>
            <div className={styles.Root}>
                <p>
                    <h2 className={styles.Header}>Izjava o privatnosti</h2>
                    <p><strong>Ova Izjava o privatnosti tvrtke Točka na J d.o.o. (OIB: 57739667576) primjenjuje se od 25. svibnja 2018.</strong></p>
                    <ol>
                        <li>svibnja 2018. godine na snagu je stupila nova Uredba o za&scaron;titi osobnih podataka (GDPR - General Data Protection Regulation) s kojom dobivate vi&scaron;e prava na za&scaron;titu i privatnost svojih osobnih podataka.</li>
                    </ol>
                    <p>Točka na J d.o.o. svojom Izjavom o privatnosti obavljuje kako skupljamo, obrađujemo i pohranjujemo osobne podatke. U nastavku možete u cijenolosti pročitati Izjavu o privatnosti te nam se slobodno obratiti za sva va&scaron;a pitanja na adresu e-po&scaron;te koju navodimo u Izjavi.</p>
                    <p>Ova Izjava opisuje koje podatke prikupljamo, na koji ih način obrađujemo te u koje ih svrhe upotrebljavamo, kao i va&scaron;a prava povezana s va&scaron;im podacima.</p>
                    <p>Kontakt osoba za za&scaron;titu osobnih podataka dostupna je putem e-po&scaron;te:<br /> e-po&scaron;te <strong>szop@g1klub.hr</strong> &nbsp;&nbsp;kontakt telefon: <strong>+385(0) 91 333 4443</strong></p>
                    <p>Osobne podatke koje prikupljamo su:&nbsp;&nbsp;ime i prezime, adresa, kontakt telefon za komunikaciju, e-mail, osobni identifikacijski broj (OIB), kolačići, ostali osobni podatci koje nam vi ili treće osobe stavljate na raspolaganje tijekom poslovne suradnje kao &scaron;to su video snimke, fotografije i dr.</p>
                    <p><strong>Općenite informacije o kolačićima</strong></p>
                    <p>Ova web stranica radi boljeg korisničkog iskustva na web stranici na va&scaron;e računalo sprema male tekstualne datoteke koje nazivamo kolačići. Ova praksa zastupljena je u najmanje 90% web stranica u svijetu, ali prema Direktivi EU iz svibnja 2011. godine, obvezni smo prije pohranjivanja kolačića zatražiti va&scaron; pristanak. Kori&scaron;tenjem web stranice pristajete na uporabu kolačića, a us slučaju njihovog blokiranja&nbsp; &nbsp;i dalje možete pregledavati stranicu, no neke njezine mogućnosti&nbsp; neće biti dostupne. Nakon 25. svibnja i početka primjene Opće uredbe za za&scaron;titu podatka (GDPR) svaka web stranica treba omogućiti korisniku mogućnost jednostavnog povlačenja privole za kori&scaron;tenje kolačića. Da biste na na&scaron;oj web stranici, nakon &scaron;to ste kliknuli "Slažem se", povukli svoju privolu, jednostavno kliknite na gumb "Kolačići - promjena postavki" i kliknite "Ne slažem se". Može se dogoditi da kori&scaron;tenje određenih funkcionalnosti web stranice postane onemogućeno nakon povlačenja privole za kori&scaron;tenje kolačića.</p>
                    <p>Kolačić je mala datoteka koju na va&scaron;e računalo sprema web stranica koju posjetite. Kolačić obavje&scaron;tava web stranicu da se "stari" korisnik ponovno priključio pamteći njegove postavke. Kolačići bilježe va&scaron;e preferencije i postavke, unose ih u web obrasce i informacije za prijavljene korisnike.</p>
                    <p>Kolačići mogu spremati &scaron;irok pojas informacija, ali ta informacija ili informacije mogu biti spremljene jedino ako korisnik to omogući ‒ web stranice ne mogu dobiti pristup informacijama koji im korisnik nije dao i ne mogu pristupiti drugim datotekama na korisnikovu računalu. Kolačići ne sadržavaju nikakve osobne podatke odnosno informacije koje bi vas mogle identificirati. Korisnik, pak, može promijeniti svoje postavke na internetu te time sam birati hoće li zahtjeve za spremanje kolačića odobriti ili odbiti, hoće li spremljene kolačiće automatski pobrisati pri zatvaranju internetskoga preglednika i sl.</p>
                    <p>Isključivanjem kolačića odlučujete hoćete li dopustiti njihovo pohranjivanje na va&scaron;em računalu. Postavke s kolačićima mogu se kontrolirati i konfigurirati u va&scaron;em internetskom pregledniku. Za informacije o postavkama kolačića odaberite internetski preglednik kojim se koristite. Preglednici:</p>
                    <p>Internet Explorer 8 i noviji<br /> FireFox 3.5 i noviji<br /> Google Chrome 10 i noviji<br /> Safari 2 i noviji<br /> Opera 10.5 i noviji.<br /> Blokiranjem kolačića i dalje možete pregledavati web stranice, no određene funkcionalnosti neće vam biti na raspolaganju na njima.</p>
                    <p>Stalni kolačići<br /> Stalni ili spremljeni kolačići sačuvani su na disku (va&scaron;em računalu) te ondje ostaju i nakon zatvaranja internetskog preglednika. Pomoću njih pohranjuju se podaci kao &scaron;to su ime za prijavu i lozinka pa se ne morate prijavljivati prilikom svakog posjeta određenom mjestu.</p>
                    <p>Privremeni kolačići<br /> Privremeni kolačići privremeno se pohranjuju na disku te se bri&scaron;u nakon zatvaranja internetskog preglednika. Pomoću njih web stranice pohranjuju privremene podatke kao &scaron;to su stavke u ko&scaron;arici za kupnju.</p>
                    <p>Kolačići prve strane<br /> "First party cookies" (kolačići prve strane) dolaze s web-mjesta koje korisnik pregledava i mogu biti trajni ili privremeni. Web-mjesta te kolačiće upotrebljavaju za spremanje informacija koje će ponovo upotrebljavati kada korisnik sljedeći put posjeti to mjesto.</p>
                    <p>Kolačići treće strane<br /> Kolačići treće strane potječu od drugih, partnerskih web-mjesta koja gledate. Treće strane mogu tako prikupljati podatke o korisnicima različitih web-mjesta i upotrebljavati ih u marketin&scaron;ke i analitičke svrhe.</p>
                    <p>Na&scaron;a web stranica i kori&scaron;tenje kolačića<br /> Koristimo kolačiće ponajprije kako bismo vam kori&scaron;tenje na&scaron;e web stranice omogućili bolje korisničko iskustvo.</p>
                    <p>Vrste kolačića kojima se koristimo<br /> Privremeni kolačići koji istječu (i automatski se bri&scaron;u) kada zatvorite internetski preglednik, a njima se služimo kako bismo unaprijedili korisničko iskustvo za posjeta.</p>
                    <p>Trajni kolačići koji obično imaju datum isteka daleko u budućnost i tako će ostati u va&scaron;em pregledniku, dok ne isteknu, ili dok ih ručno ne izbri&scaron;ete ‒ služimo se kako bismo bolje razumjeli navike korisnika, tako da možemo pobolj&scaron;ati web stranicu prema va&scaron;im navikama. Ova je informacija anonimna.</p>
                    <p>Kolačići treće strane<br /> Postoji nekoliko vanjskih servisa koji korisniku spremaju ograničene kolačiće. Ove kolačiće nije postavila na&scaron;a web stranica, ali neki služe za normalno funkcioniranje određenih mogućnosti koje korisnicima olak&scaron;avaju pristup sadržaju. Trenutačno omogućujemo:</p>
                    <p>Dru&scaron;tvene mreže:&nbsp;Korisnici se mogu prijaviti i registrirati putem vlastitih računa s dru&scaron;tvenih mreža Facebook. Također dijeljenje članaka na dru&scaron;tvenim mrežama korisniku postavlja kolačiće.</p>
                    <p>Mjerenje posjećenosti:&nbsp;Ova web stranica koristi se servisom za mjerenje posjećenosti Google Analytics. Ako želite onemogućiti da vam navedeni servis sprema kolačiće, možete ga zabraniti na sljedećem linku:</p>
                    <p><a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>
                    <p>Dodatne informacije o isključivanju kolačića</p>
                    <p>Trenutačno postoji nekoliko web stranica za isključivanje pohranjivanja kolačića za različite servise, a vi&scaron;e se možete informirati na sljedećim poveznicama:</p>
                    <p><a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a></p>
                    <p><a href="http://www.allaboutcookies.org/">http://www.youronlinechoices.eu/</a></p>
                    <p><a href="http://www.networkadvertising.org/">http://www.networkadvertising.org/</a></p>
                    <p>Osobne podatke obrađujemo u svrhu :</p>
                    <ul>
                        <li><strong>Pružanja na&scaron;ih usluga</strong>&nbsp;&scaron;to uključuje komuniciranje s vama u vezi pružanja usluga, odgovaranje na va&scaron;e upite, sklapanje ugovora i ispunjavanje obveza koje proizlaze iz sklopljenog ugovora te izdavanja računa za izvr&scaron;enu uslugu</li>
                        <li><strong>U svrhu marketin&scaron;ke komunikacije</strong>&nbsp;(uključujući obavijesti o novim uslugama i nadogradnjama postojećih usluga i događajima koje organiziramo</li>
                        <li><strong>Va&scaron;i podaci će se ustupiti trećim osobama</strong>&nbsp;samo kada to opravdava zakonski interes za osiguravanje sigurnog i zakonitog rada (na&scaron; povjeren knjigovodstveni servis&nbsp;koji ispunjava zakonske zahtjeve u vezi sa Općom uredbom za za&scaron;titu osobnih podataka) i ispunjavanje zakonskih obveza (npr. kod poreznih obaveza koje mogu uključivati posredovanje va&scaron;ih osobnih podataka u Poreznoj upravi). Va&scaron;i podaci će se ustupiti trećoj strani u svrhu izvr&scaron;enja usluge prijevozna vozila i usluga popravka vozila odgovornoj kompaniji. S njima smo potpisali&nbsp;ugovor te postali pravno obvezajući s uvjetima koji udovoljavaju zahtjevima GDPR-a i koji odražavaju va&scaron;u privatnost podataka i sigurnost obveza prema klijentima.</li>
                        <li><strong>Provođenja pravnih zahtjeva i rije&scaron;avanje sporova.&nbsp;Kako bismo za&scaron;titili na&scaron;e poslovanje i proveli i/ili za&scaron;titili svoja prava, može doći do otkrivanja osobnih podataka. Va&scaron;e osobne podatke ćemo otkriti samo na način i pod uvjetima koji su propisani zakonom.</strong></li>
                        <li><strong>Za potrebe statističke analize&nbsp;kako bismo unaprijedili iskustva posjete na&scaron;e web stranice</strong></li>
                    </ul>
                    <p>Razdoblje čuvanja osobnih podataka obrađujemo u svrhu :</p>
                    <p>Osobne podatke čuvamo u skladu s važećim Zakonom o za&scaron;titi osobnih podataka. Spremamo ih onoliko dugo koliko je potrebno za svrhu za koju se obrađuju ili za postupanje u skladu sa zakonom. Osobne podatke koje obrađujemo na temelju va&scaron;e osobne suglasnosti, pohranjujemo do va&scaron;eg opoziva.<br /> <br /> Molimo da uzmete u obzir da ne bri&scaron;emo va&scaron;e podatke:<br /> </p>
                    <ul>
                        <li>ako su potrebni za obračun tro&scaron;kova radova, a možemo ih obrađivati samo do zastare tražbina u skladu s općim propisima kojima se uređuju obvezno-pravni odnosi<br /> </li>
                        <li>u slučaju da je pokrenut postupak prisilne naplate neplaćenih potraživanja<br /> </li>
                        <li>ako je uložen prigovor na proizvod ili uslugu u roku, sve do konačnog dovr&scaron;etka postupaka po prigovoru u skladu s važećim propisima (npr. Zakon o za&scaron;titi potro&scaron;ača i dr.).</li>
                    </ul>
                    <p><br /> Moguće je da se umjesto brisanja podaci anonimiziraju.&nbsp;</p>
                    <p>Kod obrade va&scaron;ih osobnih podataka možemo se poslužiti pružateljima usluga izvan EU. To ćemo učiniti samo ako za tu treću zemlju postoji odluka Europske komisije o primjerenosti ili ako smo s pružateljem usluga ugovorili pridržavanje obvezujućih propisa o za&scaron;titi osobnih podataka.<br /> <br /> S osobnim podacima koje nam stavljate na raspolaganje prilikom ispunjavanja kontakt obrazaca ili putem e-maila postupamo povjerljivo. Va&scaron;i se podaci koriste kako bi se obradio va&scaron; upit.Va&scaron;e osobne podatke obrađujemo na temelju privola.</p>
                    <p>Za&scaron;tita osobnih podataka :</p>
                    <p>Kako bismo za&scaron;titili va&scaron;e osobne podatke od neovla&scaron;tenog pristupa, kori&scaron;tenja ili slučajnog gubitka provodimo sve&nbsp;tehničke i organizacijske sigurnosne postupke u skladu s na&scaron;im mogućnostima i pravilima Opće uredbe za za&scaron;titu osobnih podataka na način da osiguramo&nbsp;da samo ovla&scaron;tene osobe za obradu podataka imaju pristup va&scaron;im osobnim podacima samo onda kada je to potrebno i u skladu sa svrhom prikupljanja osobnih podataka.<br /> <br /> Primjeri&nbsp;takvih postupaka su:</p>
                    <ul>
                        <li>praćenje, pregled i održavanje&nbsp;IT infrastrukture</li>
                        <li>izrade sigurnosnih kopija</li>
                        <li>za&scaron;tita računala antivirusnim programima</li>
                        <li>pravilo otvaranja e-po&scaron;te iz nepoznatih izvora</li>
                        <li>itd.</li>
                    </ul>
                    <p>Prikupljeni podaci u elektroničkom obliku su za&scaron;tićeni SSL certifikatom koji enkriptira podatke i tako osigurava da se komunikacija između računala korisnika i na&scaron;e web stranice odvija sigurnim protokolom. Za&scaron;titu podataka uzimamo ozbiljno i poduzimamo razne mjere opreza kako bi osobni podaci bili za&scaron;tićeni. Nažalost, niti jedan prijenos podataka preko Interneta, ili bilo koje bežične mreže ne može biti 100% siguran. Kao posljedica toga, iako provodimo razumne za&scaron;titne mjere za za&scaron;titu podataka ne možemo jamčiti za&scaron;titu bilo koje informacije prenesene na ili s web stranice te nismo odgovorni za radnje bilo koje treće strane koja takve informacije primi.</p>
                    <p>Va&scaron;a prava :</p>
                    <p><strong>Pravo na pristup osobnim podatcima:</strong></p>
                    <p>U bilo kojem trenutku od nas možete zatražiti pristup va&scaron;im osobnim podatcima.</p>
                    <p><strong>Pravo na ispravak (dopunu, izmjenu) netočnih osobnih podataka:</strong></p>
                    <p>Ako obrađujemo va&scaron;e osobne podatke koji su nepotpuni ili netočni, u bilo kojem trenutku od nas možete zatražiti da ih ispravimo ili dopunimo.<a href="mailto:gdpr@lavpromocija.hr"><br /> </a></p>
                    <p><strong>Pravo na zaborav, odnosno brisanje osobnih podataka:</strong></p>
                    <p>U bilo kojem trenutku od nas možete zatražiti brisanje svojih osobnih podataka ali vas molimo da uzmete u obzir da postoje razlozi koji onemogućuju trenutačno brisanje, primjerice kod zakonski propisanih obveza arhiviranja. Privolu koju ste dali možete opozvati u bilo kojem trenutku.<br /> <br /> Ako opozovete dane privole, va&scaron;e podatke vi&scaron;e nećemo upotrebljavati u navedene svrhe, &scaron;to može rezultirati nemogućno&scaron;ću kori&scaron;tenja nekim dodatnim pogodnostima koje su vezane za njih.<br /> <br /> Ako ponovno želite dati svoju privolu, to možete učiniti&nbsp;slanjem privole <strong><a href="/consent">ovdje</a></strong>&nbsp;<br /> <br /> Obrada podataka nužna je za zaključenje poslovne suradnje s nama ili ispunjenje zaključenog ugovora. Ako nam ne dostavite te podatke, nećemo biti u stanju ispuniti svoje obveze prema vama niti ćemo moći s vama zaključiti ugovor.</p>
                    <p><strong>Pravo na prigovor vezan uz obradu osobnih podataka:&nbsp;</strong></p>
                    <p>Ako va&scaron;e podatke distribuiramo radi izvr&scaron;enja zadaća od javnog interesa ili javnih tijela ili se prilikom njihove obrade pozivamo na na&scaron;e legitimne interese, možete podnijeti prigovor protiv takve obrade podataka ako postoji interes za&scaron;tite va&scaron;ih podataka.</p>
                    <p><strong>Pravo na ograničenje distribucije:&nbsp;</strong></p>
                    <p>Od nas možete zatražiti ograničenje distribucije svojih podataka:</p>
                    <ul>
                        <li>ako osporite točnost podataka tijekom perioda koji nam omogućava provjeru točnosti tih podataka</li>
                        <li>ako je obrada podataka bila protupravna, ali odbijate brisanje i umjesto toga tražite ograničenje kori&scaron;tenja podacima</li>
                        <li>ako nam podaci vi&scaron;e nisu potrebni za predviđene svrhe, ali su vam jo&scaron; potrebni radi ostvarenja pravnih zahtjeva ili</li>
                        <li>ako ste podnijeli prigovor zbog distribucije tih podataka&nbsp;</li>
                    </ul>
                    <p><strong>Pravo na žalbu:</strong></p>
                    <p>Ako ste mi&scaron;ljenja da smo prilikom obrade va&scaron;ih podataka prekr&scaron;ili Hrvatske ili Europske propise o za&scaron;titi podataka, molimo da nam se obratite na adresu e-po&scaron;te&nbsp;&nbsp;kako bismo razjasnili eventualna pitanja. Svakako imate pravo uložiti pritužbu agenciji za za&scaron;titu podataka, odnosno u slučaju promjene važećih propisa drugom tijelu koje će preuzeti njenu nadležnost, a od 25. svibnja 2018. godine i nadzornom tijelu unutar europske unije.</p>
                    <p>Ako želite ostvariti neko od navedenih prava ili imate pitanja u vezi va&scaron;ih osobnih podataka obratite nam se slanjem&nbsp;zahtjeva koji se tiču provođenja prava u vezi s osobnim podatcima u pismenom obliku na adresu e-po&scaron;te Voditelja zbirke osobnih podataka: <strong>szop@g1klub.hr</strong></p>
                    <p>Radi pouzdane identifikacije, u slučaju ostvarivanja va&scaron;ih prava u vezi s osobnim podacima možemo zatražiti dodatne informacije radi provjere va&scaron;eg identiteta, a odbijanje obrade va&scaron;eg zahtjeva možemo učiniti u slučaju očite namjere zlouporabe.</p>
                    <p>Odgovorit ćemo na Va&scaron; zahtjev za ostvarivanje prava, vezan za gore navedene osobne podatke, bez nepotrebnog odgađanja i u svakom slučaju u roku od 30 dana od zaprimanja zahtjeva.</p>
                    <p>&nbsp;</p></p>
                <BackButton />
            </div>
        </PublicFormLayout>
    )
}

export default PrivacyPolicy;