import React, { useRef, useEffect } from "react";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import NewCar from "../forms/newCarForm/NewCar";
import {
  Paper,
  Button,
  Grid,
  Box,
  Modal,
} from "@material-ui/core";
import styles from "./stepper.module.scss";
import ICarProps from "../../types/ICarProps";
import IPackageSelection from "../../types/IPackageSelection";
import ISelectedPackage from "../../types/ISelectedPackage";
import PackageSelection from "../packageSelection/PackageSelection";
import ICarData from "../../types/ICarData";
import MobileStepper from "@material-ui/core/MobileStepper";
import IWarrantyStatus from "../../types/IStatus";
import WarrantyReview from "./../warrantyReview/WarrantyReview";
import { Roles } from "../../domain/enums/Roles";
import IWarranty from "../../types/IWarranty";
import FormConstants from "./../../constants/FormConstants";
import { getUserRole } from "../../utils/AuthFunctions";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import { IApplicationState } from "../../types/applicationState";
import IPhotoUploadProps from "../../types/IPhotoUploadProps";
import PhotoUploadForm from "../forms/photoUploadForm/PhotoUploadForm";
import { NotificationStyle } from "../../domain/enums/NotificationStyle";
import IActionNotification from "../../types/utilities/IActionNotification";
import WarrantyStatus from "./../../domain/enums/WarrantyStatus";
import ICarReview from "../../types/ICarReview";
import { initialCarReview } from "../../redux/reducers/carReducer";
import { ShowNotificationAction } from "../../redux/actions/ActionNotificationAction";
import { StepperHelper } from "../../utils/StepperHelper";
import ISellerProps from "../../types/ISellerProps";
import { IDamageProps } from "../../types/Damage/IDamageProps";
import ICarProblemExtraPackage from "../../types/ICarProblemExtraPackage";
import ICar from "../../types/ICar";
import { IWarrantyBuyer } from "../../types/IWarrantyBuyer";
import { WarrantyDurationModal } from "../forms/newCarForm/WarrantyDurationModal";
import stylesModal from "../../components/forms/newCarForm/warrantyDurationModal.module.scss";
import { GetCarAction } from "../../redux/actions/CarAction";

const maxSteps = 4;

interface IDispatchToProps {
  redirectToPage: () => void;
  pictureCaptured: (notification: IActionNotification) => void;
  showPicErrorSnack: () => void;
  getCar: (id: number) => void;
}

interface IProps extends ICarData, IDispatchToProps {
  postCar: ICarProps;
  postPhotoUpload: IPhotoUploadProps;
  currentWarranty: IWarranty;
  currentCar: ICarProps;
  saveCar: (data: ICarProps) => void;
  saveCarOnly: (data: ICar) => void;
  saveCarOnlyAndContinue: (data: ICar) => void;
  sellCarWithoutWarranty: (data: ICar) => void;
  savePhotos: (data: IPhotoUploadProps) => void;
  selectedPackage: ISelectedPackage;
  packagesData: IPackageSelection;
  selectedExtraPackages: { data: ISelectedPackage[] };
  saveSelectedPackage: (data: ISelectedPackage) => void;
  removeExtraPackage: (data: ISelectedPackage) => void;
  saveExtraSelectedPackages: (data: ISelectedPackage) => void;
  saveWarranty: (data: IWarranty) => void;
  saveWarrantyOnly: (data: IWarranty) => void;
  deleteWarranty: (data: IWarranty) => void;
  changeSeller: (data: IWarranty) => void;
  changeBuyer: (data: IWarrantyBuyer) => void;
  warrantyStatuses: IWarrantyStatus[];
  updateWarrantyStatus: (data: IWarranty, isReview: boolean) => void;
  getModelsByBrand?: (brandId: number) => void;
  carReview: ICarReview;
  reviewCar: (data: ICarReview) => void;
  getCarReview: (carId: number) => void;
  history: any;
  step: number;
  sellers: ISellerProps[];
  getChassisDamage?: (chassisNumber: string) => void;
  chassisDamage: IDamageProps;
  getCarProblemExtraPackage: (car: ICarProps) => void;
  carProblemExtraPackages: { data: ICarProblemExtraPackage[] };
  changeValidDate: (
    warrantyId: string,
    validFrom: string,
    validUntil: string
  ) => void;
  sendWarrantyMail: (warrantyId: string) => void;
  changeWarrantyDuration: (data: IWarranty) => void;
}

let isAdmin: boolean;
let isSuperAdmin: boolean;
let isDisabled: boolean;

const CarStepper: React.FC<IProps> = (props: IProps) => {
  const [carReview, setCarReview] = React.useState(initialCarReview);
  const [currentPhotosLen, setCurrentPhotosLen] = React.useState(0);
  const [currentWarranty, setCurrentWarranty] = React.useState(
    props.currentWarranty!
  );
  const [currentCar, setCurrentCar] = React.useState(props.currentCar!);
  const [showWarrantyDurationModal, setShowWarrantyDurationModal] =
    React.useState(false);
  const [currentSellerId, setCurrentSellerId] = React.useState<string>();
  const [currentSeller, setCurrentSeller] = React.useState<string>();
  const [saveCarData, setSaveCarData] = React.useState<ICarProps | undefined>();

  let statusForCheck = props.currentWarranty
    ? props.currentWarranty.status
      ? props.currentWarranty.status.name
      : ""
    : "";
  const newCarFormRef = useRef<NewCar>(null);
  const newPhotoUploadRef = useRef<PhotoUploadForm>(null);

  useEffect(() => {
    setCurrentCar(props.currentCar);
    if (props.currentCar && props.currentCar.carId)
      props.getCarReview(props.currentCar.carId!);
    updateVariables();
    // In case user is reviewing warannty, he should not be able to make changes
    // so redirect him on last step.
    if (
      isDisabled &&
      StepperHelper.getStep() === 1 &&
      props.currentCar &&
      props.currentCar.carId === StepperHelper.getCarIdFromUrl()
    ) {
      //added props.currentWarranty.status! because it renders without status and cannot update it
      //&& props.currentWarranty.status!) // Check only if warranty from props is loaded and matches with URL

      props.history.replace(StepperHelper.stepUrl(4));
    }
  }, [props.currentCar]);

  useEffect(() => {
    setCurrentWarranty(props.currentWarranty);
    if (props.currentWarranty && props.currentWarranty.carId)
      props.getCarReview(props.currentWarranty.carId!);
    updateVariables();
    // In case user is reviewing warannty, he should not be able to make changes
    // so redirect him on last step.
    if (
      isDisabled &&
      StepperHelper.getStep() === 1 &&
      props.currentWarranty &&
      props.currentWarranty.id === StepperHelper.getWarrantyIdFromUrl() &&
      //added props.currentWarranty.status! because it renders without status and cannot update it
      props.currentWarranty.status!
    ) {
      // Check only if warranty from props is loaded and matches with URL

      props.history.replace(StepperHelper.stepUrl(4));
    }
  }, [props.currentWarranty]);

  useEffect(() => {
    // if upgrade mode redirect to package sellection which is step 3
    if (StepperHelper.isUpgradeMode()) {
      // if (StepperHelper.getStep() !== 3) props.history.replace('/upgrade/' + StepperHelper.getUpgradeUrl(3))
    } else {
      // Validate Stepper if URL changes manually
      if (StepperHelper.getStep() === 3) {
        // check how many pictures are loaded
        if (currentPhotosLen < 6) {
          props.history.push(StepperHelper.decreasedStepUrl());
          props.showPicErrorSnack();
        }
      }
    }
  }, [location.href]);

  useEffect(() => {
    return () => {
      props.getCar(-1);
    };
  }, []);

  function updateVariables() {
    statusForCheck = props.currentWarranty
      ? props.currentWarranty.status
        ? props.currentWarranty.status.name
        : ""
      : "";
      isAdmin = getUserRole() === Roles.Admin;
      isSuperAdmin = getUserRole() === Roles.SuperAdmin;
    isDisabled = false; //StepperHelper.isWarrantyForReview(statusForCheck);
  }
  function getStepContent() {
    switch (props.step) {
      case 1:
        if (props.carBrands && props.engineTypes)
          return (
            <NewCar
              carReview
              setCarReview={setCarReview}
              status={props.currentWarranty && props.currentWarranty.status}
              handleNext={handleNext}
              {...props.postCar}
              {...props}
              chassisDamage={props.chassisDamage}
              isReview={isDisabled}
              ref={newCarFormRef}
              setSellerId={setCurrentSellerId}
              setSeller={setCurrentSeller}
            />
          );
        else return null;
      case 2:
        return (
          <PhotoUploadForm
            savePhotosToRedux={saveToStore}
            photosNumber={(photosLen: number) => setCurrentPhotosLen(photosLen)}
            isReview={isDisabled}
            onCapture={props.pictureCaptured}
            handleNext={handleNext}
            handleSaveAndContinue={handleSaveAndContinue}
            postCar={props.postCar}
            {...props.postPhotoUpload}
            ref={newPhotoUploadRef}
            currentCar={props.currentCar}
            handleSellWithoutWarranty={handleSellWithoutWarranty}
            handleWarrantyDurationModal={handleWarrantyDurationModal}
          />
        );
      case 3:
        return (
          <PackageSelection
            {...props}
            savePackage={props.saveSelectedPackage}
            saveExtraPackage={props.saveExtraSelectedPackages}
            packagesData={props.packagesData}
            removeExtraPackage={props.removeExtraPackage}
            postCar={props.postCar}
            carProblemExtraPackages={props.carProblemExtraPackages}
          />
        );
      case 4: {
        const warrantyReview = (
          <WarrantyReview
            {...props.currentWarranty}
            carData={props.currentWarranty && props.currentWarranty.carData}
            setCarReview={setCarReview}
            warrantyDataChanged={setCurrentWarranty}
            pictures={props.postPhotoUpload.pictures}
            urls={props.postPhotoUpload.urls}
            packagesData={props.packagesData}
            carProblemExtraPackages={props.carProblemExtraPackages}
            statuses={
              props.warrantyStatuses
                ? Object.values(props.warrantyStatuses)
                : []
            }
            {...props}
            {...props.postCar}
            isReview={
              (statusForCheck &&
                    statusForCheck !== WarrantyStatus.InProcess &&
                    (!isAdmin && !isSuperAdmin)) ||
                (statusForCheck !== WarrantyStatus.Submitted && (isAdmin || isSuperAdmin))
            }
            save={() => handleNext()}
            delete={() => handleDelete()}
            changeSeller={() => handleChangeSeller()}
            changeBuyer={(email: string) => handleChangeBuyer(email)}
            changeValidDate={props.changeValidDate}
            sendWarrantyMail={props.sendWarrantyMail}
                saveWarrantyStatus={() => saveWarrantyStatus()}
                changeWarrantyDuration={() => handleChangeWarrantyDuration()}
          />
        );

        if (props.currentWarranty) {
          // updating Warranty
          if (
            props.carBrands &&
            props.engineTypes &&
            props.packagesData &&
            props.selectedPackage
          )
            return warrantyReview;
          else return null;
        }
      }
    }
  }

  function handleNext() {
    if (props.step === 1) {
      const formIsValid = newCarFormRef.current!.checkSubmit();
      const selectIsValid = newCarFormRef.current!.validateForm();
      if (!(selectIsValid && formIsValid)) return;
    }

    if (props.step === 2 && currentPhotosLen < 6) {
      props.showPicErrorSnack();
      return;
    }

    saveToStore();

    if (props.step === 2) {
      props.saveCarOnly({
        carData: saveCarData,
        carPhotos: props.postPhotoUpload.pictures,
        carId: props.currentCar.carId,
      });
    }

    if (props.step === maxSteps) {
      // if package was not selected, select first by default
      const selectedPackage: ISelectedPackage =
        props.selectedPackage && props.selectedPackage.id
          ? props.selectedPackage
          : { id: props.packagesData.packages[0].packageId };
      props.saveWarrantyOnly({
        carData: saveCarData,
        allSelectedPackagesIds: {
          package: selectedPackage,
          extraPackages: props.selectedExtraPackages.data,
        },
        carId: props.postCar.carId,
        id: props.currentWarranty ? props.currentWarranty.id : undefined,
        carPhotos: props.postPhotoUpload.pictures,
          sellerId:
              (isAdmin || isSuperAdmin) && localStorage.getItem("carSeller")
            ? JSON.parse(localStorage.getItem("carSeller")!).value
            : null,
      });
    } else if (props.step !== 2)
      props.history.push(StepperHelper.increasedStepUrl());

    if (props.step !== 4) window.scrollTo(0, 0);
  }

  function handleWarrantyDurationModal() {
    setShowWarrantyDurationModal(true);
  }

  function handleWarrantyDurationModal12() {
      props.postCar.isOneYearWarranty = true;
      handleSaveAndContinue(true);
  }

  function handleWarrantyDurationModal24() {
      props.postCar.isOneYearWarranty = false;
    handleSaveAndContinue(false);
  }

  async function handleSaveAndContinue(oneYear: boolean) {
    if (props.step === 2 && currentPhotosLen < 6) {
      props.showPicErrorSnack();
      return;
    }

    saveToStore();

      if (props.step === 2) {

          if (saveCarData && saveCarData.isOneYearWarranty !== undefined) saveCarData.isOneYearWarranty = oneYear;

      await props.saveCarOnlyAndContinue({
        carData: saveCarData,
        carPhotos: props.postPhotoUpload.pictures,
        carId: props.currentCar.carId,
        sellerId: currentSellerId,
      });

      props.postCar.sellerId = currentSellerId;
      props.postCar.seller = currentSeller;
    }

    props.getCarProblemExtraPackage(props.postCar);

    setTimeout("", 3000);

    props.history.push(StepperHelper.increasedStepUrl());

    window.scrollTo(0, 0);
  }

  function handleSellWithoutWarranty() {
    if (props.step === 2 && currentPhotosLen < 6) {
      props.showPicErrorSnack();
      return;
    }

    saveToStore();

    if (props.step === 2) {
      props.sellCarWithoutWarranty({
        carData: saveCarData,
        carPhotos: props.postPhotoUpload.pictures,
        carId: props.currentCar.carId,
      });
    }

    window.scrollTo(0, 0);
  }

  function handleDelete() {
    props.deleteWarranty({
      carId: props.postCar.carId,
      id: props.currentWarranty ? props.currentWarranty.id : undefined,
      sellerId:
        (isAdmin || isSuperAdmin) && localStorage.getItem("carSeller")
          ? JSON.parse(localStorage.getItem("carSeller")!).value
          : null,
    });
  }

  function handleChangeSeller() {
    props.changeSeller({
      carId: props.postCar.carId,
      id: props.currentWarranty ? props.currentWarranty.id : undefined,
      carData: props.postCar,
        sellerId:
            (isAdmin || isSuperAdmin) && localStorage.getItem("carSeller")
          ? JSON.parse(localStorage.getItem("carSeller")!).value
          : currentSellerId,
    });
  }

  function handleChangeBuyer(email: string) {
    props.changeBuyer({
      warrantyId: props.currentWarranty.id!,
      email: email,
    });
    }

    function handleChangeWarrantyDuration() {
        props.changeWarrantyDuration({
            carId: props.postCar.carId,
            id: props.currentWarranty ? props.currentWarranty.id : undefined,
            carData: props.postCar,
            sellerId:
                (isAdmin || isSuperAdmin) && localStorage.getItem("carSeller")
                    ? JSON.parse(localStorage.getItem("carSeller")!).value
                    : currentSellerId,
        });
    }

  function handleBack() {
    saveToStore();
    props.history.push(StepperHelper.decreasedStepUrl());
  }

  function saveWarrantyStatus() {
    if (getUserRole() === Roles.Admin && props.currentWarranty) {
      // var update = !WarrantyStatusChecker.AdminViewOrUpdate(statusForCheck); //checker - true if view, false if updated
      props.updateWarrantyStatus(
        { ...currentWarranty, id: props.currentWarranty.id },
        true
      );
      props.reviewCar({ ...carReview, carId: props.currentWarranty.carId! });
    }
  }
  function saveToStore() {
    switch (props.step) {
      case 1:
        const carData = newCarFormRef.current!.getStateData();
        if (props.postCar) carData.images = props.postCar.images;
        setSaveCarData(carData);
        props.saveCar(carData);
      case 2:
        let photoData;
        if (newPhotoUploadRef.current) {
          photoData = newPhotoUploadRef.current!.getStateData();
          props.savePhotos(photoData);
        }
    }
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ marginBottom: 25 }}
    >
      <Paper className={styles.Stepper}>
        <Grid item xs className={styles.StepperPagination}>
          {!isDisabled && (
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={props.step - 1}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  type="submit"
                  form={FormConstants.CAR_PURCHASE_FORM}
                  disabled={props.step === maxSteps || props.step === 2}
                >
                  Naprijed
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={props.step === 1}
                >
                  <KeyboardArrowLeft />
                  Nazad
                </Button>
              }
            />
          )}
        </Grid>
        <Typography className={styles.Content}>{getStepContent()}</Typography>
        <Grid item xs className={styles.MobileStepperPagination}>
          {!isDisabled && (
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={props.step - 1}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  type="submit"
                  form={FormConstants.CAR_PURCHASE_FORM}
                  disabled={props.step === maxSteps || props.step === 2}
                >
                  Naprijed
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={props.step === 1}
                >
                  <KeyboardArrowLeft />
                  Nazad
                </Button>
              }
            />
          )}
        </Grid>
      </Paper>
      {showWarrantyDurationModal && (
        <Box>
          <Modal
            open={showWarrantyDurationModal}
            className={stylesModal.Modal}
            onClose={() => setShowWarrantyDurationModal(false)}
          >
            <Paper className={stylesModal.RootModal}>
              <div>
                <WarrantyDurationModal
                  closeModal={() => setShowWarrantyDurationModal(false)}
                  submitModal12={handleWarrantyDurationModal12}
                  submitModal24={handleWarrantyDurationModal24}
                >
                  {" "}
                </WarrantyDurationModal>
              </div>
            </Paper>
          </Modal>
        </Box>
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirectToPage: () => {
    dispatch({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: (isAdmin || isSuperAdmin) ? "/jamstva" : "/overview" },
    });
  },
  pictureCaptured: (notification: IActionNotification) => {
    dispatch({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: { ...notification },
    });
  },
  showPicErrorSnack: () =>
    dispatch<ShowNotificationAction>({
      type: ActionTypes.NOTIFICATION_SHOW,
      payload: {
        message: "Molimo učitajte 6 slika automobila.",
        style: NotificationStyle.Error,
      },
    }),
  getCar: (id: number) =>
    dispatch<GetCarAction>({
      type: ActionTypes.GET_CAR,
      payload: id,
    }),
});

export default connect<null, IDispatchToProps, null, IApplicationState>(
  null,
  mapDispatchToProps
)(CarStepper);
