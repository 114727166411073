import { IAuthenticationProps } from './../../types/IAuthenticationProps';
import { Reducer } from "redux";
import ActionTypes from './../actions/ActionTypes';
import IUser from './../../types/IUser';
import IBuyerProps from "../../types/IBuyerProps";

type NullableUserAuthProps = IUser | null;

export const userAuthReducer: Reducer<NullableUserAuthProps> = (state: NullableUserAuthProps = null, action): NullableUserAuthProps => {
    if (action.type === ActionTypes.LOGIN_SUCCESS) {
        return { ...action.payload }
    }

    return state;
}
export const initialBuyerProp: IBuyerProps = {
    firstName: '', lastName: '', oib: '',
    address: '', email: '', zipCode: '',
    city: '', country: '', phoneNumber: '',
    legalEntityName: '', carId: undefined,
    legalEntity: false
};

export const currentUserReducer: Reducer<IBuyerProps> = (state = initialBuyerProp, action): IBuyerProps => {
    switch (action.type) {
        case ActionTypes.GET_CURRENT_USER: return { ...action.payload }
        case ActionTypes.GET_CURRENT_USER_SUCCESS: return {...action.payload}
        case ActionTypes.GET_CURRENT_USER_ERROR: return {...action.payload} 
        default: return state;
    }
};

export const initalUser: IAuthenticationProps = {
    email: ""
}

export const confirmData: Reducer<IAuthenticationProps> = (state = initalUser, action): IAuthenticationProps => {
    switch (action.type) {
        case ActionTypes.CONFIRM_DATA:  return {...action.payload} 
        case ActionTypes.CONFIRM_DATA_SUCCESS:  return { ...action.payload} 
        case ActionTypes.CONFIRM_DATA_ERROR: return {...action.payload}
        default: return state;
    }
} 
