import React, { useEffect } from 'react'
import IBuyerProps from "../../../types/IBuyerProps";
import { IApplicationState } from "../../../types/applicationState";
import UserData from './Userdata';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ActionTypes from '../../../redux/actions/ActionTypes';
import Tos from './tos/TOS';
import AcceptHelpButtons from "./AcceptHelpButtons";
import { Paper, Divider } from '@material-ui/core';
import { IAuthenticationProps } from '../../../types/IAuthenticationProps';
import PublicFormLayout from '../../authentication/PublicFormLayout';
import style from "./firstLoginDashboard.module.scss";

interface IStateToProps {
    user: IBuyerProps
}

interface IDispatchToProps {
    currentUser: () => void,
    confirmData: (user: IAuthenticationProps) => void,
    goToLogin: () => void
}

interface IProps extends IStateToProps, IDispatchToProps { }

const FirstLoginDashboard: React.FC<IProps> = (props: IProps) => {

    const [tosAccepted, setTosAccepted] = React.useState(false);
    const [dataAccepted, setDataAccepted] = React.useState(false);

    useEffect(() => {
        props.currentUser();
    }, []);

    return (<PublicFormLayout className={style.Layout}>
        <Paper>
            <UserData user={props.user} />
            <Divider />
            <Tos tosAccepted={tosAccepted} dataAccepted={dataAccepted} setDataAccepted={() => setDataAccepted(!dataAccepted)} setTosAccepted={() => setTosAccepted(!tosAccepted)} />
            <AcceptHelpButtons goToLogin={() => props.goToLogin()} disabled={!(tosAccepted && dataAccepted)} confirmData={() => props.confirmData(props.user)} />
        </Paper>
    </PublicFormLayout>)
}
const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    user: state.currentUser
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    currentUser: () => {
        dispatch({
            type: ActionTypes.GET_CURRENT_USER
        })
    },
    confirmData: (user: IAuthenticationProps) => {
        dispatch({
            type: ActionTypes.CONFIRM_DATA,
            payload: user
        })
    },
    goToLogin: () => { 
        dispatch({
            type: ActionTypes.GO_TO_PAGE,
            payload: {
                url: "/login"
            }
        })
    }
})


export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(FirstLoginDashboard)
