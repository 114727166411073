import IUserService from "./IUserService";
import { injectable, inject } from 'inversify';
import ThirdPartyTypes from "../../di/ThirdPartyTypes";
import { AxiosInstance } from 'axios';
import IUser from "../../types/IUser";
import { loginApiURL, registerApiURL, forgotPasswordURL, confirmPasswordURL, currentUserURL, confirmDataURL } from './../../constants/apiRoutes';
import { IAuthenticationProps } from "../../types/IAuthenticationProps";
import { IForgotPassword } from "../../types/IForgotPassword";
import IBuyerProps from "../../types/IBuyerProps";
import { getUserRole } from "../../utils/AuthFunctions";

@injectable()
export default class UserService implements IUserService {

    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async login(data: IAuthenticationProps): Promise<IUser> {
        const response = await this.apiClient.post(loginApiURL, data);
        this.saveToken(response.data);
        return response.data;
    }

    public async register(data: IAuthenticationProps): Promise<any> {
        const response = await this.apiClient.post(registerApiURL, data);
        return response.data;
    }

    public async confirmData(data: IAuthenticationProps): Promise<any> {
        const response = await this.apiClient.post(confirmDataURL, data);
        return response.data;
    }

    public saveToken(data: IUser): void {
        localStorage.setItem('user', JSON.stringify(data));
    }

    public async getCurrentUser(): Promise<IBuyerProps> {
        const response = await this.apiClient.get<IBuyerProps>(currentUserURL);
        return response.data;
    }

    public logout() {
        localStorage.setItem('user', '');
    }

    public async forgotPasswordRequest(email: string): Promise<any> {
        const response = await this.apiClient.post(forgotPasswordURL, { email: email });
        return response.data;
    }

    public async confirmPassword(data: IForgotPassword): Promise<any> {
        const response = await this.apiClient.post(confirmPasswordURL, data);
        return response.data;
    }

    public isHakRole(): boolean {
        return getUserRole() === 'HAK';
    }
}