import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import { FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import styles from './WarrantyDurationRadioButtons.module.scss';

const OneYear = 'OneYear';
const TwoYears = 'TwoYears';

interface IProps {
    isDurationChanged: (isOneYear: boolean) => void,
    isDurationOneYear: boolean,
    disabled: boolean
}

export const WarrantyDurationRadioButtons: React.FC<IProps> = (props: IProps) => {
    const [isOneYear, setIsOneYear] = React.useState(props.isDurationOneYear);

    function handleChange(event: React.ChangeEvent<unknown>) {
        if ((event.target as HTMLInputElement).defaultValue !== OneYear) {
            setIsOneYear(false);
            props.isDurationChanged(false);
        }
        else {
            setIsOneYear(true);
            props.isDurationChanged(true);
        }
        
    }

    return (
        <FormControl component="fieldset" style={{marginTop: '10px', marginBottom: '10px'}}>
            <FormLabel component="legend">Trajanje jamstva:</FormLabel>
            <RadioGroup aria-label="position" name="position" value={isOneYear ? OneYear : TwoYears} onChange={handleChange}>
                <FormControlLabel
                    value="start"
                    control={<Radio
                        disabled={props.disabled}
                        checked={isOneYear}
                        value={OneYear}
                        name={OneYear}
                    />}
                    label="12 mjeseci"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="start"
                    control={<Radio
                        disabled={props.disabled}
                        checked={!isOneYear}
                        value={TwoYears}
                        name={TwoYears}
                    />}
                    label="24 mjeseca"
                    labelPlacement="end"
                />
            </RadioGroup>
        </FormControl>
    );
}
