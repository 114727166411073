
import { SendInvoiceAction, GetInvoicesAction, LastInvoiceAction, GetInvoiceAction, GetYearsAction } from './../actions/InvoiceAction';
import { Container } from 'inversify';
import { getContext, apply, put, delay } from '@redux-saga/core/effects';
import ActionTypes from './../actions/ActionTypes';
import IInvoiceService from '../../domain/services/IInvoiceService';
import InvoiceService from '../../domain/services/InvoiceService';
import { NotificationStyle } from '../../domain/enums/NotificationStyle';

export function* sendWarrantyPdf(action: SendInvoiceAction) {
    const container: Container = yield getContext('container');
    const invoicingApi = container.get<IInvoiceService>(InvoiceService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(invoicingApi, invoicingApi.sendWarrantyPdf, [action.payload]);
        yield put({ type: ActionTypes.SEND_INVOICE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.GET_LAST_INVOICE });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Račun br. ' + action.payload.invoiceNumber + ' poslan trgovcu '  + action.payload.sellerName,
                style: NotificationStyle.Success
            }
        });
        yield delay(4000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Račun poslan.',
                style: NotificationStyle.Error
            }
        });
    } catch (err) {
        yield put({ type: ActionTypes.SEND_INVOICE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
        yield put({
            type: ActionTypes.NOTIFICATION_SHOW, payload: {
                message: 'Nije poslano, probajte kasnije.',
                style: NotificationStyle.Error
            }
        });
        yield delay(2000);
        yield put({
            type: ActionTypes.NOTIFICATION_CLOSE, payload: {
                message: 'Nije poslano, probajte kasnije.',
                style: NotificationStyle.Error
            }
        });
    }
}

export function* getInvoices(action: GetInvoicesAction) {
    const container: Container = yield getContext('container');
    const invoicingApi = container.get<IInvoiceService>(InvoiceService);
    try {
        const response = yield apply(invoicingApi, invoicingApi.getInvoices, [action.payload.year]);
        yield put({ type: ActionTypes.GET_INVOICES_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_INVOICES_ERROR, payload: err })
    }
}

export function* getYears(action: GetYearsAction) {
    const container: Container = yield getContext('container');
    const invoicingApi = container.get<IInvoiceService>(InvoiceService);
    try {
        const response = yield apply(invoicingApi, invoicingApi.getYears, []);
        yield put({ type: ActionTypes.GET_YEARS_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_YEARS_ERROR, payload: err })
    }
}

export function* lastInvoice(action: LastInvoiceAction) {
    const container: Container = yield getContext('container');
    const invoicingApi = container.get<IInvoiceService>(InvoiceService);
    try {
        const response = yield apply(invoicingApi, invoicingApi.lastInvoice, []);
        yield put({ type: ActionTypes.GET_LAST_INVOICE_SUCCESS, payload: response })
    } catch (err) {
        yield put({ type: ActionTypes.GET_LAST_INVOICE_ERROR, payload: err })
    }
}



export function* getInvoice(action: GetInvoiceAction) {
    const container: Container = yield getContext('container');
    const invoicingApi = container.get<IInvoiceService>(InvoiceService);
    try {
        yield put({ type: ActionTypes.LOADING_START });
        const response = yield apply(invoicingApi, invoicingApi.getInvoice, [action.payload.invoiceNumber]);
        yield put({ type: ActionTypes.GET_INVOICE_SUCCESS, payload: response });
        yield put({ type: ActionTypes.LOADING_END });
    } catch (err) {
        yield put({ type: ActionTypes.GET_INVOICE_ERROR, payload: err });
        yield put({ type: ActionTypes.LOADING_END });
    }
}

