import React, { useEffect, useState } from 'react';
import IPackageSelection from '../../types/IPackageSelection';
import PackageCard from './PackageCard';
import MainPackageCard from './MainPackageCard';
import { Grid, LinearProgress, Box } from '@material-ui/core';
import style from './packageSelection.module.scss';
import ISelectedPackage from '../../types/ISelectedPackage';
import { StepperHelper } from '../../utils/StepperHelper';
import IPackage from '../../types/IPackage';
import ICarProps from '../../types/ICarProps';
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';

interface IProps {
    savePackage?: (data: ISelectedPackage) => void,
    saveExtraPackage?: (data: ISelectedPackage) => void,
    removeExtraPackage?: (data: ISelectedPackage) => void,
    selectedPackage: ISelectedPackage,
    selectedExtraPackages: { data: ISelectedPackage[] },
    packagesData: IPackageSelection,
    postCar: ICarProps,
    carProblemExtraPackages: { data: ICarProblemExtraPackage[] }
}

const PackageSelection: React.FC<IProps> = props => {
    const [packages, setPackages] = React.useState(props.packagesData.packages);
    const [extraPackages, setExtraPackages] = React.useState(props.packagesData.extraPackages);

    const [selectedPackage, selectPackage] = React.useState(
        props.packagesData && props.packagesData.packages && props.selectedPackage && props.selectedPackage.id ?
            props.selectedPackage.id : (props.packagesData && props.packagesData.packages.length > 0 ? props.packagesData.packages[0].packageId : null));

    useEffect(() => { if (props.savePackage && props.selectedPackage && props.packagesData.packages.length > 0) props.savePackage!({ id: props.packagesData.packages[0].packageId }) }, [])
    useEffect(() => {
        if (props.packagesData.packages.length !== packages.length) {
            setPackages(props.packagesData.packages);
        }
        if (props.packagesData.extraPackages.length !== extraPackages.length) {
            setExtraPackages(props.packagesData.extraPackages);
        }
    }, [props.packagesData])

    useEffect(() => {
        if (props.saveExtraPackage) {
            if (props.carProblemExtraPackages && props.carProblemExtraPackages.data.length !== 0) {
                for (let i = 0; i < props.carProblemExtraPackages.data.length; i++) {
                    let id = props.carProblemExtraPackages.data[i].extraPackageId;
                    props.saveExtraPackage!({ id })
                }
            } 
        }
    }, [props.carProblemExtraPackages])

    useEffect(() => { if (props.savePackage && props.selectedPackage && props.packagesData.packages.length > 0) props.savePackage!({ id: props.packagesData.packages[0].packageId }) }, [])


    function PackageSelected(id: number) {
        if (props.selectedPackage.id !== id) {
            selectPackage(id);
            props.savePackage!({ id })
        }

    }

    function ExtraPackagesSelected(id: number) {
        if (!props.selectedExtraPackages.data.find(obj => obj.id == id) && !props.carProblemExtraPackages.data.find(obj => obj.extraPackageId == id)) {
            props.saveExtraPackage!({ id })
        } else if (!props.carProblemExtraPackages.data.find(obj => obj.extraPackageId == id)) {
            props.removeExtraPackage!({ id })
        }
    }

    return <Box m={{ sm: 2, md: 6, lg: 6 }} p={{ sm: 2, md: 6, lg: 6 }} className={style.Root}>
        <Grid item container direction="row" alignItems="center" justify="space-evenly" spacing={6}>
            {packages ? packages.map((item: IPackage, index: number) =>
                <Grid key={index} item sm={10} md={10} lg={10} >
                    <MainPackageCard
                        PackageSelected={PackageSelected}
                        selected={true}
                        {...item}
                        disabled={StepperHelper.getStep() === 4}
                    />
                </Grid>
            ) : <LinearProgress style={{ flexGrow: 1 }} />}
            <hr style={{ borderTop: '1px solid #f1f1f1', width: '100%' }} />
            <h3 style={{ width: '100%', display: 'block', margin: '10px'}}>Dodaci na produženo auto jamstvo</h3>
            {extraPackages && extraPackages.length > 0 ?
                <h4 style={{ width: '100%', display: 'block', margin: '10px' }}>Sami kreirajte svoje pravo na produženo auto jamstvo tako da odaberete jedan ili više dodataka</h4>
                :
                <h4 style={{ width: '100%', display: 'block' }}>Ovaj automobil nema opciju dodavanja dodataka.</h4>
            }
            {extraPackages && extraPackages.length > 0 ? extraPackages.map((item: IPackage, index: number) =>
                <Grid key={index} item sm={12} md={6} lg={6} >
                    <PackageCard
                        PackageSelected={ExtraPackagesSelected}
                        selected={(props.selectedExtraPackages && props.selectedExtraPackages.data.length > 0 && !!props.selectedExtraPackages.data.find(obj => obj.id == item.id) || !!(props.carProblemExtraPackages && props.carProblemExtraPackages.data.find(obj => obj.extraPackageId == item.id)))}
                        {...item}
                        disabled={StepperHelper.getStep() === 4 || !!(props.carProblemExtraPackages && props.carProblemExtraPackages.data.find(obj => obj.extraPackageId == item.id))} />
                </Grid>
            ) : <LinearProgress style={{ flexGrow: 1 }} />}

        </Grid>
    </Box>
}

export default PackageSelection;