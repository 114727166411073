import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import styles from './warrantyDurationModal.module.scss';

interface IProps {
    closeModal: () => void,
    submitModal24: () => void,
    submitModal12: () => void
}

export class WarrantyDurationModal extends React.Component<IProps> {


    handleSubmit12 = () => {
        this.props.submitModal12();
        this.props.closeModal();
    }

    handleSubmit24 = () => {
        this.props.submitModal24();
        this.props.closeModal();
    }


    render() {
        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="flex-start" justify="center" >
                    <Grid item xs={12}>
                        <Grid className={styles.Question}>
                            <Typography className={styles.Text} variant="h6" align="center">Odaberite trajanje jamstva:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={12} spacing={8} justify="center">
                        <Grid item xs={12} md={6} spacing={2}>
                            <Button className={styles.Button} variant="contained" color="primary" style={{ float: "right" }} onClick={this.handleSubmit12.bind(this)}>12 MJESECI</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button className={styles.Button} variant="contained" color="primary" onClick={this.handleSubmit24.bind(this)}>24 MJESECA</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </React.Fragment>
        )
    }
}