import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import ICarSellerTable from '../../types/ICarSellerTable';
import CarsSellerTable from '../../components/tables/CarsSellerTable';
import { GetCarsSellerAction } from '../../redux/actions/CarAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    cars: ICarSellerTable[],
}

interface IDispatchToProps {
    getCars: () => void,
    goToCarDetails: (carId: number, warrantyId: string) => void,
}

interface IProps extends IDispatchToProps, IStateToProps { }

const CarSellerOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getCars();
    }, []);

    return (props.cars ? <CarsSellerTable rows={props.cars} goToCarDetails={props.goToCarDetails} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    cars: state.cars.data.seller
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getCars: () => dispatch<GetCarsSellerAction>({
        type: ActionTypes.GET_CARS_SELLER
    }),
    goToCarDetails: (carId: number, warrantyId: string) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: warrantyId === 'Treba dodati' ? '/car/' + carId + "/step=1" : '/car/' + carId + "/warranty/" + warrantyId + "/step=1" }
    })
})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(CarSellerOverview);