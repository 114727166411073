import { injectable, inject } from 'inversify';
import ThirdPartyTypes from './../../di/ThirdPartyTypes';
import { AxiosInstance } from 'axios';
import { carApiURL, buyerApiURL, warrantyApiURL, carAdminApiURL, carSellerApiURL } from './../../constants/apiRoutes';
import ICarService from './ICarService';
import ICarBrand from "../../types/ICarBrand";
import ICarModel from "../../types/ICarModel";
import { IEngineType } from "../../types/IEngineType";
import IBuyerProps from '../../types/IBuyerProps';
import ICarReview from '../../types/ICarReview';
import IMissingCarDetailsProps from '../../types/IMissingCarDetailsProps';
import { ICarCard } from '../../types/ICarCard';
import { ICarUpdatePortalData } from '../../types/ICarUpdatePortalData';
import { IImportCar } from '../../types/IImportCar';
import ICarAdminTable from '../../types/ICarAdminTable';
import ICarProps from "../../types/ICarProps";
import ICarProblemExtraPackage from '../../types/ICarProblemExtraPackage';
import ICar from "../../types/ICar";
import ICarSellerTable from '../../types/ICarSellerTable';

@injectable()
export default class CarService implements ICarService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getCarBrands(): Promise<ICarBrand[]> {
        const response = await this.apiClient.get(carApiURL + '/brands');
        return response.data;
    }
    public async getCarModels(): Promise<ICarModel[]> {
        const response = await this.apiClient.get(carApiURL + '/models');
        return response.data;
    }
    public async getEngineTypes(): Promise<IEngineType[]> {
        const response = await this.apiClient.get(carApiURL + '/engineTypes');
        return response.data;
    }
    public async sellCar(buyer: IBuyerProps): Promise<null> {
        const response = await this.apiClient.post(carApiURL + '/sell', buyer);
        return response.data;
    }

    public async reviewCar(car: ICarReview): Promise<null>{
        const response = await this.apiClient.post(carApiURL + '/review', car);
        return response.data;
    }

    public async currentCarReview(carId: number): Promise<ICarReview>{
        const response = await this.apiClient.get(carApiURL + `/review/${carId}`);
        return response.data;
    }
    public async getBuyerByCarId(carId: number, warrantyId: number): Promise<IBuyerProps> {
        const response = await this.apiClient.get(buyerApiURL + `/car/${carId}/${warrantyId}`);
        return response.data;
    }
    public async getBuyerByOib(oib: string): Promise<IBuyerProps> {
        const response = await this.apiClient.get(buyerApiURL + `/oib/${oib}`);
        return response.data;
    }
    public async getBuyerByEmail(email: string): Promise<IBuyerProps> {
        const response = await this.apiClient.get(buyerApiURL + '/email/'+ email);
        return response.data;
    }

    public async getCarModelsByBrand(brandId: number): Promise<ICarModel[]> {
        const response = await this.apiClient.get(carApiURL + `/models/${brandId}`);
        return response.data;
    }

    public async editMissingCarDetails(missingCarDetails: IMissingCarDetailsProps): Promise<null>{
        const response = await this.apiClient.post(carApiURL + '/editMissingCarDetails', missingCarDetails);
        return response.data;
    }

    public async changeShowOnPortal(value: boolean, carId: number): Promise<null>{
        const response = await this.apiClient.post(carApiURL + '/show-on-portal/' + carId + '/' + value);
        return response.data;
    }

    public async hideAllCarsOnPortal(sellerId: string): Promise<null>{
        const response = await this.apiClient.post(carApiURL + '/hide-cars-on-portal/' +  sellerId);
        return response.data;
    }

    public async getCarDetailsByCarId(carId: number): Promise<ICarCard> {
        return (await this.apiClient.get<ICarCard>(carApiURL + "/details/" + carId)).data;
    }

    public async updateCarDetails(data: ICarUpdatePortalData): Promise<any>{
        const response = await this.apiClient.post(carApiURL + '/editMissingCarDetailsForPortal', data);
        return response.data;
    }
    
    public async filterCars(filterQuery: string): Promise<{ hasMore: boolean, cars: ICarCard[] }> {
        return (await this.apiClient.get<{ hasMore: boolean, cars: ICarCard[] }>(carApiURL + "/filter" + filterQuery)).data;
    }

    public async importCar(data: IImportCar): Promise<any>{
        const response = await this.apiClient.post(warrantyApiURL + '/UpdateWarrantiesFromHtml', data);
        return response.data;
    }

    public async getCarsAdmin(page: number, pageSize: number, filters: string): Promise<ICarAdminTable[]> {
        const response = await this.apiClient.get(carAdminApiURL + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getCarsAdminTotalCount(): Promise<number> {
        const response = await this.apiClient.get(carApiURL + '/carsAdminTableTotalCount');
        return response.data;
    }

    public async getCarsAdminOnlyCarsTotalCount(): Promise<number> {
        const response = await this.apiClient.get(carApiURL + '/carsAdminOnlyCarsTableTotalCount');
        return response.data;
    }

    public async getCarsAdminSoldOnlyTotalCount(): Promise<number> {
        const response = await this.apiClient.get(carApiURL + '/carsAdminSoldOnlyTableTotalCount');
        return response.data;
    }

    public async getCarsSeller(): Promise<ICarSellerTable[]> {
        const response = await this.apiClient.get(carSellerApiURL);
        return response.data;
    }

    public async getCarsDropdownAdmin(): Promise<ICarProps[]> {
        const response = await this.apiClient.get(carAdminApiURL + "/cars");
        return response.data;
    }

    public async getCarsDropdownSeller(): Promise<ICarProps[]> {
        const response = await this.apiClient.get(carSellerApiURL + "/cars");
        return response.data;
    }

    public async getCarsAdminOnlyCars(page: number, pageSize: number, filters: string): Promise<ICarAdminTable[]> {
        const response = await this.apiClient.get(carAdminApiURL + "/onlyCars" + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getCarsAdminSoldOnlyCars(page: number, pageSize: number, filters: string): Promise<ICarAdminTable[]> {
        const response = await this.apiClient.get(carAdminApiURL + "/soldOnlyCars" + '?page=' + page + '&pageSize=' + pageSize + '&filters=' + filters);
        return response.data;
    }

    public async getCarProblemExtraPackage(car: ICarProps): Promise<ICarProblemExtraPackage[]> {
        const response = await this.apiClient.post(carApiURL + "/carProblemExtraPackage", car);
        return response.data;
    }

    public async postCarOnly(car: ICar): Promise<any> {
        const apiUrl = car.carId ? carApiURL + "/edit" : carApiURL + "/save";
        const response = await this.apiClient.post(apiUrl, car);
        return response.data;
    }

    public async postCarOnlyAndContinue(car: ICar): Promise<any> {
        const apiUrl = car.carId ? carApiURL + "/edit" : carApiURL + "/save";
        const response = await this.apiClient.post(apiUrl, car);
        return response.data;
    }

    public async sellCarWithoutWarranty(car: ICar): Promise<any> {
        const apiUrl = carApiURL + "/sellCarWithoutWarranty";
        const response = await this.apiClient.post(apiUrl, car);
        return response.data;
    }

    public async getCar(id: number): Promise<any> {
        const response = await this.apiClient.get<ICarProps>(carApiURL + '/' + id);
        return response.data;
    }

    public async deactivateCar(carId: number): Promise<null> {
        const response = await this.apiClient.post(carApiURL + "/deactivate/" + carId);
        return response.data;
    }

    public async activateCar(carId: number): Promise<null> {
        const response = await this.apiClient.post(carApiURL + "/activate/" + carId);
        return response.data;
    }

    public async changeSellerEmail(chassisNumber: string): Promise<null> {
        const response = await this.apiClient.get(carApiURL + "/changeSellerEmail/" + chassisNumber);
        return response.data;
    }
    public async getChassisCar(chassisNumber: string): Promise<any> {
        const response = await this.apiClient.get(carApiURL + "/chassisCar/" + chassisNumber);
        return response.data;
    }


    //public async getExtraPackagesByEngineDisplacement(car: ICarProps): Promise<IPackageSelection> {
    //    const response = await this.apiClient.post<IPackageSelection>(warrantyApiURL + "/extra-packages/", car);
    //    return response.data;
    //}
}