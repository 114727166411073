import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import ISellerProps from "../../types/ISellerProps";
import AdminSellersUnactiveTable from "./../../components/tables/AdminSellersUnactiveOverview";
import {
    GetAllSellersUnactiveTableAction,
    GetSellersUnactiveAdminTotalCountAction,
} from "./../../redux/actions/SellerAction";
import GoToPageAction from "../../redux/actions/GoToPageAction";
import ReactGA from "react-ga";

interface IStateToProps {
    sellers: ISellerProps[];
    totalCount: number;
}

interface IDispatchToProps {
    getSellersUnactiveTable: (page: number, pageSize: number, filters: string) => void;
    goToSellerDetails: (sellerId: string) => void;
    getTotalCount: () => void;
}

interface IProps extends IDispatchToProps, IStateToProps { }

const SellersUnactiveOverview: React.FC<IProps> = (props) => {
    useEffect(() => {
        ReactGA.pageview("Admin | pregled svih trgovaca");
        props.getTotalCount();
        props.getSellersUnactiveTable(1, 10, "");
    }, []);

    return (
        <AdminSellersUnactiveTable
            goToSellerDetails={props.goToSellerDetails}
            rows={props.sellers}
            totalCount={props.totalCount}
            getSellersUnactive={props.getSellersUnactiveTable}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    sellers: state.sellersUnactiveTable.data,
    totalCount: state.sellersUnactiveAdminTableTotalCount.data,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getSellersUnactiveTable: (page: number, pageSize: number, filters: string) =>
        dispatch<GetAllSellersUnactiveTableAction>({
            type: ActionTypes.GET_SELLERS_UNACTIVE_TABLE,
            payload: { page, pageSize, filters },
        }),
    getTotalCount: () =>
        dispatch<GetSellersUnactiveAdminTotalCountAction>({
            type: ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT,
        }),
    goToSellerDetails: (sellerId: string) =>
        dispatch<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: { url: "/seller/" + sellerId },
        }),
});

export default connect<
    IStateToProps,
    IDispatchToProps,
    IProps,
    IApplicationState
>(
    mapStateToProps,
    mapDispatchToProps
)(SellersUnactiveOverview);
