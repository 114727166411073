import { SagaIterator } from "@redux-saga/core";
import { all, takeEvery, takeLatest, throttle, takeLeading } from "@redux-saga/core/effects";
import ActionTypes from "../actions/ActionTypes";
import { initStateFromQueryString } from "./initState";
import goToPage from "./goToPage";
import { userLogin, userRegister, forgotPasswordRequest, confirmPassword, currentUser, confirmData } from "./user";
import {
    saveWarrantyData, deleteWarrantyData, getWarrantyData, getWarrantyStatuses, updateWarrantyStatus, getWarranty,
    getWarrantyForContract, changeValidDate, sendWarrantyEmail, saveWarrantyOnly, changeSeller, changeBuyer, changeWarrantyDuration
} from './warranty';
import { getPackages, getExtraPackages, upgradeWarrantyPackage } from "./package";
import {
    getAdminTableData, getAdminTableForBuyerData, getAdminArchiveTableData, getAdminTableForSellerData,
    getWarrantyAdminTableTotalCount, getWarrantyAdminBuyerTableTotalCount, getWarrantyAdminSellerTableTotalCount,
    getWarrantyAdminArchiveTableTotalCount
} from "./adminTable";
import {
    getCarData, getCar, getCarProblemExtraPackage, postCarOnly, deactivateCar, activateCar, getCarsAdmin, getCarsSeller,
    getCarsAdminOnlyCars, sellCar, getBuyerByCarId, getBuyerByOib, getBuyerByEmail, getModelsByBrand, reviewCar,
    currentCarReview, changeShowOnPortal, hideAllCarsOnPortal, getCarDetailsByCarId, updateCarDetails, getCarBrands,
    getEngineTypes, getCarModelsByBrand, loadMoreCarCards, filterCarCards, importCars, changeSellerEmail, getChassisCar,
    getCarsDropdownAdmin, getCarsDropdownSeller, getCarsAdminSoldOnlyCars, sellCarWithoutWarranty, postCarOnlyAndContinue,
    getCarsAdminTotalCount, getCarsAdminOnlyCarsTotalCount, getCarsAdminSoldOnlyTotalCount
} from './car';
import {
    getLegalEntities, saveSeller, deleteSeller, activateSeller, getAllSellers, getSellerById, getSellerDropdownData, saveNoteForSeller,
    getSellersAdminTotalCount, getAllSellersTable, submitSellerContract, cancelSellerContract, deniedSellerContract, getAllSellersUnactiveTable, getSellersUnactiveAdminTotalCount,
    getMarketingSellersTable, getMarketingSellersTableTotalCount, getOtherActiveSellersTable, getOtherActiveSellersTotalCount
} from "./seller";
import {
    getDamage, getSellerName, getBuyerName, getCarName, getChassisDamage, getDamageStatuses, saveDamage, deleteDamage,
    getActiveDamage, getDamagesAdmin, getDamagesSeller, getDamagesBuyer, getDamagesCar, getDamagesWarranty, getDamagesSellerByAdmin,
    getDamagesAdminTotalCount
} from './damage';
import {
    getAllBuyers, activateWarranty, checkWarrantiesForActivation, saveBuyer, deleteBuyer, getBuyerById, sendActivationEmail, activateBuyer,
    getBuyersAdminTotalCount, getLegalBuyers
} from './buyer';
import { editMissingCarDetails } from './missingCarDetails';
import {
    getThisMonthSoldStatistics, getSoldStatistics, getMarketingThisMonthSoldStatistic, getStatisticsMarketingWarrantiesTable,
    getStatisticsWarrantiesTable, getLastYearThisMonthStatistic
} from './statistics';
import { sendWarrantyPdf, getInvoices, lastInvoice, getInvoice, getYears } from './invoicing';
import { getCurrencies } from './currency';
export default function* rootSaga(): SagaIterator {
    yield all([
        takeEvery(ActionTypes.INIT_STATE, initStateFromQueryString),
        takeLatest(ActionTypes.GO_TO_PAGE, goToPage),
        takeLatest(ActionTypes.LOGIN_SUBMIT, userLogin),
        takeLatest(ActionTypes.SAVE_WARRANTY, saveWarrantyData),
        takeLatest(ActionTypes.DELETE_WARRANTY, deleteWarrantyData),
        takeLatest(ActionTypes.GET_PACKAGES, getPackages),
        takeLatest(ActionTypes.GET_EXTRA_PACKAGES, getExtraPackages),
        takeLatest(ActionTypes.GET_CAR_DATA, getCarData),
        takeLatest(ActionTypes.GET_WARRANTIES, getWarrantyData),
        takeLatest(ActionTypes.GET_WARRANTIES_FOR_BUYER, getAdminTableForBuyerData),
        takeLatest(ActionTypes.GET_WARRANTIES_FOR_SELLER, getAdminTableForSellerData),
        takeLatest(ActionTypes.SELL_CAR, sellCar),
        takeLatest(ActionTypes.GET_BUYER_BY_CAR_ID, getBuyerByCarId),
        takeLatest(ActionTypes.GET_BUYER_BY_OIB, getBuyerByOib),
        takeLatest(ActionTypes.GET_BUYER_BY_EMAIL, getBuyerByEmail),
        takeLatest(ActionTypes.GET_BUYER, getBuyerById),
        takeLatest(ActionTypes.GET_WARRANTY_STATUSES, getWarrantyStatuses),
        takeLatest(ActionTypes.UPDATE_WARRANTY_STATUS, updateWarrantyStatus),
        takeLatest(ActionTypes.GET_WARRANTY, getWarranty),
        takeLatest(ActionTypes.GET_CAR, getCar),
        takeLatest(ActionTypes.REGISTER_SUBMIT, userRegister),
        takeLatest(ActionTypes.GET_ADMIN_TABLE_DATA, getAdminTableData),
        takeLatest(ActionTypes.GET_ADMIN_ARCHIVE_TABLE_DATA, getAdminArchiveTableData),
        takeLatest(ActionTypes.GET_CAR_MODELS, getModelsByBrand),
        takeLatest(ActionTypes.GET_LEGAL_ENTITIES, getLegalEntities),
        takeLatest(ActionTypes.SAVE_SELLER, saveSeller),
        takeLatest(ActionTypes.ACTIVATE_SELLER, activateSeller),
        takeLatest(ActionTypes.DELETE_SELLER, deleteSeller),
        takeLatest(ActionTypes.SAVE_BUYER, saveBuyer),
        takeLatest(ActionTypes.DELETE_BUYER, deleteBuyer),
        takeLatest(ActionTypes.ACTIVATE_BUYER, activateBuyer),
        takeLatest(ActionTypes.GET_DAMAGE, getDamage),
        takeLatest(ActionTypes.GET_CHASSIS_DAMAGE, getChassisDamage),
        takeLatest(ActionTypes.GET_ACTIVE_DAMAGE, getActiveDamage),
        takeLatest(ActionTypes.GET_DAMAGE_STATUSES, getDamageStatuses),
        takeLatest(ActionTypes.SAVE_DAMAGE, saveDamage),
        takeLatest(ActionTypes.GET_DAMAGES_ADMIN, getDamagesAdmin),
        takeLatest(ActionTypes.GET_CARS_ADMIN, getCarsAdmin),
        takeLatest(ActionTypes.GET_CARS_ADMIN_ONLY_CARS, getCarsAdminOnlyCars),
        takeLatest(ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_CARS, getCarsAdminSoldOnlyCars),
        takeLatest(ActionTypes.GET_CAR_PROBLEM_EXTRA_PACKAGE, getCarProblemExtraPackage),
        takeLatest(ActionTypes.GET_DAMAGES_SELLER, getDamagesSeller),
        takeLatest(ActionTypes.GET_DAMAGES_BUYER, getDamagesBuyer),
        takeLatest(ActionTypes.GET_DAMAGES_CAR, getDamagesCar),
        takeLatest(ActionTypes.GET_DAMAGES_WARRANTY, getDamagesWarranty),
        takeLatest(ActionTypes.GET_DAMAGES_SELLERBYADMIN, getDamagesSellerByAdmin),
        takeLatest(ActionTypes.EMAIL_SUBMIT, forgotPasswordRequest),
        takeLatest(ActionTypes.PASSWORD_CONFIRM_SUBMIT, confirmPassword),
        takeLatest(ActionTypes.GET_CURRENT_USER, currentUser),
        takeLatest(ActionTypes.CONFIRM_DATA, confirmData),
        takeLatest(ActionTypes.GET_BUYERS, getAllBuyers),
        takeLatest(ActionTypes.REVIEW_CAR, reviewCar),
        takeLatest(ActionTypes.GET_CURRENT_CAR_REVIEW, currentCarReview),
        takeLatest(ActionTypes.GET_SELLERS, getAllSellers),
        takeLatest(ActionTypes.GET_SELLER, getSellerById),
        takeLatest(ActionTypes.ACTIVATE_WARRANTY, activateWarranty),
        takeLatest(ActionTypes.GET_WARRANTY_FOR_CONTRACT, getWarrantyForContract),
        takeLatest(ActionTypes.CHECK_WARRANTIES_FOR_ACTIVATION, checkWarrantiesForActivation),
        takeLatest(ActionTypes.EDIT_MISSING_CAR_DETAILS, editMissingCarDetails),
        takeLatest(ActionTypes.GET_THIS_MONTH_SOLD_STATISTIC, getThisMonthSoldStatistics),
        takeLatest(ActionTypes.GET_SOLD_STATISTIC, getSoldStatistics),
        takeLatest(ActionTypes.UPGRADE_WARRANTY_PACKGAGE, upgradeWarrantyPackage),
        takeLatest(ActionTypes.CHANGE_SHOW_ON_PORTAL, changeShowOnPortal),
        takeLatest(ActionTypes.HIDE_ALL_CARS_ON_PORTAL, hideAllCarsOnPortal),
        takeLatest(ActionTypes.GET_CAR_DETAILS, getCarDetailsByCarId),
        takeLatest(ActionTypes.UPDATE_CAR_DETAILS, updateCarDetails),
        takeLatest(ActionTypes.GET_CAR_BRANDS, getCarBrands),
        takeLatest(ActionTypes.GET_ENGINE_TYPES, getEngineTypes),
        takeLatest(ActionTypes.GET_CAR_MODELS_BY_BRAND, getCarModelsByBrand),
        takeLatest(ActionTypes.LOAD_MORE_CARS, loadMoreCarCards),       
        takeLatest(ActionTypes.GET_SELLER_DROPDOWN_DATA, getSellerDropdownData),
        takeLatest(ActionTypes.FILTER_CARS, filterCarCards),
        takeLatest(ActionTypes.SEND_INVOICE, sendWarrantyPdf),
        takeLatest(ActionTypes.GET_INVOICES, getInvoices),
        takeLatest(ActionTypes.GET_YEARS, getYears),
        takeLatest(ActionTypes.GET_INVOICE, getInvoice),
        takeLatest(ActionTypes.GET_LAST_INVOICE, lastInvoice),
        takeLatest(ActionTypes.SAVE_NOTE_FOR_SELLER, saveNoteForSeller),
        takeLatest(ActionTypes.IMPORT_CARS, importCars),
        takeLatest(ActionTypes.GET_CURRENCIES, getCurrencies),
        takeLatest(ActionTypes.CHANGE_VALID_DATE, changeValidDate),
        takeLatest(ActionTypes.GET_SELLER_NAME, getSellerName),
        takeLatest(ActionTypes.GET_BUYER_NAME, getBuyerName),
        takeLatest(ActionTypes.GET_CAR_NAME, getCarName),
        takeLatest(ActionTypes.SEND_ACTIVATION_EMAIL_BUYER, sendActivationEmail),
        takeLatest(ActionTypes.SEND_WARRANTY_EMAIL, sendWarrantyEmail),
        takeLatest(ActionTypes.DELETE_DAMAGE, deleteDamage),
        takeLatest(ActionTypes.DEACTIVATE_CAR, deactivateCar),
        takeLatest(ActionTypes.ACTIVATE_CAR, activateCar),
        takeLatest(ActionTypes.POST_CAR_ONLY, postCarOnly),
        takeLatest(ActionTypes.POST_CAR_ONLY_AND_CONTINUE, postCarOnlyAndContinue),
        takeLatest(ActionTypes.CHANGE_SELLER_EMAIL, changeSellerEmail),
        takeLatest(ActionTypes.GET_CHASSIS_CAR, getChassisCar),
        takeLatest(ActionTypes.GET_CARS_SELLER, getCarsSeller),
        takeLatest(ActionTypes.GET_CARS_DROPDOWN_ADMIN, getCarsDropdownAdmin),
        takeLatest(ActionTypes.GET_CARS_DROPDOWN_SELLER, getCarsDropdownSeller),
        takeLatest(ActionTypes.SELL_CAR_WITHOUT_WARRANTY, sellCarWithoutWarranty),
        takeLatest(ActionTypes.SAVE_WARRANTY_ONLY, saveWarrantyOnly),
        takeLatest(ActionTypes.GET_CARS_ADMIN_TOTAL_COUNT, getCarsAdminTotalCount),
        takeLatest(ActionTypes.GET_CARS_ADMIN_ONLY_CARS_TOTAL_COUNT, getCarsAdminOnlyCarsTotalCount),
        takeLatest(ActionTypes.GET_CARS_ADMIN_SOLD_ONLY_TOTAL_COUNT, getCarsAdminSoldOnlyTotalCount),
        takeLatest(ActionTypes.GET_WARRANTY_ADMIN_TABLE_TOTAL_COUNT, getWarrantyAdminTableTotalCount),
        takeLatest(ActionTypes.GET_WARRANTY_ADMIN_BUYER_TABLE_TOTAL_COUNT, getWarrantyAdminBuyerTableTotalCount),
        takeLatest(ActionTypes.GET_WARRANTY_ADMIN_SELLER_TABLE_TOTAL_COUNT, getWarrantyAdminSellerTableTotalCount),
        takeLatest(ActionTypes.GET_WARRANTY_ADMIN_ARCHIVE_TABLE_TOTAL_COUNT, getWarrantyAdminArchiveTableTotalCount),
        takeLatest(ActionTypes.GET_DAMAGES_ADMIN_TOTAL_COUNT, getDamagesAdminTotalCount),
        takeLatest(ActionTypes.GET_BUYERS_ADMIN_TOTAL_COUNT, getBuyersAdminTotalCount),
        takeLatest(ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT, getSellersAdminTotalCount),
        takeLatest(ActionTypes.GET_SELLERS_TABLE, getAllSellersTable),
        takeLatest(ActionTypes.CHANGE_SELLER, changeSeller),
        takeLatest(ActionTypes.CHANGE_BUYER, changeBuyer),
        takeLatest(ActionTypes.SUBMIT_SELLER_CONTRACT, submitSellerContract),
        takeLatest(ActionTypes.CANCEL_SELLER_CONTRACT, cancelSellerContract),
        takeLatest(ActionTypes.DENIED_SELLER_CONTRACT, deniedSellerContract), 
        takeLatest(ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT, getSellersUnactiveAdminTotalCount),
        takeLatest(ActionTypes.GET_SELLERS_UNACTIVE_TABLE, getAllSellersUnactiveTable),
        takeLatest(ActionTypes.GET_MARKETING_SELLERS_TABLE, getMarketingSellersTable),
        takeLatest(ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT, getMarketingSellersTableTotalCount),
        takeLatest(ActionTypes.GET_MARKETING_THIS_MONTH_SOLD_STATISTIC, getMarketingThisMonthSoldStatistic),
        takeLatest(ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE, getOtherActiveSellersTable),
        takeLatest(ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT, getOtherActiveSellersTotalCount),
        takeLatest(ActionTypes.GET_STATISTICS_MARKETING_WARRANTIES_TABLE, getStatisticsMarketingWarrantiesTable),
        takeLatest(ActionTypes.GET_STATISTICS_WARRANTIES_TABLE, getStatisticsWarrantiesTable),
        takeLatest(ActionTypes.GET_LEGAL_BUYERS, getLegalBuyers),
        takeLatest(ActionTypes.CHANGE_WARRANTY_DURATION, changeWarrantyDuration),
        takeLatest(ActionTypes.GET_LAST_YEAR_THIS_MONTH_STATISTIC, getLastYearThisMonthStatistic),
    ]);
}