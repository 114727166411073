import ICarReview from './../types/ICarReview';

export default class CarReviewHelper {
    /**
     *  Returns true if review has any mistake.
     * @param review 
     */
    static warrantyHasMistakes(review: ICarReview): boolean {
        return !(review.chassisNumberIsValid &&
            review.brandIsValid &&
            review.modelIsValid &&
            review.enginePowerIsValid &&
            review.engineTypeIsValid &&
            review.distanceIsValid &&
            review.manufactureYearIsValid &&
            review.registrationYearIsValid &&
            review.priceIsValid &&
            review.engineDisplacementIsValid &&
            review.maxTonnageIsValid);
    }

    static isDifferent (review1: ICarReview, review2: ICarReview): boolean {
            const result =  !(review1.brandIsValid === review2.brandIsValid 
            && review1.chassisNumberIsValid === review2.chassisNumberIsValid
            && review1.distanceIsValid === review2.distanceIsValid
            && review1.engineDisplacementIsValid === review2.engineDisplacementIsValid
            && review1.enginePowerIsValid === review2.enginePowerIsValid
            && review1.engineTypeIsValid === review2.engineTypeIsValid
            && review1.manufactureYearIsValid === review2.manufactureYearIsValid
            && review1.maxTonnageIsValid === review2.maxTonnageIsValid
            && review1.modelIsValid === review2.modelIsValid
            && review1.priceIsValid === review2.priceIsValid
            && review1.registrationYearIsValid === review2.registrationYearIsValid) 
            return result;       
    }

}