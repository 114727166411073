import React, { useEffect } from "react";
import { IApplicationState } from "../../types/applicationState";
import { Dispatch } from "redux";
import ActionTypes from "../../redux/actions/ActionTypes";
import { connect } from "react-redux";
import ISellerProps from "../../types/ISellerProps";
import AdminSellersTable from "./../../components/tables/AdminSellersOverview";
import {
  GetAllSellersTableAction,
  GetSellersAdminTotalCountAction,
} from "./../../redux/actions/SellerAction";
import GoToPageAction from "../../redux/actions/GoToPageAction";
import ReactGA from "react-ga";

interface IStateToProps {
  sellers: ISellerProps[];
  totalCount: number;
}

interface IDispatchToProps {
  getSellersTable: (page: number, pageSize: number, filters: string) => void;
  goToSellerDetails: (sellerId: string) => void;
  getTotalCount: () => void;
}

interface IProps extends IDispatchToProps, IStateToProps {}

const SellersOverview: React.FC<IProps> = (props) => {
  useEffect(() => {
    ReactGA.pageview("Admin | pregled svih trgovaca");
    props.getTotalCount();
    props.getSellersTable(1, 10, "");
  }, []);

  return (
    <AdminSellersTable
      goToSellerDetails={props.goToSellerDetails}
      rows={props.sellers}
      totalCount={props.totalCount}
      getSellers={props.getSellersTable}
    />
  );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
  sellers: state.sellersTable.data,
  totalCount: state.sellersAdminTableTotalCount.data,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
  getSellersTable: (page: number, pageSize: number, filters: string) =>
    dispatch<GetAllSellersTableAction>({
      type: ActionTypes.GET_SELLERS_TABLE,
      payload: { page, pageSize, filters },
    }),
  getTotalCount: () =>
    dispatch<GetSellersAdminTotalCountAction>({
      type: ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT,
    }),
  goToSellerDetails: (sellerId: string) =>
    dispatch<GoToPageAction>({
      type: ActionTypes.GO_TO_PAGE,
      payload: { url: "/seller/" + sellerId },
    }),
});

export default connect<
  IStateToProps,
  IDispatchToProps,
  IProps,
  IApplicationState
>(
  mapStateToProps,
  mapDispatchToProps
)(SellersOverview);
