
import { Reducer, Action } from 'redux';
import IWarrnatyStatusFormProps from './../../types/IWarrantyStatusFormProps';
import ActionTypes from './../actions/ActionTypes';
import { UpdateWarrantyStatusAction } from '../actions/WarrantyAction';
import IWarranty from '../../types/IWarranty';

type NullableWarrantyStatusesProps = IWarrnatyStatusFormProps | null;

export const warrantyStatusesReducer: Reducer<NullableWarrantyStatusesProps> = (state: NullableWarrantyStatusesProps = null, action): NullableWarrantyStatusesProps => {
    switch (action.type) {
        case ActionTypes.GET_WARRANTY_STATUSES_SUCCESS: return { ...action.payload }
        default: return state
    }
}

const status = {
    message: {
        content: "",
        errorMessage: false
    }
}

type NullableWarrantyUpdateProps = IWarranty | null;

export const updateWarrantyStatusReducer: Reducer<NullableWarrantyUpdateProps, UpdateWarrantyStatusAction> = (state: NullableWarrantyUpdateProps = status, action: UpdateWarrantyStatusAction): NullableWarrantyUpdateProps => {
    if (action.type === ActionTypes.UPDATE_WARRANTY_STATUS) return { ...action.payload, message: undefined }
    if (action.type === ActionTypes.UPDATE_WARRANTY_STATUS_SUCCESS || action.type === ActionTypes.SAVE_WARRANTY_SUCCESS) return { ...action.payload, message: { content: "Podaci su uspješno spremljeni!", errorMessage: false } }
    return state;
}

