import React, { useState, useEffect } from 'react';
import { Paper, Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './importCars.module.scss';
import ISellerProps from './../../../types/ISellerProps';
import { IImportCar } from './../../../types/IImportCar';

import Select from 'react-select';

interface IProps extends IDispatchToProps, IStateToProps {}

interface IDispatchToProps {
  submit: (data: IImportCar) => void,
}

interface IStateToProps {
  sellers: ISellerProps[];
}

const ImportCarsForm: React.FC<IProps> = (props: IProps) => {
  const handleSubmit = () => {
    if (!selectedSeller) {
      alert('Potrebno odabrati trgovca!');
      return;
    }
    var htmls: any = [];
    htmlList.forEach((htmlObject: any) => {
      if (htmlObject.html.length > 0) htmls.push(htmlObject.html);
    });
    var data: IImportCar = {
      sellerId: selectedSeller.value,
      htmls: htmls,
    };
    props.submit(data);
  };

  const [selectedSeller, setSelectedSeller] = useState<any>();

  const handleSelectSeller = (value: any) => {
    localStorage.setItem('carSeller', JSON.stringify(value));
    setSelectedSeller(value);
  };

  const [carNumber, setCarNumber] = useState<number>(1);
  const [htmlList, setHtmlList] = useState<Array<Object>>([{ id: carNumber, html: '' }]);

  const handleTextArea = (id: number) => {
    let html = (document.getElementById(id.toString()) as HTMLInputElement).value;
    let index = htmlList.findIndex((o: any) => o.id === id);
    (htmlList as any)[index].html = html;
    setHtmlList(htmlList);
  };

  const addOneMore = () => {
    htmlList.push({ id: carNumber + 1, html: '' });
    setCarNumber(carNumber + 1);
    setHtmlList(htmlList);
  };

  return (
    <Grid container justify="center" alignItems="center" direction="row" spacing={1}>
      <Paper className={styles.container}>
        <Grid item direction="row" xs={12}>
          <h2 style={{ color: 'black', display: 'block', fontSize: 22 }}>Import vozila</h2>
          <p style={{ color: 'black', display: 'block', fontSize: 14 }}>
            Ući u vozilo na njuškalo, ctrl + u, copy/paste ovdje.
          </p>
        </Grid>

        <Grid item xs={12} spacing={3} className={styles.formContainer}>
          <ValidatorForm onSubmit={() => {}}>
            <Grid item xs={12} className={styles.ListItem}>
              <p style={{ fontSize: 22 }}>Trgovac</p>
              <Select
                placeholder={'Odaberite trgovca'}
                value={selectedSeller}
                onChange={handleSelectSeller}
                options={
                  props.sellers &&
                  props.sellers.map((m: ISellerProps) => ({
                    label: m.fullName,
                    value: m.id,
                  }))
                }
                className={styles.selectSeller}
                isClearable
              />
            </Grid>
            <Grid item xs={12} className={styles.ListItem}>
              {htmlList &&
                htmlList.map((html: any) => (
                  <textarea className={styles.textarea} rows={3} id={html.id} onChange={() => handleTextArea(html.id)}>
                    {html.html}
                  </textarea>
                ))}
            </Grid>
            <Grid item xs={12} className={styles.btnWrapper}>
              <Button
                className={styles.addOneMore}
                variant="outlined"
                color="primary"
                type="submit"
                onClick={() => addOneMore()}
              >
                Još jedno auto
              </Button>
            </Grid>
            <Grid container item justify="flex-end" alignItems="center" spacing={2}>
              <Grid item className={styles.GridButton}>
                <Button variant="contained" color="primary" type="submit" onClick={() => handleSubmit()}>
                  Spremi
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ImportCarsForm;
