import React from 'react';
import styles from './legal.module.scss';
import PublicFormLayout from '../authentication/PublicFormLayout';
import { BackButton } from '../buttons/BackButton';

const Consent = () => {
    return (
        <PublicFormLayout className={styles.Layout} md={true} lg={true}>
            <div className={styles.Root}>
                <h2 className={styles.Header}>Privola</h2>
                <p>Ovime izjavljujem da sam suglasan/a s kori&scaron;tenjem mojih osobnih podataka u svrhu obavljanja osnovne djelatnost tvrtke <strong>Točka na J doo</strong> (<strong>OIB: 57739667576</strong>) kontaktiranje , slanja ponuda i pružanja zatraženih informacija . Upoznat/a sam i sa pravom te načinom povlačenja iskazane suglasnosti u svako doba.. Vi&scaron;e informacija o za&scaron;titi osobnih podatka pročitajte&nbsp;<strong><a href="/privacy-policy">ovdje</a></strong></p>
                <BackButton />
            </div>
        </PublicFormLayout>
    )
}

export default Consent;