import { Reducer } from "redux";
import ActionTypes from "../actions/ActionTypes";
import IPhotoUploadProps from './../../types/IPhotoUploadProps';

const initialValues: IPhotoUploadProps = {
    pictures: [],
    urls: []
}

const carPhotosReducer: Reducer<IPhotoUploadProps> = (state: IPhotoUploadProps = initialValues, action): IPhotoUploadProps => {
    switch (action.type) {
        case ActionTypes.SAVE_PHOTOS: return { ...action.payload };
        default: return state;
    }
}

export default carPhotosReducer;


