import { injectable, inject } from 'inversify';
import ThirdPartyTypes from './../../di/ThirdPartyTypes';
import { AxiosInstance } from 'axios';
import { currencyApiURL } from './../../constants/apiRoutes';
import { ICurrency } from '../../types/ICurrency';
import ICurrencyService from './ICurrencyService';

@injectable()
export default class CurrencyService implements ICurrencyService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getCurrencies(): Promise<ICurrency[]> {
        const response = await this.apiClient.get(currencyApiURL + '/currencies');
        return response.data;
    }

}