import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import styles from './TOS.module.scss';
import { Link } from 'react-router-dom';

interface IProps {
    dataAccepted: boolean,
    tosAccepted: boolean,
    setTosAccepted: () => void,
    setDataAccepted: () => void
}
const Tos = (props: IProps) => {
    return (<div>
        <div className={styles.Root}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item direction="row" xs={12}>
                    <FormControlLabel className={styles.FormControlLabel}
                        label={
                            <div>
                                <span>Prihvaćam </span>
                                <Link to={'tos'}>Opće uvjete poslovanja</Link>
                            </div>
                        }
                        labelPlacement="start"
                        control={
                            <Checkbox
                                value={props.tosAccepted}
                                onChange={() => props.setTosAccepted()}
                                color="primary"
                            />
                        }
                    />
                </Grid>
                <Grid item direction="row" xs={12}>
                    <FormControlLabel className={styles.FormControlLabel}
                        label={
                            <div>
                                <span>Prihvaćam </span>
                                <Link to={'privacy-policy'}>Izjavu o privatnosti</Link>
                                <span> i pripadajuću  </span>
                                <Link to={'consent'}>Privolu</Link>

                            </div>
                        }
                        labelPlacement="start"
                        control={
                            <Checkbox
                                value={props.tosAccepted}
                                onChange={() => props.setDataAccepted()}
                                color="primary"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </div>
    </div >);
}

export default Tos;