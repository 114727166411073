import React from 'react';
import { Paper, Button, Grid, Fab, Box, Tooltip } from '@material-ui/core';
import MaterialTable from 'material-table'
import styles from './warrantyTable.module.scss';
import IWarrantyOverview from './../../types/IWarrantyOverview';
import { CarStatus } from '../../domain/enums/CarStatus';
import WarrantyStatus from '../../domain/enums/WarrantyStatus';
import { Trans, useTranslation } from 'react-i18next';
import { isBuyer } from '../../utils/AuthFunctions';
import AddIcon from '@material-ui/icons/Add';
import { isSeller } from './../../utils/AuthFunctions';
import { StepperHelper } from '../../utils/StepperHelper';
import { BuyerInfoCards } from './../cards/buyerInfoCards';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import ListItem from '@material-ui/core/ListItem';
import { List, ListItemIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { warrantyPdf } from '../../constants/apiRoutes';
import UpgradeWarrantyPackage from '../../containers/warranty/UpgradeWarrantyPackage';
import moment from "moment";
import { isAdmin, isSuperAdmin } from './../../utils/AuthFunctions';


interface IProps {
    rows: IWarrantyOverview[],
    goToEditWarranty: (rowData: IWarrantyOverview) => void,
    showSellCarForm: (warrantyId: number, isCarAvailable: boolean) => void,
    goToWarrantyPage: () => void,
    warrantiesLoading: boolean,
}

const WarrantyTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const buyer = isBuyer();

    const mapPropsToTable = (): IWarrantyOverview[] => {
        let newRows = new Array<IWarrantyOverview>();
        props.rows.forEach((row: IWarrantyOverview) => {
            const isAvailable = row.carStatus === CarStatus.Available;

            let newRow: IWarrantyOverview = {
                ...row,
                warrantyStatus: t(row.warrantyStatus),
                carStatus: isAvailable ? 'Prodaja' : 'O kupcu'
            }
            newRows.push(newRow);
        });
        return newRows;
    }

    const editWarranty = (rowData: IWarrantyOverview): void => props.goToEditWarranty(rowData);

    const sellCar = (rowData: IWarrantyOverview): void => props.showSellCarForm(rowData.warrantyId, rowData.carStatus == CarStatus.Available);


    const carButton = (rowData: IWarrantyOverview) => {
        const propsRow = findByWarrantyId(rowData.warrantyId);
        const isAvailable = propsRow.carStatus === CarStatus.Available;
        const warrantyApproved = propsRow.warrantyStatus === WarrantyStatus.Approved ||
            propsRow.warrantyStatus === WarrantyStatus.Active;
        return buyer ?
            rowData.carStatus
            :
            <div style={{ display: '-webkit-inline-box' }}>
                <Button className={isAvailable ? warrantyApproved ? styles.Available : '' : ''}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => sellCar(propsRow)}
                    disabled={!warrantyApproved}
                    variant="contained"
                    size="small"
                >
                    {rowData.carStatus}
                </Button>
                {!isAvailable &&
                    <ListItem button component={Link} target="_blank" to={warrantyPdf + '?warrantyId=' + rowData.warrantyId} style={{ width: 'auto' }}>
                        <ListItemIcon><PictureAsPdf /></ListItemIcon>
                    </ListItem>}
            </div>;
    }

    const buyerPdfButton = (rowData: IWarrantyOverview) => {
        const propsRow = findByWarrantyId(rowData.warrantyId);
        const isAvailable = propsRow.carStatus === CarStatus.Available;
        return (!isAvailable ?
            <ListItem button component={Link} target="_blank" to={warrantyPdf + '?warrantyId=' + rowData.warrantyId} style={{ width: 'auto' }}>
                    <ListItemIcon><PictureAsPdf /></ListItemIcon>
            </ListItem>
            : '');
    }

    const warrantyButton = (rowData: IWarrantyOverview) => {
        const propsRow = findByWarrantyId(rowData.warrantyId);
        return buyer ? rowData.warrantyStatus
            : <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => !isBuyer() && editWarranty(propsRow)}
                variant="contained" disabled={!StepperHelper.isWarrantyForReview(propsRow.warrantyStatus)
                    && propsRow.warrantyStatus !== WarrantyStatus.InProcess}
                className={styles[propsRow.warrantyStatus]}
                style={{ minWidth: '90px' }}
                size="small"
            >{rowData.warrantyStatus}
            </Button >
    }
    const packageCell = (rowData: IWarrantyOverview) => {
        const propsRow = findByWarrantyId(rowData.warrantyId);
        var extraPackages = ""
        if (rowData.allPackages.extraPackages && rowData.allPackages.extraPackages.length > 0) {
            extraPackages = "+ " + rowData.allPackages.extraPackages.length + " dodatka"
        }
        return (
            <div style={{ display: 'flex' }}>
                <div>
                    <span style={{ display: 'block', fontSize: '12px', height: '20px' }}>{rowData.allPackages.package.packageName} &nbsp;</span>
                    <span style={{ fontSize: '12px', fontStyle: 'italic' }}>{extraPackages}</span>
                    {(propsRow.warrantyStatus === WarrantyStatus.Approved || propsRow.carStatus === CarStatus.Available) && (isAdmin() || isSuperAdmin()) ?
                        <UpgradeWarrantyPackage car={rowData.car} currentPackage={rowData.allPackages.package} currentExtraPackages={rowData.allPackages.extraPackages} warrantyId={rowData.warrantyId} />
                        :
                        <span />}
                </div>
            </div>
        )
    }

    const findByWarrantyId = (id: number): IWarrantyOverview => {
        return props.rows.filter((row: IWarrantyOverview) => row.warrantyId === id)[0];
    }

    let columns = [
        {
            title: 'Šifra', field: 'code',
            cellStyle: {
                maxWidth: 100,
                width: 100
            },
        },
        { title: 'Šasija', field: 'car.chassisNumber' },
        { title: 'Marka', field: 'brand' },
        { title: 'Model', field: 'model' },
        {
            title: 'Godište',
            field: 'car.manufactureYear',
            cellStyle: {
                maxWidth: 50,
                width: 50
            },
        },
        { title: 'Kilometri', field: 'car.distance' },
        {
            title: 'Paket', field: 'packageName',
            render: packageCell
        },
        {
            title: 'Status jamstva', field: 'warrantyStatus',
            render: warrantyButton
        }];

    if (!isBuyer()) columns.push({
        title: 'Status auta', field: 'carStatus', render: carButton
    })
    if (isBuyer()) {
        columns.push(
            { title: 'Početak valjanosti', field: 'validFrom' },
            { title: 'Završetak valjanosti', field: 'validUntil' },
            { title: '', field: '', render: buyerPdfButton }
        )
    }


    return <React.Fragment>
        <Box>
            <Grid container xs={12} justify="flex-end" alignItems="center">
                <Grid item>
                    {isSeller() && <Fab style={{ marginBottom: 15 }} onClick={() => props.goToWarrantyPage()} variant="extended" color="primary" aria-label="delete">
                        Dodaj Vozilo
                        <AddIcon />
                    </Fab>}
                </Grid>
            </Grid>
            {isBuyer() && <Grid item xs={12}>
                <BuyerInfoCards />
            </Grid>}
            <Paper className={styles.Root}>
                <Grid container item xs={12} className={isBuyer() ? styles.Buyer : ''}>
                    <MaterialTable
                        isLoading={props.warrantiesLoading}
                        options={{ padding: "dense", filtering: true }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: "Nema podataka za prikaz."
                            },
                            pagination: {
                                previousTooltip: 'Prethodna',
                                nextTooltip: 'Sljedeća',
                                firstTooltip: 'Početna',
                                lastTooltip: 'Posljednja'
                            }
                        }}
                        title="Pregled jamstava"
                        columns={columns}
                        data={[...mapPropsToTable()]}
                    />
                </Grid>
            </Paper>
        </Box>
    </React.Fragment>
}

export default WarrantyTable;
