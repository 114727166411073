import ILegalEntity from './../../types/ILegalEntitiy';
import { Reducer } from "redux";
import ActionTypes from './../actions/ActionTypes';
import ISellerProps from './../../types/ISellerProps';
import { ISeller } from '../../types/ISeller';


const parentLegalEntity: ILegalEntity = {
    id: "",
    name: ""
}

export const initalSeller: ISellerProps = {
    firstName: "",
    lastName: "",
    parentLegalEntity: parentLegalEntity,
    legalEntityName: "",
    legalEntityBool: false,
    oib: "",
    address: "",
    email: "",
    zipCode: "",
    city: "",
    country: "",
    phoneNumber: "",
    parentId: "",
    active: "",
    oznaka: false
}

const initalSellerData: { loading: boolean, seller: ISellerProps | null } = { loading: false, seller: initalSeller }

const initialLegalEntities: { loading: boolean, entities: ILegalEntity[] } = { loading: false, entities: new Array<ILegalEntity>() }

export const sellerReducer: Reducer<{ loading: boolean, seller: ISellerProps | null }> = (state = initalSellerData, action) => {
    switch (action.type) {
        case ActionTypes.SAVE_SELLER: return { ...state, loading: true, seller: null, message: undefined, error: false }
        case ActionTypes.SAVE_SELLER_SUCCESS: return {
            ...state, loading: false, seller: initalSeller, message: {
                content: "Prodavač uspješno registriran!",
                errorMessage: false
            }
        }
        case ActionTypes.SAVE_SELLER_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, seller: null, error: true
        }
        case ActionTypes.GET_SELLER: return {
            loading: true, seller: initalSeller
        }
        case ActionTypes.GET_SELLER_SUCCESS: return {
            loading: false, seller: action.payload
        }
        case ActionTypes.GET_SELLER_ERROR: return {
            loading: false, seller: initalSeller
        }
        case ActionTypes.CLEAR_SELLER: return {
            loading: false, seller: initalSeller
        }
        case ActionTypes.DELETE_SELLER: return { ...state, loading: true, seller: null, message: undefined, error: false }
        case ActionTypes.DELETE_SELLER_SUCCESS: return {
            ...state, loading: false, seller: initalSeller, message: {
                content: "Prodavač uspješno obrisan!",
                errorMessage: false
            }
        }
        case ActionTypes.DELETE_SELLER_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, seller: null, error: true
        }
        case ActionTypes.ACTIVATE_SELLER: return { ...state, loading: true, seller: null, message: undefined, error: false }
        case ActionTypes.ACTIVATE_SELLER_SUCCESS: return {
            ...state, loading: false, seller: initalSeller, message: {
                content: "Prodavač uspješno aktiviran!",
                errorMessage: false
            }
        }
        case ActionTypes.ACTIVATE_SELLER_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, seller: null, error: true
        }
        case ActionTypes.SUBMIT_SELLER_CONTRACT: return { ...state, loading: true, seller: null, message: undefined, error: false }
        case ActionTypes.SUBMIT_SELLER_CONTRACT_SUCCESS: window.close(); return {
            ...state, loading: false, seller: initalSeller, message: {
                content: "Ugovor trgovca uspješno aktiviran!",
                errorMessage: false
            }
        }
        case ActionTypes.SUBMIT_SELLER_CONTRACT_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, seller: null, error: true
        }
        case ActionTypes.CANCEL_SELLER_CONTRACT: return { ...state, loading: true, seller: null, message: undefined, error: false }
        case ActionTypes.CANCEL_SELLER_CONTRACT_SUCCESS: window.close(); return {
            ...state, loading: false, seller: initalSeller, message: {
                content: "Ugovor trgovca uspješno otkazan!",
                errorMessage: false
            }
        }
        case ActionTypes.CANCEL_SELLER_CONTRACT_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, seller: null, error: true
        }
        case ActionTypes.DENIED_SELLER_CONTRACT: return { ...state, loading: true, seller: null, message: undefined, error: false }
        case ActionTypes.DENIED_SELLER_CONTRACT_SUCCESS: return {
            ...state, loading: false, seller: initalSeller, message: {
                content: "Ugovor trgovca uspješno odbijen!",
                errorMessage: false
            }
        }
        case ActionTypes.DENIED_SELLER_CONTRACT_ERROR: return {
            ...state, loading: false, message: {
                content: action.payload.response.data,
                errorMessage: true
            }, errors: action.payload, seller: null, error: true
        }
        default: return state;
    }
}

export const legalEntitiesReducer: Reducer<{ loading: boolean, entities: ILegalEntity[] }> = (state = initialLegalEntities, action) => {
    switch (action.type) {
        case ActionTypes.GET_LEGAL_ENTITIES: return { ...state, loading: true, entities: [] }
        case ActionTypes.GET_LEGAL_ENTITIES_SUCCESS: return { ...state, loading: false, entities: action.payload.legalEntities }
        case ActionTypes.GET_LEGAL_ENTITIES_ERROR: return { ...state, loading: false, errors: action.payload, entities: [] }
        default: return state;
    }
}

export default sellerReducer;

const initialSellersData: { loading: boolean, data: ISellerProps[] } = { loading: false, data: new Array<ISellerProps>() }

export const getSellersReducer: Reducer<{ loading: boolean, data: ISellerProps[] }> = (state = initialSellersData, action) => {
    switch (action.type) {
        case ActionTypes.GET_SELLERS: return { loading: true, data: [] }
        case ActionTypes.GET_SELLERS_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_SELLERS_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}

const initialSellersDropdownData: { errors?: any, data: ISeller[] } = {
    data: []
}

export const sellerDropdowndDataReducer: Reducer<{ errors?: any, data: ISeller[] }> = (state = initialSellersDropdownData, action) => {
    switch (action.type) {
        case ActionTypes.GET_SELLER_DROPDOWN_DATA_SUCCESS: return { data: action.payload };
        case ActionTypes.GET_SELLER_DROPDOWN_DATA_SUCCESS: return { errors: action.payload, data: [] };
        default: return state;
    }
}


type SellersAdminTotalCountData = { data: number };

export const sellersAdminTotalCountReducer: Reducer<SellersAdminTotalCountData> = (state: SellersAdminTotalCountData = { data: 0 }, action: any): SellersAdminTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_SELLERS_ADMIN_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}


type SellersUnactiveAdminTotalCountData = { data: number };

export const sellersUnactiveAdminTotalCountReducer: Reducer<SellersUnactiveAdminTotalCountData> = (state: SellersUnactiveAdminTotalCountData = { data: 0 }, action: any): SellersUnactiveAdminTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_SELLERS_UNACTIVE_ADMIN_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}


type OtherActiveSellersTotalCountData = { data: number };

export const otherActiveSellersTotalCountReducer: Reducer<OtherActiveSellersTotalCountData> = (state: OtherActiveSellersTotalCountData = { data: 0 }, action: any): OtherActiveSellersTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_OTHER_ACTIVE_SELLERS_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}

const initialSellersTableData: { loading: boolean, data: ISellerProps[] } = { loading: false, data: new Array<ISellerProps>() }

export const getSellersTableReducer: Reducer<{ loading: boolean, data: ISellerProps[] }> = (state = initialSellersTableData, action) => {
    switch (action.type) {
        case ActionTypes.GET_SELLERS_TABLE: return { loading: true, data: [] }
        case ActionTypes.GET_SELLERS_TABLE_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_SELLERS_TABLE_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}

const initialSellersUnactiveTableData: { loading: boolean, data: ISellerProps[] } = { loading: false, data: new Array<ISellerProps>() }

export const getSellersUnactiveTableReducer: Reducer<{ loading: boolean, data: ISellerProps[] }> = (state = initialSellersUnactiveTableData, action) => {
    switch (action.type) {
        case ActionTypes.GET_SELLERS_UNACTIVE_TABLE: return { loading: true, data: [] }
        case ActionTypes.GET_SELLERS_UNACTIVE_TABLE_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_SELLERS_UNACTIVE_TABLE_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}


    const initialOtherActiveSellersTableData: { loading: boolean, data: ISellerProps[] } = { loading: false, data: new Array<ISellerProps>() }

    export const getOtherActiveSellersTableReducer: Reducer<{ loading: boolean, data: ISellerProps[] }> = (state = initialOtherActiveSellersTableData, action) => {
        switch (action.type) {
            case ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE: return { loading: true, data: [] }
            case ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE_SUCCESS: return { loading: false, data: action.payload }
            case ActionTypes.GET_OTHER_ACTIVE_SELLERS_TABLE_ERROR: return { loading: false, errors: action.payload, data: [] }
            default: return state;
        }
    }


//marketing

const initialMarketingSellersTableData: { loading: boolean, data: ISellerProps[] } = { loading: false, data: new Array<ISellerProps>() }

export const getMarketingSellersTableReducer: Reducer<{ loading: boolean, data: ISellerProps[] }> = (state = initialMarketingSellersTableData, action) => {
    switch (action.type) {
        case ActionTypes.GET_MARKETING_SELLERS_TABLE: return { loading: true, data: [] }
        case ActionTypes.GET_MARKETING_SELLERS_TABLE_SUCCESS: return { loading: false, data: action.payload }
        case ActionTypes.GET_MARKETING_SELLERS_TABLE_ERROR: return { loading: false, errors: action.payload, data: [] }
        default: return state;
    }
}


type MarketingSellersTotalCountData = { data: number };

export const marketingSellersTotalCountReducer: Reducer<MarketingSellersTotalCountData> = (state: MarketingSellersTotalCountData = { data: 0 }, action: any): MarketingSellersTotalCountData => {
    switch (action.type) {
        case ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT: return { data: 0 }
        case ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_MARKETING_SELLERS_TABLE_TOTAL_COUNT_ERROR: return { data: 0 }

        default: return state;
    }
}