import React from 'react';
import { Paper, Modal, Grid, FormControl, Box, FormHelperText, Button } from '@material-ui/core';
import styles from './newCar.module.scss';
import stylesModal from './chassisNumberModal.module.scss';
import ICarProps from '../../../types/ICarProps';
import IReviewMode from '../../../types/IReviewModeProps';
import ICarData from '../../../types/ICarData';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CarFormConstans from "../../../constants/FormConstants";
import Validator from "../../../utils/validation";
import SelectDropdown from 'react-select';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { components } from '../../dropdowns/DropdowndAutocomplete.utils';
import ICarModel from '../../../types/ICarModel';
import ICarBrand from '../../../types/ICarBrand';
import { IEngineType } from '../../../types/IEngineType';
import hrLocale from 'date-fns/esm/locale/hr/index';
import NumberFormat from 'react-number-format';
import { getNumberFromThousandsString } from './../../../utils/Converter';
import ReportIcon from '@material-ui/icons/Report';
import WarrantyStatusChecker from '../../../utils/warrantyStatusChecker';
import IWarrantyStatus from '../../../types/IStatus';
import ICarReview from '../../../types/ICarReview';
import { getUserRole } from '../../../utils/AuthFunctions';
import { Roles } from '../../../domain/enums/Roles';
import DateHelper from './../../../utils/DateHelper';
import { AutomaticGearBoxCheckButton } from './automaticGearBoxCheckButton';
import { FactoryWarrantyCheckButton } from './factoryWarrantyCheckButton';
import { RiskyCheckButton} from './RiskyCheckButton';
import translateText from './../../../utils/translateText';
import IWarranty from '../../../types/IWarranty';
import WarrantyStatus from '../../../domain/enums/WarrantyStatus';
import CarReviewHelper from '../../../utils/CarReviewHelper';
import moment from 'moment';
import { ChassisNumberModal } from './ChassisNumberModal';
import { IDamageProps } from '../../../types/Damage/IDamageProps';
import { WarrantyDurationRadioButtons } from './WarrantyDurationRadioButtons';
import { isAdmin, isSuperAdmin } from './../../../utils/AuthFunctions';

interface IProps extends ICarProps, ICarData, IReviewMode {
    ref?: React.RefObject<NewCarForm | undefined>,
    handleNext?: () => void,
    status?: IWarrantyStatus,
    getModelsByBrand?: (brandId: number) => void,
    carReview: ICarReview,
    setCarReview: (data: ICarReview) => void,
    currentWarranty?: IWarranty,
    getChassisDamage?: (chassisNumber: string) => void,
    chassisDamage?: IDamageProps
}

interface IState extends IProps {
    showChassisModal?: boolean,
    isOneYearWarranty?: boolean
}


export default class NewCarForm extends React.Component<IProps> {
    state = {
        showChassisModal: false,
        brand: {
            items: this.props.carBrands,
            value: {
                label: this.props.brand,
                value: this.props.brandId
            },
            hasError: false,
        },
        model: {
            items: this.props.carModels,
            value: {
                label: this.props.model,
                value: this.props.modelId
            },
            hasError: false,
        },
        engineType: {
            items: this.props.engineTypes,
            value: {
                label: this.props.engineTypeLabel,
                value: this.props.engineType
            },
            hasError: false,
        },
        enginePower: {
            value: this.props.enginePower,
        },
        engineDisplacement: {
            value: this.props.engineDisplacement
        }, maxTonnage: {
            value: this.props.maxTonnage
        },
        distance: {
            value: this.props.distance,
        },
        chassisNumber: {
            value: this.props.chassisNumber,
        },
        manufactureYear: {
            value: this.props.manufactureYear,
        },
        registrationYear: {
            value: this.props.registrationYear ? this.props.registrationYear : new Date()
        },
        price: {
            value: this.props.price,
        },
        automaticGearBox: {
            value: this.props.automaticGearBox
        },
        factoryWarrantyBool: {
            value: this.props.factoryWarrantyDate ? true : false
        },
        factoryWarrantyDate: {
            value: this.props.factoryWarrantyDate ? this.props.factoryWarrantyDate : new Date()
        },
        submitValid: false,
        status: this.props.status,
        carReview: {
            updateCarReview: false,
            carId: this.props.carReview.carId,
            brandIsValid: this.props.carReview.brandIsValid,
            modelIsValid: this.props.carReview.modelIsValid,
            engineTypeIsValid: this.props.carReview.engineTypeIsValid,
            enginePowerIsValid: this.props.carReview.enginePowerIsValid,
            distanceIsValid: this.props.carReview.distanceIsValid,
            chassisNumberIsValid: this.props.carReview.chassisNumberIsValid,
            manufactureYearIsValid: this.props.carReview.manufactureYearIsValid,
            registrationYearIsValid: this.props.carReview.registrationYearIsValid,
            priceIsValid: this.props.carReview.priceIsValid,
            engineDisplacementIsValid: this.props.carReview.engineDisplacementIsValid,
            maxTonnageIsValid: this.props.carReview.maxTonnageIsValid
        },
        isOneYearWarranty: {
            value: this.props.isOneYearWarranty !== undefined ? this.props.isOneYearWarranty : true
        },
        napomena: {
            value: this.props.napomena,
        },
        risky: {
            value: this.props.risky
        },
        carSaleId: {
            value: this.props.carSaleId
        },
        newWarranty: {
            value: this.props.newWarranty
        },
    }

    componentDidMount() {
        ValidatorForm.addValidationRule("isDistanceOverMax", distance => getNumberFromThousandsString(distance) < 230000);
        ValidatorForm.addValidationRule("validateChassis", chassis => Validator.validateChassis(chassis));
    }

    componentDidUpdate(prevState: IProps){
        
        this.props.setCarReview(this.state.carReview);

        if (this.props.chassisDamage && this.props.chassisDamage.carId !== 0) {
            if (prevState.chassisDamage === undefined) {
                this.showChassisModal();
            }
            else if(prevState.chassisDamage && prevState.chassisDamage.carId !== this.props.chassisDamage.carId){
                this.showChassisModal();
            }
        }

    }

    showChassisModal() {
        if (this.state.showChassisModal == false) {
            if (this.props.chassisDamage && ((this.props.chassisDamage.noOfReportedDamages !== 0 && this.props.chassisDamage.noOfReportedDamages !== undefined) ||
                (this.props.chassisDamage.noOfApprovedDamages != 0 && this.props.chassisDamage.noOfApprovedDamages != undefined) ||
                (this.props.chassisDamage.noOfDeclinedDamages != 0 && this.props.chassisDamage.noOfDeclinedDamages != undefined) ||
                (this.props.chassisDamage.noOfSolvedDamages != 0 && this.props.chassisDamage.noOfSolvedDamages != undefined))) {
                this.setState({ showChassisModal: true });
            }
        }
        
    }
    
    static getDerivedStateFromProps(props: IProps, state: any) {
        if (CarReviewHelper.isDifferent(props.carReview, state.carReview)) {
            if (!state.carReview.updateCarReview) return { carReview: { ...props.carReview, updateCarReview: false } }
        }

        if (!state.price.value && props.currentWarranty && props.currentWarranty!.carData! && props.currentWarranty.carData!.price !== state.price.value)
            return ({
                brand: {
                    items: props.carBrands,
                    value: {
                        label: props.brand,
                        value: props.brandId
                    },
                    hasError: false,
                },
                model: {
                    items: props.carModels,
                    value: {
                        label: props.model,
                        value: props.modelId
                    },
                    hasError: false,
                },
                engineType: {
                    items: props.engineTypes,
                    value: {
                        label: props.engineTypeLabel,
                        value: props.engineType
                    },
                    hasError: false,
                },
                enginePower: {
                    value: props.enginePower,
                },
                engineDisplacement: {
                    value: props.engineDisplacement
                },
                maxTonnage: {
                    value: props.maxTonnage
                },
                distance: {
                    value: props.distance,
                },
                chassisNumber: {
                    value: props.chassisNumber,
                },
                manufactureYear: {
                    value: props.manufactureYear,
                },
                registrationYear: {
                    value: props.registrationYear,
                },
                price: {
                    value: props.price,
                },
                automaticGearBox: {
                    value: props.automaticGearBox
                },
                factoryWarrantyBool: {
                    value: props.factoryWarrantyBool
                },
                factoryWarrantyDate: {
                    value: props.factoryWarrantyDate
                },
                isOneYearWarranty: {
                    value: props.isOneYearWarranty
                },
                napomena: {
                    value: props.napomena
                },
                risky: {
                    value: props.risky
                },
                carSaleId: {
                    value: props.carSaleId
                },
                newWarranty: {
                    value: props.newWarranty
                },
            })

        //models arrived from db
        if (props.carModels) {
            const items = props.carModels.filter((item: ICarModel) => item.brandId && item.brandId === state.brand.value.value);
            let value = state.model.value;
            try {
                if (props.carModels.length) {
                    if (!state.model.items.length) value = { label: undefined, value: undefined }
                    else {
                        if (props.carModels[0].name !== state.model.items[0].name) value = { label: props.model, value: props.modelId }
                    }
                } else value = { label: props.model, value: props.modelId }
            } catch { }
            if (props.carReview.carId !== state.carReview.carId)
                return {
                    model: { items, value, hasError: false, marked: state.model.marked }
                };
            return {
                model: { items, value, hasError: false, marked: state.model.marked }
            };
        }
    }

    private handleChange(el: any) {
        const { name, value } = el.target;
        this.setState((prevState: any) => ({
            ...prevState, [name]: { value }
        }))
    }

    private handleSelectChange(el: any, propName: string) {
        const { label, value } = el;
        const hasError = false;
        if (propName === 'brand') this.props.getModelsByBrand!(value);
        this.setState((prevState: any) => ({
            ...prevState,
            [propName]: { ...prevState[propName], value: { label: label, value: value }, hasError }
        }));
    }

    private handleChassisChange(el: any) {
        const { name, value } = el.target;

        if(value && value.length === 17) this.props.getChassisDamage!(value);

        this.setState((prevState: any) => ({
            ...prevState, [name]: { value }
        }))

    }

    private handleDateChange(date: Date | null, propName: string | null | undefined) {
        let newDate;
        try {
            newDate = date!.toISOString();
        } catch { }
        if (date) {
            if (date < new Date())
                this.setState({
                    registrationYear: { value: newDate ? newDate : date }
                })

            else
                this.setState({
                    factoryWarrantyDate: { value: newDate ? newDate : date }
                })
        }

    }

    public validateForm(): boolean {
        const state = this.state;
        let formValid = true;
        if (state.brand.value.value === undefined) {
            const hasError = true;
            formValid = false;
            const name = "brand";
            this.setState((prevState: any) => ({
                ...prevState,
                [name]: { ...prevState[name], hasError }
            }));
        }
        if (state.model.value.value === undefined) {
            const hasError = true;
            formValid = false;
            const name = "model";
            this.setState((prevState: any) => ({
                ...prevState,
                [name]: { ...prevState[name], hasError }
            }));
        }
        if (state.engineType.value.value === undefined) {
            const hasError = true;
            formValid = false;
            const name = "engineType";
            this.setState((prevState: any) => ({
                ...prevState,
                [name]: { ...prevState[name], hasError }
            }));
        }
        if (DateHelper.dateDiffInYears(this.state.registrationYear.value, new Date()) > 12) formValid = false;
        return formValid;
    }

    checkSubmit(): boolean {
        return this.state.submitValid;
    }

    handleSubmit(props: IProps) {
        this.setState((prevState: any) => ({
            ...prevState,
            submitValid: true
        }))
        if (props.handleNext) props.handleNext();
    }

    public changeMarked(name: string, marked: boolean) {
        this.setState((prevState: any) => ({
            ...prevState,
            carReview: { ...prevState.carReview, [name]: marked, updateCarReview: true }
        }));
    }


    public getStateData(): ICarProps {
        const state = this.state;

        let data: ICarProps = {
            brandId: state.brand.value.value,
            brand: state.brand.value.label,
            modelId: state.model.value.value,
            model: state.model.value.label,
            engineType: state.engineType.value.value,
            engineTypeLabel: state.engineType.value.label,
            enginePower: state.enginePower.value,
            distance: state.distance.value && getNumberFromThousandsString(state.distance.value),
            chassisNumber: state.chassisNumber.value,
            manufactureYear: state.manufactureYear.value,
            registrationYear: state.registrationYear ? state.registrationYear.value : new Date(),
            price: state.price.value && getNumberFromThousandsString(state.price.value),
            carId: this.props.carId,
            engineDisplacement: state.engineDisplacement.value,
            maxTonnage: state.maxTonnage.value,
            automaticGearBox: state.automaticGearBox.value,
            factoryWarrantyBool: state.factoryWarrantyBool.value,
            factoryWarrantyDate: state.factoryWarrantyBool.value ? state.factoryWarrantyDate.value : undefined,
            isOneYearWarranty: state.isOneYearWarranty.value,
            napomena: state.napomena.value,
            risky: state.risky.value,
            carSaleId: state.carSaleId.value ? state.carSaleId.value : undefined,
            newWarranty: state.newWarranty.value
        };
        return data;
    }

    private prepareDate(date: any): string {
      let isoDate;
      try {
        if (date !== undefined && date !== null) {
            try {
                isoDate = (date).toISOString();
          } catch {
              let temp = date.split('/');
              temp = temp.reverse();
              isoDate = temp.join('-');
          }
        }
        else isoDate = (new Date()).toISOString();
      }
      catch
      {
          isoDate = date
      }
        return isoDate;
      }

    render() {
        const state = this.state;

        let registrationYear = this.prepareDate(state.registrationYear.value);
        let factoryWarrantyDate = this.prepareDate(state.factoryWarrantyDate.value);
        
        const currentYear = (new Date()).getFullYear();
        const colSize = this.props.isReview ? 6 : 4;
        const editCarMode = this.props.carId;
        const visible = (this.props.carId ? true : false) && this.props.status && this.props.status!.name !== WarrantyStatus.Active && this.props.status!.name !== WarrantyStatus.Approved;
        const xsProp = visible ? 10 : 12;
        var update;

        if (state.status !== null && state.status !== undefined) {
            update = WarrantyStatusChecker.AdminViewOrUpdate(state.status!.name)
        }
        else {
            update = false;
        }
        const disabled = getUserRole() === Roles.Seller || update;

        const engineTypesTranslated = {
            items: state.engineType.items.map((et: IEngineType) => ({
                label: translateText(et.name),
                value: et.id,
            })),
            value: {
                label: translateText(state.engineType.value.label),
                value: state.engineType.value.value
            }
        };

        return (<Box p={1} m={3} className={styles.Root}>
            <ValidatorForm
                ref="form"
                id={CarFormConstans.CAR_PURCHASE_FORM}
                onSubmit={() => this.handleSubmit(this.props)}
            >
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={styles.Root}
                    spacing={3}
                    style={{ marginBottom: 30 }}
                >
                    {!this.props.isReview && (
                        <Grid item xs={12} md={colSize}>
                            <div>
                                <h3>{editCarMode ? 'Pregled' : 'Unos'} Vozila</h3>
                                <div>
                                    {editCarMode ? 'Molimo ažurirajte podatke o vozilu' :
                                        'Molimo ispunite podatke o vozilu'}
                                </div>
                            </div>
                        </Grid>
                    )}
                    <Grid item container direction="column" xs={12} md={colSize} style={{ paddingBottom: 0, paddingRight: 10 }}>
                        <FormControl
                            error={state.brand.hasError}
                        >
                            <Grid item container direction="row" style={{ paddingBottom: 0 }}>
                                <Grid item direction="column" xs={xsProp} style={{ paddingBottom: 0 }}>
                                    <SelectDropdown
                                        styles={{
                                            menuPortal: base => {
                                                const { zIndex, ...rest } = base;  // remove zIndex from base by destructuring
                                                return { ...rest, zIndex: 9999 };
                                            }
                                        }}
                                        menuPortalTarget={document.body}
                                        isDisabled={this.props.isReview}
                                        inputId="brand"
                                        value={state.brand.value.label ? state.brand.value : null}
                                        TextFieldProps={{
                                            InputLabelProps: {
                                                htmlFor: 'brand',
                                                shrink: true,
                                            },
                                            error: state.brand.hasError,
                                            disabled: this.props.isReview
                                        }}
                                        placeholder="Marka"
                                        options={state.brand.items.map((b: ICarBrand) => ({
                                            label: b.name,
                                            value: b.id,
                                        }))}
                                        components={components}
                                        onChange={(el: any) => this.handleSelectChange(el, 'brand')}
                                    />
                                    {state.brand.hasError && (
                                        <FormHelperText focused={true}>
                                            Ovo polje je obavezno.
                                        </FormHelperText>
                                    )}
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('brandIsValid', !this.state.carReview.brandIsValid)}>
                                            <ReportIcon style={{ cursor: 'pointer' }} color={this.state.carReview.brandIsValid ? 'disabled' : 'error'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl
                            error={state.model.hasError}
                        >
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <SelectDropdown
                                        inputId="model"
                                        styles={{
                                            menuPortal: base => {
                                                const { zIndex, ...rest } = base;  // remove zIndex from base by destructuring
                                                return { ...rest, zIndex: 9999 };
                                            }
                                        }}
                                        menuPortalTarget={document.body}
                                        isDisabled={this.props.isReview || !state.brand.value.value}
                                        value={state.model.value.label ? state.model.value : null}
                                        TextFieldProps={{
                                            InputLabelProps: {
                                                label: 'Model',
                                                htmlFor: 'model',
                                                shrink: true,
                                            },
                                            error: state.brand.hasError,
                                            disabled: this.props.isReview
                                        }}
                                        placeholder={state.brand.value.value ? 'Model' : 'Molimo prvo odaberite marku'}
                                        options={state.model.items && state.model.items.map((m: ICarModel) => ({
                                            label: m.name,
                                            value: m.id,
                                        }))}
                                        components={components}
                                        onChange={(el: any) => this.handleSelectChange(el, 'model')}
                                    />
                                    {state.model.hasError && (
                                        <FormHelperText focused={true}>
                                            Ovo polje je obavezno.
                                        </FormHelperText>
                                    )}
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('modelIsValid', !this.state.carReview.modelIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={this.state.carReview.modelIsValid ? 'disabled' : 'error'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl
                            error={state.engineType.hasError}
                        >
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <SelectDropdown
                                        styles={{
                                            menuPortal: base => {
                                                const { zIndex, ...rest } = base;  // remove zIndex from base by destructuring
                                                return { ...rest, zIndex: 9999 };
                                            }
                                        }}
                                        menuPortalTarget={document.body}
                                        isDisabled={this.props.isReview}
                                        inputId="engineType"
                                        value={state.engineType.value.label ? engineTypesTranslated.value : null}
                                        TextFieldProps={{
                                            InputLabelProps: {
                                                htmlFor: 'engineType',
                                                shrink: true,
                                            },
                                            error: state.brand.hasError,
                                            disabled: this.props.isReview
                                        }}
                                        placeholder="Vrsta motora"
                                        options={engineTypesTranslated.items}
                                        components={components}
                                        onChange={(el: any) => this.handleSelectChange(el, 'engineType')}
                                    />
                                    {state.engineType.hasError && (
                                        <FormHelperText focused={true}>
                                            Ovo polje je obavezno.
                                        </FormHelperText>
                                    )}
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('engineTypeIsValid', !this.state.carReview.engineTypeIsValid)}>
                                            <ReportIcon style={{ cursor: 'pointer' }} color={this.state.carReview.engineTypeIsValid ? 'disabled' : 'error'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl className={styles.MarginTop} >
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <TextValidator
                                        className={styles.TextValidator}
                                        disabled={this.props.isReview}
                                        label="Snaga (kW)"
                                        onChange={this.handleChange.bind(this)}
                                        name="enginePower"
                                        type="number"
                                        value={state.enginePower.value}
                                        validators={["required", "minNumber:1", "maxNumber:200"]}
                                        errorMessages={[
                                            "Ovo polje je obavezno.",
                                            "Snaga mora biti minimalno 1.",
                                            "Snaga ne smije biti veća od 200 kW"
                                        ]}
                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('enginePowerIsValid', !this.state.carReview.enginePowerIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.enginePowerIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl >
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <TextValidator
                                        className={styles.TextValidator}
                                        disabled={this.props.isReview}
                                        label="Obujam motora (ccm)"
                                        onChange={this.handleChange.bind(this)}
                                        name="engineDisplacement"
                                        type="number"
                                        value={state.engineDisplacement.value}
                                        validators={["required", "minNumber:1", "maxNumber:3000"]}
                                        errorMessages={[
                                            "Ovo polje je obavezno.",
                                            "Obujam motora mora biti minimalno 1 ccm.",
                                            "Obujam motora mora biti manji od 3000"
                                        ]}
                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('engineDisplacementIsValid', !this.state.carReview.engineDisplacementIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.engineDisplacementIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl>
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <NumberFormat
                                        className={styles.TextValidator}
                                        customInput={TextValidator}
                                        thousandSeparator={' '}
                                        disabled={this.props.isReview}
                                        label="Prijeđeni kilometri (km)"
                                        onChange={this.handleChange.bind(this)}
                                        name="distance"
                                        value={state.distance.value}
                                        validators={["required", "minNumber:0", "isDistanceOverMax"]}
                                        errorMessages={[
                                            "Ovo polje je obavezno.",
                                            "Broj prijeđenih kilometara mora biti minimalno 0 km.",
                                            "Ne prihvaćamo aute koji su prešli više od 230 000 km."
                                        ]}
                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('distanceIsValid', !this.state.carReview.distanceIsValid)}>
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.distanceIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl >
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <TextValidator
                                        className={styles.TextValidator}
                                        disabled={this.props.isReview}
                                        label="Broj šasije"
                                        onChange={this.handleChassisChange.bind(this)}
                                        name="chassisNumber"
                                        type="text"
                                        value={state.chassisNumber.value}
                                        validators={["required", "validateChassis"]}
                                        errorMessages={["Ovo polje je obavezno.", "Broj šasije mora imati 17 znakova."]}
                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('chassisNumberIsValid', !this.state.carReview.chassisNumberIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.chassisNumberIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        {(isAdmin() || isSuperAdmin()) && <FormControl>
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <TextValidator
                                        className={styles.TextValidator}
                                        disabled={this.props.isReview}
                                        label="Napomena"
                                        onChange={this.handleChange.bind(this)}
                                        name="napomena"
                                        type="text"
                                        value={state.napomena.value}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>}
                        
                    </Grid>
                    <Grid item container direction="column" justify="flex-start" xs={12} md={colSize}>
                        <FormControl >
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <TextValidator
                                        className={styles.TextValidator}
                                        disabled={this.props.isReview}
                                        label="Masa praznog vozila (kg)"
                                        onChange={this.handleChange.bind(this)}
                                        name="maxTonnage"
                                        type="number"
                                        value={state.maxTonnage.value}
                                        validators={["required", "minNumber:1", "maxNumber:3500"]}
                                        errorMessages={[
                                            "Ovo polje je obavezno.",
                                            "Masa praznog vozila mora biti minimalno 1.",
                                            "Masa praznog vozila ne smije biti veća od 3 500 kg."
                                        ]}

                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('maxTonnageIsValid', !this.state.carReview.maxTonnageIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.maxTonnageIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl>
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <TextValidator
                                        className={styles.TextValidator}
                                        disabled={this.props.isReview}
                                        label="Godina proizvodnje"
                                        onChange={this.handleChange.bind(this)}
                                        name="manufactureYear"
                                        type="number"
                                        value={state.manufactureYear.value}
                                        validators={[
                                            "required",
                                            "minNumber:1900",
                                            "maxNumber:" + currentYear
                                        ]}
                                        errorMessages={[
                                            "Ovo polje je obavezno.",
                                            "Godina proizvodnje mora biti veća od 1900",
                                            "Godina proizvodnje ne smije biti veća od " + currentYear
                                        ]}
                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('manufactureYearIsValid', !this.state.carReview.manufactureYearIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.manufactureYearIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl>
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
                                        <KeyboardDatePicker
                                            className={styles.TextValidator}
                                            format={"dd.MM.yyyy."}
                                            margin="normal"
                                            id="mui-pickers-date"
                                            label="Datum prve registracije"
                                            name="registrationYear"
                                            minDate={DateHelper.subtractYears(new Date(), 12)}
                                            minDateMessage={"Auto ne smije biti stariji od 12 godina."}
                                            invalidDateMessage={"Molimo unesite ispravan datum."}
                                            maxDate={new Date()}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disabled={this.props.isReview}
                                            value={registrationYear}
                                            onChange={this.handleDateChange.bind(this)}
                                            onBlur=""
                                            onFocus=""
                                            ref={null}
                                            innerRef={null}
                                            rows=""
                                            rowsMax=""
                                            variant={undefined}
                                            style={undefined}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('registrationYearIsValid', !this.state.carReview.registrationYearIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.registrationYearIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl>
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <NumberFormat
                                        className={styles.TextValidator}
                                        customInput={TextValidator}
                                        thousandSeparator={' '}
                                        disabled={this.props.isReview}
                                        label="Cijena (€)"
                                        onChange={this.handleChange.bind(this)}
                                        name="price"
                                        value={state.price.value}
                                        validators={["required", "minNumber:1"]}
                                        errorMessages={[
                                            "Ovo polje je obavezno.",
                                            "Cijena mora biti veća od 0"
                                        ]}
                                    />
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('priceIsValid', !this.state.carReview.priceIsValid)}>
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.priceIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>
                        <FormControl>
                            <Grid item container direction="row">
                                <Grid item xs={12}>
                                    <AutomaticGearBoxCheckButton disabled={this.props.isReview} automatic={this.state.automaticGearBox.value} setAutomaticGearBox={(automatic: boolean) => this.setState({ automaticGearBox: { value: automatic } })} />
                                </Grid>
                            </Grid>
                        </FormControl>
                        {(isAdmin() || isSuperAdmin()) && <FormControl>
                            <Grid item container direction="row">
                                <Grid item xs={12}>
                                    <RiskyCheckButton disabled={this.props.isReview} risky={this.state.risky.value} setRisky={(risky: boolean) => this.setState({ risky: { value: risky } })} />
                                </Grid>
                            </Grid>
                        </FormControl>}
                        <FormControl>
                            <Grid item container direction="row">
                                <Grid item xs={12}>
                                    <FactoryWarrantyCheckButton disabled={this.props.isReview} hasFactoryWarranty={this.state.factoryWarrantyBool.value} setFactoryWarrantyBool={(hasFactoryWarranty: boolean) => this.setState({ factoryWarrantyBool: { value: hasFactoryWarranty } })} />
                                </Grid>
                            </Grid>
                        </FormControl>
                        {state.factoryWarrantyBool.value && <FormControl>
                            <Grid item container direction="row">
                                <Grid item direction="column" xs={xsProp}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={hrLocale}>
                                        <KeyboardDatePicker
                                            className={styles.TextValidator}
                                            format={"dd.MM.yyyy."}
                                            margin="normal"
                                            id="mui-pickers-date2"
                                            label="Datum isteka tvorničkog jamstva"
                                            name="factoryWarrantyDate"
                                            minDate={new Date()}
                                            invalidDateMessage={"Molimo unesite ispravan datum."}
                                            value={factoryWarrantyDate}
                                            onChange={this.handleDateChange.bind(this)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disabled={this.props.isReview}
                                            onBlur=""
                                            onFocus=""
                                            ref={null}
                                            innerRef={null}
                                            rows=""
                                            rowsMax=""
                                            variant={undefined}
                                            style={undefined}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {visible &&
                                    <Grid item container direction="column" xs={1} justify="center" alignItems="center">
                                        <Button disabled={disabled} onClick={() => this.changeMarked('registrationYearIsValid', !this.state.carReview.registrationYearIsValid)} >
                                            <ReportIcon style={{ cursor: 'pointer' }} color={!this.state.carReview.registrationYearIsValid ? 'error' : 'disabled'} />
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </FormControl>}
                        <FormControl>
                            <Grid item container direction="row">
                                <WarrantyDurationRadioButtons
                                    isDurationChanged={(isOneYear: boolean) => {
                                        this.setState({
                                            isOneYearWarranty: isOneYear
                                        })
                                    }}
                                    isDurationOneYear={this.state.isOneYearWarranty.value}
                                    disabled={this.props.isReview || this.props.carId !== undefined} />
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </ValidatorForm>
            {
                this.state.showChassisModal &&
                <Box>
                    <Modal open={this.state.showChassisModal} className={stylesModal.Modal} onClose={() => this.setState({ showChassisModal: false })}>
                        <Paper className={stylesModal.RootModal}>
                            <div>
                                <ChassisNumberModal closeModal={() => this.setState({ showChassisModal: false })} chassisNumber={this.state.chassisNumber.value} 
                                reportedDamages={this.props.chassisDamage && this.props.chassisDamage.noOfReportedDamages} approvedDamages={this.props.chassisDamage && this.props.chassisDamage.noOfApprovedDamages} 
                                declinedDamages={this.props.chassisDamage && this.props.chassisDamage.noOfDeclinedDamages} solvedDamages={this.props.chassisDamage && this.props.chassisDamage.noOfSolvedDamages}></ChassisNumberModal>
                            </div>
                        </Paper >
                    </Modal>
                </Box>
            }
        </Box >)
    }
}
