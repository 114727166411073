import React, { useEffect } from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import DamagesSellerTable from '../../components/tables/DamagesSellerTable';
import { GetDamagesSellerAction } from '../../redux/actions/DamageAction';
import { IDamageSellerTable } from '../../types/Damage/IDamageSellerTable';
import GoToPageAction from '../../redux/actions/GoToPageAction';

interface IStateToProps {
    damages: IDamageSellerTable[]
}

interface IDispatchToProps {
    getDamages: () => void,
    goToWarrantyReview: (warrantyId: number) => void
}

interface IProps extends IDispatchToProps, IStateToProps { }

const DamageSellerOverview: React.FC<IProps> = props => {
    useEffect(() => {
        props.getDamages();
    }, []);

    return (props.damages ? <DamagesSellerTable goToWarrantyReview={props.goToWarrantyReview} rows={props.damages} /> : null)
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    damages: state.damages.data.seller
})

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    getDamages: () => dispatch<GetDamagesSellerAction>({
        type: ActionTypes.GET_DAMAGES_SELLER
    }),
    goToWarrantyReview: (warrantyId: number) => dispatch<GoToPageAction>({
        type: ActionTypes.GO_TO_PAGE,
        payload: { url: `/warranty/${warrantyId}/step=4` }
    })

})

export default connect<IStateToProps, IDispatchToProps, IProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(DamageSellerOverview);