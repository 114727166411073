import { createStore, applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './redux/sagas';
import combinedReducers from './redux/reducers/index'
import { History } from 'history';
import { routerMiddleware } from 'react-router-redux';


export default function configureStore(history: History, context?: any) {
    const composeEnhancers = composeWithDevTools({});
    const saga = createSagaMiddleware({
        context: context,
    });
    const router = routerMiddleware(history);

    const store = createStore(
        combinedReducers,
        undefined,
        composeEnhancers(applyMiddleware( saga, router))
    );

    saga.run(rootSaga);

    return store;
};
