import React from 'react';

const AnalyticsOverview = () => {
    return (
            <div>
                <h5>AnalyticsOverview</h5>
               
            </div>
    )
}

export default AnalyticsOverview;