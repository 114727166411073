import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import { FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';

const YES = 'yes', NO = 'no';

interface IProps {
    warrantyCoversDamage: (damageCovered: boolean) => void,
    damageCovered: boolean
}

export const WarrantyRadioButtons: React.FC<IProps> = (props: IProps) => {
    const [isDamageCovered, setIsDamageCovered] = React.useState(props.damageCovered);

    useEffect(() => setIsDamageCovered(props.damageCovered), [props]);

    function handleChange(event: React.ChangeEvent<unknown>) {
        const damageCovered = (event.target as HTMLInputElement).value === YES;
        setIsDamageCovered(damageCovered);
        props.warrantyCoversDamage(damageCovered);
    }

    return (
        <FormControl component="fieldset" >
            <FormLabel component="legend">Jamstvo pokriva štetu:</FormLabel>
            <RadioGroup aria-label="position" name="position" value={isDamageCovered ? YES : NO} onChange={handleChange} row>
                <FormControlLabel
                    value="start"
                    control={<Radio
                        checked={isDamageCovered}
                        onChange={handleChange}
                        value={YES}
                        name={YES}
                    />}
                    label="DA"
                    labelPlacement="start"
                />
                <FormControlLabel
                    value="start"
                    control={<Radio
                        checked={!isDamageCovered}
                        onChange={handleChange}
                        value={NO}
                        name={NO}
                    />}
                    label="NE"
                    labelPlacement="start"
                />
            </RadioGroup>
        </FormControl>
    );
}
