import { Container, BindingScopeEnum, decorate, injectable } from 'inversify';
import StateInitializer from '../utils/StateInitializer';
import IStateInitializer from '../utils/IStateInitializer';
import Axios, { AxiosInstance } from 'axios';
import ThirdPartyTypes from './ThirdPartyTypes';
import IUserService from './../domain/services/IUserService';
import UserService from './../domain/services/UserService';
import IWaranntyService from './../domain/services/IWarrantyService';
import WarrantyService from './../domain/services/WarrantyService';
import IPackageService from '../domain/services/IPackageService';
import PackageService from '../domain/services/PackageService';
import ICarService from './../domain/services/ICarService';
import CarService from './../domain/services/CarService';
import addAuthHeader from './../utils/AuthInterceptor';
import AdminTableService from '../domain/services/AdminTableService';
import IAdminTableServices from '../domain/services/IAdminTableService';
import ISellerService from '../domain/services/ISellerService';
import SellerService from '../domain/services/SellerService';
import IDamageService from './../domain/services/IDamageService';
import DamageService from './../domain/services/DamageService';
import IBuyerService from './../domain/services/IBuyerService';
import BuyerService from './../domain/services/BuyerService';
import IStatisticsService from '../domain/services/IStatisticsService';
import StatisticsService from '../domain/services/StatisticsService';
import IInvoceService from '../domain/services/IInvoiceService';
import InvoiceService from '../domain/services/InvoiceService';
import ICurrencyService from '../domain/services/ICurrencyService';
import CurrencyService from '../domain/services/CurrencyService';
decorate(injectable(), Axios);

export default function configureContainer(): Container {
    const container = new Container({ defaultScope: BindingScopeEnum.Singleton });

    container.bind<AxiosInstance>(ThirdPartyTypes.Axios).toFactory(() => {
        const axios = Axios.create();
        addAuthHeader(axios);
        return axios;
    });

    container.bind<IStateInitializer>(StateInitializer).toSelf();
    container.bind<IUserService>(UserService).toSelf();
    container.bind<IWaranntyService>(WarrantyService).toSelf();
    container.bind<IPackageService>(PackageService).toSelf();
    container.bind<ICarService>(CarService).toSelf();
    container.bind<IAdminTableServices>(AdminTableService).toSelf();
    container.bind<ISellerService>(SellerService).toSelf();
    container.bind<IDamageService>(DamageService).toSelf();
    container.bind<IBuyerService>(BuyerService).toSelf();
    container.bind<IStatisticsService>(StatisticsService).toSelf();
    container.bind<IInvoceService>(InvoiceService).toSelf();
    container.bind<ICurrencyService>(CurrencyService).toSelf();

    return container;
};