import React, { useState, useEffect, useRef } from "react";
import { Paper, Input, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import ICarAdminTable from "../../types/ICarAdminTable";
import styles from "./carsAdminTable.module.scss";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import IFilterModel from "../../types/IFilterModel";

interface IProps {
  getCars: (page: number, pageSize: number, filters: string) => void;
  rows: ICarAdminTable[];
  totalCount: number;
  goToCarDetails: (carId: number) => void;
  goToCarWarrantyDetails: (carId: number, warrantyId: string) => void;
  deactivate: (carId: number) => void;
  activate: (carId: number) => void;
}

const CarsAdminTable: React.FC<IProps> = (props: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterArray, setFilterArray] = useState<IFilterModel[]>([
    { key: "id", value: "" },
    { key: "chassisNumber", value: "" },
    { key: "warrantyId", value: "" },
    { key: "seller", value: "" },
  ]);
  const [filters, setFilters] = useState<string>("");
  const [columnFocus, setColumnFocus] = useState<string>("");
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const a = tableRef.current as any;
      a.onQueryChange();
    }
  }, [props.rows]);

  const onRowClick = (
    event?: React.MouseEvent,
    rowData?: ICarAdminTable,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => {
    if (
      event &&
      !event.currentTarget.baseURI.endsWith("damages") &&
      !event.currentTarget.baseURI.includes("activate")
    ) {
      if (rowData!.warrantyId) {
        //props.goToCarWarrantyDetails(rowData!.id!, rowData!.warrantyId!);

        window.open(
          "/car/" +
            rowData!.id! +
            "/warranty/" +
            rowData!.warrantyId! +
            "/step=1"
        );
      } else {
        // props.goToCarDetails(rowData!.id!);

        window.open("/car/" + rowData!.id! + "/step=1");
      }
    }
  };

  const damagesButton = (id: number) => (
    <Link
      to={"/carDamage/" + id + "/damages"}
      style={{
        display: "-webkit-inline-box",
        color: "unset",
        textDecoration: "none",
      }}
    >
      <Button variant="contained" size="small">
        Pregled
      </Button>
    </Link>
  );

  const activationButton = (id: number, active: string) =>
    active == "DA" ? (
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={() => props.deactivate(id!)}
      >
        DEAKTIVIRAJ
      </Button>
    ) : (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => props.activate(id!)}
      >
        AKTIVIRAJ
      </Button>
    );

  const createFilters = () => {
    let newFilters = "";

    const carId = filterArray.find(
      (x: IFilterModel): boolean => x.key === "id"
    )!.value;
    const chassisNumber = filterArray.find(
      (x: IFilterModel): boolean => x.key === "chassisNumber"
    )!.value;
    const warrantyId = filterArray.find(
      (x: IFilterModel): boolean => x.key === "warrantyId"
    )!.value;
    const sellerName = filterArray.find(
      (x: IFilterModel): boolean => x.key === "seller"
    )!.value;

    if (carId !== "") {
      newFilters = newFilters + "CarId==" + carId;
    }

    if (chassisNumber !== "") {
      newFilters = newFilters + ",Car.ChassisNumber@=*" + chassisNumber;
    }

    if (warrantyId !== "") {
      newFilters = newFilters + ",WarrantyId==" + warrantyId;
    }

    if (sellerName !== "") {
      newFilters = newFilters + ",Seller.LegalEntityName@=*" + sellerName;
    }

    if (newFilters.substring(0, 1) === ",")
      newFilters = newFilters.substring(1);

    setFilters(newFilters);

    // if (
    //   (carId !== "" && carId.length === 5) ||
    //   (chassisNumber !== "" && chassisNumber.length === 17) ||
    //   (warrantyId !== "" && warrantyId.length === 5) ||
    //   (sellerName !== "" && sellerName.length > 2) ||
    //   (sellerName === "" &&
    //     chassisNumber === "" &&
    //     carId === "" &&
    //     warrantyId === "")
    // )
    //   props.getCars(page + 1, pageSize, newFilters);
  };

  const setFilterValue = (field: string, value: string) => {
    filterArray.find((x: IFilterModel): boolean => x.key === field)!.value =
      value;

    createFilters();
  };

  return (
    <Paper className={styles.Root}>
      <Grid
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => props.getCars(page + 1, pageSize, filters)}
          color="inherit"
          variant="contained"
        >
          TRAŽI
        </Button>
      </Grid>
      <MaterialTable
        tableRef={tableRef}
        options={{
          filtering: true,
          search: false,
          paging: true,
          initialPage: page,
          paginationType: "normal",
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        onRowClick={onRowClick}
        localization={{
          body: {
            emptyDataSourceMessage: "Nema podataka za prikaz.",
          },
          pagination: {
            previousTooltip: "Prethodna",
            nextTooltip: "Sljedeća",
            firstTooltip: "Početna",
            lastTooltip: "Posljednja",
          },
        }}
        title="Pregled svih vozila s jamstvima"
        columns={[
          { title: "ID auta", field: "id", filtering: true },
          { title: "Broj šasije", field: "chassisNumber", filtering: true },
          { title: "Jamstvo", field: "warrantyId", filtering: true },
          { title: "Trgovac", field: "seller", filtering: true },
          { title: "Ima štete", field: "damagesCount", filtering: false },
          { title: "Marka auta", field: "brand", filtering: false },
          { title: "Model auta", field: "model", filtering: false },
          { title: "Kilometri", field: "distance", filtering: false },
          {
            title: "Godina proizvodnje",
            field: "manufactureYear",
            filtering: false,
          },
          {
            title: "Rizičan",
            field: "risky",
            filtering: false,
            render: (row: ICarAdminTable) =>
              row.risky! === true ? "DA" : "NE",
          },
          { title: "Aktivan", field: "active", filtering: false },
          {
            title: "Štete",
            field: "damages",
            filtering: false,
            render: (row: ICarAdminTable) => damagesButton(row.id!),
          },
          {
            title: "Akcija",
            field: "activate",
            filtering: false,
            render: (row: ICarAdminTable) =>
              activationButton(row.id!, row.active!),
          },
        ]}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            resolve({
              data: props.rows,
              page: page,
              totalCount: props.totalCount,
            });
          })
        }
        components={{
          FilterRow: (rowProps) => {
            const { columns } = rowProps;

            return (
              <>
                <tr>
                  {columns
                    .filter((c: any): boolean => c.filtering === true)
                    .map((col: any) => {
                      if (col.field) {
                        return (
                          <td>
                            <Input
                              autoFocus={columnFocus === col.field}
                              key={col.field}
                              id={col.field}
                              value={
                                filterArray.find(
                                  (x: IFilterModel): boolean =>
                                    x.key === col.field
                                )!.value
                              }
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                              onChange={(e) => {
                                setFilterValue(col.field, e.target.value);
                                setColumnFocus(col.field);
                              }}
                            />
                          </td>
                        );
                      }
                    })}
                </tr>
              </>
            );
          },
        }}
        onChangePage={(newPage: number) => {
          setPage(newPage);
          props.getCars(newPage + 1, pageSize, filters);
        }}
        onChangeRowsPerPage={(newPageSize: number) => {
          setPageSize(newPageSize);
          props.getCars(page + 1, newPageSize, filters);
        }}
      />
    </Paper>
  );
};

export default CarsAdminTable;
