import React, { ReactNode } from 'react';
import styles from './footerLink.module.scss';

interface IProps {
    children: ReactNode,
    href?: string,
}

const FooterLink: React.SFC<IProps> = (props) => {
    return (<a href={props.href} className={styles.link}>{props.children}</a>);
}

FooterLink.defaultProps = {
    href: '#',
};

export default FooterLink;