import React, { useRef, useState, useEffect } from "react";
import { Paper, Button, Input, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import ISellerProps from "./../../types/ISellerProps";
import NotIcon from "@material-ui/icons/CancelSharp";
import YesIcon from "@material-ui/icons/CheckBox";
import styles from "./adminSellersUnactiveOverview.module.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import IFilterModel from "../../types/IFilterModel";

interface IProps {
    rows: ISellerProps[];
    goToSellerDetails: (sellerId: string) => void;
    getSellersUnactive: (page: number, pageSize: number, filters: string) => void;
    totalCount: number;
}

const AdminSellersUnactiveTable: React.FC<IProps> = (props: IProps) => {
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filterArray, setFilterArray] = useState<IFilterModel[]>([
        { key: "fullName", value: "" },
        { key: "email", value: "" },
    ]);
    const [filters, setFilters] = useState<string>("");
    const [columnFocus, setColumnFocus] = useState<string>("");
    const tableRef = useRef();

    useEffect(() => {
        if (tableRef && tableRef.current) {
            const a = tableRef.current as any;
            a.onQueryChange();
        }
    }, [props.rows]);

    const onRowClick = (
        event?: React.MouseEvent,
        rowData?: ISellerProps,
        toggleDetailPanel?: (panelIndex?: number) => void
    ) => {
        if (
            event &&
            !event.currentTarget.baseURI.endsWith("damages") &&
            !event.currentTarget.baseURI.endsWith("warranties")
        ) {

            window.open("/seller/" + rowData!.id!);
        }
    };

    const TOS = (isAccepted: boolean) => (
        <div className={styles.Icon}>
            {isAccepted ? (
                <YesIcon className={styles.Accepted} />
            ) : (
                <NotIcon className={styles.Declined} />
            )}
        </div>
    );

    const damagesButton = (id: string) => (
        <Link
            to={"/sellerDamage/" + id + "/damages"}
            style={{
                display: "-webkit-inline-box",
                color: "unset",
                textDecoration: "none",
            }}
        >
            <Button className="PREGLED" variant="contained" size="small">
                Pregled
            </Button>
        </Link>
    );

    const carsButton = (id: string) => (
        <Link
            to={"/seller/" + id + "/warranties"}
            style={{
                display: "-webkit-inline-box",
                color: "unset",
                textDecoration: "none",
            }}
        >
            <Button variant="contained" size="small">
                Pregled
            </Button>
        </Link>
    );

    const createFilters = () => {
        let newFilters = "";

        const name = filterArray.find(
            (x: IFilterModel): boolean => x.key === "fullName"
        )!.value;
        const email = filterArray.find(
            (x: IFilterModel): boolean => x.key === "email"
        )!.value;

        if (name !== "") {
            newFilters = newFilters + "FullName@=*" + name;
        }

        if (email !== "") {
            newFilters = newFilters + ",Email@=*" + email;
        }

        if (newFilters.substring(0, 1) === ",")
            newFilters = newFilters.substring(1);

        setFilters(newFilters);
    };

    const setFilterValue = (field: string, value: string) => {
        filterArray.find((x: IFilterModel): boolean => x.key === field)!.value =
            value;

        createFilters();
    };

    return (
        <Paper className={styles.Root}>
            <Grid
                style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            >
                <Button
                    onClick={() => props.getSellersUnactive(page + 1, pageSize, filters)}
                    color="inherit"
                    variant="contained"
                >
                    TRAŽI
                </Button>
            </Grid>
            <MaterialTable
                tableRef={tableRef}
                options={{
                    filtering: true,
                    search: false,
                    paging: true,
                    initialPage: page,
                    paginationType: "normal",
                    pageSize: pageSize,
                    pageSizeOptions: [5, 10, 20, 50, 100, 250],
                    rowStyle: (row: ISellerProps) => {
                        return {
                            backgroundColor: row.oznaka === true ? "#FCEAEA" : "#FFFFFFF",
                        };
                    },
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "Nema podataka za prikaz.",
                    },
                }}
                title="Pregled neaktivnih prodavača"
                onRowClick={onRowClick}
                columns={[
                    { title: "Ime trgovca", field: "fullName", filtering: true },
                    { title: "Email", field: "email", filtering: true },
                    { title: "Kontakt broj", field: "phoneNumber", filtering: false },
                    { title: "Status ugovora", field: "statusUgovora", filtering: false },
                    {
                        title: "Datum statusa ugovora",
                        field: "datumStatusaUgovora",
                        filtering: false,
                        render: (row: ISellerProps) => row.datumStatusaUgovora ?
                            moment(row.datumStatusaUgovora).format("DD.MM.YYYY") : "",
                    },
                    {
                        title: "Datum registracije",
                        field: "createdAt",
                        filtering: false,
                        render: (row: ISellerProps) => 
                            moment(row.createdAt).format("DD.MM.YYYY") ,
                        defaultSort: "desc",
                    },
                    { title: "Registrirao", field: "createdBy", filtering: false },
                    {
                        title: "Prodana vozila",
                        field: "cars",
                        filtering: false,
                        render: (row: ISellerProps) => carsButton(row.id!),
                    },
                    {
                        title: "Štete",
                        field: "damages",
                        filtering: false,
                        render: (row: ISellerProps) => damagesButton(row.id!),
                    },
                ]}
                data={(query) =>
                    new Promise(async (resolve, reject) => {
                        resolve({
                            data: props.rows,
                            page: page,
                            totalCount: props.totalCount,
                        });
                    })
                }
                components={{
                    FilterRow: (rowProps) => {
                        const { columns } = rowProps;

                        return (
                            <>
                                <tr>
                                    {columns
                                        .filter((c: any): boolean => c.filtering === true)
                                        .map((col: any) => {
                                            if (col.field) {
                                                return (
                                                    <td>
                                                        <Input
                                                            autoFocus={columnFocus === col.field}
                                                            key={col.field}
                                                            id={col.field}
                                                            value={
                                                                filterArray.find(
                                                                    (x: IFilterModel): boolean =>
                                                                        x.key === col.field
                                                                )!.value
                                                            }
                                                            style={{ marginLeft: "5px", marginRight: "5px" }}
                                                            onChange={(e) => {
                                                                setFilterValue(col.field, e.target.value);
                                                                setColumnFocus(col.field);
                                                            }}
                                                        />
                                                    </td>
                                                );
                                            }
                                        })}
                                </tr>
                            </>
                        );
                    },
                }}
                onChangePage={(newPage: number) => {
                    setPage(newPage);
                    props.getSellersUnactive(newPage + 1, pageSize, filters);
                }}
                onChangeRowsPerPage={(newPageSize: number) => {
                    setPageSize(newPageSize);
                    props.getSellersUnactive(page + 1, newPageSize, filters);
                }}
            />
        </Paper>
    );
};

export default AdminSellersUnactiveTable;
