import { Reducer } from "redux";
import ActionTypes from '../actions/ActionTypes';
import ISelectedPackage from '../../types/ISelectedPackage';
import { SaveSelectedPackageAction } from "../actions/PackagesAction";
import IPackageSelection from '../../types/IPackageSelection';
import IPackage from "../../types/IPackage";
import { getExtraPackages } from "../sagas/package";
import { startOfISOWeek } from "date-fns";

type NullablePackageSelected = ISelectedPackage | null;
type NullableExtraPackageSelected = {
    data: ISelectedPackage[]
}

const initialPackagesData: { loading: boolean, data: IPackageSelection } =
{
    loading: false,
    data: {
        packages: new Array<IPackage>(),
        extraPackages: new Array<IPackage>()
    }
}

export const packageSelectReducer: Reducer<NullablePackageSelected, SaveSelectedPackageAction> = (state: NullablePackageSelected = null, action: SaveSelectedPackageAction): NullablePackageSelected => {
    if (action.type === ActionTypes.SAVE_PACKAGE) return { ...action.payload }
    return state;
}

export const extraPackageSelectReducer: Reducer<NullableExtraPackageSelected, SaveSelectedPackageAction> = (state: NullableExtraPackageSelected = { data: [] }, action: SaveSelectedPackageAction): NullableExtraPackageSelected => {
    switch (action.type) {
        case ActionTypes.SAVE_EXTRA_PACKAGE: return {
            ...state,
            data: [...state.data.filter(item => item.id !== action.payload.id), action.payload]
        }
        case ActionTypes.REMOVE_EXTRA_PACKAGE: return {
            ...state,
            data: state.data.filter(item => item.id !== action.payload.id),
        }
        //case ActionTypes.CLEAR_SELECTED_EXTRA_PACKAGES: return {
        //    ...state,
        //    data: []
        //} 
        default: return state;
    }
}

export const getPackageReducer: Reducer<{ loading: boolean, data: IPackageSelection | {} }> = (state = initialPackagesData, action) => {
    switch (action.type) {
        case ActionTypes.GET_PACKAGES: return {
            loading: true,
            data: {
                packages: [],
                extraPackages: []

            }
        }
        case ActionTypes.GET_PACKAGES_SUCCESS: return {
            loading: false,
            data: {
                ...state.data,
                packages: action.payload,
            }
        }
        case ActionTypes.GET_EXTRA_PACKAGES: return {
            loading: true,
            data: {
                extraPackages: [],
                packages: []
            }
        }
        case ActionTypes.GET_EXTRA_PACKAGES_SUCCESS: return {
            loading: false,
            data: {
                ...state.data,
                extraPackages: action.payload,
            }
        }
        case ActionTypes.GET_PACKAGES_ERROR: return { loading: false, errors: action.payload, data: [] }
        case ActionTypes.GET_EXTRA_PACKAGES_ERROR: return { loading: false, errors: action.payload, data: [] }


        default: return state;
    }
}