import React, { useState } from 'react';
import IWarrantyStatus from '../../types/IStatus';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Button } from '@material-ui/core';
import IWarrnatyStatusFormProps from './../../types/IWarrantyStatusFormProps';
import styles from './warrantyStatusForm.module.scss';
import WarrantyStatus from '../../domain/enums/WarrantyStatus';
import { useTranslation } from 'react-i18next';
import IStatus from '../../types/IStatus';


interface IProps extends IWarrnatyStatusFormProps {
    dataChanged?: (data: IWarrnatyStatusFormProps) => void,
    warrantyHasMistakes: boolean,
    setEnabledSaveButton: (value: boolean) => void,
    setTooltipMessage: (message: string | any) => void,
    saveWarrantyStatus: () => void,
}
const WarrantyStatusForm: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const [currentData, setCurrentData] = useState({
        status: props.status,
        comment: props.comment
    });

    const [statuses, setStatuses] = useState();

    const handleChange = (element: any) => {
        const { value, name } = element.target;
        let comment = currentData.comment;
        let status = currentData.status;
        if (name === 'comment') comment = value;
        else {
            status = value;
            props.statuses!.forEach((statusItem: IWarrantyStatus) => {
                if (statusItem.id === value) status = statusItem;
            });
        }
        props.dataChanged!({ status, comment });
        setCurrentData({ ...currentData, status, comment });
    }

    const getStatuses = (): IWarrantyStatus[] | undefined => {
        if (currentData.status &&
            (
                ((currentData.status.name === WarrantyStatus.InProcess || currentData.status.name === WarrantyStatus.Declined) && props.warrantyHasMistakes)
                || (currentData.status.name === WarrantyStatus.Approved && !props.warrantyHasMistakes)
                || currentData.status.name === WarrantyStatus.Inactive || (currentData.status.name === WarrantyStatus.Active && !props.warrantyHasMistakes) || currentData.status.name === WarrantyStatus.Submitted
            )
        ) {
            props.setEnabledSaveButton(true);
        }
        else {
            props.setEnabledSaveButton(false);
            if (currentData.status && currentData.status.name === WarrantyStatus.Approved && props.warrantyHasMistakes) props.setTooltipMessage("Ne možete odobriti jamstvo ako ima pogrešaka, molimo odznačite pogreške.")
            else if (currentData.status && currentData.status.name === WarrantyStatus.Active && props.warrantyHasMistakes) props.setTooltipMessage("Ne možete aktivirati jamstvo ako ima pogrešaka, molimo odznačite pogreške.")
            else props.setTooltipMessage("Molimo označite pogrešku.")
        }
        var statuses: IWarrantyStatus[] = [];
        props.statuses! && props.statuses!.map(status => {
            if (status.name === WarrantyStatus.Active || status.name === WarrantyStatus.Inactive || status.name === WarrantyStatus.Archive) {
                statuses.push({ id: status.id, name: t(status.name) + " - NE DIRAJ" });
            }
            else statuses.push(status);
        })
        return statuses;
    }

    return (
        <Grid container justify="center" alignItems="center" className={styles.container}>
            <Grid item xs={10} justify="center" alignItems="flex-start">
                <p style={{ fontSize: 22 }}>Uredite status jamstva</p>
            </Grid>

            <Grid item xs={10} md={10}>
                <FormControl className={styles.FormControl}>
                    <InputLabel htmlFor="status">Status jamstva</InputLabel>
                    <Select
                        onChange={handleChange}
                        value={currentData.status!.id}
                        name="status">
                        {props.statuses && getStatuses()!.map(
                            (status: IWarrantyStatus) => (
                                <MenuItem value={status.id} key={status.id}>
                                    {t(status.name)}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={10} style={{marginTop: 40}}>
                <FormControl className={styles.FormControl}>
                    <TextField
                        placeholder="Komentar..."
                        name="comment"
                        value={currentData.comment}
                        onChange={handleChange}
                        multiline={true}
                        rows={3}
                        rowsMax={10}
                    />
                </FormControl>
                <Button color="primary" variant="contained" style={{ float: 'right', margin: '20px'}} onClick={props.saveWarrantyStatus} >Spremi status</Button>
            </Grid>
        </Grid>
    );
}

export default WarrantyStatusForm;