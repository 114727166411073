
import { Reducer } from 'redux';
import ActionTypes from './../actions/ActionTypes';
import { IDamageData } from '../../types/Damage/IDamageData';

export const initialDamageProps: IDamageData = {
    damage: {
        description: '', seller: undefined, buyer: undefined, status: undefined,
        manufactureYear: '', cost: null, reported: '', model: '',
        brand: '', package: '', carDistance: null, serviceHandler: ''
    },
    statuses: []
};

export const damageReducer: Reducer<IDamageData> = (state = initialDamageProps, action): IDamageData => {
    switch (action.type) {
        case ActionTypes.GET_DAMAGE: return { ...action.payload }
        case ActionTypes.GET_ACTIVE_DAMAGE: return { ...action.payload }
        case ActionTypes.GET_DAMAGE_SUCCESS: return { ...state, damage: { ...action.payload } }
        case ActionTypes.GET_ACTIVE_DAMAGE_SUCCESS: return { ...state, damage: { ...action.payload } }
        case ActionTypes.GET_DAMAGE_ERROR: return { ...state, damage: initialDamageProps.damage, errorMessage: action.payload }
        case ActionTypes.GET_ACTIVE_DAMAGE_ERROR: return { ...state, damage: initialDamageProps.damage, errorMessage: action.payload }
        case ActionTypes.GET_DAMAGE_STATUSES_SUCCESS: return { ...state, statuses: action.payload }
        case ActionTypes.GET_DAMAGE_STATUSES_ERROR: return { ...state, statuses: initialDamageProps.statuses, errorMessage: action.payload }
        case ActionTypes.GET_CHASSIS_DAMAGE: return { ...action.payload }
        case ActionTypes.GET_CHASSIS_DAMAGE_SUCCESS: return { ...state, damage: { ...action.payload } }
        case ActionTypes.GET_CHASSIS_DAMAGE_ERROR: return { ...state, damage: initialDamageProps.damage, errorMessage: action.payload }
        default: return state
    }
}


type SellerNameData = { data: string };

export const sellerNameReducer: Reducer<SellerNameData> = (state: SellerNameData = { data: '' }, action: any): SellerNameData => {
    switch (action.type) {
        case ActionTypes.GET_SELLER_NAME: return { data: '' }
        case ActionTypes.GET_SELLER_NAME_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_SELLER_NAME_ERROR: return { data: '' }

        default: return state;
    }
}


type BuyerNameData = { data: string };

export const buyerNameReducer: Reducer<BuyerNameData> = (state: BuyerNameData = { data: '' }, action: any): BuyerNameData => {
    switch (action.type) {
        case ActionTypes.GET_BUYER_NAME: return { data: '' }
        case ActionTypes.GET_BUYER_NAME_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_BUYER_NAME_ERROR: return { data: '' }

        default: return state;
    }
}


type CarNameData = { data: string };

export const carNameReducer: Reducer<CarNameData> = (state: CarNameData = { data: '' }, action: any): CarNameData => {
    switch (action.type) {
        case ActionTypes.GET_CAR_NAME: return { data: '' }
        case ActionTypes.GET_CAR_NAME_SUCCESS: return { data: action.payload }
        case ActionTypes.GET_CAR_NAME_ERROR: return { data: '' }

        default: return state;
    }
}
